import React from 'react';
import PropTypes from 'prop-types';

import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { getMatchResult, getReadyMatchId } from 'reducers';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';

const mapStateToProps = state => ({
  result: getMatchResult(state, getReadyMatchId(state)),
});

const Score = ({ result }) => (
  <div styleName='halftimestats-blur' className='flex flex-col  items-center flex-1 h-full'>
    {/* TODO: add new translation after TAPI epic */}
    {/* <h3 className='font-main--black fs-25 uppercase text-center'>Set summary</h3> */}
    {/* <h3 className='font-main fs-20 uppercase text-center'>set time: 1h 4min</h3> */}
    <div styleName='halftimestats-score' className='fs-50 font-main--black uppercase flex items-center'>
      <h2>{result.home}</h2>
      <h2 className='fs-40'>-</h2>
      <h2>{result.away}</h2>
    </div>
  </div>
);

Score.propTypes = {
  result: PropTypes.object,
};

Score.defaultProps = {
  result: {
    home: null,
    away: null,
    winner: null,
  },
};

const ScoreStyled = cssModule(Score, style);
export default connect(mapStateToProps)(ScoreStyled);
