import {
  useRef,
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
} from 'react';
import debounce from 'lodash.debounce';
import { percentageValueFromString } from '../utils/index';

export { default as useImportImageNewDesign } from 'newDesign/modules/common/hooks/ImportImage';

export function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        // eslint-disable-next-line no-console
        console.log('[why-did-you-update]', name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

export function useClientRect() {
  const [rect, setRect] = useState({ width: 0, height: 0 });
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
}

export function CaptureResize(props) {
  const [size, setSize] = useState({});

  const { captureRef } = props;
  function updateSize() {
    setSize(captureRef.current.getBoundingClientRect());
  }
  useLayoutEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.children(size);
}

export const useGetViewportSize = () => {
  const getViewportSize = () => {
    let e = window;
    let a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    const ret = {
      width: e[a + 'Width'],
      height: e[a + 'Height'],
    };
    return ret;
  };

  const [viewportSize, setViewportSize] = useState(getViewportSize());

  useEffect(() => {
    const setFromEvent = () => setViewportSize(getViewportSize());

    const debouncedSet = debounce(setFromEvent, 100, {
      leading: true,
      trailing: true,
    });

    window.addEventListener('resize', debouncedSet);

    return () => {
      window.removeEventListener('resize', debouncedSet);
    };
  }, []);

  return viewportSize;
};

export function useKey(key) {
  // Keep track of key state
  const [pressed, setPressed] = useState(false);

  // Bind and unbind events
  useEffect(() => {
    // Does an event match the key we're watching?
    const match = event => key.toLowerCase() === event.key.toLowerCase();

    // Event handlers
    const onDown = event => {
      if (match(event)) setPressed(true);
    };

    const onUp = event => {
      if (match(event)) setPressed(false);
    };

    window.addEventListener('keydown', onDown);
    window.addEventListener('keyup', onUp);
    return () => {
      window.removeEventListener('keydown', onDown);
      window.removeEventListener('keyup', onUp);
    };
  }, [key]);

  return pressed;
}


export const useKeyCount = key => {
  const [count, setCount] = useState(0);
  const keyPressed = useKey(key);

  useEffect(() => {
    if (keyPressed) {
      setCount(c => c + 1);
    }
  }, [keyPressed]);

  return count;
};


export const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => {
      window.removeEventListener(event, callback);
    };
  }, [event, callback]);
};


export const useIntervalIncrementer = timer => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(c => c + 1);
    }, timer);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return counter;
};

export const useBestStat = (homeStat, awayStat, higherIsBetter) => {
  const [best, setBest] = useState([false, false]);

  useEffect(() => {
    let homeIsBest;
    let awayIsBest;

    const hStat = percentageValueFromString(homeStat);
    const aStat = percentageValueFromString(awayStat);

    if (hStat > aStat) {
      homeIsBest = higherIsBetter;
      awayIsBest = !higherIsBetter;
    } else if (hStat < aStat) {
      homeIsBest = !higherIsBetter;
      awayIsBest = higherIsBetter;
    } else {
      homeIsBest = false;
      awayIsBest = false;
    }


    setBest([homeIsBest, awayIsBest]);
  }, [higherIsBetter, homeStat, awayStat]);

  return best;
};

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export function useIsMounted() {
  const isMountedRef = useRef(true);
  useEffect(() => () => {
    isMountedRef.current = false;
  }, []);
  return () => isMountedRef.current;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useToObject = (...args) => {
  const [value, setValue] = useState(null);

  const callback = useCallback((...callbackArgs) => {
    setValue({
      ...callbackArgs,
    });
  }, []);

  useEffect(
    () => {
      callback(...args);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, ...args],
  );


  return value;
};
