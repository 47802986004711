import React from 'react';
import { isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getMatchTable,
  getMatchId,
  getMatchTeams,
} from 'reducers';
import LeagueStandings from './LeagueStandings';

const mapStateToProps = state => ({
  table: getMatchTable(state, getMatchId(state)),
  teams: getMatchTeams(state, getMatchId(state)),
});

/**
 * Wrapper is needed to solve issue with useOnScrollHorizontal() hook
 * In some cases, there is no data for table / teams and there is no element for ref - it breaks hook and component
 */
const LeagueStandingsWrapper = ({ teams, table }) => {
  if (_isEmpty(teams) || _isEmpty(table)) {
    return null;
  }

  return <LeagueStandings teams={teams} table={table} />;
};

LeagueStandingsWrapper.propTypes = {
  teams: PropTypes.object,
  table: PropTypes.array,
};

LeagueStandingsWrapper.defaultProps = {
  teams: null,
  table: [],
};

export default connect(mapStateToProps)(LeagueStandingsWrapper);
