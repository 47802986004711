import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import ErrorBoundary from 'utils/ErrorBoundary';

import styles from 'newDesign/modules/common/components/layout/topBottomLayout/TopBottomLayout.scss';

const TopBottomLayout = ({ main, bottom }) => (
  <div styleName='TopBottomLayoutGrid'>
    <div styleName='TBLMainCol'>
      <div styleName='TBLMainTop'>
        <ErrorBoundary fallback={<div />}>
          {main}
        </ErrorBoundary>
      </div>
      <div styleName='TBLMainBottom'>
        <ErrorBoundary fallback={<div />}>
          {bottom}
        </ErrorBoundary>
      </div>
    </div>
  </div>
);

TopBottomLayout.propTypes = {
  main: PropTypes.element.isRequired,
  bottom: PropTypes.element,
};

TopBottomLayout.defaultProps = {
  bottom: null,
};

const TopBottomLayoutStyled = cssModule(TopBottomLayout, styles);

export default TopBottomLayoutStyled;
