import React from 'react';
import PropTypes from 'prop-types';

const TimelineTime = ({ time, injuryTime, hide }) => (
  <h2 className={'font-main--bold fs-20'}>{!hide && (time + (injuryTime > 0 ? '+' + injuryTime : '') + "'")}</h2>
);

TimelineTime.propTypes = {
  time: PropTypes.number,
  injuryTime: PropTypes.number,
  hide: PropTypes.bool,
};

TimelineTime.defaultProps = {
  time: 0,
  injuryTime: 0,
  hide: false,
};

export default TimelineTime;
