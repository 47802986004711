import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getMatchSportId } from 'reducers/index';
import { sport } from 'constants/enum';
import Tennis from './sports/tennis/Tennis';
import OtherSports from './sports/otherSports/OtherSports';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
});

const PreMatchCompetitors = ({ matchId, sportId, separator }) => {
  if ([sport.tennis, sport.table_tennis].includes(sportId)) {
    return <Tennis matchId={matchId} showRanking />;
  }

  return <OtherSports matchId={matchId} separator={separator} />;
};

PreMatchCompetitors.propTypes = {
  matchId: PropTypes.number.isRequired,
  sportId: PropTypes.number.isRequired,
  separator: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null])]),
};

PreMatchCompetitors.defaultProps = {
  separator: null,
};

export default connect(mapStateToProps)(PreMatchCompetitors);
