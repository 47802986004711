import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { matchStatusIds } from 'constants/enum';

import style from '../../ScoreBoard.scss';

const MatchStatus = ({ status, statusId }) => {
  const getStatusElement = (fontSizeClass, styleName) => (
    <span styleName={styleName} className={`${fontSizeClass} font-second--bold uppercase ${styleName}`}>
      {status}
    </span>
  );

  const isNotStarted = statusId === matchStatusIds.not_started;
  const isEndedOrHalftime = statusId === matchStatusIds.ended || statusId === matchStatusIds.halftime;
  const isOngoingMatch = !isNotStarted && !isEndedOrHalftime;

  return (
    <div className='flex'>
      {isNotStarted && getStatusElement('fs-20')}
      {isEndedOrHalftime && getStatusElement('fs-24')}
      {isOngoingMatch && getStatusElement('fs-16', 'scoreboard-period-active')}
    </div>
  );
};

MatchStatus.propTypes = {
  status: PropTypes.string,
  statusId: PropTypes.number,
};

MatchStatus.defaultProps = {
  status: '',
  statusId: null,
};

const MatchStatusStyled = cssModule(MatchStatus, style);
export default MatchStatusStyled;
