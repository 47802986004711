import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';
import { isNull as _isNull } from 'lodash';

import {
  getLastMatch,
  getMatchResult,
  getMatchSportId,
  getMatchStatus,
  getMatchTeamsNames,
} from 'reducers/index';
import { isEnded } from 'utils/matchstatus';
import { getThreeDigitsResultClassName } from 'newDesign/modules/common/utils/isThreeDigitsResultAvailable';

import styles from 'newDesign/modules/common/components/ticker/styles/MatchTickerStyles.scss';

const mapStateToProps = state => ({
  matchStatusId: getMatchStatus(state, getLastMatch(state)),
  result: getMatchResult(state, getLastMatch(state)),
  teamNames: getMatchTeamsNames(state, getLastMatch(state)),
  sportId: getMatchSportId(state, getLastMatch(state)),
});

/**
 * Results are displayed on the left side of the Ticker
 */
const DefaultTickerTable = ({
  matchStatusId, result, teamNames, sportId,
}) => {
  let isHomeWinner;
  let isAwayWinner;
  const threeDigitsClassName = getThreeDigitsResultClassName(sportId);

  if (isEnded(matchStatusId)) {
    isHomeWinner = result.home > result.away;
    isAwayWinner = result.away > result.home;
  }

  return (
    <Fragment>
      <div className={`${styles.matchticker__table} ${isHomeWinner ? styles.matchticker__table__game_winner : ''} flex items-center w-full box-border font-main fs-16`}>
        <span styleName={`two-digits-total-result-available ${threeDigitsClassName}`} className={'fs-20 font-main--black flex h-full items-center justify-center'}>{!_isNull(result.home) ? result.home : '-'}</span>
        <div className={`${styles.matchticker__separator} ${styles.matchticker__separator_table}`} />
        <div className={'flex flex-1 items-center'}>
          <h3 styleName='name-ellipsis' className={'fs-16 font-second--bold whitespace-nowrap uppercase'}>{teamNames.home}</h3>
        </div>
      </div>
      <div className={`${styles.matchticker__table} ${isAwayWinner ? styles.matchticker__table__game_winner : ''} flex items-center w-full box-border font-main fs-16`}>
        <span styleName={`two-digits-total-result-available ${threeDigitsClassName}`} className={'fs-20 font-main--black flex h-full items-center justify-center'}>{!_isNull(result.away) ? result.away : '-'}</span>
        <div className={`${styles.matchticker__separator} ${styles.matchticker__separator_table}`} />
        <div className={'flex flex-1 items-center'}>
          <h3 styleName='name-ellipsis' className={'fs-16 font-second--bold whitespace-nowrap uppercase'}>{teamNames.away}</h3>
        </div>
      </div>
    </Fragment>
  );
};

DefaultTickerTable.propTypes = {
  matchStatusId: PropTypes.number,
  result: PropTypes.object,
  teamNames: PropTypes.object,
  sportId: PropTypes.number.isRequired,
};

DefaultTickerTable.defaultProps = {
  matchStatusId: 0,
  result: {
    home: null,
    away: null,
    winner: null,
  },
  teamNames: {
    home: null,
    away: null,
  },
};

const DefaultTickerTableStyled = cssModule(DefaultTickerTable, styles, { allowMultiple: true });
export default connect(mapStateToProps)(DefaultTickerTableStyled);
