import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { isEnded, isInterrupted, isNotStarted } from 'utils/matchstatus';
import {
  getMatchCurPeriod,
  getMatchResult,
  getMatchStatus,
  getMatchStatusName,
  getReadyMatchId,
  getMatchSportId,
  getMatchTimeInfo,
  getScoreBreak,
  getClockBreak,
} from 'reducers';
import { matchStatusIds, sport } from 'constants/enum';
import BasketClock from 'newDesign/modules/common/components/clock/basketball/BasketClock';
import IceHockeyClock from 'newDesign/modules/common/components/clock/iceHockey/IceHockeyClock';

import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const mapStateToProps = state => ({
  statusNameTranslated: getMatchStatusName(state, getReadyMatchId(state)),
  result: getMatchResult(state, getReadyMatchId(state)),
  curPeriod: getMatchCurPeriod(state, getReadyMatchId(state)),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  statusId: getMatchStatus(state, getReadyMatchId(state)),
  timeInfo: getMatchTimeInfo(state, getReadyMatchId(state)),
  scoreBreak: getScoreBreak(state),
  clockBreak: getClockBreak(state),
});

const Score = ({
  result,
  matchStatusId,
  curPeriod,
  statusNameTranslated,
  sportId,
  statusId,
  timeInfo,
  scoreBreak,
  clockBreak,
}) => {
  const intl = useIntl();

  // TODO: implement catching of the tennis brake events.
  const onBreak = false;

  let periodInfo = curPeriod;
  let periodInfoFontSizeClass = '';

  const hasTimer = [sport.basketball, sport.ebasketball, sport.ice_hockey].includes(sportId);

  const periodInfoEndStatus = [
    matchStatusIds.AP,
    matchStatusIds.AP_hockey,
    matchStatusIds.AP_handball,
    matchStatusIds.AET,
  ].includes(matchStatusId) ? statusNameTranslated : null;

  switch (sportId) {
    case sport.basketball:
    case sport.ebasketball:
    case sport.beach_volley:
    case sport.volleyball:
    case sport.ice_hockey:
      periodInfoFontSizeClass = 'fs-24';
      break;
    case sport.table_tennis:
    case sport.tennis:
      periodInfoFontSizeClass = 'fs-28';
      break;
    default:
      periodInfoFontSizeClass = 'fs-28';
      break;
  }

  if ([sport.basketball, sport.ebasketball].includes(sportId)) {
    periodInfo = intl.formatMessage({ id: 't_general_p' }) + `${curPeriod}`;
  }

  return (
    <div styleName='scoreboard__text' className={`${!isEnded(matchStatusId) ? 'flex-col' : ''} flex-col flex-2 flex justify-center items-center`}>
      {!isNotStarted(matchStatusId) && !isEnded(matchStatusId) && !isInterrupted(matchStatusId) && (
        <div styleName='scoreboard-time' className='flex items-center'>
          <div className='flex items-center'>
            {!hasTimer && (
              <Fragment>
                <span className={'uppercase fs-40 font-second--bold'}>
                  {(result.home == null || scoreBreak) ? '-' : result.home}
                </span>
                <span className={'uppercase fs-40 font-second--bold'}>-</span>
                <span className={'uppercase fs-40 font-second--bold'}>
                  {(result.away == null || scoreBreak) ? '-' : result.away}
                </span>
              </Fragment>
            )}

            {[sport.basketball, sport.ebasketball].includes(sportId) && !clockBreak && (
              <span className='uppercase fs-24 font-second--bold'><BasketClock statusId={statusId} timeInfo={timeInfo} /></span>
            )}

            {sport.ice_hockey === sportId && !clockBreak && (
              <span className='uppercase fs-24 font-second--bold'><IceHockeyClock statusId={statusId} timeInfo={timeInfo} /></span>
            )}
          </div>

          {(!clockBreak || !hasTimer) && (
            <div styleName='scoreboard__seperator' />
          )}

          <span className={`uppercase ${onBreak ? 'fs-22' : periodInfoFontSizeClass} font-second--bold flex items-center`}>
            {/* TODO: connect with clockBreak if break for tennis will be implemented */}
            {onBreak ? intl.formatMessage({ id: 't_scoreboard_break' }) : periodInfo}
          </span>
        </div>
      )}

      {(isInterrupted(matchStatusId) || isNotStarted(matchStatusId)) && (
        <span className='uppercase fs-20 font-second--bold text-center'>
          {statusNameTranslated}
        </span>
      )}

      {isEnded(matchStatusId) && (
        <div styleName='scoreboard-time' className='flex items-center'>
          <span className='uppercase fs-20 font-second--bold flex items-center'>
            {periodInfoEndStatus || intl.formatMessage({ id: 't_general_ended' })}
          </span>
        </div>
      )}
    </div>
  );
};

Score.propTypes = {
  result: PropTypes.object,
  matchStatusId: PropTypes.number,
  curPeriod: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  statusNameTranslated: PropTypes.string,
  sportId: PropTypes.number.isRequired,
  statusId: PropTypes.number,
  timeInfo: PropTypes.object,
  scoreBreak: PropTypes.bool,
  clockBreak: PropTypes.bool,
};

Score.defaultProps = {
  result: {
    home: null,
    away: null,
    winner: null,
  },
  matchStatusId: matchStatusIds.not_started,
  curPeriod: null,
  statusNameTranslated: '',
  statusId: null,
  timeInfo: {
    played: '0',
    remaining: '0',
    running: false,
  },
  scoreBreak: false,
  clockBreak: false,
};

const ScoreStyled = cssModule(Score, style);
export default connect(mapStateToProps)(ScoreStyled);
