// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClientOrMatchIdSeparator__match_id_wrapper___2GSp8{margin:0 auto 50px;text-align:center;max-width:600px;position:relative;padding-right:10rem;padding-left:10rem}.ClientOrMatchIdSeparator__match_id___3wAvx:before{content:\"\";display:block;width:113px;height:1px;background:#585a60;left:0;top:50%;position:absolute}.ClientOrMatchIdSeparator__match_id___3wAvx:after{content:\"\";display:block;width:113px;height:1px;background:#585a60;right:0;top:50%;position:absolute}@media only screen and (min-width: 1536px){.ClientOrMatchIdSeparator__match_id_wrapper___2GSp8{font-size:1.4vw}}", ""]);
// Exports
exports.locals = {
	"match_id_wrapper": "ClientOrMatchIdSeparator__match_id_wrapper___2GSp8",
	"match_id": "ClientOrMatchIdSeparator__match_id___3wAvx"
};
module.exports = exports;
