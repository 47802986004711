/* eslint-disable max-classes-per-file */
import lcrRequest from 'lcrRequest';
import { transformAPIError } from 'utils';
import { APIError, ErrorLog } from 'utils/LoggerItem';
import {
  BETSHOP_CONFIG_MAX_AGE,
  HTTP_STATUS_CODE_PAYMENT_REQUIRED,
} from 'constants/bcms';
import { RESPONSE_HTTP_OK } from 'constants/app';
import { getOperatorUrlParams } from 'newDesign/modules/common/utils/buildOperatorUrlParams';

class BCMSFeed {
  constructor(feed, params = undefined, isOperator = 0) {
    // eslint-disable-next-line no-param-reassign
    delete params.channel;
    const reqParams = new URLSearchParams(params).toString();

    const lcrParams = isOperator ? `?${reqParams}` : '';

    if (feed === 'BetshopConfig') {
      this.url = '/feeds/betshop-config' + lcrParams;
    } else if (feed === 'NextMatches') {
      this.url = '/feeds/next-matches' + lcrParams;
    } else if (feed === 'HighlightsOfTheDay') {
      this.url = '/feeds/highlights-of-the-day' + lcrParams;
    } else if (feed === 'ChannelStageConfig') {
      const queryString = getOperatorUrlParams(params.id, 'stage', isOperator);

      this.url = queryString
        ? `/feeds/channel-stage-config?${queryString}`
        : '/feeds/channel-stage-config';
    } else if (feed === 'Translations') {
      const queryString = getOperatorUrlParams(params.id, 'lang', isOperator);

      this.url = queryString
        ? `/api/translations?${queryString}`
        : '/api/translations';
    }

    this.maxAge = 5;
  }

  getMaxAge() {
    return this.maxAge;
  }

  get() {
    return lcrRequest
      .get(this.url)
      .then(data => {
        if (data.code === HTTP_STATUS_CODE_PAYMENT_REQUIRED) {
          return data;
        }

        if (data.error) {
          throw data;
        }

        return data.doc[0];
      })
      .catch(transformAPIError);
  }

  getTranslations() {
    return lcrRequest
      .get(this.url)
      .then(data => {
        if (data.status !== RESPONSE_HTTP_OK) {
          throw data.doc[0].data.message;
        }

        return data;
      });
  }
}

export class BetshopConfigRequest extends BCMSFeed {
  constructor(userId, isOperator) {
    super('BetshopConfig', { id: userId }, isOperator);
  }

  normalizeData(response) {
    if (response.code === HTTP_STATUS_CODE_PAYMENT_REQUIRED) {
      return response;
    }

    const {
      data,
      _maxage,
    } = response;
    this.maxAge = _maxage || BETSHOP_CONFIG_MAX_AGE;

    if (!data || response.data.code === 0) {
      const { message } = response.data;
      throw new APIError(
        'BCMS API error',
        message,
      );
    }
    return {
      betshopConfig: data,
    };
  }

  get() {
    return super
      .get()
      .then(response => {
        if (response.error) {
          throw response;
        }
        return this.normalizeData(response);
      })
      .catch(error => transformAPIError(error));
  }
}

class OddsPackageMarketsRequest extends BCMSFeed {
  constructor(packageId) {
    super('OddsPackageMarkets', { id: packageId });
  }
}

export class BetshopOddsPanelSettingsRequest extends BCMSFeed {
  constructor(betshopId, sportId) {
    super('BetshopOddsPanelSettings', {
      id: betshopId,
      sport_id: sportId,
    });
  }

  exec() {
    return super
      .get()
      .then(response => {
        if (response.data.package === null) {
          return null;
        }
        const request = new OddsPackageMarketsRequest(response.data.package);
        return request.get();
      })
      .catch(() => null);
  }
}


export class NextMatchesRequest extends BCMSFeed {
  constructor(userId, channelId, isOperator) {
    super('NextMatches', {
      id: userId,
      channel: channelId,
    }, isOperator);
  }

  static normalizeData(response) {
    const normalizedMatches = response.data.map(item => {
      const {
        status,
        ts,
        showrealcategory,
        statusmsg,
        alt,
        matchid,
      } = item;

      return {
        displaySolution: item.display_solution,
        status,
        timestamp: ts,
        showRealcategory: showrealcategory,
        statusMessage: statusmsg,
        alternativeEvent: alt,
        matchId: parseInt(matchid, 10),
      };
    });
    return normalizedMatches;
  }

  get() {
    return super
      .get()
      .then(response => NextMatchesRequest.normalizeData(response))
      .catch(error => error);
  }
}

export class HighlightsOfTheDayRequest extends BCMSFeed {
  constructor(userId, channelId, isOperator) {
    super('HighlightsOfTheDay', {
      id: userId,
      channel: channelId,
    }, isOperator);
  }
}

// TODO: update after BE ready, loading of messages without modification of initial translator
export class TranslationsRequest extends BCMSFeed {
  constructor(bId, isOperator) {
    super('Translations', {
      id: bId,
    }, isOperator);
  }

  get() {
    return super
      .getTranslations()
      .then(response => response)
      .catch(error => {
        throw new ErrorLog(`Translations Request error: ${error}`);
      });
  }
}

export class ChannelStageConfigRequest extends BCMSFeed {
  constructor(userId, channelId, stageId, isOperator) {
    super('ChannelStageConfig', {
      id: userId,
      channel: channelId,
      stage: stageId,
    }, isOperator);
  }
}
