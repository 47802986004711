import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';

const GeneralStatTemplate = ({
  stat, isBetter, extra,
}) => (
  <Fragment>
    <span styleName={isBetter ? 'active' : ''} className='font-main fs-20'>{stat}</span>
    { extra && <span styleName={isBetter ? 'active' : ''} className='font-main fs-20'>{extra}</span>}
  </Fragment>

);

GeneralStatTemplate.propTypes = {
  stat: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isBetter: PropTypes.bool,
  extra: PropTypes.string,
};

GeneralStatTemplate.defaultProps = {
  isBetter: false,
  extra: '',
};

const GeneralStatTemplateStyled = cssModule(GeneralStatTemplate, style);
export default GeneralStatTemplateStyled;
