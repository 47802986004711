import React from 'react';
import cssModule from 'react-css-modules';
import PropTypes from 'prop-types';

import style from 'newDesign/modules/live/components/livePlayer/videoDivider/VideoDivider.scss';

const VideoDivider = ({
  logoUrl,
  message,
}) => {
  let messageElement = null;
  if (message && message !== '') {
    messageElement = <div styleName='message'>{message}</div>;
  }

  // TODO: animation not tested in action yet due to lack of streams
  return (
    <div styleName='separator-container' className='animate-fadeIn-0.3s'>
      <img src={logoUrl} alt='Logo' width='400' />
      {messageElement}
    </div>
  );
};

VideoDivider.propTypes = {
  logoUrl: PropTypes.string,
  message: PropTypes.string,
};

VideoDivider.defaultProps = {
  logoUrl: '',
  message: '',
};

export default cssModule(VideoDivider, style);
