// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DebugPlayer__debugPlayerLabel___3Wyw1{position:absolute;background:#111;border:2px dashed #fff;text-align:center;top:60px;right:-70px;line-height:50px;font-size:17px;height:50px;width:300px;transform:rotate(45deg);z-index:1;color:#fff;visibility:hidden}.DebugPlayer__debugPlayerContainer___J0NYP{width:200px;height:200px;position:absolute;top:0px;right:0px;z-index:1;overflow:hidden}.DebugPlayer__debugPlayerContainer___J0NYP:hover .DebugPlayer__debugPlayerLabel___3Wyw1{visibility:visible}", ""]);
// Exports
exports.locals = {
	"debugPlayerLabel": "DebugPlayer__debugPlayerLabel___3Wyw1",
	"debugPlayerContainer": "DebugPlayer__debugPlayerContainer___J0NYP"
};
module.exports = exports;
