import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import DefaultSeparator from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/defaultSeparator/DefaultSeparator';
import CompetitorAvatar from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/competitorAvatar/CompetitorAvatar';
import {
  getMatchJerseys,
  getMatchSportId,
  getMatchTeams,
} from 'reducers/index';

import style from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/PreMatchCompetitors.scss';
import { getTeamNameProperty } from 'newDesign/modules/common/utils/teamName';

const mapStateToProps = (state, ownProps) => ({
  jerseys: getMatchJerseys(state, ownProps.matchId),
  sportId: getMatchSportId(state, ownProps.matchId),
  teams: getMatchTeams(state, ownProps.matchId),
});

const OtherSports = ({
  jerseys, teams, separator, sportId,
}) => {
  const homeTeam = teams.home;
  const awayTeam = teams.away;
  const teamNameProperty = getTeamNameProperty(sportId);

  return (
    <div styleName='prematch__competitors' className={'z-10 mb-20 flex justify-center items-start w-full'}>
      <div className={'flex justify-center items-center flex-col w-2/5'}>
        <CompetitorAvatar sportId={sportId} jersey={jerseys.home.player} />
        <h2 className={'uppercase items-center flex text-center font-main--bold'}>{homeTeam[0][teamNameProperty]}</h2>
      </div>
      <div className={'flex justify-center items-center h-full align-middles max-w-[20%]'}>
        {!separator ? <DefaultSeparator /> : separator}
      </div>
      <div className={'flex justify-center items-center flex-col w-2/5'}>
        <CompetitorAvatar sportId={sportId} jersey={jerseys.away.player} />
        <h2 className={'uppercase items-center flex text-center font-main--bold'}>{awayTeam[0][teamNameProperty]}</h2>
      </div>
    </div>
  );
};

OtherSports.propTypes = {
  jerseys: PropTypes.object,
  sportId: PropTypes.number.isRequired,
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
  separator: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null])]),
};

OtherSports.defaultProps = {
  jerseys: {
    away: {
      player: {},
    },
    home: {
      player: {},
    },
  },
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
  separator: null,
};

const SoccerStyled = cssModule(OtherSports, style);
export default connect(mapStateToProps)(SoccerStyled);
