import React from 'react';
import cssModule from 'react-css-modules';
import { useImportImageNewDesign } from 'hooks/index';
import style from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/PreMatchCompetitors.scss';

const PlayerIcon = () => {
  const controllerIcon = useImportImageNewDesign('general/esoccer.svg');

  return (
    <img styleName={'icon-controller'} className={'mb-6 max-w-[120px]'} src={controllerIcon} alt={''} />
  );
};

const PlayerIconStyled = cssModule(PlayerIcon, style);

export default PlayerIconStyled;
