// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TeamNames__teamNamesWrapper___D1viB{width:24vw;overflow:hidden}.TeamNames__teamNamesWrapper___D1viB .TeamNames__teamNames___3kNtX{overflow:hidden;white-space:nowrap}.TeamNames__teamNamesWrapper___D1viB .TeamNames__competitor___Pw41w{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}", ""]);
// Exports
exports.locals = {
	"teamNamesWrapper": "TeamNames__teamNamesWrapper___D1viB",
	"teamNames": "TeamNames__teamNames___3kNtX",
	"competitor": "TeamNames__competitor___Pw41w"
};
module.exports = exports;
