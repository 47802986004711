// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FooterBasketballThreeOnThree__slider-container___3l9ZH{position:relative;--animation-duration: 80s;--fade-duration: 1s;--total-slides: 4}.FooterBasketballThreeOnThree__slider___2rrAK{top:0;left:0;position:absolute;width:100%;min-height:100%;overflow:hidden}.FooterBasketballThreeOnThree__slide___uezcB{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0;animation:FooterBasketballThreeOnThree__fadeInOut___8EPsF var(--animation-duration) infinite;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.FooterBasketballThreeOnThree__slide___uezcB img{position:absolute;top:0;left:0;width:100%;height:auto;display:block}@keyframes FooterBasketballThreeOnThree__fadeInOut___8EPsF{0%,100%{opacity:0}5%,20%{opacity:1}25%,90%{opacity:0}}.FooterBasketballThreeOnThree__slide___uezcB:nth-child(1){animation-delay:0s}.FooterBasketballThreeOnThree__slide___uezcB:nth-child(2){animation-delay:calc(var(--animation-duration)/var(--total-slides))}.FooterBasketballThreeOnThree__slide___uezcB:nth-child(3){animation-delay:calc(2*var(--animation-duration)/var(--total-slides))}.FooterBasketballThreeOnThree__slide___uezcB:nth-child(4){animation-delay:calc(3*var(--animation-duration)/var(--total-slides))}", ""]);
// Exports
exports.locals = {
	"slider-container": "FooterBasketballThreeOnThree__slider-container___3l9ZH",
	"slider": "FooterBasketballThreeOnThree__slider___2rrAK",
	"slide": "FooterBasketballThreeOnThree__slide___uezcB",
	"fadeInOut": "FooterBasketballThreeOnThree__fadeInOut___8EPsF"
};
module.exports = exports;
