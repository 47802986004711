// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoggerMessage__message___3kHR_{padding:10px;text-shadow:0px 0px 8px #000}.LoggerMessage__errorMessage___2ix32{background:rgba(210,71,71,.8)}.LoggerMessage__infoMessage___2ztvP{background:rgba(132,209,249,.8)}", ""]);
// Exports
exports.locals = {
	"message": "LoggerMessage__message___3kHR_",
	"errorMessage": "LoggerMessage__errorMessage___2ix32 LoggerMessage__message___3kHR_",
	"infoMessage": "LoggerMessage__infoMessage___2ztvP LoggerMessage__message___3kHR_"
};
module.exports = exports;
