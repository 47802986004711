import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash';

import MatchTeams from 'newDesign/modules/announcement/components/matchTeams/MatchTeams';
import MatchOdds from 'newDesign/modules/announcement/components/matchOdds/MatchOdds';
import SportInfoCmp from 'newDesign/modules/announcement/components/sportInfoCmp/SportInfo';
import TournamentInfo from 'newDesign/modules/announcement/components/tournamentInfo/TournamentInfo';
import {
  getMatchStatus,
  getNextMatchStatus,
  getShowOdds,
  getBgColor,
  getMatchTennisDouble,
} from 'reducers';
import darken from 'newDesign/modules/common/utils/darken';
import { twoOrThreeWay } from 'constants/enum';

import style from 'newDesign/modules/announcement/components/match/Match.scss';

const mapStateToProps = (state, ownProps) => ({
  matchStatus: getMatchStatus(state, ownProps.matchId),
  matchBCMSStatus: getNextMatchStatus(state, ownProps.matchId),
  showOdds: getShowOdds(state),
  bgColor: getBgColor(state),
  tennisDouble: getMatchTennisDouble(state, ownProps.matchId),
});

const Match = ({
  matchId, bgColor, matchBCMSStatus, matchStatus, showOdds, tennisDouble,
}) => {
  const linearGradientStyles = {};

  if (matchBCMSStatus) {
    const delayGradientColorRgbaOne = darken(bgColor, 36, 'rgba', 0);
    const delayGradientColorRgbaTwo = darken(bgColor, 28, 'rgba', 1);
    const delayGradientColorRgbaThree = darken(bgColor, 5, 'rgba', 1);

    linearGradientStyles.background = `linear-gradient(90deg, 
      ${delayGradientColorRgbaOne} 0%, 
      ${delayGradientColorRgbaTwo} 25%, 
      ${delayGradientColorRgbaThree} 50%, 
      ${delayGradientColorRgbaTwo} 75%, 
      ${delayGradientColorRgbaOne} 100%)`;
  }

  return (
    <div className={`event ${!_isEmpty(tennisDouble) && style.event__doubles} my-5`}>
      <div
        styleName='event__meta'
        className={`${matchBCMSStatus ? 'event__meta--delay' : ''} flex justify-center items-stretch`}
        style={linearGradientStyles}
      >
        <SportInfoCmp matchId={matchId} />
      </div>
      <MatchTeams matchId={matchId} />
      <div styleName='event__meta' className='flex justify-center items-stretch gap-x-20 fs-17'>
        <TournamentInfo matchId={matchId} />
        {showOdds ? (
          <MatchOdds
            matchStatus={matchStatus}
            matchId={matchId}
            marketIds={twoOrThreeWay}
            maxMarkets={1}
            useFeedOutcomes
          />
        ) : null}
      </div>
    </div>
  );
};

Match.propTypes = {
  showOdds: PropTypes.bool.isRequired,
  matchStatus: PropTypes.number,
  matchBCMSStatus: PropTypes.string,
  matchId: PropTypes.number.isRequired,
  bgColor: PropTypes.string.isRequired,
  tennisDouble: PropTypes.object,
};

Match.defaultProps = {
  matchStatus: null,
  matchBCMSStatus: null,
  tennisDouble: {},
};

const MatchStyled = cssModule(Match, style);
export default connect(mapStateToProps)(MatchStyled);
