import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';

const CardStatTemplate = ({
  stat, cardStyleName,
}) => (
  <div styleName='halftimestats-cards' className='flex'>
    <svg
      styleName={`card ${cardStyleName}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 100 100'
      style={{ display: 'block' }}
    >
      <path
        d='M23.2,0.3h54c5,0,9,4,9,9v81c0,5-4,9-9,9h-54c-5,0-9-4-9-9v-81C14.2,4.4,18.2,0.3,23.2,0.3z'
        fill='currentColor'
      />
    </svg>
    <span className='font-main fs-20'>{stat}</span>
  </div>
);

CardStatTemplate.propTypes = {
  stat: PropTypes.number.isRequired,
  cardStyleName: PropTypes.string.isRequired,
};

const CardStatTemplateStyled = cssModule(CardStatTemplate, style, { allowMultiple: true });
export default CardStatTemplateStyled;
