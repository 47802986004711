import React, { useEffect } from 'react';
import { getChannelTrailerCachedUrl } from 'reducers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setStaticVideoEnded, setStaticVideoPlaying } from 'actions/stages';
import PropTypes from 'prop-types';
import LivePlayer from 'components/LivePlayer/LivePlayer';

const mapStateToProps = state => ({
  channelTrailerUrl: getChannelTrailerCachedUrl(state),
});

const mapDispatchToProps = dispatch => ({
  channelTrailerEnded: bindActionCreators(setStaticVideoEnded, dispatch),
  channelTrailerPlaying: bindActionCreators(setStaticVideoPlaying, dispatch),
});

export const ChannelTrailer = ({
  channelTrailerUrl,
  channelTrailerPlaying,
  channelTrailerEnded,
}) => {
  useEffect(() => {
    if (!channelTrailerUrl) {
      channelTrailerEnded();
    }
  }, [channelTrailerEnded, channelTrailerUrl]);

  if (!channelTrailerUrl) {
    return null;
  }

  return (
    <LivePlayer
      streams={[
        {
          url: channelTrailerUrl,
          type: 'video/mp4',
        },
      ]}
      playing={channelTrailerPlaying}
      ended={channelTrailerEnded}
      errorCallback={channelTrailerEnded}
    />
  );
};
ChannelTrailer.propTypes = {
  channelTrailerUrl: PropTypes.string,
  channelTrailerEnded: PropTypes.func,
  channelTrailerPlaying: PropTypes.func,

};

ChannelTrailer.defaultProps = {
  channelTrailerUrl: null,
  channelTrailerEnded: () => null,
  channelTrailerPlaying: () => null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChannelTrailer);
