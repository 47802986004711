const bsTranslations = {
  goldenpalace: {
    fr: 'Pariez maintenant sur',
    nl: 'Wed nu op',
  },
  coderespain: {
    es: 'Apuesta ahora por',
  },
  lukiaes: {
    es: 'Apuesta ahora a',
  },
  orenes: {
    es: 'Apuesta ahora a',
  },
  tipico: {
    de: 'Wette jetzt auf',
  },
  caspiantechuof: {
    aze: 'İndi mərc et',
  },
  boviktest: {
    no: 'sats nååå',
  },
};

export default bsTranslations;
