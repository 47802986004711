import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import style from 'styles/components/BettingSuggestions/BettingSuggestions.scss';
import { getCallToAction } from 'localization/index';
import { LANG_EN } from 'constants/app';
import BSOdds from './BSOdds';

const Event = ({
  latest, matchId, secondaryColor, setHasOdds, lang, srAlias,
}) => {
  let eventString = '';
  if (latest && latest.name) {
    eventString = `${latest.name} - `;
  }

  return (
    <div styleName='event'>
      <div styleName='eventHeader'>
        <span>
          {eventString}
        </span>
        <span
          styleName='callToAction'
          style={{
            color: secondaryColor,
          }}
        >
          {getCallToAction(srAlias, lang)}
        </span>
      </div>
      <BSOdds
        setHasOdds={setHasOdds}
        matchId={matchId}
        maxMarkets={16}
        secondaryColor={secondaryColor}
      />
    </div>
  );
};

Event.propTypes = {
  matchId: PropTypes.number,
  secondaryColor: PropTypes.string,
  latest: PropTypes.shape(),
  setHasOdds: PropTypes.func,
  srAlias: PropTypes.string,
  lang: PropTypes.string,
};

Event.defaultProps = {
  matchId: 0,
  secondaryColor: '#FFFFFF',
  latest: null,
  setHasOdds: () => null,
  srAlias: '',
  lang: LANG_EN,
};

export default cssModule(Event, style);
