import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchId,
  getMatchHomeAttStrength,
  getMatchAwayAttStrength,
} from 'reducers';
import StrengthBar from 'newDesign/modules/live/components/footer/footerBottom/strengthBar/StrengthBar';
import NumberBarStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberBarStatSlide';

const mapStateToProps = state => ({
  homeStat: getMatchHomeAttStrength(state, getMatchId(state)),
  awayStat: getMatchAwayAttStrength(state, getMatchId(state)),
});

const AttStrength = ({ homeStat, awayStat }) => {
  const intl = useIntl();

  const home = <StrengthBar percentage={homeStat} />;
  const away = <StrengthBar percentage={awayStat} />;

  return (
    <NumberBarStatSlide
      statText={intl.formatMessage({ id: 't_footer_att_strength' })}
      homeStat={home}
      awayStat={away}
      homeStatText={homeStat + '%'}
      awayStatText={awayStat + '%'}
      higherIsBetter
    />
  );
};

AttStrength.propTypes = {
  homeStat: PropTypes.number,
  awayStat: PropTypes.number,
};

AttStrength.defaultProps = {
  homeStat: null,
  awayStat: null,
};

export default connect(mapStateToProps)(AttStrength);
