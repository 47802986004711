// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TennisDouble__double_flags___1Y_LM img{max-width:120px;max-height:100px}.TennisDouble__double_flags___1Y_LM img:first-child{margin-right:5px}.TennisDouble__double_flags___1Y_LM img:last-child{margin-left:5px}", ""]);
// Exports
exports.locals = {
	"double_flags": "TennisDouble__double_flags___1Y_LM"
};
module.exports = exports;
