import React, { useRef } from 'react';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import useOnScrollHorizontal from 'newDesign/modules/common/hooks/OnScrollHorizontal';
import { SEC } from 'constants/app';

import style from '../FooterCrawler.scss';


const LeagueStandings = ({ teams, table }) => {
  const scrollEndRef = useRef(null);
  const scrollPosition = useOnScrollHorizontal(scrollEndRef, 3, 60 * SEC);
  const crawlerDivRef = useRef(null);
  const currentMatchTeams = [teams.home[0].name, teams.away[0].name];

  /**
 * Calculate width because TV screen has different resolution and crawler container on the bottom as well
 * We need to move position of the text to the right, depending on the width of container
 * Text needs to start scrolling from the right and move to the left, initially text is invisible
 */
  const widthCrawlerContainer = _get(crawlerDivRef, 'current.clientWidth', 0);

  /**
 * Start position from the right side and add width of container and + 100px for initial padding
 * @type {number}
 */
  const marginLeft = -scrollPosition + widthCrawlerContainer + 100;

  return (
    <div
      ref={crawlerDivRef}
      styleName='textMarquee'
      className='flex flex-1 relative'
      style={{ 'left': marginLeft + 'px' }}
    >
      {table.map(tableTeam => {
        const isCurrentMatchTeam = currentMatchTeams.includes(tableTeam.name);

        return (
          <div className='font-second fs-16 uppercase flex items-center' key={tableTeam.name}>
            <span styleName={`position ${isCurrentMatchTeam ? 'active' : ''}`}>{'#' + tableTeam.pos}</span>
            <span styleName={isCurrentMatchTeam ? 'active' : ''}>{tableTeam.name.toUpperCase()}</span>
            <span>{tableTeam.pts + 'pts'}</span>
          </div>
        );
      })}
      <div style={{ 'marginLeft': widthCrawlerContainer + 'px' }} ref={scrollEndRef} />
    </div>
  );
};

LeagueStandings.propTypes = {
  teams: PropTypes.object.isRequired,
  table: PropTypes.array.isRequired,
};

const LeagueStandingsStyled = cssModule(LeagueStandings, style, { allowMultiple: true });
export default LeagueStandingsStyled;
