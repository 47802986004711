import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchId,
  getMatchHomeStatsMatchOU,
  getMatchAwayStatsMatchOU,
} from 'reducers';
import NumberStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberStatSlide';

const mapStateToProps = state => ({
  homeStat: getMatchHomeStatsMatchOU(state, getMatchId(state)),
  awayStat: getMatchAwayStatsMatchOU(state, getMatchId(state)),
});

const SetsPerMatch = ({
  homeStat, awayStat,
}) => {
  const intl = useIntl();

  let homeRounded;
  let awayRounded;

  if (homeStat && homeStat.pointspermatch) {
    homeRounded = homeStat.setspermatch;
    awayRounded = awayStat.setspermatch;
  }

  const translatedPhrase = intl.formatMessage({ id: 't_footer_sets_per_match' });

  return (
    <NumberStatSlide
      statText={intl.formatMessage({ id: 't_footer_score_action_prefix' }, { action: translatedPhrase })}
      homeStat={homeRounded}
      awayStat={awayRounded}
      higherIsBetter
    />
  );
};

SetsPerMatch.propTypes = {
  homeStat: PropTypes.object,
  awayStat: PropTypes.object,
};

SetsPerMatch.defaultProps = {
  homeStat: null,
  awayStat: null,
};

export default connect(mapStateToProps)(SetsPerMatch);
