import React from 'react';
import cssModule from 'react-css-modules';
import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';

import style from 'newDesign/modules/announcement/components/tomorrowMatchLabel/TomorrowMatchLabel.scss';

const TomorrowMatchLabel = () => (
  <div styleName='tomorrow-block' className='justify-center flex'>
    <div className='flex items-center min-w-[25%]'>
      <div styleName='line' className='w-full' />
      <h3 className='font-second--bold uppercase mx-9'><TranslationFormattedMessage id='t_announcement_tomorrow' /></h3>
      <div styleName='line' className='w-full' />
    </div>
  </div>
);

const TomorrowMatchLabelStyled = cssModule(TomorrowMatchLabel, style);
export default TomorrowMatchLabelStyled;
