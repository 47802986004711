// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VideoDivider__separator-container___1qSCZ{height:auto;width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;-ms-flex-direction:column;flex-direction:column;transform:translateY(-50%);top:50%;position:absolute}.VideoDivider__message___3W84d{text-transform:uppercase;margin:20px;font-size:24px}", ""]);
// Exports
exports.locals = {
	"separator-container": "VideoDivider__separator-container___1qSCZ",
	"message": "VideoDivider__message___3W84d"
};
module.exports = exports;
