import { useState, useEffect } from 'react';
import { matchStatusIds } from '../constants/enum';
import { useCountdown } from './Timers';


const useSuspensionClock = (
  running,
  played,
  suspensionTime,
  statusId,
) => {
  const [first, setFirst] = useState(true);


  const {
    elapsedTime,
    setTimer,
    pauseTimer,
    resumeTimer,
  } = useCountdown();


  useEffect(() => {
    if (played) {
      setFirst(false);
      const playedPar = parseInt(played, 10);
      const timeRemaining = suspensionTime - playedPar;

      if (timeRemaining <= 0) {
        setTimer(0);
      } else {
        setTimer(timeRemaining);
      }
    }
  }, [played, setTimer, suspensionTime]);

  useEffect(() => {
    if (!running) {
      pauseTimer();
    } else if (statusId !== matchStatusIds.pause) {
      resumeTimer();
    }
  }, [pauseTimer, resumeTimer, running, statusId]);


  if (first) {
    return '';
  } else if (elapsedTime === '00:00') {
    return '';
  } else {
    return elapsedTime;
  }
};

export default useSuspensionClock;
