import { useRef, useState, useEffect } from 'react';
import delay from 'newDesign/modules/common/utils/delay';
import useOnScreen from './OnScreen';

/**
 * Horizontal scroll
 * @param elementRef Element that needs to be visible to reset scroll
 * @param stepPixels Number of pixels for each step
 * @param delayTime Number of delay before scrolling in ms (1000 is 1 sec)
 * @return {number}
 */
export default function useOnScrollHorizontal(elementRef, stepPixels, delayTime) {
  const [left, setLeft] = useState(0);
  const [scrolling, setScrolling] = useState(false);

  const isVisible = useOnScreen(elementRef);
  const isVisibleRef = useRef(false);
  isVisibleRef.current = isVisible;

  useEffect(() => {
    async function resetTablePosition() {
      await delay(delayTime);
      setLeft(0);
      setScrolling(false);
    }
    async function scrollTable() {
      // to avoid that setInterval is executed multiple times
      setScrolling(true);
      await delay(delayTime);

      // If element is invisible, scroll down until element become visible
      // Afterward, wait DELAY time, reset position to 0, wait again and scroll again
      const intervalPosition = setInterval(() => {
        if (!isVisibleRef.current) {
          return setLeft(currentLeft => currentLeft + stepPixels);
        }

        clearInterval(intervalPosition);
        resetTablePosition();

        return () => {};
      }, 20);
    }

    if (!scrolling) {
      scrollTable();
    }

    return () => {};
  }, [left, setLeft, scrolling, delayTime, stepPixels]);

  return left;
}
