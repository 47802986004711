const getTimelineEventProps = (event, key, teamNames) => {
  const teamName = event.team === 'home' ? teamNames.home : teamNames.away;

  return {
    team: event.team,
    text: event[key] && event[key].name
      ? event[key].name
      : teamName,
    secondaryText: (event[key] && event[key].position && event[key].position.name)
      ? event[key].position.name
      : undefined,
    secondaryShort: (event[key] && event[key].position && event[key].position.shortname)
      ? '(' + event[key].position.shortname + ')'
      : undefined,

  };
};

export default getTimelineEventProps;
