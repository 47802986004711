/* eslint-disable react/display-name */
import React, {
  useState,
  useEffect,
  useRef,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import LoadingSpinnerCpm from 'newDesign/modules/common/components/loadingSpinner/LoadingSpinner';
import { getIsSirLoaded } from 'reducers';
import {
  LAYOUT_FRAME,
  LAYOUT_FULLSCREEN,
  LAYOUT_MAINSIDEBAR,
} from 'constants/bcms';
import { DOMRectToObject } from 'utils/index';
import LMTConfig from 'constants/LMT';
import { useGetViewportSize } from 'hooks/index';

import style from 'newDesign/modules/live/components/LMT/LMT.scss';

const mapStateToProps = state => ({
  SIR: getIsSirLoaded(state),
});

const LMT = ({
  name, SIR, layout, options = {},
}) => {
  const [uniqueKey] = useState(
    Math.trunc(Math.random() * 100000000),
  );
  const [rect, setRect] = useState({ height: 0, width: 0 });
  const vp = useGetViewportSize();
  const ref = useRef(null);

  useEffect(() => {
    let tmpRect = DOMRectToObject(ref.current);

    let calcW;
    if (layout === LAYOUT_FULLSCREEN || layout === LAYOUT_MAINSIDEBAR) {
      // (viewport height - lmt footer) * aspect ratio of lmt component
      // TODO: MVP improvements ticket?
      calcW = (vp.height - 28) * 1.61;
    } else {
      // TODO: MVP improvements ticket?
      // (viewport height - scoreboard height - padding - lmt footer) * aspect ratio of lmt component
      // calcW = (vp.height - 236 - 28 - 20) * 1.61;
      calcW = (vp.height - ((vp.height / 100) * 26.8)) * 1.61;
    }

    if (calcW > tmpRect.width) {
      calcW = tmpRect.width;
    }

    tmpRect = {
      ...tmpRect,
      calcWidth: calcW,
    };
    setRect(tmpRect);
  }, [vp, layout]);

  useEffect(() => {
    if (SIR) {
      window.SIR('addWidget', '#sir-' + uniqueKey, name, options);
    }
  }, [SIR, uniqueKey, name, options]);

  let heightAdjustment;
  // 0.75 represents height (including paddings and margins) of all elements visible on the screen except lmt component
  if (vp) {
    if (layout === LAYOUT_FULLSCREEN || layout === LAYOUT_MAINSIDEBAR) {
      heightAdjustment = (vp.height - 28) * 1.61;
    } else {
      heightAdjustment = vp.height * 0.75 - 20;
    }
  }

  return (
    <div
      ref={ref}
      styleName='LMTContainer'
      className='LMTContainer'
    >
      <div
        id={'sir-' + uniqueKey}
        styleName='LMT'
        style={{
          width: rect && rect.calcWidth ? rect.calcWidth : 0,
          height: heightAdjustment,
        }}
      >
        <LoadingSpinnerCpm />
      </div>
    </div>
  );
};

LMT.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.shape(),
  SIR: PropTypes.bool,
  layout: PropTypes.number,

};

LMT.defaultProps = {
  name: '',
  options: null,
  SIR: false,
  layout: LAYOUT_FRAME,
};

const LMTStyled = cssModule(LMT, style);

const LMTConnected = connect(
  mapStateToProps,
)(LMTStyled);


// eslint-disable-next-line react/prop-types
const LMTMemo = memo(({ id, layoutProp, logoUrl }) => (
  <LMTConnected
    name='match.lmtPlus'
    layout={layoutProp}
    options={{
      ...LMTConfig, // import static config
      matchId: id,
      logo: [logoUrl],
      goalBannerImage: logoUrl,
      pitchLogo: logoUrl,
    }}
  />
));

export default LMTMemo;
