import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import React from 'react';

import Jersey from 'newDesign/modules/common/components/jersey/Jersey';

import {
  getMatchSportId, getMatchStatus,
  getReadyMatchId,
} from 'reducers';

import { connect } from 'react-redux';
import { matchStatusIds, sport, jerseyTypeNoSleeves } from 'constants/enum';
import { isNotStarted } from 'utils/matchstatus';
import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
});

const TeamWithJersey = ({
  team,
  jersey,
  sportId,
  matchStatusId,
}) => {
  let jerseySize = 'jersey_auto_period_sport';

  if ([sport.basketball, sport.ebasketball, sport.basketball3x3].includes(sportId)
    || jersey.type === jerseyTypeNoSleeves) {
    jerseySize = 'jersey_auto_no_sleeves';
  }

  return (
    <div className='flex flex-col flex-1 justify-center items-center overflow-hidden whitespace-nowrap'>
      <div className='flex flex-1 justify-center items-center overflow-hidden whitespace-nowrap gap-3'>
        <Jersey className={'h-full'} jerseySize={jerseySize} jersey={jersey} sportId={sportId} />
      </div>

      {!isNotStarted(matchStatusId) && (
        <div className='mb-4' />
      )}

      {isNotStarted(matchStatusId) && (
        <div className='flex flex-col max-w-full'>
          <h2 className='fs-18 font-second--bold max-w-full text-ellipsis overflow-hidden'>
            {team[0].abbr ? team[0].abbr : team[0].name}
          </h2>
        </div>
      )}
    </div>
  );
};

TeamWithJersey.propTypes = {
  team: PropTypes.array.isRequired,
  jersey: PropTypes.object,
  sportId: PropTypes.number.isRequired,
  matchStatusId: PropTypes.number,
};

TeamWithJersey.defaultProps = {
  matchStatusId: matchStatusIds.not_started,
  jersey: null,
};

const TeamWithJerseyStyled = cssModule(TeamWithJersey, style);
export default connect(mapStateToProps)(TeamWithJerseyStyled);
