import React, { useState } from 'react';
import cssModule from 'react-css-modules';
import style from 'styles/components/BettingSuggestions/BettingSuggestions.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getBSLatest, getBSVisibility, getClientAlias, getColor, getLanguage,
} from 'reducers/index';
import { LANG_EN, PRIMARY_COLOR_IDX } from 'constants/app';
import { useKeyCount } from 'hooks/index';
import Event from './Event';

const mapStateToProps = state => ({
  latest: getBSLatest(state),
  show: getBSVisibility(state),
  secondaryColor: getColor(state, PRIMARY_COLOR_IDX),
  srAlias: getClientAlias(state),
  lang: getLanguage(state),
});

const BettingSuggestions = ({
  matchId, secondaryColor, latest, show, lang, srAlias,
}) => {
  const [hasOdds, setHasOdds] = useState(false);
  const count = useKeyCount('b');
  const keyToggle = (count % 2 !== 0);

  return (
    <div
      styleName='bettingSuggestions'
      style={{
        display: keyToggle || (show && hasOdds) ? 'flex' : 'none',
      }}
    >
      {
        (keyToggle || show) && (
          <Event
            latest={latest}
            matchId={matchId}
            secondaryColor={secondaryColor}
            setHasOdds={setHasOdds}
            lang={lang}
            srAlias={srAlias}
          />
        )
      }
    </div>
  );
};

BettingSuggestions.propTypes = {
  matchId: PropTypes.number,
  secondaryColor: PropTypes.string,
  latest: PropTypes.shape(),
  show: PropTypes.bool,
  srAlias: PropTypes.string,
  lang: PropTypes.string,
};

BettingSuggestions.defaultProps = {
  matchId: 0,
  secondaryColor: '#FFFFFF',
  latest: null,
  show: false,
  srAlias: '',
  lang: LANG_EN,
};

const BettingSuggestionsStyled = cssModule(BettingSuggestions, style);
export default connect(
  mapStateToProps,
)(BettingSuggestionsStyled);
