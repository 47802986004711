import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';
import { isEmpty as _isEmpty } from 'lodash';

import { eSports } from 'constants/enum';
import { getMatchSportId, getMatchTennisDouble } from 'reducers';

import style from 'newDesign/modules/announcement/components/matchTeam/MatchTeam.scss';
import MatchTeamTennisDouble from './MatchTeamTennisDouble';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
  tennisDouble: getMatchTennisDouble(state, ownProps.matchId),
});

const MatchTeam = ({ teams, sportId, tennisDouble }) => {
  if (!_isEmpty(tennisDouble)) {
    return (
      <MatchTeamTennisDouble teams={teams} />
    );
  }

  return (
    <div styleName='team' className='flex flex-1 items-center'>
      <h2 styleName='team-name' className={`flex-nowrap font-bold uppercase fs-20 ${style.team_name}`}>
        <span>{eSports.includes(sportId) ? teams[0].mediumname : teams[0].name}</span>
      </h2>
    </div>
  );
};

MatchTeam.propTypes = {
  teams: PropTypes.array.isRequired,
  sportId: PropTypes.number.isRequired,
  tennisDouble: PropTypes.object.isRequired,
};

const MatchTeamStyled = cssModule(MatchTeam, style);
export default connect(mapStateToProps)(MatchTeamStyled);
