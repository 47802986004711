import React from 'react';
import style from 'styles/widgets/IssuesStage.scss';
import cssModule from 'react-css-modules';
import { getLogoUrl } from 'reducers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Logo from 'views/widgets/Logo';

const mapStateToProps = state => ({
  logoUrl: getLogoUrl(state),
});

const ExpirationStage = ({ logoUrl }) => (
  <div styleName='container'>
    <div styleName='messageBox'>
      <Logo logoUrl={logoUrl} />
      <div styleName='message'>
        <div styleName='title'>
          License expired
        </div>
        <div styleName='messageBody'>
          Your license has expired, please contact your sales representative or{' '}
          <a href='mailto:sales@betradar.com'>sales@betradar.com</a>
        </div>
      </div>
    </div>
  </div>
);

ExpirationStage.propTypes = {
  logoUrl: PropTypes.string,
};

ExpirationStage.defaultProps = {
  logoUrl: '',
};

export default connect(mapStateToProps)(cssModule(ExpirationStage, style));
