import React from 'react';

import { eventTypeId } from 'constants/enum';
import StatWrapper from 'newDesign/modules/break/components/statSlides/liveStats/statWrapper/StatWrapper';

const IceHockey = () => [
  <StatWrapper
    key={1}
    statKey={eventTypeId.ball_possession}
    relative
  />,
  <StatWrapper
    key={2}
    statKey={eventTypeId.suspensions}
    relative
    higherIsBetter={false}
  />,
  <StatWrapper
    key={3}
    statKey={eventTypeId.goals_powerplay}
    relative
  />,
  <StatWrapper
    key={4}
    statKey={eventTypeId.goals_shorthanded}
    relative
  />,
  <StatWrapper
    key={5}
    statKey={eventTypeId.powerplays}
    relative
  />,

];

export default IceHockey;
