import React, {
  useEffect,
  useState,
  useCallback,
  Fragment,
} from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import { updateActiveVideoHandler, pollStreamUrls } from 'actions/stream';
import { streamStoppedPlaying, streamIsPlaying, playerAutoplayBlocked } from 'actions/liveplayer';
import VideoDivider from 'newDesign/modules/live/components/livePlayer/videoDivider/VideoDivider';
import { DARK_BACKGROUND_COLOR } from 'constants/app';
import { useKeyCount } from 'hooks';
import {
  getStreamUrls,
  getStreamRequestError,
  getLogoUrl,
  getVolumeSettings,
  getVideoBreak,
  getChannelTrailerCachedUrl,
  getLayout,
  getShowPlayerUI,
  getIsPlayerLoaded,
} from 'reducers';
import {
  NOT_LOADED,
  PLAYING,
  ENDED,
  ERROR,
  VIDEOBREAK,
  NO_STREAMS,
  TEST_SOURCE,
  AUTOPLAY_BLOCKED,
} from 'constants/liveplayer';
import { LAYOUT_FULLSCREEN } from 'constants/bcms';
import LivePlayer from 'newDesign/modules/live/components/livePlayer/LivePlayer';

import style from 'newDesign/modules/live/components/livePlayer/livePlayerContainer/LivePlayerContainer.scss';

const mapStateToProps = state => ({
  streams: getStreamUrls(state),
  streamRequestError: getStreamRequestError(state),
  logoUrl: getLogoUrl(state),
  volumeSettings: getVolumeSettings(state),
  videoBreak: getVideoBreak(state),
  channelTrailerUrl: getChannelTrailerCachedUrl(state),
  layout: getLayout(state),
  queryParamShowUI: getShowPlayerUI(state),
  videoPlayerLoaded: getIsPlayerLoaded(state),
});
const mapDispatchToProps = dispatch => ({
  setActiveVideoHandler: bindActionCreators(updateActiveVideoHandler, dispatch),
  streamStopped: bindActionCreators(streamStoppedPlaying, dispatch),
  streamPlaying: bindActionCreators(streamIsPlaying, dispatch),
  pollNewStreamUrls: bindActionCreators(pollStreamUrls, dispatch),
  autoplayDispatch: bindActionCreators(playerAutoplayBlocked, dispatch),
});


const PlayerContainer = ({
  streams,
  logoUrl,
  videoBreak,
  setActiveVideoHandler,
  streamStopped,
  streamPlaying,
  pollNewStreamUrls,
  autoplayDispatch,
  layout,
  useFullScreen,
  queryParamShowUI,
  videoPlayerLoaded,
}) => {
  const [playerState, setPlayerState] = useState(NOT_LOADED);
  const [videoMessage, setVideoMessage] = useState('');
  const [overlay, setOverlay] = useState('loadingOverlay');
  const [key, setKey] = useState(0);
  const [showUI, setShowUI] = useState(false);

  const showUIKey = useKeyCount('u');

  const playing = useCallback(
    currentVideoHandler => {
      setActiveVideoHandler(currentVideoHandler);
      streamPlaying.call();
      setPlayerState(PLAYING);
    },
    [streamPlaying, setActiveVideoHandler],
  );

  const ended = useCallback(() => {
    streamStopped.call();
    pollNewStreamUrls();
    setPlayerState(ENDED);
  }, [streamStopped, pollNewStreamUrls]);

  const error = useCallback(
    err => {
      streamStopped.call();
      pollNewStreamUrls(err); // maybe only do it for some errors
      setPlayerState(ERROR);
    },
    [streamStopped, pollNewStreamUrls],
  );

  // reload player with UI enabled and display error message
  const autoplayBlocked = useCallback(() => {
    if (!showUI) {
      setShowUI(true);
      setActiveVideoHandler('DASH');
      setPlayerState(AUTOPLAY_BLOCKED);
      setKey(k => k + 1);
      autoplayDispatch();
    }
  }, [showUI, setActiveVideoHandler, autoplayDispatch]);

  useEffect(() => {
    if (videoBreak) {
      setPlayerState(VIDEOBREAK);
    } else {
      setPlayerState(TEST_SOURCE);
    }
  }, [videoBreak]);

  useEffect(() => {
    if (!streams.length) {
      setPlayerState(NO_STREAMS);
    } else {
      setPlayerState(TEST_SOURCE);
      setVideoMessage('');
    }
  }, [streams]);

  useEffect(() => {
    if (playerState === PLAYING) {
      setOverlay('loadingOverlayHidden');
    } else {
      setOverlay('loadingOverlay');
    }
  }, [playerState]);


  const divider = (
    <VideoDivider
      message={videoMessage}
      logoUrl={logoUrl}
    />
  );
  // TODO: check layouts
  let wrapperStyle = layout === LAYOUT_FULLSCREEN
    ? 'playerWrapperFullscreen'
    : 'playerWrapper';

  wrapperStyle = useFullScreen ? 'playerWrapperFullscreen' : wrapperStyle;

  return (
    <Fragment>
      {/* TODO: temp hotfix, improve during fixes of player styles */}
      <div styleName='containerHeightAdjuster' />

      <div styleName='liveplayerContainer' key={key}>
        <div styleName={wrapperStyle}>
          {playerState !== AUTOPLAY_BLOCKED ? (
            <div
              styleName={overlay}
              style={{
                background: DARK_BACKGROUND_COLOR,
              }}
            >
              {divider}
            </div>
          ) : null}
          {
            videoPlayerLoaded
            && !videoBreak
            && streams.length
            && (playerState !== ERROR && playerState !== ENDED)
              ? (
                <LivePlayer
                  streams={streams}
                  playing={playing}
                  ended={ended}
                  errorCallback={error}
                  autoplay={autoplayBlocked}
                  showUI={queryParamShowUI || (showUI || (showUIKey % 2 !== 0))}
                  key={(queryParamShowUI || showUI || (showUIKey % 2 !== 0))}
                />
              )
              : null
          }
        </div>
      </div>
    </Fragment>
  );
};

PlayerContainer.propTypes = {
  streams: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  logoUrl: PropTypes.string,
  setActiveVideoHandler: PropTypes.func,
  streamStopped: PropTypes.func,
  streamPlaying: PropTypes.func,
  videoBreak: PropTypes.bool,
  pollNewStreamUrls: PropTypes.func,
  autoplayDispatch: PropTypes.func,
  layout: PropTypes.number,
  useFullScreen: PropTypes.bool,
  queryParamShowUI: PropTypes.bool,
  videoPlayerLoaded: PropTypes.bool,
};

PlayerContainer.defaultProps = {
  streams: [],
  logoUrl: '',
  setActiveVideoHandler: null,
  streamStopped: null,
  streamPlaying: null,
  videoBreak: false,
  pollNewStreamUrls: null,
  autoplayDispatch: () => null,
  layout: 1,
  useFullScreen: false,
  queryParamShowUI: false,
  videoPlayerLoaded: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(cssModule(PlayerContainer, style));
