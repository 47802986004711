import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';

import { getSeparatorColor } from 'reducers/index';
import styles from '../../../styles/MatchTickerStyles.scss';

const mapStateToProps = state => ({
  bgColor: getSeparatorColor(state),
});

const ServiceIcon = ({ bgColor }) => (
  <div
    className={`${styles.table__row__serve_circle} flex`}
    style={{
      background: bgColor,
    }}
  />
);

ServiceIcon.propTypes = {
  bgColor: PropTypes.string.isRequired,
};

const ServiceIconStyled = cssModule(ServiceIcon, styles);
export default connect(mapStateToProps)(ServiceIconStyled);
