import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import darken from 'newDesign/modules/common/utils/darken';
import {
  getFontColor, getNextMatchTimestamp, getBgColor,
} from 'reducers';

const mapStateToProps = (state, ownProps) => ({
  nextMatchTimestamp: getNextMatchTimestamp(state, ownProps.matchId),
  bgColor: getBgColor(state),
  fontColor: getFontColor(state),
});

function getMatchTime(ts) {
  const strDate = new Date(ts * 1000);
  const strHours = `00${strDate.getHours()}`.substr(-2);
  const strMins = `00${strDate.getMinutes()}`.substr(-2);
  return `${strHours}:${strMins}`;
}

const MatchTimeInfo = ({
  bgColor, fontColor, nextMatchTimestamp,
}) => (
  <div
    className='color-snd px-5 flex items-center font-medium fs-18 py-0.5'
    style={{
      background: `linear-gradient(90deg, ${darken(bgColor, 5)} 0%, ${bgColor} 0.39%, ${darken(bgColor, 5)} 100%)`,
    }}
  >
    <span style={{ color: fontColor }}>{getMatchTime(nextMatchTimestamp)}</span>
  </div>
);
MatchTimeInfo.propTypes = {
  nextMatchTimestamp: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(MatchTimeInfo);
