import React from 'react';
import PropTypes from 'prop-types';

import { sport, jerseyTypeNoSleeves } from 'constants/enum';
import Jersey from 'newDesign/modules/common/components/jersey/Jersey';
import PlayerIcon from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/competitorAvatar/PlayerIcon';

const CompetitorAvatar = ({ sportId, jersey }) => {
  let jerseySize = 'jersey_big';

  if ([sport.basketball, sport.ebasketball, sport.basketball3x3].includes(sportId)
    || jersey.type === jerseyTypeNoSleeves) {
    jerseySize = 'jersey_big_no_sleeves';
  } else if (sportId === sport.esoccer) {
    return <PlayerIcon />;
  }

  return <Jersey jersey={jersey} jerseySize={jerseySize} sportId={sportId} />;
};

CompetitorAvatar.propTypes = {
  sportId: PropTypes.number.isRequired,
  jersey: PropTypes.object.isRequired,
};

export default CompetitorAvatar;
