import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchStatusName,
  getMatchSportId,
  getMatchStatus,
  getMatchTimeInfo,
  getMatchPTime,
  getMatchPeriodLength,
  getMatchSuspended,
  getMatchOvertimeLength,
} from 'reducers/index';
import { isInPlay } from 'utils/matchstatus';
import { matchStatusIds, sport } from 'constants/enum';
import BasketClock from 'newDesign/modules/common/components/clock/basketball/BasketClock';
import IceHockeyClock from 'newDesign/modules/common/components/clock/iceHockey/IceHockeyClock';
import HandballClock from 'newDesign/modules/common/components/clock/handball/HandballClock';
import SoccerClock from 'newDesign/modules/common/components/clock/soccer/SoccerClock';
import FutsalClock from 'newDesign/modules/common/components/clock/futsal/FutsalClock';

const mapStateToProps = (state, ownProps) => ({
  matchStatus: getMatchStatusName(state, ownProps.lastMatchId),
  matchStatusId: getMatchStatus(state, ownProps.lastMatchId),
  sportId: getMatchSportId(state, ownProps.lastMatchId),
  timeInfo: getMatchTimeInfo(state, ownProps.lastMatchId),
  pTime: getMatchPTime(state, ownProps.lastMatchId),
  periodLength: getMatchPeriodLength(state, ownProps.lastMatchId),
  suspended: getMatchSuspended(state, ownProps.lastMatchId),
  overtimeLength: getMatchOvertimeLength(state, ownProps.lastMatchId),
});

const TickerClockContainer = ({
  lastMatchId,
  matchStatus,
  matchStatusId,
  sportId,
  timeInfo,
  pTime,
  periodLength,
  suspended,
  overtimeLength,
}) => {
  const intl = useIntl();

  let clock;
  switch (sportId) {
    case sport.basketball:
    case sport.ebasketball:
      clock = <BasketClock
        statusId={matchStatusId}
        timeInfo={timeInfo}
      />;
      break;
    case sport.ice_hockey:
      clock = <IceHockeyClock
        statusId={matchStatusId}
        timeInfo={timeInfo}
      />;
      break;
    case sport.handball:
      clock = <HandballClock
        matchId={lastMatchId}
        statusId={matchStatusId}
        pTime={pTime}
        periodLength={periodLength}
        sportId={sportId}
        suspended={suspended}
        timeInfo={timeInfo}
        overtimeLength={overtimeLength}
      />;
      break;
    case sport.soccer:
      clock = <SoccerClock
        matchId={lastMatchId}
        statusId={matchStatusId}
        pTime={pTime}
        periodLength={periodLength}
        sportId={sportId}
        suspended={suspended}
      />;
      break;
    case sport.futsal:
      clock = <FutsalClock
        statusId={matchStatusId}
        timeInfo={timeInfo}
      />;
      break;
    default:
      clock = matchStatus;
      break;
  }

  let tickerCurrentState;
  if (isInPlay(matchStatusId)) {
    tickerCurrentState = clock;
  } else {
    tickerCurrentState = matchStatusId === matchStatusIds.halftime
      ? intl.formatMessage({ id: 't_general_ht' })
      : matchStatus;
  }

  return (
    <h3 className={'fs-20 font-second--bold uppercase'}>{tickerCurrentState}</h3>
  );
};

TickerClockContainer.propTypes = {
  sportId: PropTypes.number,
  matchStatus: PropTypes.string,
  matchStatusId: PropTypes.number,
  lastMatchId: PropTypes.number,
  timeInfo: PropTypes.object,
  pTime: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  periodLength: PropTypes.number,
  suspended: PropTypes.bool,
  overtimeLength: PropTypes.number,
};

TickerClockContainer.defaultProps = {
  sportId: 0,
  matchStatus: '',
  matchStatusId: 0,
  lastMatchId: 0,
  timeInfo: null,
  pTime: null,
  periodLength: 45,
  suspended: false,
  overtimeLength: 15,
};

export default connect(mapStateToProps)(TickerClockContainer);
