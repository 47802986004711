import { sport } from 'constants/enum';

export const secondTableTennisCompetitorCheck = (teams, sportId) => {
  const copiedTeams = {
    home: [...teams.home.map(team => ({ ...team }))],
    away: [...teams.away.map(team => ({ ...team }))],
  };

  const isDoubleTableTennisSecondHomeCompetitor = sportId === sport.table_tennis
    && teams.tennisDouble
    && !teams.home[1];
  const isDoubleTableTennisSecondAwayCompetitor = sportId === sport.table_tennis
    && teams.tennisDouble
    && !teams.away[1];

  if (isDoubleTableTennisSecondHomeCompetitor) {
    copiedTeams.home.push({ name: '-', countryCodeA2: 'xyz' });
  }

  if (isDoubleTableTennisSecondAwayCompetitor) {
    copiedTeams.away.push({ name: '-', countryCodeA2: 'xyz' });
  }

  return copiedTeams;
};

export const splitTableTennisTeamNames = teams => {
  let homeTeam = teams.home;
  let awayTeam = teams.away;

  if (!teams.home[1]) {
    const homeTeamSplitted = teams.tennisDouble.home[0].name.split('/');
    const homeTeamAbbr = teams.tennisDouble.home[0].abbr;
    homeTeam = homeTeamSplitted.map(name => ({ name, abbr: homeTeamAbbr }));
  }
  if (!teams.away[1]) {
    const awayTeamSplitted = teams.tennisDouble.away[0].name.split('/');
    const awayTeamAbbr = teams.tennisDouble.away[0].abbr;
    awayTeam = awayTeamSplitted.map(name => ({ name, abbr: awayTeamAbbr }));
  }

  return { homeTeam, awayTeam };
};
