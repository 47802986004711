/* eslint-disable no-nested-ternary */
import { createReducer } from 'utils';
import { isEmpty as _isEmpty } from 'lodash';
import {
  BCMS_BETSHOP_CONFIG_LOAD_SUCCESS,
  START_LCR,
  SET_TRANSLATIONS,
  SET_AS_MATCHID,
  SET_AS_MATCHES,
  SET_LMT_MATCH,
  SET_AS_STAGEID,
  INITALSTAGE_ENDED,
  SET_READY_MATCHID,
  LCR_AUTH_CHECK,
} from 'constants/actions';
import * as config from 'Config/app';
import * as fn from 'Config/fishnetUrls';
import { createSelector } from 'reselect';
import {
  showUSOddsFormatList,
  DECIMAL_ODDS,
  AMERICAN_ODDS,
  STAGE_ANNOUNCEMENT_LOOP,
} from 'constants/bcms';
import noOddsList from 'ClientsBranding/configs/noOdds';
import swapOverUnderClients from 'ClientsBranding/configs/swapOverUnder';
import justClientMatchId from 'ClientsBranding/configs/justClientMatchId';
import { CHANNELS_ESOCCER_ONLY } from 'constants/app';

const prodCheck = (qMirrorProd, baseUrlDefault, stagingUrl, prodUrl) => (
  typeof qMirrorProd === 'undefined'
    ? baseUrlDefault
    : qMirrorProd === false
      ? stagingUrl
      : prodUrl
);

const initialState = {};
export default createReducer(initialState, {
  [START_LCR]: (state, payload) => ({
    ...state,
    ...payload,
    // use right bcms url, undefined = default based on ENV, true=prod, false=staging
    config: {
      ...config.default,
      baseUrl: prodCheck(
        payload.qMirrorProd,
        config.baseUrlDefault,
        config.stagingUrl,
        config.prodgUrl,
      ),
    },

    // only update if stageid changed
    tempstage: typeof payload.qTmpStage === 'number' && (!state.tempstage || payload.qTmpStage !== state.tempstage.stageid)
      ? {
        timestamp: payload.qDateNow,
        stageid: payload.qTmpStage,
      }
      : state.tempstage,
  }),
  [BCMS_BETSHOP_CONFIG_LOAD_SUCCESS]: (state, payload) => {
    if (!payload) {
      return state;
    }
    const { crawler, ...bcmsData } = payload;
    const url = bcmsData.liveodds || '';
    const idxFirst = url.indexOf('?/') + 2;
    const idxLast = url.indexOf('/', idxFirst);

    const alias = url.slice(idxFirst, idxLast);

    return {
      ...state,
      ...bcmsData,
      srlive_alias: bcmsData.srlive_alias || alias,
      tempstage: typeof state.qTmpStage === 'number' ? state.tempstage : bcmsData.tempstage,

      // use right bcms url, undefined = default based on ENV, true=prod, false=staging
      config: {
        ...config.default,
        baseUrl: typeof state.qMirrorProd === 'undefined'
          ? state.config.baseUrlDefault
          : state.qMirrorProd === false
            ? state.config.stagingUrl
            : state.config.prodUrl,
      },
    };
  },
  [LCR_AUTH_CHECK]: state => ({
    ...state,
    config: {
      ...config.default,
      authorizationSettings: {
        ...state.authorizationSettings,
      },
    },
  }),
  [SET_TRANSLATIONS]: (state, payload) => ({
    ...state,
    translations: payload,
    isTranslationsAvailable: true,
  }),
  [SET_AS_MATCHID]: (state, payload) => ({
    ...state,
    asMatchId: payload,
  }),
  [SET_AS_MATCHES]: (state, payload) => {
    if (!payload) {
      return state;
    }

    return {
      ...state,
      asMatches: [...payload],
    };
  },
  [SET_LMT_MATCH]: (state, payload) => ({
    ...state,
    asLMT: payload,
  }),
  [SET_AS_STAGEID]: (state, payload) => {
    if (!payload) {
      return state;
    }
    return {
      ...state,
      asStageId: payload,
    };
  },
  [INITALSTAGE_ENDED]: state => ({
    ...state,
    initialStageEnded: true,
  }),
  [SET_READY_MATCHID]: (state, matchId) => ({
    ...state,
    readyMatchId: matchId,
  }),
});

// Input-selectors:
// They are created as ordinary non-memoized selector functions
// because they do not transform the data they select.
export const getPrimaryMatchId = state => state.primarymatchid;
export const getIsMatchBreak = state => {
  if (state.primarymatchid !== 0 && (state.qMatchId || state.matchid) !== state.primarymatchid) {
    return true;
  }
  return false;
};

export const getChannelId = createSelector(
  state => state.channelid,
  channelId => channelId,
);

export const getBCMSStage = createSelector(
  state => state.stage,
  stage => stage,
);

const checkManualControl = (bcms, autosport, queryParam, channelId, stage) => {
  if (CHANNELS_ESOCCER_ONLY.includes(channelId) && stage !== STAGE_ANNOUNCEMENT_LOOP) {
    return queryParam || bcms || autosport;// if auto channel is NOT in Ann loop use stageId from bcms
  }
  return queryParam || autosport || bcms;
};

export const getStage = createSelector(
  state => state.stage,
  state => state.asStageId,
  state => state.qStage,
  getChannelId,
  state => state.stage,
  checkManualControl,
);

export const getBetshopId = state => state.betshopid;

export const getQueryStreamUrl = createSelector(
  state => state.qUrl,
  qUrl => qUrl,
);

export const getQueryFeed = createSelector(
  state => state && state.qFeed,
  state => state && state.qRelease,
  (qFeed, useLsRelease) => (useLsRelease ? fn.fishnetFeedsReleaseUrl : qFeed),
);

export const getUseReplayAPI = state => state && state.qReplay;
export const getUseRefresh = state => state && !!state.qRefresh;
export const getIsTranslationsAvailable = state => state && state.isTranslationsAvailable;
export const getTranslations = state => state && state.translations;

export const getLcrStreamUrl = createSelector(
  state => (state.config ? state.config.lcrStreamUrl : null),
  lcrStreamUrl => lcrStreamUrl,
);

export const getLcrWorldLotteryUrl = createSelector(
  state => (state.config ? state.config.lcrWorldLotteryUrl : null),
  lcrWorldLotteryUrl => lcrWorldLotteryUrl,
);

export const getAuthPollingInterval = createSelector(
  state => (
    (state && state.config && state.config.authorizationSettings)
      ? state.config.authorizationSettings.pollInterval
      : null
  ),
  pollInterval => pollInterval || 30000,
);
export const getUserId = createSelector(
  state => state.userId,
  userId => userId,
);

export const getMatchId = createSelector(
  state => state.matchid,
  state => state.asMatchId,
  state => state.qMatchId,
  getChannelId,
  getBCMSStage,
  checkManualControl,
);

export const getReadyMatchId = createSelector(
  state => state.readyMatchId,
  readyMatchId => readyMatchId,
);

// Derived selectors
export const getShowLmt = createSelector(
  state => state.lmt,
  state => state.asLMT,
  state => state.qLMT,
  (lmt, asLMT, qLMT) => {
    if (qLMT === false || qLMT === true) {
      return qLMT;
    }
    if (asLMT === false || asLMT === true) {
      return asLMT;
    }
    return !!lmt;
  },
);
export const getOddsBreak = createSelector(
  state => state.oddsbreak,
  oddsbreak => oddsbreak === 1,
);

export const getScoreBreak = createSelector(
  state => state.scorebreak,
  scorebreak => scorebreak === 1,
);

export const getClockBreak = createSelector(
  state => state.clockbreak,
  clockbreak => clockbreak === 1,
);

export const getMatchIdVisibility = createSelector(
  state => state.use_match_ids,
  useMatchIds => !!useMatchIds,
);

export const getChannelTrailer = createSelector(
  state => state.channeltrailer,
  channeltrailer => channeltrailer,
);

export const getLayout = createSelector(
  state => state.layout,
  state => state.qLayout,
  (layout, qLayout) => qLayout || layout,
);

export const getQueryStatSlide = createSelector(
  state => state.qStatSlide,
  qStatSlide => qStatSlide,
);

export const getMarketingVideo = createSelector(
  state => state.marketingvideo,
  marketingvideo => marketingvideo,
);

export const getLogoUrl = createSelector(
  [
    state => state.betshoplogo,
    state => state.betshopid,
    state => state.clientid,
  ],
  (betshoplogo, betshopid, clientid) => {
    if (betshoplogo) {
      return config.lcrBetshopLogoUrl.replace(':clientId', clientid).replace(':betshopId', betshopid);
    }

    return config.lcrClientLogoUrl.replace(':clientId', clientid);
  },
);

export const getColor = (state, idx) => {
  if (!state.color) {
    return '#ffffff';
  }
  if (idx === 4 && state.color[idx] === '') {
    return `#${state.color[idx - 1]}`;
  }
  return `#${state.color[idx]}`;
};

export const getSeparatorColor = createSelector(
  state => state.separator_color,
  separatorColor => `#${separatorColor}`,
);

export const getFontColor = createSelector(
  state => state.font_color,
  fontColor => `#${fontColor}`,
);

export const getBgColor = createSelector(
  state => state.background_color,
  bgColor => `#${bgColor}`,
);
export const getLogoBgColor = createSelector(
  state => state.logo_background_color,
  logoBgColor => (
    _isEmpty(logoBgColor)
      ? '#000000'
      : `#${logoBgColor}`
  ),
);


export const getLastMatch = createSelector(
  state => state.lastmatch,
  state => state.qLastMatchId,
  (lastmatch, qLastMatchId) => qLastMatchId || lastmatch,
);

export const getRefresh = createSelector(
  state => state.refresh,
  refresh => refresh,
);

export const getTicker = createSelector(
  state => state.ticker,
  getLastMatch,
  (ticker, matchId) => {
    if (ticker && matchId !== 0) {
      return true;
    }
    return false;
  },
);

export const getTemporaryStage = createSelector(
  state => state.tempstage,
  tempstage => tempstage,
);

export const getClientAlias = createSelector(
  state => state.srlive_alias,
  alias => alias,
);

export const getLanguage = createSelector(
  state => state.language,
  state => state.qLang,
  (language, qLang) => qLang || language,
);

export const getVolumeSettings = createSelector(
  [state => state.volumeleft, state => state.volumeright],
  (left, right) => ({
    left,
    right,
  }),
);

export const getVideoBreak = createSelector(
  state => state.videobreak,
  videobreak => videobreak === 1,
);

export const getStatsBreak = createSelector(
  state => state.statsbreak,
  statsbreak => statsbreak === 1,
);

export const getLmtColors = createSelector(
  [state => state.lmthomecolor, state => state.lmtawaycolor],
  (lmtHomeColor, lmtAwayColor) => ({
    lmtHomeColor,
    lmtAwayColor,
  }),
);

export const getScoreboardRefresh = createSelector(
  state => state.scoreboardrefresh,
  scoreboardrefresh => scoreboardrefresh,
);

export const getOddsPanelIframeURL = createSelector(
  state => state.liveodds_panel.iframe_url,
  getMatchId,
  (iframeUrl, matchId) => iframeUrl.replace('[M]', matchId),
);

export const getOddsPanelType = createSelector(
  state => state.liveodds_panel.type,
  type => type,
);


export const getOddsFormat = createSelector(
  state => state.srlive_alias,
  alias => (showUSOddsFormatList.includes(alias) ? AMERICAN_ODDS : DECIMAL_ODDS),
);

export const showMatchIdComp = createSelector(
  getClientAlias,
  getMatchIdVisibility,
  (state, clientMatchId) => clientMatchId,
  (srAlias, visibility, clientMatchId) => {
    if (justClientMatchId.includes(srAlias)) {
      return !!clientMatchId;
    }

    return visibility;
  },
);

export const getShowOdds = createSelector(
  getClientAlias,
  alias => !noOddsList.includes(alias), // Not in list = show odds
);

export const getSwapOverUnder = createSelector(
  getClientAlias,
  alias => swapOverUnderClients.includes(alias), // In list swap over/under
);

export const getShowPlayerUI = createSelector(
  state => state.qPlayerUI,
  qPlayerUI => qPlayerUI,
);

export const getAutoSport = createSelector(
  state => state.qAutoSport,
  qAutoSport => qAutoSport,
);

export const getAutoSportMatches = createSelector(
  state => state.asMatches,
  matches => matches || [],
);

export const getRotateOddspanel = createSelector(
  state => state.qOddsRot,
  qOddsRot => qOddsRot,
);

export const getUseAutoStage = createSelector(
  state => state.qAutoStage,
  qAutoStage => qAutoStage,
);

export const getInitialStageEnded = createSelector(
  state => state.initialStageEnded,
  initialStageEnded => initialStageEnded,
);

export const getUseSRDev = createSelector(
  state => state.qSRDev,
  qSRDev => qSRDev,
);
