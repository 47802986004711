import React from 'react';
import PropTypes from 'prop-types';

import useSuspensionClock from 'hooks/SuspensionClockHook';
import { matchStatusIds } from 'constants/enum';

const SuspensionBox = ({
  running,
  played,
  suspensionTime,
  matchStatusId,

}) => {
  const time = useSuspensionClock(
    running,
    played,
    suspensionTime,
    matchStatusId,
  );

  if (!time) {
    return null;
  }

  return (
    <span className='fs-18 font-main--medium'>{time}</span>
  );
};
SuspensionBox.propTypes = {
  running: PropTypes.bool,
  played: PropTypes.string,
  suspensionTime: PropTypes.string,
  matchStatusId: PropTypes.number,
};

SuspensionBox.defaultProps = {
  running: false,
  played: '0',
  suspensionTime: '0',
  matchStatusId: matchStatusIds.not_started,
};

export default SuspensionBox;
