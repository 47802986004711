import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import useImportImageFacelift from 'newDesign/modules/common/hooks/ImportImage';
import { getBackgroundImageUrl } from 'newDesign/modules/common/utils/sportBackground';
import { getMatchGround, getMatchSportId, getReadyMatchId } from 'reducers';

import styles from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';
import TimelineHeader from './timelineHeader/TimelineHeader';
import TimelineContent from './timelineContent/TimelineContent';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  ground: getMatchGround(state, getReadyMatchId(state)),
});

const Timeline = ({ sportId, ground }) => {
  const backgroundImage = useImportImageFacelift(getBackgroundImageUrl(sportId, ground, 'statSlides'));

  return (
    <div className={'w-full flex-col relative'}>
      <div className={'absolute inset-0 flex justify-center animate-fadeIn-1.25s'}>
        <div styleName='halftimestats__content' className='flex absolute h-full w-full flex-col'>
          <div className={'flex flex-col'}>
            <TimelineHeader />
            <TimelineContent />
          </div>
        </div>
        <img className='w-full h-full object-cover' src={backgroundImage} alt='' />
      </div>
    </div>
  );
};


Timeline.propTypes = {
  sportId: PropTypes.number.isRequired,
  ground: PropTypes.string,
};

Timeline.defaultProps = {
  ground: '',
};

const TimelineStyled = cssModule(Timeline, styles);
export default connect(mapStateToProps)(TimelineStyled);
