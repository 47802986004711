import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Jersey from 'newDesign/modules/common/components/jersey/Jersey';
import TimelineScore from 'newDesign/modules/break/components/statSlides/timeline/timelineScore/TimelineScore';
import {
  getMatchJerseys, getMatchSportId,
  getMatchTeams,
  getReadyMatchId,
} from 'reducers';

import styles from 'newDesign/modules/break/components/statSlides/timeline/timelineHeader/TimelineHeader.scss';
import { getTeamNameProperty } from 'newDesign/modules/common/utils/teamName';

const mapStateToProps = state => ({
  teams: getMatchTeams(state, getReadyMatchId(state)),
  jerseys: getMatchJerseys(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});
const TimelineHeader = ({ teams, jerseys, sportId }) => {
  const homeTeam = teams.home;
  const awayTeam = teams.away;
  const teamNameProperty = getTeamNameProperty(sportId);

  return (
    <div className={`${styles.timeline_header} ${styles.timeline_header_bg} flex justify-center items-center`}>
      <div className={'flex flex-1 items-center'}>
        <h3 className={'font-main--medium fs-24 flex-1 uppercase'}>{homeTeam[0][teamNameProperty]}</h3>
        <div className={'flex items-center'}>
          <Jersey jersey={jerseys.home.player} jerseySize='jersey_timeline_stats' sportId={sportId} />
        </div>
      </div>
      <TimelineScore />
      <div className={'flex flex-1 items-center'}>
        <h3 className={'font-main--medium fs-24 flex-1 uppercase'}>{awayTeam[0][teamNameProperty]}</h3>
        <div className={'flex items-center'}>
          <Jersey jersey={jerseys.away.player} jerseySize='jersey_timeline_stats' sportId={sportId} />
        </div>
      </div>
    </div>
  );
};


TimelineHeader.propTypes = {
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
  jerseys: PropTypes.object,
  sportId: PropTypes.number.isRequired,
};

TimelineHeader.defaultProps = {
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
  jerseys: {
    away: {
      player: {},
    },
    home: {
      player: {},
    },
  },
};
export default connect(mapStateToProps)(TimelineHeader);
