// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoScheduledMatches__rectangle___1b-qR{color:#fff;border:3px solid;border-image-slice:1;border-left:0;border-right:0}.NoScheduledMatches__rectangle___1b-qR h2{font-family:Roboto Condensed;font-size:24px;font-style:normal;font-weight:500;line-height:normal}@media only screen and (min-width: 1536px){.NoScheduledMatches__rectangle___1b-qR h2{font-size:2vw}}", ""]);
// Exports
exports.locals = {
	"rectangle": "NoScheduledMatches__rectangle___1b-qR"
};
module.exports = exports;
