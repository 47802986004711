// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FullscreenLayout__prematch___1xELu{color:#fff}.FullscreenLayout__prematch__background___SnPHS{position:absolute;background-size:cover;height:100%;width:100%}.FullscreenLayout__prematch__background___SnPHS::before{opacity:.95;background:rgba(0,0,0,.6);position:absolute;width:100%;height:100%;content:\"\"}.FullscreenLayout__bg-container___2M0cL{background:linear-gradient(180deg, rgba(0, 0, 0, 0.29) 0.02%, rgba(0, 0, 0, 0.6) 43.24%, rgba(0, 0, 0, 0.39) 58.83%, rgba(0, 0, 0, 0) 94.96%, rgba(0, 0, 0, 0) 136.06%);position:relative;min-width:80%}.FullscreenLayout__bg-container___2M0cL::before{background:linear-gradient(191deg, rgba(16, 13, 13, 0.23) 8.22%, rgba(0, 0, 0, 0.3) 91.77%);box-shadow:0px 8px 30px 0px rgba(0,0,0,.25);-webkit-backdrop-filter:blur(12.5px);backdrop-filter:blur(12.5px);position:absolute;width:100%;height:100%;content:\"\";left:0;right:0}.FullscreenLayout__bg-container___2M0cL::after{background:linear-gradient(178deg, rgba(255, 255, 255, 0.06) 20.14%, rgba(255, 255, 255, 0.19) 49.66%, rgba(255, 255, 255, 0) 123.85%);box-shadow:0px 8px 30px 0px rgba(0,0,0,.25);-webkit-backdrop-filter:blur(12.5px);backdrop-filter:blur(12.5px);position:absolute;width:100%;height:100%;content:\"\";left:0;right:0;opacity:.8}", ""]);
// Exports
exports.locals = {
	"prematch": "FullscreenLayout__prematch___1xELu",
	"prematch__background": "FullscreenLayout__prematch__background___SnPHS",
	"bg-container": "FullscreenLayout__bg-container___2M0cL"
};
module.exports = exports;
