import React from 'react';
import * as config from 'Config/fishnetUrls';
import fallbackDefaultFlag from 'newDesign/styles/images/default-flag.png';
import PropTypes from 'prop-types';
import ImageFallback from 'newDesign/modules/common/utils/ImageFallback';

const Flags = ({
  uid, size, typeClassName,
}) => {
  const crestUrl = config.fishnetCrestUrl.replace('[size]', size).replace('[UID]', uid);

  return <ImageFallback
    fallback={fallbackDefaultFlag}
    typeClassName={typeClassName}
    src={crestUrl}
    alt={'Flag'}
  />;
};

Flags.propTypes = {
  uid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  size: PropTypes.string,
  typeClassName: PropTypes.string.isRequired,
};

Flags.defaultProps = {
  size: 'big',
};

export default Flags;
