// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr__wrapper .announcementLoopWrapper{display:inherit}#lcr__wrapper .announcement .container{min-height:initial}.AnnouncementStage__announcement___1I4QH{min-height:100vh}@media only screen and (min-width: 1536px){.AnnouncementStage__event__meta___3KnJB{font-size:1.25vw}.AnnouncementStage__time___3YXZR{font-size:1.5vw}}", ""]);
// Exports
exports.locals = {
	"announcement": "AnnouncementStage__announcement___1I4QH",
	"event__meta": "AnnouncementStage__event__meta___3KnJB",
	"time": "AnnouncementStage__time___3YXZR"
};
module.exports = exports;
