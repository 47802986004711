import React from 'react';

import FooterTop from 'newDesign/modules/live/components/footer/footerTop/FooterTop';
import FooterBody from 'newDesign/modules/live/components/footer/footerBody/FooterBody';
import FooterBottom from 'newDesign/modules/live/components/footer/footerBottom/FooterBottom';
import FooterCrawler from 'newDesign/modules/live/components/footer/footerCrawler/FooterCrawler';

const Footer = () => (
  <div className='flex max-w-full flex-1 flex-col'>
    <FooterTop />
    <FooterBody />
    <FooterBottom />
    <FooterCrawler />
  </div>
);

export default Footer;
