import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';
import { isNull as _isNull } from 'lodash';

import {
  getLastMatch,
  getMatchCurPeriod,
  getMatchGameScore,
  getMatchPeriodsSets,
  getMatchResult,
  getMatchSportId,
  getMatchStatus,
  getMatchTeamsNames,
} from 'reducers/index';
import { isEnded } from 'utils/matchstatus';
import TennisGameScore from 'newDesign/modules/common/components/ticker/tickerTables/components/tennisGameScore/TennisGameScore';
import ServiceIcon from 'newDesign/modules/common/components/ticker/tickerTables/components/serviceIcon/ServiceIcon';

import styles from 'newDesign/modules/common/components/ticker/styles/MatchTickerStyles.scss';
import { sport } from 'constants/enum';

const mapStateToProps = state => ({
  result: getMatchResult(state, getLastMatch(state)),
  gamescore: getMatchGameScore(state, getLastMatch(state)),
  period: getMatchCurPeriod(state, getLastMatch(state)),
  periods: getMatchPeriodsSets(state, getLastMatch(state)),
  teamNames: getMatchTeamsNames(state, getLastMatch(state)),
  matchStatusId: getMatchStatus(state, getLastMatch(state)),
  sportId: getMatchSportId(state, getLastMatch(state)),
});

const SportsWithServiceTickerTable = ({
  result, gamescore, period, periods, teamNames, matchStatusId, sportId,
}) => {
  let isHomeWinner;
  let isAwayWinner;
  let styleNameEllipsisBySport = '';

  if (isEnded(matchStatusId)) {
    isHomeWinner = result.home > result.away;
    isAwayWinner = result.away > result.home;
  }

  let p = { home: null, away: null };
  if (periods && periods['p' + period]) {
    p = periods['p' + period];
  }

  if ([sport.table_tennis, sport.tennis].includes(sportId)) {
    styleNameEllipsisBySport = 'tennis-name-ellipsis';
  } else {
    styleNameEllipsisBySport = 'sport-with-service-name-ellipsis';
  }

  return (
    <Fragment>
      <div className={`${styles.matchticker__table} ${isHomeWinner ? styles.matchticker__table__game_winner : ''} flex items-center w-full box-border font-main fs-16`}>
        <div className={'flex flex-1 items-center'}>
          <h3 styleName={styleNameEllipsisBySport} className={`fs-16 font-second--bold whitespace-nowrap uppercase ${styles.table__row__score_total}`}>{teamNames.home}</h3>
          {(gamescore && gamescore.service === '1') && <ServiceIcon /> }
        </div>
        <span className={'fs-20 font-main--black flex h-full items-center px-2'}>{result.home}</span>
        {/*
        Show period during match (on the break or halftime,
        but do not display it when the match is not started or ended
        */}
        {!_isNull(p.home) && (
          <Fragment>
            <div className={`${styles.matchticker__separator} ${styles.period__separator}`} />
            <span styleName='two-digits--period-result-available' className={'font-main--medium flex h-full items-center px-2 justify-center'}>{p.home}</span>
          </Fragment>
        )}
        <TennisGameScore gamescore={gamescore.home} />
      </div>
      <div
        className={`${styles.matchticker__table} ${isAwayWinner ? styles.matchticker__table__game_winner : ''} flex items-center w-full box-border font-main fs-16`}
      >
        <div className={'flex flex-1 items-center'}>
          <h3
            styleName={styleNameEllipsisBySport}
            className={`${styles.table__row__score_total} fs-16 font-second--bold whitespace-nowrap uppercase`}
          >{teamNames.away}
          </h3>
          {(gamescore && gamescore.service === '2') && <ServiceIcon />}
        </div>
        <span className={'fs-20 font-main--black flex h-full items-center px-2'}>{result.away}</span>
        {/*
        Show period during match (on the break or halftime,
        but do not display it when the match is not started or ended
        */}
        {!_isNull(p.away) && (
          <Fragment>
            <div className={`${styles.matchticker__separator} ${styles.period__separator}`} />
            <span styleName='two-digits--period-result-available' className={'font-main--medium flex h-full items-center px-2 justify-center'}>{p.away}</span>
          </Fragment>
        )}
        <TennisGameScore gamescore={gamescore.away} />
      </div>
    </Fragment>
  );
};

SportsWithServiceTickerTable.propTypes = {
  result: PropTypes.object,
  gamescore: PropTypes.object,
  period: PropTypes.number,
  periods: PropTypes.object,
  teamNames: PropTypes.object,
  matchStatusId: PropTypes.number,
  sportId: PropTypes.number.isRequired,
};

SportsWithServiceTickerTable.defaultProps = {
  result: {
    home: null,
    away: null,
    winner: null,
  },
  gamescore: {
    home: null,
    away: null,
  },
  period: null,
  periods: null,
  teamNames: {
    home: null,
    away: null,
  },
  matchStatusId: 0,
};

const SportsWithServiceTickerTableStyled = cssModule(SportsWithServiceTickerTable, styles, { allowMultiple: true });
export default connect(mapStateToProps)(SportsWithServiceTickerTableStyled);
