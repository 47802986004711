// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr .Timeline__timeline_game_item___ftRk_>div{width:50%;padding:.7vw 0;box-sizing:border-box}#lcr .Timeline__timeline_game_item___ftRk_ span{text-align:right}#lcr .Timeline__timeline_game_item___ftRk_ .Timeline__flex-row-reverse___TrZbX h2{margin:0 1vw 0 1.5vw}#lcr .Timeline__timeline_game_item___ftRk_ .Timeline__flex-row-reverse___TrZbX span{text-align:left}#lcr .Timeline__timeline_game_item___ftRk_ .Timeline__flex-row-reverse___TrZbX img{margin:0 1.3vw 0 0}#lcr .Timeline__timeline_game_item___ftRk_ .Timeline__flex-row-reverse___TrZbX svg{margin:0 1.3vw 0 0}#lcr .Timeline__timeline_game_item___ftRk_ .Timeline__flex-row-reverse___TrZbX .Timeline__timeline_icon_block___2-v9t span{margin:0 1.3vw 0 0}#lcr .Timeline__timeline_game_item___ftRk_ img{margin:0 0 0 1.3vw;width:1.8vw;-ms-flex-negative:0;flex-shrink:0}#lcr .Timeline__timeline_game_item___ftRk_ svg{margin:0 0 0 1.3vw;width:1.8vw;-ms-flex-negative:0;flex-shrink:0}#lcr .Timeline__timeline_game_item___ftRk_ h2{margin:0 1.5vw 0 1vw;min-width:1.97vw}#lcr .Timeline__substitution-icon___PRsHi{background:#fff;border-radius:50%;height:1.8vw}#lcr .Timeline__substitution-icon___PRsHi path:first-child{fill:#b90f2b;stroke:#b90f2b}#lcr .Timeline__substitution-icon___PRsHi path:last-child{fill:#459b35;stroke:#459b35}#lcr .Timeline__substitution-player-out___34maC{color:#bcbab9}", ""]);
// Exports
exports.locals = {
	"timeline_game_item": "Timeline__timeline_game_item___ftRk_",
	"flex-row-reverse": "Timeline__flex-row-reverse___TrZbX",
	"timeline_icon_block": "Timeline__timeline_icon_block___2-v9t",
	"substitution-icon": "Timeline__substitution-icon___PRsHi",
	"substitution-player-out": "Timeline__substitution-player-out___34maC"
};
module.exports = exports;
