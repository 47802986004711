import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import darken from 'newDesign/modules/common/utils/darken';
import { getSeparatorColor } from 'reducers';

import style from 'newDesign/modules/preMatch/common/components/preMatchDecoratedTitle/PreMatchDecoratedTitle.scss';

const mapStateToProps = state => ({
  separatorColor: getSeparatorColor(state),
});

const PreMatchDecoratedTitle = ({ separatorColor, titleTranslation }) => {
  if (!titleTranslation) {
    return null;
  }

  const linearGradientStyles = {};
  const underlineGradientColorRgba = darken(separatorColor, 36, 'rgba', 0.4);

  linearGradientStyles.background = `linear-gradient(90deg, 
    rgba(193, 15, 44, 0.00), 
    ${underlineGradientColorRgba} 21.88%, 
    ${separatorColor} 52.08%, 
    ${underlineGradientColorRgba} 81.25%, 
    rgba(181, 15, 42, 0.00) 100%)`;

  return (
    <div styleName='prematch__title' className='z-10 flex justify-center items-center uppercase pb-4 mb-10'>
      <h1 className={'font-second'}>{titleTranslation}<div styleName='branded-color-underline' style={linearGradientStyles} /></h1>
    </div>
  );
};

PreMatchDecoratedTitle.propTypes = {
  separatorColor: PropTypes.string.isRequired,
  titleTranslation: PropTypes.string,
};

PreMatchDecoratedTitle.defaultProps = {
  titleTranslation: null,
};

const PreMatchDecoratedTitleStyled = cssModule(PreMatchDecoratedTitle, style);
export default connect(mapStateToProps)(PreMatchDecoratedTitleStyled);
