import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import PeriodResultsTableRow from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/resultTable/PeriodResultsTableRow';
import { isEnded } from 'utils/matchstatus';
import {
  getMatchStatus,
  getReadyMatchId,
  getMatchSportId,
} from 'reducers';
import { matchStatusIds, sport } from 'constants/enum';

import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const mapStateToProps = state => ({
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const PeriodResultsTable = ({
  matchStatusId,
  sportId,
}) => {
  let matchEndedClass = '';
  let noPeriodsScoreClass = '';
  let scoreboardTableClass = 'scoreboard-table__row';

  if (isEnded(matchStatusId)) {
    matchEndedClass = 'scoreboard-table__row--ended';

    if (![sport.tennis, sport.table_tennis].includes(sportId)) {
      noPeriodsScoreClass = 'no-periods-score';
    }
  }

  if (sport.table_tennis === sportId) {
    scoreboardTableClass = 'scoreboard-table-tennis__row';
  }

  return (
    <div styleName={`${scoreboardTableClass} ${noPeriodsScoreClass} ${matchEndedClass}`} className='flex overflow-hidden flex-col h-full box-border justify-end'>
      <PeriodResultsTableRow side='home' />
      <PeriodResultsTableRow side='away' />
    </div>
  );
};

PeriodResultsTable.propTypes = {
  matchStatusId: PropTypes.number,
  sportId: PropTypes.number.isRequired,
};

PeriodResultsTable.defaultProps = {
  matchStatusId: matchStatusIds.not_started,
};
const PeriodResultsTableStyled = cssModule(PeriodResultsTable, style, { allowMultiple: true });
export default connect(mapStateToProps)(PeriodResultsTableStyled);
