import {
  useState, useCallback,
} from 'react';
import { convertToTimeString } from '../utils/index';
import { useInterval } from './index';

export const useTimer = (updateRate = 0.1, countdown = false) => {
  const [isRunning, setIsRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  const [speedupFactor, setSpeedupFactor] = useState(1);

  const run = useCallback(
    () => {
      if (isRunning) {
        setElapsedTime(prevElapsedTime => prevElapsedTime + updateRate * speedupFactor);
      }
    },
    [isRunning, speedupFactor, updateRate],
  );

  const countdownward = useCallback(
    () => {
      if (isRunning) {
        setElapsedTime(prevElapsedTime => {
          const a = prevElapsedTime - updateRate * speedupFactor;

          if (a < 0) {
            return 0;
          }
          return a;
        });
      }
    },
    [isRunning, speedupFactor, updateRate],
  );

  useInterval(() => {
    if (countdown) {
      countdownward();
    } else {
      run();
    }
  }, 1000 * updateRate);

  return {
    isRunning,
    setIsRunning,
    elapsedTime,
    setElapsedTime,
    setSpeedupFactor,
  };
};

export const useStopwatch = updateRate => {
  const [laps, setLaps] = useState([]);

  const {
    isRunning, setIsRunning, elapsedTime, setElapsedTime, setSpeedupFactor,
  } = useTimer(updateRate);

  const startTimer = useCallback(
    (initialTime = 0) => {
      setElapsedTime(initialTime);
      setIsRunning(true);
    },
    [setElapsedTime, setIsRunning],
  );

  const stopTimer = useCallback(
    (time = null) => {
      if (time !== null) {
        setElapsedTime(time);
      }
      setIsRunning(false);
    },
    [setElapsedTime, setIsRunning],
  );

  const pauseTimer = useCallback(
    () => setIsRunning(false),
    [setIsRunning],
  );

  const resumeTimer = useCallback(
    () => setIsRunning(true),
    [setIsRunning],
  );

  const setTimer = useCallback(
    (initialTime = 0) => {
      setElapsedTime(initialTime);
    },
    [setElapsedTime],
  );

  const resetTimer = useCallback(
    () => {
      setIsRunning(false);
      setElapsedTime(0);
      setLaps([]);
    },
    [setElapsedTime, setIsRunning],
  );

  const handleAddLap = useCallback(
    () => {
      const prevTotal = laps.length > 0 ? laps.reduce((acc, curr) => acc + curr, 0) : 0;
      const currentLap = laps.length > 0 ? elapsedTime - prevTotal : elapsedTime;
      if (isRunning) {
        setLaps([...laps, currentLap]);
      }
    },
    [elapsedTime, isRunning, laps],
  );

  const timeStr = convertToTimeString(elapsedTime);

  return {
    elapsedTimeSecs: elapsedTime,
    elapsedTime: timeStr,
    startTimer,
    stopTimer,
    pauseTimer,
    resetTimer,
    resumeTimer,
    addLap: handleAddLap,
    setSpeedupFactor,
    setTimer,
  };
};


export const useCountdown = (updateRate = 0.1) => {
  const [laps, setLaps] = useState([]);
  const countDown = true;

  const {
    isRunning, setIsRunning, elapsedTime, setElapsedTime, setSpeedupFactor,
  } = useTimer(updateRate, countDown);

  const startTimer = useCallback(
    (initialTime = 12 * 60) => {
      setElapsedTime(initialTime);
      setIsRunning(true);
    },
    [setElapsedTime, setIsRunning],
  );

  const stopTimer = useCallback(
    (time = null) => {
      if (time !== null) {
        setElapsedTime(time);
      }
      setIsRunning(false);
    },
    [setElapsedTime, setIsRunning],
  );

  const pauseTimer = useCallback(
    () => setIsRunning(false),
    [setIsRunning],
  );

  const resumeTimer = useCallback(
    () => setIsRunning(true),
    [setIsRunning],
  );

  const resetTimer = useCallback(
    () => {
      setIsRunning(false);
      setElapsedTime(0);
      setLaps([]);
    },
    [setElapsedTime, setIsRunning],
  );

  const setTimer = useCallback(
    (initialTime = 0) => {
      setElapsedTime(initialTime);
    },
    [setElapsedTime],
  );

  const handleAddLap = useCallback(
    () => {
      const prevTotal = laps.length > 0 ? laps.reduce((acc, curr) => acc + curr, 0) : 0;
      const currentLap = laps.length > 0 ? elapsedTime - prevTotal : elapsedTime;
      if (isRunning) {
        setLaps([...laps, currentLap]);
      }
    },
    [elapsedTime, isRunning, laps],
  );

  const timeStr = convertToTimeString(elapsedTime);

  return {
    elapsedTimeSecs: elapsedTime,
    elapsedTime: timeStr,
    startTimer,
    stopTimer,
    pauseTimer,
    resetTimer,
    resumeTimer,
    setTimer,
    addLap: handleAddLap,
    setSpeedupFactor,
  };
};
