import React from 'react';

import { eventTypeId } from 'constants/enum';
import StatWrapper from 'newDesign/modules/break/components/statSlides/liveStats/statWrapper/StatWrapper';

const Futsal = () => [

  <StatWrapper
    key={1}
    statKey={eventTypeId.corner_kick_count}
    relative
  />,
  <StatWrapper
    key={2}
    statKey={eventTypeId.direct_foul_count}
    higherIsBetter={false}
    relative
  />,
  <StatWrapper
    key={3}
    statKey={eventTypeId.direct_freekick_count}
    relative
  />,
  <StatWrapper
    key={4}
    statKey={eventTypeId.yellow_card_count}
    relative
    higherIsBetter={false}
  />,
  <StatWrapper
    key={5}
    statKey={eventTypeId.red_card_count}
    relative
    higherIsBetter={false}
  />,
];

export default Futsal;
