import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import TeamNames from 'newDesign/modules/preMatch/components/statSlides/common/components/teamNames/TeamNames';
import {
  getIsThreeDigitsResultAvailable,
  getThreeDigitsResultClassName,
} from 'newDesign/modules/common/utils/isThreeDigitsResultAvailable';
import { translationPeriodKey } from 'constants/enum';
import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';

import styles from './PreviousMeeting.scss';

const PreviousMeeting = ({
  tournaments, result, teams, date, sportId,
}) => {
  if (!result || !teams || !tournaments) {
    return null;
  }

  const homeWin = result.home > result.away;
  const awayWin = result.away > result.home;

  const isThreeDigitsResultAvailable = getIsThreeDigitsResultAvailable(sportId);
  const threeDigitsResultClassName = getThreeDigitsResultClassName(sportId);

  let period;

  if (['ot', 'ap', 'aet'].includes(result.period)) {
    period = (
      <React.Fragment>
        <div className={`z-10 flex flex-row justify-center items-center uppercase ${styles.prematch__period}`}>
          <TranslationFormattedMessage
            id={translationPeriodKey[result.period]}
          />
        </div>
        <div className={`flex flex-col h-full items-center mx-2 justify-center ${styles.prematch__period_separator}`} />
      </React.Fragment>
    );
  }

  return (
    <div className={'flex flex-col box-border'}>
      <div className={'z-10 flex font-main fs-18 items-center justify-center color-prematch-2'}>
        <div className={'flex w-4/5'}>
          <TeamNames competitorName={teams.home.name} activeClass={homeWin && styles.active} justifyClass={'justify-end'} />
        </div>
        <div className={'w-1/5 flex flex-nowrap items-center'}>
          <div className={`flex flex-col ml-4 mr-1 ${styles.prematch__scores} ${isThreeDigitsResultAvailable && styles[threeDigitsResultClassName]} ${homeWin && styles.active}`}>
            <span className={isThreeDigitsResultAvailable ? 'fs-17' : ''}>{result.home}</span>
          </div>
          <div className={`flex flex-col mr-4 ml-1 ${styles.prematch__scores} ${isThreeDigitsResultAvailable && styles[threeDigitsResultClassName]} ${awayWin && styles.active}`}>
            <span className={isThreeDigitsResultAvailable ? 'fs-17' : ''}>{result.away}</span>
          </div>
        </div>
        <div className={'flex w-4/5'}>
          <TeamNames competitorName={teams.away.name} activeClass={awayWin && styles.active} />
        </div>
      </div>
      <div className={'z-10 fs-16 pb-12 uppercase italic flex justify-center color-prematch-2 items-center'}>
        {period}{tournaments.name} ({date})
      </div>
    </div>
  );
};

PreviousMeeting.propTypes = {
  tournaments: PropTypes.object,
  result: PropTypes.object,
  teams: PropTypes.object,
  date: PropTypes.string,
  sportId: PropTypes.number.isRequired,
};

PreviousMeeting.defaultProps = {
  tournaments: null,
  result: null,
  teams: null,
  date: '',
};

const PreviousMeetingStyled = cssModule(PreviousMeeting, styles);
export default PreviousMeetingStyled;
