import React, {
  memo, useState, useEffect, Fragment,
} from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import ScoreBoardWrapper from 'newDesign/modules/common/components/oddsPanel/scoreBoard/scoreBoardManager/ScoreBoardManagerWrapper';
import ClientOrMatchId from 'newDesign/modules/common/components/oddsPanel/clientOrMatchId/ClientOrMatchId';
import OddsPanelMarket from 'newDesign/modules/common/components/oddsPanel/oddsPanelMarket/OddsPanelMarket';
import { makeMapStateToProps } from 'reducers/oddsProjection';
import { useIntervalIncrementer } from 'hooks/index';

import style from 'newDesign/modules/common/components/oddsPanel/OddsPanel.scss';

const OddsPanelMarketMemo = memo(OddsPanelMarket);

const getIndex = (i, startIdx, len) => {
  const zeroIndexed = (i + 1) % len;
  const cond = zeroIndexed < startIdx;
  const idx = cond ? zeroIndexed + startIdx : zeroIndexed;

  return idx;
};

const OddsPanel = ({
  matchId,
  marketsOutcomesArr,
  marketsNameShortArr,
  showMatchIdCmp,
  swapOverUnder,
  carousel,
}) => {
  const [mk1] = useState(0);
  const [mk2, setMk2] = useState(1);
  const [mk3, setMk3] = useState(2);
  const [mk4, setMk4] = useState(3);

  const count = useIntervalIncrementer(10000);

  const matchIdCmp = (
    <Fragment>
      {showMatchIdCmp ? <ClientOrMatchId matchId={matchId} /> : null}
    </Fragment>
  );

  useEffect(() => {
    if (carousel) {
      if (marketsNameShortArr.length <= 3 && count > 0) {
        setMk2(1);
        setMk3(2);
        setMk4(3);
      } else if (count > 0) {
        setMk2(mIdx => getIndex(mIdx, 1, marketsNameShortArr.length));
        setMk3(mIdx => getIndex(mIdx, 1, marketsNameShortArr.length));
        setMk4(mIdx => getIndex(mIdx, 1, marketsNameShortArr.length));
      }
    }
  }, [count, marketsNameShortArr.length, carousel]);


  if (!marketsOutcomesArr.length) {
    return (
      <Fragment>
        {matchIdCmp}
        <ScoreBoardWrapper />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {matchIdCmp}
      <ScoreBoardWrapper />
      <div styleName='odds-block' className='flex flex-col'>
        <OddsPanelMarketMemo
          outcomes={marketsOutcomesArr[mk1]}
          marketName={marketsNameShortArr[mk1]}
          swapOverUnder={swapOverUnder}
        />
        <OddsPanelMarketMemo
          outcomes={marketsOutcomesArr[mk2]}
          marketName={marketsNameShortArr[mk2]}
          swapOverUnder={swapOverUnder}
        />
        <OddsPanelMarketMemo
          outcomes={marketsOutcomesArr[mk3]}
          marketName={marketsNameShortArr[mk3]}
          swapOverUnder={swapOverUnder}
        />
        <OddsPanelMarketMemo
          outcomes={marketsOutcomesArr[mk4]}
          marketName={marketsNameShortArr[mk4]}
          swapOverUnder={swapOverUnder}
        />
      </div>
    </Fragment>
  );
};

OddsPanel.propTypes = {
  matchId: PropTypes.number.isRequired,
  marketsOutcomesArr: PropTypes.array,
  marketsNameShortArr: PropTypes.array,
  showMatchIdCmp: PropTypes.bool,
  swapOverUnder: PropTypes.bool,
  carousel: PropTypes.number,
};

OddsPanel.defaultProps = {
  marketsOutcomesArr: [],
  marketsNameShortArr: [],
  showMatchIdCmp: true,
  swapOverUnder: false,
  carousel: 0,
};
const OddsPanelStyled = cssModule(OddsPanel, style);
export default connect(
  makeMapStateToProps,
)(OddsPanelStyled);
