import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchId,
  getMatchFirstHalfScore,
} from 'reducers';
import NumberStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberStatSlide';

const mapStateToProps = state => ({
  stat: getMatchFirstHalfScore(state, getMatchId(state)),
});

const FirstHalfScore = ({
  stat,
}) => {
  const intl = useIntl();

  if (!stat) {
    return null;
  }

  const homeRounded = stat.home;
  const awayRounded = stat.away;

  return (
    <NumberStatSlide
      statText={intl.formatMessage({ id: 't_footer_first_half_score' })}
      homeStat={homeRounded}
      awayStat={awayRounded}
      higherIsBetter
    />
  );
};

FirstHalfScore.propTypes = {
  stat: PropTypes.object,
};

FirstHalfScore.defaultProps = {
  stat: null,
};

export default connect(mapStateToProps)(FirstHalfScore);
