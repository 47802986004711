// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Match__event__meta___3DQ9F{background:#444e60;background:linear-gradient(90deg, rgba(68, 78, 96, 0) 0%, rgba(68, 78, 96, 0.4) 25%, #444e60 50%, rgba(68, 78, 96, 0.4) 75%, rgba(68, 78, 96, 0) 100%);color:#fff;font:100% \"Roboto Condensed\",sans-serif;font-size:24px}.Match__event__meta___3DQ9F:last-child{background:linear-gradient(90deg, rgba(140, 145, 153, 0) 0%, rgba(140, 145, 153, 0.4) 25%, #8c9199 50%, rgba(140, 145, 153, 0.4) 75%, rgba(140, 145, 153, 0) 100%)}@media only screen and (min-width: 1536px){.Match__event__meta___3DQ9F{font-size:1.3vw}}.Match__event___2VpOX.Match__event__doubles___Tqus- .Match__competitor-logo___1oTiN>div{left:90%;gap:1vw}.Match__event___2VpOX.Match__event__doubles___Tqus- .Match__competitor-logo___1oTiN img{max-width:5vw}.Match__event___2VpOX.Match__event__doubles___Tqus- .Match__competitor-logo___1oTiN:last-child>div{right:90%;left:initial}.Match__event___2VpOX.Match__event__doubles___Tqus- .Match__team___WS_d3{line-height:initial;padding:.2vw 1vw .2vw 2vw;position:relative;gap:.2vw}", ""]);
// Exports
exports.locals = {
	"event__meta": "Match__event__meta___3DQ9F",
	"event": "Match__event___2VpOX",
	"event__doubles": "Match__event__doubles___Tqus-",
	"competitor-logo": "Match__competitor-logo___1oTiN",
	"team": "Match__team___WS_d3"
};
module.exports = exports;
