import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import OddsPanelOutcome from 'newDesign/modules/common/components/oddsPanel/oddsPanelOutcome/OddsPanelOutcome';
import { overUnderMarkets } from 'constants/enum';

import style from 'newDesign/modules/common/components/oddsPanel/oddsPanelMarket/OddsPanelMarket.scss';

const OddsPanelMarket = ({
  outcomes = [],
  marketName,
  swapOverUnder,
}) => {
  if (!outcomes.length) {
    return null;
  }

  const isOverUnderMarket = overUnderMarkets.includes(outcomes[0].marketId);
  let outcomeComps = null;

  outcomeComps = outcomes.map(outcome => (
    <OddsPanelOutcome
      key={outcome.tbid}
      outcome={outcome}
    />
  ));

  if (isOverUnderMarket && swapOverUnder) {
    outcomeComps = outcomeComps.reverse(); // swap over under outcomes
  }

  return (
    <div>
      <div styleName='odd-title' className='flex box-border fs-17 font-second justify-between'>
        <span>{marketName}</span>
      </div>
      <div styleName='odd-number' className={`${outcomeComps.length === 3 ? 'justify-between' : 'justify-around'} odd-number fs-20 font-second--bold flex box-border justify-between`}>
        {outcomeComps}
      </div>
    </div>
  );
};
OddsPanelMarket.propTypes = {
  outcomes: PropTypes.array,
  marketName: PropTypes.string,
  swapOverUnder: PropTypes.bool,
};

OddsPanelMarket.defaultProps = {
  outcomes: [],
  marketName: '',
  swapOverUnder: false,
};
const OddsPanelMarketStyled = cssModule(OddsPanelMarket, style);
export default OddsPanelMarketStyled;
