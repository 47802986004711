import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import Logo from 'newDesign/modules/common/components/logo/Logo';
import PreMatchHeaderMatchInformation
from 'newDesign/modules/preMatch/common/components/preMatchHeaderMatchInformation/PreMatchHeaderMatchInformation';
import useImportImageFacelift from 'newDesign/modules/common/hooks/ImportImage';
import { getBackgroundImageUrl } from 'newDesign/modules/common/utils/sportBackground';
import {
  getLogoBgColor,
  getLogoUrl,
  getMatchGround,
  getMatchSportId,
  getReadyMatchId,
} from 'reducers';

import style from 'newDesign/modules/common/components/layout/twoColSidebarRightNewDesign/TwoColSidebarRightNewDesign.scss';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
  logoUrl: getLogoUrl(state),
  logoBgColor: getLogoBgColor(state),
  ground: getMatchGround(state, getReadyMatchId(state)),
});

const TwoColSidebarRightNewDesign = ({
  main,
  sidebar,
  matchId,
  sportId,
  logoUrl,
  logoBgColor,
  ground,
}) => {
  const backgroundImage = useImportImageFacelift(getBackgroundImageUrl(sportId, ground, 'statSlides'));

  return (
    <div styleName='prematch' className={'min-h-screen flex'}>
      <div className={'flex-1 relative justify-start items-center flex-col flex'}>
        <div styleName='prematch__background'>
          <img alt={''} className={'h-full w-full object-cover'} src={backgroundImage} />
        </div>
        <div styleName='bg-container' className={'z-10 h-full flex flex-col px-24 box-border'}>
          <PreMatchHeaderMatchInformation matchId={matchId} displayStadium={false} />
          <div className={'mb-8'} />
          {main}
        </div>
      </div>
      <div className='odds-panel'>
        <div
          className='flex justify-center items-center p-[0.8vw]'
          style={{
            background: `${logoBgColor}`,
          }}
        >
          <Logo logoClassName='odds-panel-logo' logoUrl={logoUrl} />
        </div>
        {sidebar}
      </div>
    </div>
  );
};

TwoColSidebarRightNewDesign.propTypes = {
  main: PropTypes.element.isRequired,
  sidebar: PropTypes.element.isRequired,
  sportId: PropTypes.number.isRequired,
  matchId: PropTypes.number.isRequired,
  logoUrl: PropTypes.string,
  logoBgColor: PropTypes.string.isRequired,
  ground: PropTypes.string,
};

TwoColSidebarRightNewDesign.defaultProps = {
  logoUrl: '',
  ground: '',
};

const TwoColSidebarRightNewDesignStyled = cssModule(TwoColSidebarRightNewDesign, style);
export default connect(mapStateToProps)(TwoColSidebarRightNewDesignStyled);
