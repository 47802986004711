import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import {
  getSeparatorColor,
} from 'reducers';

import style from 'newDesign/modules/announcement/components/noScheduledMatches/NoScheduledMatches.scss';

const mapStateToProps = state => ({
  separatorColor: getSeparatorColor(state),
});

const NoScheduledMatches = ({ separatorColor }) => (
  <div
    styleName='rectangle'
    className='flex justify-center align-middle py-8 mt-8'
    style={{
      borderImageSource: `linear-gradient( 280deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 20%, ${separatorColor} 50%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0) )`,
    }}
  >
    <h2 className='uppercase'><TranslationFormattedMessage id='t_announcement_no_more_matches' /></h2>
  </div>
);

NoScheduledMatches.propTypes = {
  separatorColor: PropTypes.string.isRequired,
};

const NoScheduledMatchesStyled = cssModule(NoScheduledMatches, style);
export default connect(mapStateToProps)(NoScheduledMatchesStyled);
