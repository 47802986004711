import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Matches from 'newDesign/modules/preMatch/components/statSlides/lastMatches/matches/Matches';
import {
  getReadyMatchId,
  getMatchTeams, getMatchSportId,
} from 'reducers/index';
import WDLResultBox from 'newDesign/modules/preMatch/components/statSlides/common/components/WDLResultBox/WDLResultBox';

import styles from 'newDesign/modules/preMatch/components/statSlides/lastMatches/LastMatches.scss';
import { getTeamNameProperty } from 'newDesign/modules/common/utils/teamName';
import { sport } from 'constants/enum';
import { splitTableTennisTeamNames } from 'utils/tableTennisHelpers';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
  teams: getMatchTeams(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const LastMatches = ({
  matchId,
  teams,
  sportId,
}) => {
  let homeTeam = teams.home;
  let awayTeam = teams.away;
  const teamNameProperty = getTeamNameProperty(sportId);

  if (sportId === sport.table_tennis
    && teams.tennisDouble
    && (!teams.home[1] || !teams.away[1])) {
    const splitTeams = splitTableTennisTeamNames(teams);
    homeTeam = splitTeams.homeTeam;
    awayTeam = splitTeams.awayTeam;
  }

  return (
    <Fragment>
      <div className={`z-10 ${styles.competitors_form} ${styles.competitors_form__arrow} flex justify-center mb-4`}>
        <span className={'uppercase fs-18'}>
          {homeTeam[0][teamNameProperty]}<br />
          {(1 in homeTeam) && homeTeam[1][teamNameProperty]}
        </span>
        <span className={'uppercase fs-18'}>
          {awayTeam[0][teamNameProperty]}<br />
          {(1 in awayTeam) && awayTeam[1][teamNameProperty]}
        </span>
      </div>
      <WDLResultBox matchId={matchId} />
      <Matches
        matchId={matchId}
      />
    </Fragment>
  );
};

LastMatches.propTypes = {
  matchId: PropTypes.number.isRequired,
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
    tennisDouble: PropTypes.array,
  }),
  sportId: PropTypes.number.isRequired,
};

LastMatches.defaultProps = {
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
    tennisDouble: [
      { home: [{ name: '', abbr: '' }] },
      { away: [{ name: '', abbr: '' }] },
    ],
  },
};

export default connect(mapStateToProps)(LastMatches);
