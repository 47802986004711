import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchId,
  getMatchTeams,
} from 'reducers';
import NumberStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberStatSlide';

const mapStateToProps = state => ({
  teams: getMatchTeams(state, getMatchId(state)),
});

const getNationalityText = player => {
  try {
    return player.countryCodeA2 ? player.cc.a3 : '-';
  } catch (error) {
    return '';
  }
};

const formatDoubleNationality = team => team
  .map((player, i) => (i === 1 ? '/' : '') + getNationalityText(player)).join('');

const Nationality = ({ teams }) => {
  const intl = useIntl();

  if (!teams || !teams.home) {
    return null;
  }

  const homeNationality = formatDoubleNationality(teams.home);
  const awayNationality = formatDoubleNationality(teams.away);

  return (
    <NumberStatSlide
      statText={intl.formatMessage({ id: 't_footer_nationality' })}
      homeStat={homeNationality}
      awayStat={awayNationality}
      disableComparison
    />
  );
};

Nationality.propTypes = {
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
};

Nationality.defaultProps = {
  teams: null,
};

export default connect(mapStateToProps)(Nationality);
