import PropTypes from 'prop-types';

import useSoccerClock from 'newDesign/modules/common/hooks/clock/SoccerClockHook';

const SoccerClock = ({
  statusId,
  pTime,
  sportId,
  periodLength,
  numPeriods,
  suspended,
}) => {
  const time = useSoccerClock(
    statusId,
    pTime,
    sportId,
    periodLength,
    suspended,
    numPeriods,
  );

  return time;
};

SoccerClock.propTypes = {
  statusId: PropTypes.number,
  pTime: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sportId: PropTypes.number,
  periodLength: PropTypes.number,
  numPeriods: PropTypes.number,
  suspended: PropTypes.bool,
};

SoccerClock.defaultProps = {
  statusId: null,
  pTime: null,
  sportId: null,
  periodLength: null,
  numPeriods: null,
  suspended: false,
};

export default SoccerClock;
