// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Jersey__jersey_big___2KqP2{width:150px;height:250px}.Jersey__jersey_big_no_sleeves___1LTeQ{width:90px;height:250px}.Jersey__jersey_auto___FaKUX{width:4.5vw;height:auto}.Jersey__jersey_auto_period_sport___2d9WI{width:4.5vw;height:auto;max-height:3.6vw}.Jersey__jersey_auto_no_sleeves___38bx_{width:2.733vw;height:auto;max-height:3.6vw}.Jersey__jersey_halftime_stats___1mJgS{height:9vw;width:auto}.Jersey__jersey_timeline_stats___2JJHZ{height:4vw;filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))}", ""]);
// Exports
exports.locals = {
	"jersey_big": "Jersey__jersey_big___2KqP2",
	"jersey_big_no_sleeves": "Jersey__jersey_big_no_sleeves___1LTeQ",
	"jersey_auto": "Jersey__jersey_auto___FaKUX",
	"jersey_auto_period_sport": "Jersey__jersey_auto_period_sport___2d9WI",
	"jersey_auto_no_sleeves": "Jersey__jersey_auto_no_sleeves___38bx_",
	"jersey_halftime_stats": "Jersey__jersey_halftime_stats___1mJgS",
	"jersey_timeline_stats": "Jersey__jersey_timeline_stats___2JJHZ"
};
module.exports = exports;
