// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HOTD__prematch___1x29E{color:#fff}.HOTD__prematch__background___2ppTl{position:absolute;background-size:cover;height:100%;width:100%}.HOTD__prematch__background___2ppTl::before{opacity:.95;background:rgba(0,0,0,.5);position:absolute;width:100%;height:100%;content:\"\"}.HOTD__bg-container___1kDi9{background:linear-gradient(180deg, rgba(0, 0, 0, 0.29) 0.02%, rgba(0, 0, 0, 0.6) 43.24%, rgba(0, 0, 0, 0.39) 58.83%, rgba(0, 0, 0, 0) 94.96%, rgba(0, 0, 0, 0) 136.06%);position:relative;min-width:65%;max-width:65%}.HOTD__bg-container___1kDi9::before{background:linear-gradient(191deg, rgba(16, 13, 13, 0.23) 8.22%, rgba(0, 0, 0, 0.3) 91.77%);box-shadow:0px 8px 30px 0px rgba(0,0,0,.25);-webkit-backdrop-filter:blur(12.5px);backdrop-filter:blur(12.5px);position:absolute;width:100%;height:100%;content:\"\";left:0;right:0}.HOTD__bg-container___1kDi9::after{background:linear-gradient(178deg, rgba(255, 255, 255, 0.06) 20.14%, rgba(255, 255, 255, 0.19) 49.66%, rgba(255, 255, 255, 0) 123.85%);box-shadow:0px 8px 30px 0px rgba(0,0,0,.25);-webkit-backdrop-filter:blur(12.5px);backdrop-filter:blur(12.5px);position:absolute;width:100%;height:100%;content:\"\";left:0;right:0;opacity:.8}@media only screen and (min-width: 1536px){#lcr__wrapper .highlightOfTheDayWrapper .preMatchMetaHeader > span:last-child{font-size:1.2vw}}", ""]);
// Exports
exports.locals = {
	"prematch": "HOTD__prematch___1x29E",
	"prematch__background": "HOTD__prematch__background___2ppTl",
	"bg-container": "HOTD__bg-container___1kDi9"
};
module.exports = exports;
