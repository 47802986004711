import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isNil as _isNil } from 'lodash';
import Flags from '../flags/Flags';

const TennisFlags = ({ teams }) => (
  <div className={'flex flex-row align-middle'}>
    {teams.map(team => {
      const uid = team.countryCodeA2 ? team.countryCodeA2 : team.uniqueId;
      const crestType = team.countryCodeA2 ? 'flag-crest' : '';

      if (_isNil(uid)) {
        return null;
      }

      return (
        <Fragment key={team.uniqueId}>
          <Flags uid={uid} typeClassName={crestType} />
        </Fragment>
      );
    })}
  </div>
);

TennisFlags.propTypes = {
  teams: PropTypes.array.isRequired,
};

export default TennisFlags;
