import React from 'react';
import cssModule from 'react-css-modules';
import { useImportImageNewDesign } from 'hooks/index';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';

const PlayerIcon = () => {
  const controllerIcon = useImportImageNewDesign('general/esoccer.svg');

  return (
    <img styleName={'icon-controller'} src={controllerIcon} alt={''} />
  );
};

const PlayerIconStyled = cssModule(PlayerIcon, style);

export default PlayerIconStyled;
