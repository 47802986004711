// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LeagueTable__prematch__table___3ruQe{row-gap:1.65vw;overflow:hidden}.LeagueTable__prematch__table___3ruQe span{white-space:nowrap;text-transform:uppercase}.LeagueTable__prematch__table___3ruQe .LeagueTable__grid___30WER{row-gap:1.25vw;-moz-column-gap:1.25vw;column-gap:1.25vw;position:relative;top:0}.LeagueTable__prematch__table___3ruQe .LeagueTable__odd-arrow___2nnv7{opacity:0}.LeagueTable__prematch__table___3ruQe .LeagueTable__odd-arrow___2nnv7 svg{height:.9vw}.LeagueTable__prematch__table___3ruQe .LeagueTable__active___28h1y{color:#ddf51b;opacity:1}.LeagueTable__prematch__table___3ruQe span.LeagueTable__t-number___SvXFG.LeagueTable__active___28h1y{position:relative;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;-ms-grid-row-align:center;align-items:center}.LeagueTable__prematch__table___3ruQe span.LeagueTable__t-number___SvXFG.LeagueTable__active___28h1y::after{position:absolute;width:2.3vw;height:2.3vw;border:1px solid #ddf51b;content:\"\";border-radius:.25rem}.LeagueTable__prematch__table___3ruQe .LeagueTable__t-body___m2Wm0{row-gap:1.25vw}", ""]);
// Exports
exports.locals = {
	"prematch__table": "LeagueTable__prematch__table___3ruQe",
	"grid": "LeagueTable__grid___30WER",
	"odd-arrow": "LeagueTable__odd-arrow___2nnv7",
	"active": "LeagueTable__active___28h1y",
	"t-number": "LeagueTable__t-number___SvXFG",
	"t-body": "LeagueTable__t-body___m2Wm0"
};
module.exports = exports;
