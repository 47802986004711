import React from 'react';

import { eventTypeId } from 'constants/enum';
import StatWrapper from 'newDesign/modules/break/components/statSlides/liveStats/statWrapper/StatWrapper';
import AttStrength from 'newDesign/modules/break/components/statSlides/liveStats/attStrength/AttStrength';

const Soccer = () => [
  <AttStrength
    key={1}
  />,
  <StatWrapper
    key={2}
    statKey={eventTypeId.yellow_card}
    higherIsBetter={false}
    relative
  />,
  <StatWrapper
    key={3}
    statKey={eventTypeId.yellow_red_card}
    higherIsBetter={false}
    relative
  />,
  <StatWrapper
    key={4}
    statKey={eventTypeId.red_card}
    higherIsBetter={false}
    relative
  />,
  <StatWrapper
    key={5}
    statKey={eventTypeId.corner_kick_count}
    relative
  />,
  <StatWrapper
    key={6}
    statKey={eventTypeId.shot_on_target_count}
    relative
    hideOnEmpty
  />,
  <StatWrapper
    key={7}
    statKey={eventTypeId.shot_off_target_count}
    relative
    hideOnEmpty
  />,
  <StatWrapper
    key={8}
    statKey={eventTypeId.save_count}
    relative
  />,
  <StatWrapper
    key={9}
    statKey={eventTypeId.goalattempts}
    relative
  />,
  <StatWrapper
    key={10}
    statKey={eventTypeId.free_kick_count}
    relative
  />,
  <StatWrapper
    key={11}
    statKey={eventTypeId.ball_possession}
    percentage
    hideOnEmpty
  />,
  <StatWrapper
    key={12}
    statKey={eventTypeId.offside_count}
    relative
  />,
];

export default Soccer;
