// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr__wrapper .double-logo img:first-child{width:80% !important;bottom:0;margin-bottom:.25rem}#lcr__wrapper .double-logo img:last-child{width:80% !important;top:0;margin-top:.25rem}#lcr__wrapper .double-logo img.flag-crest:first-child{width:80% !important;bottom:0;margin-bottom:-11%}#lcr__wrapper .double-logo img.flag-crest:last-child{width:80% !important;top:0;margin-top:-11%}#lcr__wrapper .double-flags-positioning img.first-flag{margin-top:-10px}#lcr__wrapper .double-flags-positioning img.second-flag{margin-top:-15px}#nd__wrapper .double-flags-positioning img.not-started-alignment{margin-top:-10px}.MatchTeamCrest__competitor-logo___16L-a{max-width:6%;min-width:6%}.MatchTeamCrest__competitor-logo___16L-a:first-child img{left:80%}.MatchTeamCrest__competitor-logo___16L-a:last-child img{right:80%}", ""]);
// Exports
exports.locals = {
	"competitor-logo": "MatchTeamCrest__competitor-logo___16L-a"
};
module.exports = exports;
