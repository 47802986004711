import { createReducer } from 'utils';
import {
  SET_LIVEPLAYER_INSTANCE,
  STREAM_IS_PLAYING,
  STREAM_STOPPED_PLAYING,
  PLAYER_LOADING,
  PLAYER_LOAD_ERROR,
  PLAYER_LOAD_SUCCESS,
} from 'constants/actions';
import { createSelector } from 'reselect';

const initialState = {
  liveplayerInstance: null,
  isPlaying: false,
  playerLoaded: false,
  playerLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [SET_LIVEPLAYER_INSTANCE]: (state, payload) => ({
    ...state,
    liveplayerInstance: payload,
  }),
  [STREAM_IS_PLAYING]: state => ({
    ...state,
    isPlaying: true,
  }),
  [STREAM_STOPPED_PLAYING]: state => ({
    ...state,
    isPlaying: false,
  }),

  [PLAYER_LOADING]: state => ({
    ...state,
    playerLoaded: false,
    isLoading: true,
  }),
  [PLAYER_LOAD_ERROR]: (state, payload) => ({
    ...state,
    playerLoaded: false,
    isLoading: false,
    error: payload,

  }),
  [PLAYER_LOAD_SUCCESS]: state => ({
    ...state,
    playerLoaded: true,
    isLoading: false,

  }),
});

export const getIsPlayerLoaded = createSelector(
  state => state.playerLoaded,
  playerLoaded => playerLoaded,
);
