
import {
  put,
  select,
  apply,
  call,
} from 'redux-saga/effects';
import * as config from 'Config/fishnetUrls';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
} from 'reducers';
import { MatchTimelineRequest } from 'fishnet-api';
import { updateMatchTimelineFailed, updateMatchTimeline } from 'actions/fishnet';
import { pollTillResponse } from '../utilLCR';

function* loadTimeline(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));

  const request = new MatchTimelineRequest(matchId, clientAlias, language, qFeed, offset);
  const response = yield apply(request, request.get);

  if (response && !response.error) {
    yield put(updateMatchTimeline(response, matchId));
  } else if (response && response.error) {
    yield put(updateMatchTimelineFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update timeline failed');
  }
}

// request once
function* pollTimeline(matchId, offset) {
  yield call(pollTillResponse, {}, loadTimeline, matchId, offset); // poll this endpoint once
}

export default pollTimeline;
