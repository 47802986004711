import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import CompetitorAvatar from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/competitorAvatar/CompetitorAvatar';
import Score from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/sports/otherSports/Score';
import {
  getMatchJerseys,
  getMatchSportId,
  getMatchTeams,
  getReadyMatchId,
} from 'reducers/index';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';
import { getTeamNameProperty } from 'newDesign/modules/common/utils/teamName';

const mapStateToProps = state => ({
  jerseys: getMatchJerseys(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  teams: getMatchTeams(state, getReadyMatchId(state)),
});

const OtherSports = ({
  jerseys, teams, sportId,
}) => {
  const homeTeam = teams.home;
  const awayTeam = teams.away;
  const teamNameProperty = getTeamNameProperty(sportId);

  return (
    <div styleName='halftimestats__content__header' className='flex w-full'>
      <div className='flex flex-col items-center h-full'>
        <div className='flex justify-center items-center'>
          <CompetitorAvatar sportId={sportId} jersey={jerseys.home.player} />
        </div>
        <span styleName='halftimestats-competitior' className='fs-20 font-main--bold uppercase text-center'>{homeTeam[0][teamNameProperty]}
        </span>
      </div>

      <Score />

      <div className='flex flex-col items-center h-full'>
        <div className='flex justify-center items-center'>
          <CompetitorAvatar sportId={sportId} jersey={jerseys.away.player} />
        </div>
        <span styleName='halftimestats-competitior' className='fs-20 font-main--bold uppercase text-center'>{awayTeam[0][teamNameProperty]}
        </span>
      </div>
    </div>
  );
};

OtherSports.propTypes = {
  jerseys: PropTypes.object,
  sportId: PropTypes.number.isRequired,
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
};

OtherSports.defaultProps = {
  jerseys: {
    away: {
      player: {},
    },
    home: {
      player: {},
    },
  },
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};

const SoccerStyled = cssModule(OtherSports, style);
export default connect(mapStateToProps)(SoccerStyled);
