/* eslint-disable max-classes-per-file */
import lcrRequest from 'lcrRequest';
import { DASH } from 'constants/bcms';
import { isEmpty as _isEmpty } from 'lodash';

export class StreamURLRequest {
  constructor(feed, type, options = undefined) {
    const paramArr = Object.keys(options).map(
      param => `${param}=${options[param]}`,
    );

    let paramStr = '';

    // Avoid empty & in the URL if there are no parameters
    if (!_isEmpty(paramArr)) {
      paramStr = `?${paramArr.join('&')}`;
    }

    this.url = `${feed}${paramStr}`;
    this.type = type;
  }

  get() {
    return lcrRequest
      .get(this.url, { credentials: 'include' })
      .then(data => {
        if (data) {
          return {
            url: data.url,
            type: this.type,
          };
        }
        return null;
      })
      .catch(() => null);
  }
}

export class StreamUrlRequest extends StreamURLRequest {
  constructor(streamUrl, matchId, userId, channelId) {
    super(streamUrl, DASH, {
      matchid: matchId,
      userid: userId,
      channelid: channelId,
    });
  }
}

export class LcrDashUrlWorldLotteryRequest extends StreamURLRequest {
  constructor(worldLotteryUrl, isOperator, userId) {
    const options = {};

    if (isOperator) {
      options.user_id = userId;
    }

    super(worldLotteryUrl, DASH, options);
  }
}


export class LcrStreamUrlRequest extends StreamURLRequest {
  constructor(streamUrl, matchId, isOperator, userId) {
    const options = {
      match_id: matchId,
    };

    if (isOperator) {
      options.user_id = userId;
    }

    super(streamUrl, DASH, options);
  }
}
