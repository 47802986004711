import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { getReadyMatchId } from 'reducers/index';
import useOnScrollVertical from 'newDesign/modules/common/hooks/OnScrollVertical';
import Table from 'newDesign/modules/preMatch/components/statSlides/leagueTable/components/table/Table';
import { SEC } from 'constants/app';

import styles from 'newDesign/modules/preMatch/components/statSlides/leagueTable/components/leagueTable/LeagueTable.scss';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
});

const LeagueTable = ({ matchId }) => {
  const intl = useIntl();

  const scrollEndRef = useRef(null);
  const scrollPosition = useOnScrollVertical(scrollEndRef, 1, 4 * SEC);

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'changeTotal', // accessor is the "key" in the data
      },
      {
        Header: intl.formatMessage({ id: 't_statistics_pos' }),
        accessor: 'pos',
      },
      {
        Header: intl.formatMessage({ id: 't_statistics_team' }),
        accessor: 'name',
      },
      {
        Header: intl.formatMessage({ id: 't_statistics_pld' }),
        accessor: 'pld',
      },
      {
        Header: intl.formatMessage({ id: 't_statistics_w' }),
        accessor: 'wins',
      },
      {
        Header: intl.formatMessage({ id: 't_statistics_d' }),
        accessor: 'draws',
      },
      {
        Header: intl.formatMessage({ id: 't_statistics_l' }),
        accessor: 'losses',
      },
      {
        Header: intl.formatMessage({ id: 't_statistics_gf_ga' }),
        accessor: 'gfga',
      },
      {
        Header: intl.formatMessage({ id: 't_statistics_pts' }),
        accessor: 'pts',
      },
    ],
    [intl],
  );

  return (
    <div styleName='prematch__table' className={'z-10 fs-18 font-second'}>
      <div
        styleName='grid'
        className={'grid grid-cols-[0.3fr_0.3fr_3fr_0.3fr_0.3fr_0.3fr_0.3fr_0.3fr_0.3fr]'}
        style={{ 'top': -scrollPosition + 'px' }}
      >
        <Table matchId={matchId} columns={columns} />
        <div ref={scrollEndRef} />
      </div>
    </div>
  );
};

LeagueTable.propTypes = {
  matchId: PropTypes.number.isRequired,
};

const LeagueTableStyled = cssModule(LeagueTable, styles);
export default connect(mapStateToProps)(LeagueTableStyled);
