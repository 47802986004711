import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { useIntl } from 'react-intl';

import { useBestStat } from 'hooks';
import { percentageValueFromString } from 'utils';
import { eventTypeId } from 'constants/enum';
import GeneralStatTemplate from 'newDesign/modules/break/components/statSlides/liveStats/stat/generalStatTemplate/GeneralStatTemplate';
import CardStatTemplate from 'newDesign/modules/break/components/statSlides/liveStats/stat/cardStatTemplate/CardStatTemplate';
import SuspensionStatTemplate from 'newDesign/modules/break/components/statSlides/liveStats/stat/suspensionStatTemplate/SuspensionStatTemplate';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';

const Stat = ({
  text,
  percentage,
  stat,
  higherIsBetter,
  relative,
  hideOnEmpty,
  statKey,
}) => {
  const intl = useIntl();

  let [homeStat, awayStat] = stat ? [stat.home || 0, stat.away || 0] : [0, 0]; // can't use condition before hook
  const [homeIsBest, awayIsBest] = useBestStat(homeStat, awayStat, higherIsBetter);

  if (!stat || (hideOnEmpty && homeStat === 0 && awayStat === 0)) {
    return null;
  }

  let homeExtraStats = [];
  let awayExtraStats = [];
  let equal = false;
  let zeroEqual = false;
  let homeExtra = '';
  let awayExtra = '';


  if ((homeStat != null) && (typeof homeStat !== 'number')) {
    homeExtraStats = homeStat.split('/');
  }

  if ((awayStat != null) && (typeof awayStat !== 'number')) {
    awayExtraStats = awayStat.split('/');
  }

  // Stat relative to other team
  // Example: homeStat = 10 points = 100% filled bar, awayStat = 7 points = 70% filled bar
  if (relative && homeStat === awayStat) {
    equal = true;

    if (homeStat === 0) {
      zeroEqual = true;
    }
  }


  /**
   * Divides first number with last number.
   * Example input string "4/6/10" = 4 made shots / 6 missed shots / 10 total shots => 4 / 10 = 40%
   * Example input string "4/10" = 4 made shots / 10 total shots => 4 / 10 = 40%
  */
  if (homeExtraStats.length >= 2) {
    homeStat = percentageValueFromString(homeStat, 0);
    homeStat += '%';
    homeExtra = homeExtraStats[0] + '/' + homeExtraStats.pop();
  } else if (percentage) {
    // been directly passed percentage
    homeStat += '%';
  }

  if (awayExtraStats.length >= 2) {
    awayStat = percentageValueFromString(awayStat, 0);
    awayStat += '%';
    awayExtra = awayExtraStats[0] + '/' + awayExtraStats.pop();
  } else if (percentage) {
    // been directly passed percentage
    awayStat += '%';
  }

  const homeIsHighlighted = homeIsBest || (equal && higherIsBetter && !zeroEqual);
  const awayIsHighlighted = awayIsBest || (equal && higherIsBetter && !zeroEqual);

  let homeStatTemplate;
  let awayStatTemplate;

  switch (statKey) {
    case eventTypeId.yellow_card:
    case eventTypeId.yellow_card_count:
      homeStatTemplate = <CardStatTemplate stat={homeStat} cardStyleName='card--yellow' />;
      awayStatTemplate = <CardStatTemplate stat={awayStat} cardStyleName='card--yellow' />;
      break;
    case eventTypeId.yellow_red_card:
      homeStatTemplate = <CardStatTemplate stat={homeStat} cardStyleName='card--red' />;
      awayStatTemplate = <CardStatTemplate stat={awayStat} cardStyleName='card--red' />;
      break;
    case eventTypeId.red_card:
    case eventTypeId.red_card_count:
      homeStatTemplate = <CardStatTemplate stat={homeStat} cardStyleName='card--red' />;
      awayStatTemplate = <CardStatTemplate stat={awayStat} cardStyleName='card--red' />;
      break;
    case eventTypeId.suspensions:
      homeStatTemplate = <SuspensionStatTemplate stat={homeStat} isBetter={homeIsHighlighted} />;
      awayStatTemplate = <SuspensionStatTemplate stat={awayStat} isBetter={awayIsHighlighted} />;
      break;
    default:
      homeStatTemplate = <GeneralStatTemplate stat={homeStat} extra={homeExtra} isBetter={homeIsHighlighted} />;
      awayStatTemplate = <GeneralStatTemplate stat={awayStat} extra={awayExtra} isBetter={awayIsHighlighted} />;
      break;
  }

  return (
    <div styleName='halftimestats__content__body' className='flex flex-col w-full'>
      <div className='flex flex-col'>
        <div styleName='halftimestats-row' className='flex justify-center items-center'>
          <div className='flex justify-center'>
            {homeStatTemplate}
          </div>
          <div className='flex justify-center'>
            <span className='text-center font-main fs-18'>{text ? intl.formatMessage({ id: text }) : stat.name}</span>
          </div>
          <div className='flex justify-center'>
            {awayStatTemplate}
          </div>
        </div>
      </div>
    </div>
  );
};

Stat.propTypes = {
  higherIsBetter: PropTypes.bool,
  text: PropTypes.string,
  stat: PropTypes.object,
  relative: PropTypes.bool,
  percentage: PropTypes.bool,
  hideOnEmpty: PropTypes.bool,
  statKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Stat.defaultProps = {
  stat: null,
  higherIsBetter: true,
  relative: false,
  percentage: false,
  text: '',
  hideOnEmpty: false,
  statKey: '',
};

const MatchesStyled = cssModule(Stat, style);
export default MatchesStyled;
