import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import Score from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/board/Score';
import Team from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/board/team/Team';
import { isNotStarted } from 'utils/matchstatus';
import { matchStatusIds } from 'constants/enum';
import {
  getMatchTeams,
  getReadyMatchId,
  getMatchStatus,
  getMatchTennisDouble,
  getMatchJerseys,
  getMatchSportId,
} from 'reducers';

import { PERIOD_BASE_SPORTS } from 'constants/components';
import style from '../../ScoreBoard.scss';

const mapStateToProps = state => ({
  teams: getMatchTeams(state, getReadyMatchId(state)),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
  tennisDouble: getMatchTennisDouble(state, getReadyMatchId(state)),
  jerseys: getMatchJerseys(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const Board = ({
  teams,
  matchStatusId,
  tennisDouble,
  jerseys,
  sportId,
}) => {
  const isPeriodBasedSportsWithFlags = PERIOD_BASE_SPORTS.includes(sportId);

  let homeTeam;
  let awayTeam;
  let flagsHome;
  let flagsAway;
  let periodBasedSportsWithFlagsNotStartedClass = '';

  if (tennisDouble) {
    const homeTeamFirstMemberFlag = teams.home[0] ? (teams.home[0].countryCodeA2 ? teams.home[0].countryCodeA2 : '') : '';
    const homeTeamSecondMemberFlag = teams.home[1] ? (teams.home[1].countryCodeA2 ? teams.home[1].countryCodeA2 : '') : '';
    const awayTeamFirstMemberFlag = teams.away[0] ? (teams.away[0].countryCodeA2 ? teams.away[0].countryCodeA2 : '') : '';
    const awayTeamSecondMemberFlag = teams.away[1] ? (teams.away[1].countryCodeA2 ? teams.away[1].countryCodeA2 : '') : '';
    flagsHome = [homeTeamFirstMemberFlag, homeTeamSecondMemberFlag];
    flagsAway = [awayTeamFirstMemberFlag, awayTeamSecondMemberFlag];
    homeTeam = tennisDouble.home;
    awayTeam = tennisDouble.away;
  } else if (teams) {
    flagsHome = [teams.home[0].countryCodeA2];
    flagsAway = [teams.away[0].countryCodeA2];
    homeTeam = teams.home;
    awayTeam = teams.away;
  }

  if (isNotStarted(matchStatusId) && isPeriodBasedSportsWithFlags) {
    periodBasedSportsWithFlagsNotStartedClass = 'scoreboard-table--not-started';
  }

  return (
    <div styleName={`scoreboard scoreboard-table ${periodBasedSportsWithFlagsNotStartedClass}`}>
      <div className='flex'>
        <Team
          team={homeTeam}
          jersey={jerseys ? jerseys.home.player : undefined}
          flags={isPeriodBasedSportsWithFlags ? flagsHome : []}
          side='left'
        />

        <div styleName='scoreboard__text' className='flex-col flex-2 flex justify-center items-center'>
          <Score />
        </div>

        <Team
          team={awayTeam}
          jersey={jerseys ? jerseys.away.player : undefined}
          flags={isPeriodBasedSportsWithFlags ? flagsAway : []}
          side='right'
        />
      </div>
    </div>
  );
};

Board.propTypes = {
  teams: PropTypes.object,
  matchStatusId: PropTypes.number,
  tennisDouble: PropTypes.object,
  jerseys: PropTypes.object,
  sportId: PropTypes.number.isRequired,
};

Board.defaultProps = {
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
  matchStatusId: matchStatusIds.not_started,
  tennisDouble: null,
  jerseys: {
    away: {
      player: {},
    },
    home: {
      player: {},
    },
  },
};
const BoardStyled = cssModule(Board, style, { allowMultiple: true });
export default connect(mapStateToProps)(BoardStyled);
