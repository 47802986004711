import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import darken from 'newDesign/modules/common/utils/darken';
import {
  getBgColor,
  getMatchSportId,
  getMatchTeams,
  getReadyMatchId,
} from 'reducers';
import { sport } from 'constants/enum';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  bgColor: getBgColor(state),
  teams: getMatchTeams(state, getReadyMatchId(state)),
});

const ScoreBasedCompetitors = ({
  sportId, teams, bgColor,
}) => {
  const intl = useIntl();

  let homeName = intl.formatMessage({ id: 't_scoreboard_home' });
  let awayName = intl.formatMessage({ id: 't_scoreboard_away' });

  if (!_isEmpty(teams)) {
    const eSport = sportId === sport.esoccer;
    homeName = eSport ? teams.home[0].mediumname : teams.home[0].name;
    awayName = eSport ? teams.away[0].mediumname : teams.away[0].name;
  }

  return (
    <div styleName='lframe-header lframe-header--noimg' className='flex justify-center items-strech'>
      <div styleName='lframe-competitor' className='flex items-center box-border'>
        <div className='flex max-w-full overflow-hidden text-ellipsis'>
          <span className='fs-20 font-main--bold uppercase'>{homeName}</span>
        </div>
      </div>
      <div
        className='flex items-center justify-center'
        style={{
          background: `linear-gradient(90deg,
            ${darken(bgColor, 6)} 0%,
            ${bgColor} 0.50%,
            ${darken(bgColor, 6)} 100%)`,
        }}
      >
        <span className='fs-20 font-main--bold uppercase'>
          <TranslationFormattedMessage id='t_general_vs' />
        </span>
      </div>
      <div styleName='lframe-competitor' className='flex items-center box-border'>
        <div className='flex max-w-full overflow-hidden text-ellipsis'>
          <span className='fs-20 font-main--bold uppercase'>{awayName}</span>
        </div>
      </div>
    </div>
  );
};

ScoreBasedCompetitors.propTypes = {
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
  sportId: PropTypes.number.isRequired,
  bgColor: PropTypes.string.isRequired,
};

ScoreBasedCompetitors.defaultProps = {
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};

const ScoreBasedCompetitorsStyled = cssModule(ScoreBasedCompetitors, style, { allowMultiple: true });
export default connect(mapStateToProps)(ScoreBasedCompetitorsStyled);
