export const SEC = 1000;
export const MIN = 60 * SEC;
export const HOUR = 60 * MIN;

export const NO_RETRY = 'NO_RETRY';
export const BLOCK_HERE = 'BLOCK_HERE';
export const EMPTY_RESPONSE = 'EMPTY_RESPONSE';
export const TIMEOUT = 'TIMEOUT';


export const API_TIMEOUT = 10 * SEC;
export const API_RETRY_DELAY = 1 * SEC;
export const MAX_API_RETRIES = 3;
export const STAGE_RETRY_DELAY = 5 * MIN;
export const MATCH_INFO_WAKEUP_TIME = 30 * SEC;

export const MAX_RESTARTS = 999;
export const BASE_TIME = 500;
export const MAX_WAIT_TIME = 3 * MIN;
export const BASE_NUMBER = 1.5;

export const STAGE_RETRIES = 3;
export const STAGE_REFILL_TIME = 30 * MIN;

export const DARK_BACKGROUND_COLOR = '#000002';

export const PRIMARY_COLOR_IDX = 3;

export const LANG_EN = 'en';

export const CLIENT_ALIAS_ESOCCER_ONLY = 'caspiantechuof';
export const LIGA_PRO = 2262;
export const CYBER_LIVE_ARENA = 2263;
export const ESPORTS_BATTLE = 2264;
export const GT_SPORTS_LEAGUE = 2265;
export const E_ADRIATIC = 2440;

export const ODDSPANEL_N_MARKETS = 4;

export const CALL_TO_ACTION = 'bet now on';
export const BETTING_SUGGESTION_WAIT_TIME = 5 * SEC;
export const BETTING_SUGGESTION_DURATION = 10 * SEC;
export const BETTING_SUGGESTION_INITIAL_WAIT = 30 * SEC;
export const RESPONSE_HTTP_OK = 200;

const CHANNEL_ESOCCER_MAIN = 262;
const CHANNEL_ESOCCER_CODERE = 269;

export const CHANNELS_ESOCCER_ONLY = [CHANNEL_ESOCCER_MAIN, CHANNEL_ESOCCER_CODERE];
