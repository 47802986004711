import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { connect } from 'react-redux';
import { makeMapStateToProps } from 'reducers/oddsProjection';
import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import style from './PreMatchOdds.scss';

const PreMatchOdds = ({ marketsOutcomesArr }) => {
  if (!marketsOutcomesArr.length) {
    return <div styleName='oddsGridRemove' />;
  }

  const outcomes = marketsOutcomesArr[0];

  return (
    <div styleName='prematch__odds' className={'z-10 items-center flex flex-col'}>
      <h3 styleName='decoration-underline' className={'uppercase pb-4 font-second'}>
        <TranslationFormattedMessage id='t_opener_who_will_win' />
      </h3>
      <div className={'flex gap-x-8'}>
        {outcomes.map(outcome => (
          <div key={outcome.id} styleName='prematch__odds__blocks' className={'pt-4 flex flex-col items-center'}>
            <div className={'flex justify-center items-center mb-0.5'}>
              <span className={'color-theme-highlight font-second--bold'}>{outcome.odds}</span>
            </div>
            <span className={'uppercase font-second text-center'}>{outcome.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

PreMatchOdds.propTypes = {
  marketsOutcomesArr: PropTypes.array,
};

PreMatchOdds.defaultProps = {
  marketsOutcomesArr: [],
};

const PreMatchOddsStyled = cssModule(PreMatchOdds, style);
export default connect(makeMapStateToProps)(PreMatchOddsStyled);
