import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { eventStatus, sport } from 'constants/enum';
import { useImportImageNewDesign } from 'hooks';

import styles from '../../Timeline.scss';

const Goal = ({
  sportId, status, team, ownGoal,
}) => {
  const penaltyMissed = useImportImageNewDesign('general/timeline/penalty_missed.png');
  const hockeyOrSoccerIcon = useImportImageNewDesign(`general/timeline/timeline-goal-icon-${sportId}.svg`);
  const ownGoalIcon = useImportImageNewDesign('general/timeline/own_goal.svg');
  let iconGoalSrc;

  if (status === eventStatus.penalty.miss) {
    iconGoalSrc = penaltyMissed;
  } else if (ownGoal) {
    iconGoalSrc = ownGoalIcon;
  } else {
    iconGoalSrc = hockeyOrSoccerIcon;
  }

  return (
    <div styleName={team === 'away' ? 'flex-row-reverse' : ''} className={`${styles.timeline_icon_block} flex`}>
      <img src={iconGoalSrc} alt={''} />
    </div>
  );
};

Goal.propTypes = {
  sportId: PropTypes.number,
  status: PropTypes.string,
  team: PropTypes.string,
  ownGoal: PropTypes.bool,
};

Goal.defaultProps = {
  sportId: sport.soccer,
  status: '',
  team: '',
  ownGoal: false,
};

const GoalStyled = cssModule(Goal, styles);
export default GoalStyled;
