import {
  values as _values,
  orderBy as _orderBy,
  groupBy as _groupBy,
  forEach as _forEach,
  first as _first,
  filter as _filter,
} from 'lodash';
import {
  call,
  put,
  select,
  fork,
  take,
  apply,
  race,
  delay,
} from 'redux-saga/effects';
import {
  getMatchStatus,
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getStreamUrls,
  getBcmsFeedStage,
  getUseAutoStage,
  getMatchStartTime,
  getChannelId,
  getShownStage,
  getLcrStreamUrl,
} from 'reducers/index';
import { LcrStreamUrlRequest } from 'streams-api';
import { UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA } from 'constants/actions';
import { SportMatchesRequest } from 'fishnet-api';
import {
  setAutoSportMatchId,
  setAutoSportMatches,
  setLMTMatch,
  setAutoSportStageId,
} from 'actions/bcmsConfig';
import { isEnded, isLive, isNotStarted } from 'utils/matchstatus';
import {
  STAGE_ANNOUNCEMENT, STAGE_BREAK, STAGE_LIVE, STAGE_POST_MATCH, STAGE_PRE_MATCH,
} from 'constants/bcms';
import { log } from 'actions/logger';
import { matchStatusIds as msIds, sport } from 'constants/enum';
import { ConsoleLog } from 'utils/LoggerItem';
import {
  CHANNELS_ESOCCER_ONLY,
  CLIENT_ALIAS_ESOCCER_ONLY,
  E_ADRIATIC,
  GT_SPORTS_LEAGUE,
  MIN,
} from 'constants/app';
import { sagaCancel } from './utilSagas';

const RANKING_DEFAULT = [
  msIds.first_set,
  msIds.second_set,
  msIds.third_set,
  msIds.fourth_set,
  msIds.fifth_set,

  msIds.first_half,
  msIds.second_half,

  msIds.first_quarter,
  msIds.second_quarter,
  msIds.third_quarter,
  msIds.fourth_quarter,

  msIds.pause,
  msIds.halftime,
  msIds.match_about_to_start,
  msIds.not_started,
];

// priority by order
const PREFERRED_LEAGUES = [
  E_ADRIATIC,
  GT_SPORTS_LEAGUE,
];

function rankingESoccer(asMatches, clientAlias) {
  const now = Date.now() / 1000;
  let matches = asMatches;

  if (clientAlias === CLIENT_ALIAS_ESOCCER_ONLY) {
    matches = matches.filter(m => PREFERRED_LEAGUES.includes(m.rcid));
  }

  /*
    sort matches from:
    - starting in less than 10m
    - latestly started
    - not started by time to start
    - ended
  */
  matches = matches
    .map(m => {
      const timeDiff = m.dt.uts - now;
      const timeDiffAbs = Math.sqrt((timeDiff) ** 2);
      const isLessThan10MinToStart = (
        (timeDiffAbs < 600)
        && isNotStarted(m.statusId)
      );
      let weightedTimeDiffAbs = timeDiffAbs;
      if (PREFERRED_LEAGUES.includes(m.rcid)) {
        weightedTimeDiffAbs = timeDiffAbs >= 120 ? timeDiffAbs - 120 : 0;
      }


      const rank = (
        isLessThan10MinToStart
          ? 1
          : (
            isLive(m.statusId)
              ? 2
              : 3
          )
      );

      const statusRank = (
        isEnded(m.statusId)
          ? 200
          : m.statusId
      );

      return {
        ...m,
        timeDiff,
        timeDiffAbs,
        weightedTimeDiffAbs,
        isLessThan10MinToStart,
        rank,
        statusRank,
      };
    })
    .sort((a, b) => (
      (a.rank - b.rank) // if equal check next condition
      || (a.statusRank - b.statusRank)
      || (a.weightedTimeDiffAbs - b.weightedTimeDiffAbs)
      || PREFERRED_LEAGUES.indexOf(a.rcid) - PREFERRED_LEAGUES.indexOf(b.rcid)
    ));

  return matches;
}

// prefers live
function rankingDefault(asMatches) {
  const now = Date.now() / 1000;

  const rankArr = RANKING_DEFAULT;

  const rank = rankArr.reduce((acc, cur, idx) => {
    acc[cur] = idx;

    return acc;
  }, {});

  const matches = asMatches
    .map(obj => ({
      ...obj,
      timeDiff: obj.timestamp - now,
      timeDiffAbs: Math.sqrt((obj.timestamp - now) ** 2),
    }))
    .sort((a, b) => {
      const myRank = val => (rank[val.statusId] || rankArr.length);
      return (myRank(a) - myRank(b)) || ((a.timeDiffAbs > b.timeDiffAbs) ? 1 : -1);
    }); // lower -> higher

  return matches;
}

function* getSchedule(sportId, prevMatchId) { // how to handle date change
  const date = new Date(Date.now()).toISOString().split('T')[0]; // 'YYYY-MM-DD';
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = yield select(getQueryFeed);

  const request = new SportMatchesRequest(sportId, date, clientAlias, language, qFeed, -1);
  let asMatches = yield apply(request, request.get);

  if (sportId === sport.esoccer) {
    // Keep only matches with scout
    const matchesWithScoutCoverage = _filter(asMatches, match => (!!match.scout_match));
    const matchesByTimestamp = _groupBy(matchesWithScoutCoverage, match => match.timestamp);

    let order = 'asc';

    _forEach(matchesByTimestamp, (matches, timestamp) => {
      // Order by rcid (category) ASC/DESC - we support only 2 leagues
      matchesByTimestamp[timestamp] = _orderBy(matches, ['rcid'], [order]);
      // Get only 1st match
      matchesByTimestamp[timestamp] = _first(matchesByTimestamp[timestamp]);

      if (order === 'asc') {
        order = 'desc';
      } else {
        order = 'asc';
      }
    });

    asMatches = _values(matchesByTimestamp);
  }

  yield put(setAutoSportMatches(asMatches));

  if (asMatches && asMatches.length) {
    let matches = [];

    if (sportId === sport.esoccer) {
      matches = rankingESoccer(asMatches, clientAlias);
    } else {
      matches = rankingDefault(asMatches);
    }

    const len = matches.length;

    if (len > 20) {
      const startend = [
        ...matches.slice(0, 10),
        ...matches.slice(-10),
        ...matches.filter(a => a.statusId === 0).slice(0, 1),
      ];
      // eslint-disable-next-line no-console
      startend.forEach(a => console.log(a.date, a.statusId, a.statusName, a.id, a.realcategory));
    } else {
      matches
        .slice(0, 20)
        // eslint-disable-next-line no-console
        .forEach(a => console.log(a.date, a.statusId, a.statusName, a.id, a.realcategory));
    }

    let i = 0;
    while (matches && matches.length && matches[i]) {
      if (matches[i].id !== prevMatchId) {
        yield put(setAutoSportMatchId(matches[i].id));
        return matches.slice(i);
      }
      i++;
    }
  }

  return [];
}
function* monitorStatus(matchId, initStatus, sportId) {
  let status = initStatus;
  let mId = matchId;
  let stage = yield select(getBcmsFeedStage);
  const channelId = yield select(getChannelId);

  const autoSwitchStage = (CHANNELS_ESOCCER_ONLY.includes(channelId)) || (yield select(getUseAutoStage));

  if (!autoSwitchStage) {
    if (stage !== STAGE_LIVE) {
      yield put(setAutoSportStageId(STAGE_LIVE));
    }
    return;
  }

  for (;;) {
    status = yield select(getMatchStatus, mId);
    stage = yield select(getBcmsFeedStage);

    if (sportId === sport.esoccer && mId === matchId) {
      const startTime = yield select(getMatchStartTime, matchId);
      const timeToStart = startTime - (Date.now() / 1000);
      const isStartingVerySoon = timeToStart < 30; // less than 30sec to start
      const isOver30mToStart = timeToStart > (60 * 30); // more than 30 min to start

      if ((isLive(status) || isStartingVerySoon)) {
        if (stage !== STAGE_LIVE) { // avoid spam changing to the same
          yield put(setAutoSportStageId(STAGE_LIVE));
        }
      } else if (isOver30mToStart && isNotStarted(status)) {
        if (stage !== STAGE_ANNOUNCEMENT) { // avoid spam changing to the same
          yield put(setAutoSportStageId(STAGE_ANNOUNCEMENT));
        }
      } else if (isNotStarted(status)) {
        if (stage !== STAGE_PRE_MATCH) { // avoid spam changing to the same
          yield put(setAutoSportStageId(STAGE_PRE_MATCH));
        }
      }
    } else if (mId === matchId) {
      if (stage !== STAGE_BREAK && status === msIds.pause) {
        yield delay(10 * 1000);
        yield put(setAutoSportStageId(STAGE_BREAK));
      } else if (stage === STAGE_BREAK && status === msIds.pause) {
        // do nothing
      } else if (stage !== STAGE_LIVE && isLive(status)) {
        yield put(setAutoSportStageId(STAGE_LIVE));
      } else if (stage !== STAGE_PRE_MATCH && isNotStarted(status)) {
        yield put(setAutoSportStageId(STAGE_PRE_MATCH));
      }
    }
    ({ matchId: mId } = (yield take([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA])));
  }
}

function* checkForOtherStreams(rankedMatches) {
  const streamBaseUrl = yield select(getLcrStreamUrl);

  for (let i = 0; i < rankedMatches.length; i++) {
    if (rankedMatches[i].isLessThan10MinToStart || isLive(rankedMatches[i].statusId)) {
      const matchId = rankedMatches[i].id;

      const streamRequest = new LcrStreamUrlRequest(streamBaseUrl, matchId);
      const streamUrl = yield apply(streamRequest, streamRequest.get);

      if (streamUrl && streamUrl.url) {
        return i;
      }
    }
  }
  return -1;
}

function* autoSportSaga(sportId) {
  try {
    let scheduledMatchId = 0;
    let noStreamCount = 0;
    let monitor = null;
    let first = 1;
    let useBetterAlternative = 0;
    let rankedMatches = [];

    for (;;) {
      if (useBetterAlternative === 0) {
        rankedMatches = yield call(getSchedule, sportId, scheduledMatchId);
        if (rankedMatches && rankedMatches.length) {
          scheduledMatchId = rankedMatches[useBetterAlternative].id; // select first
        } else {
          scheduledMatchId = 0;
        }
      } else {
        // use already created schedule
        scheduledMatchId = rankedMatches[useBetterAlternative].id;
      }

      if (scheduledMatchId <= 0 || isEnded(rankedMatches[useBetterAlternative].statusId)) {
        if (scheduledMatchId <= 0) {
          const msg = new ConsoleLog('AutoSport: No Matches', '', 1);
          yield put(log(msg));

          yield put(setAutoSportStageId(STAGE_ANNOUNCEMENT));
        } else {
          const msg = new ConsoleLog('AutoSport: All matches ended', '', 1);
          yield put(log(msg));

          yield put(setAutoSportMatchId(scheduledMatchId)); // sets scheduled match
          yield put(setAutoSportStageId(STAGE_POST_MATCH));
        }

        const now = Date.now();
        const nextDay = new Date(now);

        nextDay.setUTCDate(nextDay.getUTCDate() + 1);
        nextDay.setHours(0, 0, 0, 0);
        const timeToNextDay = nextDay.getTime() - now;

        // check schedule every 30min or with date change
        yield race([
          delay(timeToNextDay + 5000),
          delay(30 * MIN),
        ]);
        useBetterAlternative = 0;
        // eslint-disable-next-line no-continue
        continue;
      }

      let msg = new ConsoleLog(
        `AutoSport: Set Match ${scheduledMatchId} with status ${rankedMatches[useBetterAlternative].statusId}. Time to start: ${Math.floor(rankedMatches[useBetterAlternative].timeDiff)}`,
        '',
        1,
      );
      yield put(log(msg));
      yield put(setAutoSportMatchId(scheduledMatchId)); // sets scheduled match

      let matchStatus = -1;
      noStreamCount = 0;
      first = 1;
      for (;;) {
        yield call(function* (matchId) {
          // selected match is ended already and wont get new updates
          if (isEnded(yield select(getMatchStatus, matchId))) {
            return;
          }

          for (;;) {
            const { matchId: mId } = (yield take([UPDATE_MATCH_INFO, UPDATE_MATCH_DELTA]));
            if (mId === matchId) {
              return;
            }
          }
        }, scheduledMatchId);

        matchStatus = yield select(getMatchStatus, scheduledMatchId);

        if (first) {
          first = 0;
          monitor = yield fork(monitorStatus, scheduledMatchId, matchStatus, sportId);
        }

        const stage = yield select(getShownStage);

        if (isEnded(matchStatus)) {
          yield delay(10 * 1000);
          useBetterAlternative = 0;
          break;
        } else if (isLive(matchStatus)) {
          const isStreamAvailable = (yield select(getStreamUrls)).length;

          if (!isStreamAvailable && noStreamCount === 3) {
            // match is live and stream is not available
            // check if other matches in schedule have streams
            const betterAlternative = yield call(checkForOtherStreams, rankedMatches);
            if (betterAlternative > -1) {
              msg = new ConsoleLog(
                `AutoSport: Switched from ${scheduledMatchId} to alternative ${rankedMatches[betterAlternative].id} `,
                '',
                1,
              );
              yield put(log(msg));

              useBetterAlternative = betterAlternative;
              break;
            }

            yield put(setLMTMatch(true));
          } else if (isStreamAvailable && noStreamCount !== -1) {
            yield put(setLMTMatch(false));
            noStreamCount = -1;
          }
          if (!isStreamAvailable && stage === STAGE_LIVE) {
            // only count from when live stage is on
            // since it wont start polling stream before it switches to live stage
            noStreamCount++;
          }
        }
      }
      yield put(setLMTMatch(false));
      sagaCancel(monitor);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    yield put(setAutoSportMatchId(0));
    yield put(setLMTMatch(false));
  }
}

export default autoSportSaga;
