// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OddsChangeIndicator__odd-change-arrow___1funl.OddsChangeIndicator__hide-indicator___8j8gE{opacity:0}.OddsChangeIndicator__odd-change-arrow___1funl.OddsChangeIndicator__show-indicator___XhLV-{opacity:1}.OddsChangeIndicator__odd-change-arrow___1funl svg{height:.8vw;margin-top:-0.4vw}.OddsChangeIndicator__odd-change-arrow___1funl svg path{fill:rgba(0,0,0,0)}.OddsChangeIndicator__odd-change-arrow--up___2oa8f svg{filter:drop-shadow(0px 1px 2px rgba(38, 189, 10, 0.4))}.OddsChangeIndicator__odd-change-arrow--up___2oa8f svg path{fill:#26bd0a}.OddsChangeIndicator__odd-change-arrow--down___22lll svg{transform:rotate(180deg);filter:drop-shadow(0px 1px 2px rgba(255, 0, 42, 0.4))}.OddsChangeIndicator__odd-change-arrow--down___22lll svg path{fill:#ff002a}", ""]);
// Exports
exports.locals = {
	"odd-change-arrow": "OddsChangeIndicator__odd-change-arrow___1funl",
	"hide-indicator": "OddsChangeIndicator__hide-indicator___8j8gE",
	"show-indicator": "OddsChangeIndicator__show-indicator___XhLV-",
	"odd-change-arrow--up": "OddsChangeIndicator__odd-change-arrow--up___2oa8f",
	"odd-change-arrow--down": "OddsChangeIndicator__odd-change-arrow--down___22lll"
};
module.exports = exports;
