import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import React from 'react';
import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash';

import TeamWithFlagOrCrest from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/board/team/TeamWithFlagOrCrest';
import TeamWithJersey from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/board/team/TeamWithJersey';
import { matchStatusIds } from 'constants/enum';

import {
  getMatchStatus,
  getReadyMatchId,
} from 'reducers';

import style from '../../../ScoreBoard.scss';

const mapStateToProps = state => ({
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
});

const Team = ({
  matchStatusId,
  team,
  jersey,
  flags,
  side,
}) => {
  if (!team) {
    return null;
  }

  if (!_isEmpty(flags)) {
    return <TeamWithFlagOrCrest
      matchStatusId={matchStatusId}
      team={team}
      flags={flags}
      side={side}
    />;
  }

  return <TeamWithJersey team={team} jersey={jersey} />;
};

Team.propTypes = {
  matchStatusId: PropTypes.number,
  team: PropTypes.array.isRequired,
  flags: PropTypes.array,
  jersey: PropTypes.object,
  side: PropTypes.string.isRequired,
};

Team.defaultProps = {
  matchStatusId: matchStatusIds.not_started,
  flags: [],
  jersey: null,
};

const TeamStyled = cssModule(Team, style);
export default connect(mapStateToProps)(TeamStyled);
