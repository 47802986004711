import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import ErrorBoundary from 'views/widgets/Logo';
import Logo from 'utils/ErrorBoundary';

import styles from 'newDesign/modules/common/components/layout/mainSidebarLayout/MainSidebarLayout.scss';

const MainSidebarLayout = ({
  main, sidebar, logoUrl,
}) => (
  <div styleName='MainSidebarLayoutGrid'>
    <div styleName='MSLMainCol'>
      <div styleName='MSLMainTop'>
        <ErrorBoundary fallback={<div />}>
          {main}
        </ErrorBoundary>
      </div>
    </div>
    <div styleName='MSLSidebarCol'>
      <ErrorBoundary fallback={<div />}>
        <Logo logoUrl={logoUrl} />
        {sidebar}
      </ErrorBoundary>
    </div>
  </div>
);

MainSidebarLayout.propTypes = {
  main: PropTypes.element.isRequired,
  sidebar: PropTypes.element,
  logoUrl: PropTypes.string,
};

MainSidebarLayout.defaultProps = {
  logoUrl: '',
  sidebar: null,
};

const SidebarBottomLayoutStyled = cssModule(MainSidebarLayout, styles);

export default SidebarBottomLayoutStyled;
