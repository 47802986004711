import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getMatchTeams, getMatchTennisDouble, getReadyMatchId } from 'reducers/index';
import TennisDouble from './tennisDouble/TennisDouble';
import TennisSingle from './tennisSingle/TennisSingle';

const mapStateToProps = state => ({
  tennisDouble: getMatchTennisDouble(state, getReadyMatchId(state)),
  teams: getMatchTeams(state, getReadyMatchId(state)),
});

const Tennis = ({
  tennisDouble, teams,
}) => {
  let tennisComponent;

  if (tennisDouble) {
    tennisComponent = <TennisDouble competitors={tennisDouble} />;
  } else if (teams) {
    tennisComponent = <TennisSingle competitors={teams} />;
  }

  return (
    <div className={'z-10 flex flex-col'}>
      {tennisComponent}
    </div>
  );
};

Tennis.propTypes = {
  tennisDouble: PropTypes.object,
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
};

Tennis.defaultProps = {
  tennisDouble: null,
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};

export default connect(mapStateToProps)(Tennis);
