import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import RankingTitle from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/sports/tennis/components/ranking/RankingTitle';
import {
  getMatchSportId,
  getMatchTeamsRank,
  getPreMatchSlideIdx,
  getReadyMatchId,
  getShownStage,
} from 'reducers/index';
import { STAGE_PRE_MATCH } from 'constants/bcms';
import { SS_FORM_OVERALL, SS_PREMATCH_SUBSTAGE_PERFORMANCE } from 'constants/components';

import { sport } from 'constants/enum';
import styles from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/sports/tennis/components/ranking/Ranking.scss';

const mapStateToProps = state => ({
  preMatchSlideIdx: getPreMatchSlideIdx(state),
  ranking: getMatchTeamsRank(state, getReadyMatchId(state)),
  stageId: getShownStage(state),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});


const Ranking = ({
  stageId, preMatchSlideIdx, ranking, sportId,
}) => {
  let showRanking;
  let displayAtpOrWta;
  let fontWeight;

  if (sportId === sport.table_tennis) {
    return null;
  }

  // Performance ranking and WTA/ATP depends on Pre Match Substage (display only for OVERALL substage)
  // This "if" is used for ALL 5 GROUND TYPES (depends on Substage Performance page)
  if (stageId === STAGE_PRE_MATCH && SS_PREMATCH_SUBSTAGE_PERFORMANCE.includes(preMatchSlideIdx)) {
    showRanking = (preMatchSlideIdx === SS_FORM_OVERALL);
    displayAtpOrWta = (preMatchSlideIdx === SS_FORM_OVERALL);
    fontWeight = 600;
  // Pre Match, Match Opener - ranking always visible, but text for ATP/WTA not visible
  } else {
    showRanking = true;
    displayAtpOrWta = false;
    fontWeight = 300;
  }

  if (!showRanking) {
    return null;
  }

  return (
    <div className={'z-10 mb-8 fs-20 flex items-center justify-center w-full'}>
      <div className={'flex flex-col items-center w-1/5'}>
        <span
          styleName='ranking_number'
          style={{
            fontWeight,
          }}
        >{ranking && ranking.home ? `#${ranking.home}` : '-'}
        </span>
      </div>
      <div className={'flex justify-center items-center flex-row w-2/5'}>
        <RankingTitle displayAtpOrWta={displayAtpOrWta} ranking={ranking} />
      </div>
      <div className={'flex flex-col items-center w-1/5'}>
        <span
          styleName='ranking_number'
          style={{
            fontWeight,
          }}
        >{ranking && ranking.away ? `#${ranking.away}` : '-'}
        </span>
      </div>
    </div>
  );
};

Ranking.propTypes = {
  ranking: PropTypes.object,
  stageId: PropTypes.number.isRequired,
  sportId: PropTypes.number,
  preMatchSlideIdx: PropTypes.number.isRequired,
};

Ranking.defaultProps = {
  ranking: null,
  sportId: sport.tennis,
};

const RankingStyled = cssModule(Ranking, styles);
export default connect(mapStateToProps)(RankingStyled);
