import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import PeriodScore from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/resultTable/PeriodScore';
import GameScore from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/resultTable/tennis/gameScore/GameScore';
import SetScore from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/resultTable/tennis/setScore/SetScore';
import TotalScore from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/resultTable/periodSports/totalScore/TotalScore';
import TableRowName from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/resultTable/periodSports/tableRowName/TableRowName';
import { isEnded } from 'utils/matchstatus';
import {
  getMatchGameScore,
  getMatchResult, getMatchSportId,
  getMatchStatus,
  getMatchTeams,
  getReadyMatchId,
} from 'reducers';
import { matchStatusIds, sport } from 'constants/enum';

import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const mapStateToProps = state => ({
  teams: getMatchTeams(state, getReadyMatchId(state)),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
  gameScore: getMatchGameScore(state, getReadyMatchId(state)),
  result: getMatchResult(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const PeriodResultsTableRow = ({
  gameScore,
  result,
  matchStatusId,
  teams,
  side,
  sportId,
}) => {
  let teamWinClass = '';
  const isTennis = [sport.tennis, sport.table_tennis].includes(sportId);
  const isMatchEnded = isEnded(matchStatusId);

  if (isMatchEnded) {
    if (result.winner === side) {
      teamWinClass = 'scoreboard-table--game-winner';
    }
  }

  return (
    <div styleName={teamWinClass} className='flex items-center w-full box-border font-main fs-16'>
      <TableRowName teams={teams} side={side} />

      {isTennis && (
      <Fragment>
        {!isMatchEnded && <SetScore result={result} side={side} />}
        <PeriodScore side={side} />
        {(!isMatchEnded && sportId !== sport.table_tennis) && <GameScore score={gameScore[side]} />}
        {isMatchEnded && <TotalScore result={result} side={side} />}
      </Fragment>
      )}

      {!isTennis && (
      <Fragment>
        {!isMatchEnded && <PeriodScore side={side} />}
        <TotalScore result={result} side={side} />
      </Fragment>
      )}

    </div>
  );
};

PeriodResultsTableRow.propTypes = {
  gameScore: PropTypes.object,
  result: PropTypes.object,
  matchStatusId: PropTypes.number,
  teams: PropTypes.object,
  side: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
};

PeriodResultsTableRow.defaultProps = {
  gameScore: {},
  result: undefined,
  matchStatusId: matchStatusIds.not_started,
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};
const PeriodResultsTableRowStyled = cssModule(PeriodResultsTableRow, style);
export default connect(mapStateToProps)(PeriodResultsTableRowStyled);
