import PropTypes from 'prop-types';
import React from 'react';
import { useImportImageNewDesign } from 'hooks/index';
import cssModule from 'react-css-modules';
import style from '../../ScoreBoard.scss';

const PlayerIcon = ({ teamName }) => {
  const controllerIcon = useImportImageNewDesign('general/scoreboard/esoccer.svg');

  return (
    <div className='flex flex-col flex-1 justify-center items-center overflow-hidden whitespace-nowrap'>
      <img src={controllerIcon} alt={''} className={'pt-5'} styleName={'scoreboard-controller'} />
      <h2 className='fs-18 font-second--bold max-w-full text-ellipsis overflow-hidden uppercase'>
        {teamName}
      </h2>
    </div>
  );
};

PlayerIcon.propTypes = {
  teamName: PropTypes.string.isRequired,
};

const PlayerIconStyled = cssModule(PlayerIcon, style);

export default PlayerIconStyled;
