import PropTypes from 'prop-types';

import useIceHockeyClock from 'newDesign/modules/common/hooks/clock/IceHockeyClockHook';

const IceHockeyClock = ({
  statusId,
  timeInfo,
}) => {
  const time = useIceHockeyClock(
    statusId,
    timeInfo.remaining,
    timeInfo.running,
  );

  return time;
};

IceHockeyClock.propTypes = {
  statusId: PropTypes.number,
  timeInfo: PropTypes.object,
};

IceHockeyClock.defaultProps = {
  statusId: null,
  timeInfo: {
    played: '0',
    remaining: '0',
    running: false,
  },
};

export default IceHockeyClock;
