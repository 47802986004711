import React, { Fragment } from 'react';
import cssModule from 'react-css-modules';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isLive } from 'utils/matchstatus';
import {
  getMessage,
  getVisibility,
  getMatchStatus,
  getMatchId,
  getSeparatorColor,
  getReadyMatchId,
  getMatchSportId,
} from 'reducers';
import LiveBanner from 'newDesign/modules/live/liveBanner/LiveBanner';
import darken from 'newDesign/modules/common/utils/darken';
import { sport } from 'constants/enum';

import styles from 'newDesign/modules/live/crawler/Crawler.scss';

const mapStateToProps = state => ({
  message: getMessage(state),
  visible: getVisibility(state),
  matchStatus: getMatchStatus(state, getMatchId(state)),
  separatorColor: getSeparatorColor(state),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const Crawler = ({
  message, visible, matchStatus, separatorColor, sportId,
}) => {
  let content = <div />;
  let contentLive = <div />;

  if (visible && message && message.text) {
    content = (
      <div
        className={`${styles.lframe_topcrawler} absolute left-0 right-0 animate-fadeIn-0.3s`}
        style={{
          background: `linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, ${darken(separatorColor, 1)} 40%, ${darken(separatorColor, 1)} 50%, ${darken(separatorColor, 1)} 60%, rgba(0, 0, 0, 1) 100%)`,
        }}
      >
        <div className={`${styles.textMarquee} relative overflow-hidden`}>
          <span className={'font-second--medium fs-18 uppercase whitespace-nowrap'}>{message.text}</span>
        </div>
      </div>
    );
  }

  if (isLive(matchStatus) && sportId !== sport.basketball3x3) {
    contentLive = <LiveBanner />;
  }

  return (
    <Fragment>
      {content}
      {contentLive}
    </Fragment>
  );
};

Crawler.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.shape(),
  matchStatus: PropTypes.number,
  separatorColor: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
};

Crawler.defaultProps = {
  visible: false,
  message: null,
  matchStatus: 0,
};

const CrawlerStyled = cssModule(Crawler, styles);
export default connect(mapStateToProps)(CrawlerStyled);
