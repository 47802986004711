const stagingUrl = 'https://lbc-staging.sportradar.ag';
const prodUrl = 'https://lbc.betradar.com';

export default {
  baseUrl: prodUrl,
  stagingUrl,
  prodUrl,
  baseUrlDefault: prodUrl,
  NODE_ENV: process.env.NODE_ENV,
  lcrClientLogoUrl: 'https://lcr-cms-prod.s3.eu-central-1.amazonaws.com/client_:clientId_logo',
  lcrBetshopLogoUrl: 'https://lcr-cms-prod.s3.eu-central-1.amazonaws.com/client_:clientId_betshop_:betshopId_logo',
  lcrStreamUrl: '/stream/std-stream',
  worldLotteryUrl: '/streaming/dash-url-wl/',
  lcrWorldLotteryUrl: '/stream/wns-stream',
  debugPlayerUrl: 'https://avplayer-cdn.akamaized.net/avvpl-debug-player?streamUrl=:url&type=:format',
  authorizationSettings: {
    pollInterval: 60,
  },
  liveplayerLogLevel: 2,
};
