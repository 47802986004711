import React from 'react';
import NumberStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberStatSlide';
import { useIntl } from 'react-intl';

const Ended = () => {
  const intl = useIntl();
  return (
    <NumberStatSlide
      statText={intl.formatMessage({ id: 't_general_ended' })}
      homeStat={null}
      awayStat={null}
    />
  );
};

export default Ended;
