import React from 'react';
import { connect } from 'react-redux';
import { logReactError } from 'actions/bcmsConfig';
import PropTypes from 'prop-types';

const actionCreators = {
  logReactError,
};


class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    const getDisplayName = child => {
      if (child && child.type && (child.type.displayName || child.type.name)) {
        return child.type.displayName || child.type.name;
      }
      return null;
    };
    const compNames = Object.values(this.props.children)
      .map(child => getDisplayName(child))
      .filter(names => names !== null);

    const errorObj = {
      error: error.toString(),
      errorInfo: errorInfo.componentStack,
    };

    this.props.logReactError(errorObj, compNames);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback || <div>Error</div>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.array,
  ]),
  fallback: PropTypes.shape(),
  logReactError: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  children: {},
  logReactError: () => null,
  fallback: null,
};

export default connect(
  null,
  actionCreators,
)(ErrorBoundary);
