import React from 'react';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';
import PropTypes from 'prop-types';

import {
  getMatchResult, getMatchSportId, getMatchTeams, getReadyMatchId,
} from 'reducers/index';
import TennisFlags from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/sports/tennisManager/components/tennisFlags/TennisFlags';
import Score from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/sports/tennisManager/components/score/Score';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';
import { secondTableTennisCompetitorCheck } from 'utils/tableTennisHelpers';

const mapStateToProps = state => ({
  teams: getMatchTeams(state, getReadyMatchId(state)),
  result: getMatchResult(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const TennisDoubles = ({
  competitors, teams, sportId,
}) => {
  const homeTeams = competitors.home[0].name.split('/');
  const awayTeams = competitors.away[0].name.split('/');

  /*
     In case of doubles, we need to check if the second competitor is missing
     and add a dummy competitor with a dash as a name and 'xyz' as a country code.
     This is needed to keep the layout consistent.
  */
  const copiedTeams = secondTableTennisCompetitorCheck(teams, sportId);

  return (
    <div styleName='halftimestats__content__header' className='flex w-full'>
      <div className='flex flex-col items-center h-full'>
        <div styleName='tennis_flags' className='flex justify-center items-center'>
          <TennisFlags teams={copiedTeams.home} />
        </div>
        <span styleName='halftimestats-competitior' className='fs-20 font-main--bold uppercase text-center'>{homeTeams[0]}</span>
        <span styleName='halftimestats-competitior' className='fs-20 font-main--bold uppercase text-center'>{homeTeams[1]}</span>
      </div>

      <Score />

      <div className='flex flex-col items-center h-full'>
        <div styleName='tennis_flags' className='flex justify-center items-center'>
          <TennisFlags teams={copiedTeams.away} />
        </div>
        <span styleName='halftimestats-competitior' className='fs-20 font-main--bold uppercase text-center'>{awayTeams[0]}
        </span>
        <span styleName='halftimestats-competitior' className='fs-20 font-main--bold uppercase text-center'>{awayTeams[1]}
        </span>
      </div>
    </div>
  );
};

TennisDoubles.propTypes = {
  competitors: PropTypes.object.isRequired,
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
  sportId: PropTypes.number.isRequired,
};

TennisDoubles.defaultProps = {
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};

const TennisDoublesStyled = cssModule(TennisDoubles, style);
export default connect(mapStateToProps)(TennisDoublesStyled);
