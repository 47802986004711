import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import ClientMatchId from 'newDesign/modules/announcement/components/clientMatchId/ClientMatchId';
import SportIcon from 'newDesign/modules/common/components/sportIcon/SportIcon';
import MatchStatus from 'newDesign/modules/announcement/components/matchStatus/MatchStatus';

const SportInfo = ({
  matchId,
}) => (
  <Fragment>
    <MatchStatus matchId={matchId} />
    <div className='flex items-center box-border justify-center'>
      <SportIcon matchId={matchId} />
      <ClientMatchId matchId={matchId} />
    </div>
  </Fragment>
);

SportInfo.propTypes = {
  matchId: PropTypes.number.isRequired,
};

export default SportInfo;
