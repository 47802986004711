/**
 * Constructs a URL query string based on the provided parameters and checks the URL hash for additional parameters.
 * This function is specifically used to fetch and handle the dynamic parameters for URLs, especially in scenarios
 * where operator-specific parameters are needed.
 * - If the user is an operator (`isOperator` is 1), the `id` is always included in the query.
 * - Additionally, if a specific parameter (e.g., 'stage' or any other specified by `parameter`) is found in the URL
 * hash, it's added to the query string under the key 'stage'.
 *
 * @param {string} id - The unique identifier to be included in the query if `isOperator` is true.
 * @param {string} parameter - The name of the parameter to look for in the URL hash.
 * @param {number} isOperator - Flag to determine if additional operator-specific parameters should be included.
 * @returns {string} The constructed query string, which may be empty if no parameters are to be included.
 */
// eslint-disable-next-line import/prefer-default-export
export const getOperatorUrlParams = (id, parameter, isOperator) => {
  if (!isOperator) {
    return '';
  }

  const { hash } = window.location;
  const hashQueryString = hash.split('?')[1] || '';
  const hashParams = new URLSearchParams(hashQueryString);
  const queryParameters = {};

  queryParameters.id = id;

  if (hashParams.get(parameter)) {
    queryParameters[parameter] = hashParams.get(parameter);
  }

  return new URLSearchParams(queryParameters).toString();
};
