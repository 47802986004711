/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import { makeMapStateToProps } from 'reducers/oddsProjection';

import { oddsT } from 'constants/enum';
import { LANG_EN } from 'constants/app';

import style from 'newDesign/modules/preMatch/components/highlightOfTheDay/odds/Odds.scss';

const Odds = ({
  marketsOutcomesArr,
  outcomeName,
  marketId,
  lang,
  funFactQuestion,
}) => {
  let outcome = null;
  if (marketsOutcomesArr && marketsOutcomesArr.length) {
    for (let i = 0; i < marketsOutcomesArr.length; i++) {
      // string vs number so ==
      const marketArr = marketsOutcomesArr[i]
        .filter(m => m.marketId == marketId && m.feedName === outcomeName);

      if (marketArr.length) {
        const [market] = marketArr; // should only be one
        if (lang === LANG_EN) {
          if (marketId == oddsT.pre.correct_score) {
            const feedOutcome = market;
            outcome = {
              ...feedOutcome,
              name: 'Correct score ' + feedOutcome.name,
            };
          } else if ((marketId == oddsT.pre.three_way || marketId == oddsT.pre.two_way)) {
            const feedOutcome = market;
            outcome = {
              ...feedOutcome,
              name: feedOutcome.name,
            };
          }
        } else {
          const feedOutcome = market;
          outcome = {
            ...feedOutcome,
          };
        }
        break;
      }
    }
  }

  if (!outcome) {
    return null;
  }

  return (
    <div styleName='prematch__odds' className='flex-1 z-10 items-center flex flex-col'>
      <h3 styleName='decoration-underline' className='uppercase font-second pb-4'>{funFactQuestion}</h3>
      <div className='flex gap-x-8 w-full justify-center pt-4'>
        <div styleName='prematch__odds__blocks' className='flex flex-col items-center'>
          <div className='flex justify-center items-center mr-2 mb-0.5'>
            <span className='color-theme-highlight font-second--bold'>{outcome.odds}</span>
          </div>
          <span className='uppercase text-center'>{outcome.name || outcome.abbr}</span>
        </div>
      </div>
    </div>
  );
};

Odds.propTypes = {
  marketsOutcomesArr: PropTypes.array,
  outcomeName: PropTypes.string,
  marketId: PropTypes.number,
  lang: PropTypes.string,
  funFactQuestion: PropTypes.string.isRequired,
};

Odds.defaultProps = {
  marketsOutcomesArr: [],
  outcomeName: '',
  marketId: '',
  lang: LANG_EN,
};

const OddsStyled = cssModule(Odds, style);
export default connect(makeMapStateToProps)(OddsStyled);
