import React, { useEffect, useState, useReducer } from 'react';
import StreamsContainer from 'views/widgets/StreamsContainer';
import PropTypes from 'prop-types';
import {
  NOT_LOADED,
  PLAYING,
  ENDED,
  ERROR,
  NO_STREAMS,
  AUTOPLAY_BLOCKED,
} from 'constants/liveplayer';


const initialState = {
  playerState: 0,
  currentVideoHandler: '',
  errorCount: 0,
  errorCode: 0,
  errorMsg: '',
  hasPlayed: 0,
};

function playerReducer(state, action) {
  switch (action.type) {
    case 'PLAYING':
      return {
        ...state,
        playerState: PLAYING,
        errorCount: 0,
        currentVideoHandler: action.payload,
        errorCode: 0,
        errorMsg: '',
        hasPlayed: state.hasPlayed + 1, // trigger useeffect diff to fire playing callback every time
      };
    case 'ERROR':
      return {
        ...state,
        playerState: ERROR,
        errorCount: state.errorCount + 1,
        errorCode: action.payload.message.errorCode,
        errorMsg: action.payload.message.userMessage,
      };

    case 'ENDED':
      return {
        ...state,
        playerState: ENDED,
        errorCode: 0,
        errorMsg: '',
      };
    case 'AUTOPLAY_BLOCKED':
      return {
        ...state,
        playerState: AUTOPLAY_BLOCKED,
      };

    default:
      return state;
  }
}

const LivePlayer = ({
  streams,
  playing,
  ended,
  errorCallback,
  autoplay,
  showUI,
}) => {
  const [uniqueKey, setUniqueKey] = useState(0);
  const [state, playerDispatch] = useReducer(playerReducer, initialState);

  // trigger creation of (new) liveplayer on streams change
  useEffect(() => {
    setUniqueKey(Math.trunc(Math.random() * 100000000));
  }, [streams]);

  useEffect(() => {
    // not run on first mount since uniqueKey will be null
    if (uniqueKey) {
      const element = `sr-liveplayer-${uniqueKey}`;
      // eslint-disable-next-line new-cap
      const lpInst = new window.avvpl.setupPlayer({
        id: element,
        autoplay: true,
        trackLiveEdge: true,
        enableUI: showUI,
        bufferTime: 4.5,
      });

      lpInst.on('playing', () => {
        playerDispatch({
          type: 'PLAYING',
          payload: lpInst.getActiveVideoHandler(),
        });
      });

      lpInst.on('ended', () => {
        playerDispatch({
          type: 'ENDED',
          payload: null,
        });
      });

      lpInst.on('autoplayBlocked', () => {
        playerDispatch({
          type: 'AUTOPLAY_BLOCKED',
          payload: null,
        });
      });

      lpInst.on('error', error => {
        playerDispatch({
          type: 'ERROR',
          payload: error,
        });
      });

      return () => {
        if (lpInst) {
          lpInst.remove();
        }
      };
    }
    return () => null;
  }, [uniqueKey, showUI]);

  useEffect(() => {
    switch (state.playerState) {
      case PLAYING:
        // temp fix
        playing(state.currentVideoHandler);
        break;

      case ENDED:
        ended();
        break;

      case ERROR:
        /*
         let the player retry 3 times if "playing" event has not happened
         since it won't do the retries if the player has successfully started playback previously
         so it has to be recreated
        */
        if (state.errorCount > 3 || state.hasPlayed) {
          errorCallback({
            errorCode: state.errorCode,
            errorMsg: state.errorMsg,
          });
        }
        break;

      case AUTOPLAY_BLOCKED:
        autoplay();
        break;

      case NOT_LOADED:
        break;

      case NO_STREAMS:
        break;

      default:
        break;
    }
  }, [
    state.playerState,
    state.errorCount,
    state.errorMsg,
    state.errorCode,
    playing,
    ended,
    errorCallback,
    state.currentVideoHandler,
    autoplay,
    state.hasPlayed,
  ]);


  return (
    <div
      id={`sr-liveplayer-${uniqueKey}`}
      key={uniqueKey}
      style={{
        overflow: 'auto',
      }}
    >
      <StreamsContainer streams={streams} />
    </div>
  );
};

LivePlayer.propTypes = {
  streams: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  playing: PropTypes.func.isRequired,
  ended: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  autoplay: PropTypes.func,
  showUI: PropTypes.bool,

};
LivePlayer.defaultProps = {
  streams: [],
  autoplay: () => null,
  showUI: false,
};

export default LivePlayer;
