// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TimelineContent__timeline_body___VEC4l{margin-top:.8vw}.TimelineContent__timeline_body___VEC4l>div:not(.TimelineContent__timeline_game_status____E7NN){position:relative}.TimelineContent__timeline_body___VEC4l>div:not(.TimelineContent__timeline_game_status____E7NN)::after{content:\"\";background:#999ca5;width:2px;height:100%;position:absolute;-ms-flex-pack:center;justify-content:center}.TimelineContent__timeline_body___VEC4l h3{line-height:initial}.TimelineContent__timeline_body___VEC4l .TimelineContent__timeline_game_status____E7NN{background:var(--L-Shape-Halftime-Background, linear-gradient(90deg, rgba(66, 75, 94, 0) 0%, rgba(67, 77, 96, 0.4) 21.88%, rgba(116, 123, 136, 0.68) 52.08%, rgba(63, 73, 92, 0.4) 81.77%, rgba(69, 78, 97, 0) 100%));box-shadow:0px 3px 6px 0px rgba(0,0,0,.25);padding:.5vw;min-width:21vw;margin:2vw 0}.TimelineContent__timeline_body___VEC4l .TimelineContent__timeline_game_status____E7NN:first-child{margin:0 0 2vw 0}.TimelineContent__timeline_body___VEC4l .TimelineContent__timeline_game_status____E7NN:last-child{margin:2vw 0 0 0}", ""]);
// Exports
exports.locals = {
	"timeline_body": "TimelineContent__timeline_body___VEC4l",
	"timeline_game_status": "TimelineContent__timeline_game_status____E7NN"
};
module.exports = exports;
