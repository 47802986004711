import React from 'react';
import PropTypes from 'prop-types';
import { useImportImageNewDesign } from 'hooks/index';

const Cell = ({
  cell,
}) => {
  const upIcon = useImportImageNewDesign('general/up.png');
  const downIcon = useImportImageNewDesign('general/down.png');

  if (cell.column.id === 'changeTotal') {
    let image;

    if (cell.value > 0) {
      image = <img src={upIcon} alt={''} />;
    } else if (cell.value < 0) {
      image = <img src={downIcon} alt={''} />;
    } else {
      return '';
    }

    return image;
  }

  return cell.render('Cell');
};

Cell.propTypes = {
  cell: PropTypes.shape(),
};
Cell.defaultProps = {
  cell: null,
};

export default Cell;
