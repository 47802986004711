import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ logoUrl, logoClassName }) => (
  <img className={logoClassName} src={logoUrl} alt='Logo' />
);

Logo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  logoClassName: PropTypes.string,
};

Logo.defaultProps = {
  logoClassName: '',
};

export default Logo;
