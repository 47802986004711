import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import { matchStatusIds, sport } from 'constants/enum';
import {
  getMatchPeriods,
  getMatchPeriodsBasketballHockey,
  getMatchPeriodsSets,
  getMatchSportId,
  getMatchStatus,
  getReadyMatchId,
} from 'reducers';

import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const mapStateToProps = state => ({
  basketballHockeyPeriods: getMatchPeriodsBasketballHockey(state, getReadyMatchId(state)),
  periodsSets: getMatchPeriodsSets(state, getReadyMatchId(state)),
  periods: getMatchPeriods(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
});

const PeriodScore = ({
  basketballHockeyPeriods,
  periodsSets,
  periods,
  curPeriod,
  matchStatusId,
  sportId,
  side,
}) => {
  let usedPeriods = null;

  if ([sport.basketball, sport.ice_hockey, sport.ebasketball, sport.basketball3x3].includes(sportId)) {
    usedPeriods = basketballHockeyPeriods;
  } else if ([sport.volleyball, sport.beach_volley, sport.tennis].includes(sportId)) {
    usedPeriods = periodsSets;
  } else {
    usedPeriods = periods;
  }

  if (usedPeriods === null) {
    return null;
  }

  const sets = Object.entries(usedPeriods).map((cur, i) => {
    const [key, value] = cur;

    const period = value;
    const overTimeKeyPrefix = 'ot';
    const periodKeyPrefix = 'p';
    let wonLostClass = '';
    let onBreak = false;

    const twoDigitsClassName = sportId === sport.tennis ? 'two-digits-tennis-result-available' : 'two-digits-result-available';

    if ((key !== periodKeyPrefix + curPeriod && !key.includes(overTimeKeyPrefix))
       || (key !== curPeriod && !key.includes(periodKeyPrefix))) {
      if (period.home > period.away) {
        wonLostClass = side === 'home' ? 'table__row__set--won' : 'table__row__set--lost';
      } else if (period.home < period.away) {
        wonLostClass = side === 'home' ? 'table__row__set--lost' : 'table__row__set--won';
      }
    }

    if (!key.includes(overTimeKeyPrefix)) {
      onBreak = matchStatusIds.pause === matchStatusId && i + 1 === curPeriod;
    }

    return (
      <span key={i} styleName={`${wonLostClass} ${twoDigitsClassName}`} className='font-main--medium flex h-full items-center justify-center'>
        {onBreak ? '-' : period[side]}
      </span>
    );
  });

  return sets;
};

PeriodScore.propTypes = {
  curPeriod: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  periods: PropTypes.shape(),
  basketballHockeyPeriods: PropTypes.shape(),
  periodsSets: PropTypes.shape(),
  matchStatusId: PropTypes.number,
  side: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
};

PeriodScore.defaultProps = {
  curPeriod: null,
  periods: null,
  basketballHockeyPeriods: null,
  periodsSets: null,
  matchStatusId: matchStatusIds.not_started,
};

const PeriodTableRowElementStyled = cssModule(PeriodScore, style, { allowMultiple: true });
export default connect(mapStateToProps)(PeriodTableRowElementStyled);
