import { Redirect, Route } from 'react-router-dom';
import React, { Fragment } from 'react';
import Login from './newDesign/modules/auth/login/Login';
import ForgotPassword from './newDesign/modules/auth/forgotPassword/ForgotPassword';
import ResetPassword from './newDesign/modules/auth/resetPassword/ResetPassword';
import Client from './Client';

const Routes = () => (
  <Fragment>
    <Route
      exact
      path='/login'
      render={() => (
        <Login />
      )}
    />
    <Route
      exact
      path='/forgot-password'
      render={() => (
        <ForgotPassword />
      )}
    />
    <Route
      exact
      path='/reset-password'
      render={() => (
        <ResetPassword />
      )}
    />
    <Route
      exact
      path='/client/:userId'
      render={() => (
        <Client />
      )}
    />
    <Route
      exact
      path='/'
      render={() => (
        <Redirect to='/login' />
      )}
    />
  </Fragment>
);

export default Routes;
