// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FunFact__decoration-underline___2Q_Ch{position:relative}.FunFact__decoration-underline___2Q_Ch::after{position:absolute;width:100%;content:\"\";height:2px;background:#585a60;left:0;right:0;bottom:0}@media only screen and (min-width: 1536px){.FunFact__prematch__add-box___2kzwn{font-size:1.4vw}}", ""]);
// Exports
exports.locals = {
	"decoration-underline": "FunFact__decoration-underline___2Q_Ch",
	"prematch__add-box": "FunFact__prematch__add-box___2kzwn"
};
module.exports = exports;
