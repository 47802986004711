import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { useBestStat } from 'hooks';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const NumberStatSlide = ({
  statText, homeStat, awayStat, higherIsBetter, disableComparison,
}) => {
  const [homeIsBest, awayIsBest] = useBestStat(homeStat, awayStat, higherIsBetter);

  return (
    <div styleName='lframe-stats__text' className=' flex items-center justify-center'>
      <span styleName={(homeIsBest && !disableComparison) ? 'active' : ''} className='fs-18 font-main--bold uppercase active'>{homeStat}</span>
      <span className='fs-16 font-main--medium uppercase'>{statText}</span>
      <span styleName={(awayIsBest && !disableComparison) ? 'active' : ''} className='fs-18 font-main--bold uppercase'>{awayStat}</span>
    </div>
  );
};
NumberStatSlide.propTypes = {
  statText: PropTypes.string,
  higherIsBetter: PropTypes.bool,
  disableComparison: PropTypes.bool,
  homeStat: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string],
  ),
  awayStat: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string],
  ),
};

NumberStatSlide.defaultProps = {
  statText: '',
  higherIsBetter: true,
  disableComparison: false,
  homeStat: null,
  awayStat: null,
};
const NumberStatSlideStyled = cssModule(NumberStatSlide, style);
export default NumberStatSlideStyled;
