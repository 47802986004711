import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchId,
  getMatchHomeLastMResult,
  getMatchAwayLastMResult,
} from 'reducers';
import LastMatchesTeam from 'newDesign/modules/live/components/footer/footerBottom/slides/lastMatches/LastMatchesTeam';
import NumberBoxStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberBoxStatSlide';

const nrOfMatches = 5;

const mapStateToProps = state => ({
  homeStat: getMatchHomeLastMResult(state, getMatchId(state), nrOfMatches),
  awayStat: getMatchAwayLastMResult(state, getMatchId(state), nrOfMatches),
});

const LastMatches = ({
  homeStat, awayStat,
}) => {
  const intl = useIntl();

  return (
    <NumberBoxStatSlide
      statText={intl.formatMessage({ id: 't_footer_last_matches' })}
      homeStat={<LastMatchesTeam results={homeStat} />}
      awayStat={<LastMatchesTeam results={awayStat} />}
      higherIsBetter
    />
  );
};

LastMatches.propTypes = {
  homeStat: PropTypes.array,
  awayStat: PropTypes.array,
};

LastMatches.defaultProps = {
  homeStat: [],
  awayStat: [],
};

export default connect(mapStateToProps)(LastMatches);
