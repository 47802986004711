// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PreMachHeaderMatchInformation__flag___foZ8S{width:45px;height:45px;filter:drop-shadow(0 0 1px white)}.PreMachHeaderMatchInformation__prematch__meta-header___2wIN0 span{color:#d9d9d9}@media only screen and (min-width: 1536px){.PreMachHeaderMatchInformation__prematch__meta-header___2wIN0{font-size:1.4vw}.PreMachHeaderMatchInformation__prematch__meta-header___2wIN0>span:last-child{font-size:1.2vw}}", ""]);
// Exports
exports.locals = {
	"flag": "PreMachHeaderMatchInformation__flag___foZ8S",
	"prematch__meta-header": "PreMachHeaderMatchInformation__prematch__meta-header___2wIN0"
};
module.exports = exports;
