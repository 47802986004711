import React from 'react';

import { eventTypeId } from 'constants/enum';
import StatWrapper from 'newDesign/modules/break/components/statSlides/liveStats/statWrapper/StatWrapper';

const TableTennis = () => [
  <StatWrapper
    key={1}
    statKey={eventTypeId.tennis_max_points_in_a_row}
    relative
  />,
  <StatWrapper
    key={2}
    statKey={eventTypeId.tennis_break_points}
    relative
  />,
  <StatWrapper
    key={3}
    statKey={eventTypeId.service_errors_volley}
    relative
  />,
  <StatWrapper
    key={4}
    statKey={eventTypeId.tennis_service_points}
    relative
  />,
  <StatWrapper
    key={5}
    statKey={eventTypeId.receiver_points}
    relative
  />,
  <StatWrapper
    key={6}
    statKey={eventTypeId.tennis_total_points_count}
    relative
  />,

];

export default TableTennis;
