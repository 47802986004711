// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DefaultSeparator__prematch__vs___2TrSa span{margin-top:-2rem}@media only screen and (min-width: 1536px){.DefaultSeparator__prematch__vs___2TrSa span{font-size:2vw}}", ""]);
// Exports
exports.locals = {
	"prematch__vs": "DefaultSeparator__prematch__vs___2TrSa"
};
module.exports = exports;
