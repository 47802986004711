import {
  put,
  select,
  apply,
  call,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
} from 'reducers';
import { StatsMatchOverUnderRequest } from 'fishnet-api';
import * as config from 'Config/fishnetUrls';
import {
  updateStatsMatchOverUnder,
  updateStatsMatchOverUnderFailed,
} from 'actions/fishnet/index';
import { pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadOverUnder(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : yield select(getQueryFeed);

  const request = new StatsMatchOverUnderRequest(
    matchId,
    clientAlias,
    language,
    qFeed,
  );
  const response = yield apply(request, request.get);

  if (response && !response.error && response.averages) {
    const overUnder = {
      [matchId]: {
        home: response.averages.home,
        away: response.averages.away,
      },
    };

    yield put(updateStatsMatchOverUnder(overUnder, matchId));
  } else if (response && response.error) {
    yield put(updateStatsMatchOverUnderFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update stats_season_overunder failed');
  }
}

// request once
function* pollStatsMatchOverUnder(matchId, offset) {
  yield call(takeMatchUpdate, matchId); // wait on one succesful match_info / match_timelinedelta
  yield call(pollTillResponse, {}, loadOverUnder, matchId, offset); // poll this endpoint once
}

export default pollStatsMatchOverUnder;
