// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PreMatchOpener__prematch___3NmwA{color:#fff}.PreMatchOpener__prematch__background___2_S-I{position:absolute;background-size:cover;height:100%;width:100%}.PreMatchOpener__prematch__background___2_S-I::before{opacity:.95;background:rgba(0,0,0,.5);position:absolute;width:100%;height:100%;content:\"\"}.PreMatchOpener__bg-container___2Krjx{background:linear-gradient(180deg, rgba(0, 0, 0, 0.29) 0.02%, rgba(0, 0, 0, 0.6) 43.24%, rgba(0, 0, 0, 0.39) 58.83%, rgba(0, 0, 0, 0) 94.96%, rgba(0, 0, 0, 0) 136.06%);position:relative;min-width:65%;max-width:65%}.PreMatchOpener__bg-container___2Krjx::before{background:linear-gradient(191deg, rgba(16, 13, 13, 0.23) 8.22%, rgba(0, 0, 0, 0.3) 91.77%);box-shadow:0px 8px 30px 0px rgba(0,0,0,.25);-webkit-backdrop-filter:blur(12.5px);backdrop-filter:blur(12.5px);position:absolute;width:100%;height:100%;content:\"\";left:0;right:0}.PreMatchOpener__bg-container___2Krjx::after{background:linear-gradient(178deg, rgba(255, 255, 255, 0.06) 20.14%, rgba(255, 255, 255, 0.19) 49.66%, rgba(255, 255, 255, 0) 123.85%);box-shadow:0px 8px 30px 0px rgba(0,0,0,.25);-webkit-backdrop-filter:blur(12.5px);backdrop-filter:blur(12.5px);position:absolute;width:100%;height:100%;content:\"\";left:0;right:0;opacity:.8}", ""]);
// Exports
exports.locals = {
	"prematch": "PreMatchOpener__prematch___3NmwA",
	"prematch__background": "PreMatchOpener__prematch__background___2_S-I",
	"bg-container": "PreMatchOpener__bg-container___2Krjx"
};
module.exports = exports;
