import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import { getMatchStatus } from 'reducers';

import style from '../../../../ScoreBoard.scss';

const mapStateToProps = (state, ownProps) => ({
  matchStatusId: getMatchStatus(state, ownProps.matchId),
});

const SetScore = ({ result, side }) => {
  const score = result[side];

  return (
    <span styleName='table__row__score-total' className='fs-22 font-main--black flex h-full items-center'>
      {score}
    </span>
  );
};

SetScore.propTypes = {
  result: PropTypes.object,
  side: PropTypes.string.isRequired,
};

SetScore.defaultProps = {
  result: {
    home: null,
    away: null,
  },
};

const SetScoreStyled = cssModule(SetScore, style);
export default connect(mapStateToProps)(SetScoreStyled);
