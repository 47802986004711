import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchId,
  getMatchHomeLeagueRank,
  getMatchAwayLeagueRank,
} from 'reducers';
import NumberStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberStatSlide';

const mapStateToProps = state => ({
  homeStat: getMatchHomeLeagueRank(state, getMatchId(state)),
  awayStat: getMatchAwayLeagueRank(state, getMatchId(state)),
});

const LeagueRank = ({ homeStat, awayStat }) => {
  const intl = useIntl();
  return (
    <NumberStatSlide
      statText={intl.formatMessage({ id: 't_footer_league_ranking' })}
      homeStat={homeStat}
      awayStat={awayStat}
      higherIsBetter={false}
    />
  );
};

LeagueRank.propTypes = {
  homeStat: PropTypes.number,
  awayStat: PropTypes.number,
};

LeagueRank.defaultProps = {
  homeStat: null,
  awayStat: null,
};

export default connect(mapStateToProps)(LeagueRank);
