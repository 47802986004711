import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import Jersey from 'newDesign/modules/common/components/jersey/Jersey';

import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const TeamJersey = ({
  jersey,
  teamName,
  sportId,
}) => (
  <div className='flex flex-col flex-1 justify-center items-center overflow-hidden whitespace-nowrap'>
    <Jersey className={'h-full'} jerseySize='jersey_auto' jersey={jersey} sportId={sportId} />
    <h2 className='fs-18 font-second--bold max-w-full text-ellipsis overflow-hidden uppercase'>
      {teamName}
    </h2>
  </div>
);

TeamJersey.propTypes = {
  jersey: PropTypes.object.isRequired,
  teamName: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
};

const TeamJerseyStyled = cssModule(TeamJersey, style);

export default TeamJerseyStyled;
