import { createReducer } from 'utils';
import {
  SIR_LOADING,
  SIR_LOAD_ERROR,
  SIR_LOAD_SUCCESS,
} from 'constants/actions';
import { createSelector } from 'reselect';

const initialState = {
  sirLoaded: false,
  isLoading: false,
};

export default createReducer(initialState, {
  [SIR_LOADING]: state => ({
    ...state,
    sirLoaded: false,
    isLoading: true,
  }),
  [SIR_LOAD_ERROR]: (state, payload) => ({
    ...state,
    sirLoaded: false,
    isLoading: false,
    error: payload,

  }),
  [SIR_LOAD_SUCCESS]: state => ({
    ...state,
    sirLoaded: true,
    isLoading: false,

  }),
});

export const getIsSirLoaded = createSelector(
  state => state.sirLoaded,
  sirLoaded => sirLoaded,
);
