import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';


import style from 'newDesign/modules/live/components/footer/Footer.scss';

const NumberBarStatSlide = ({
  statText, homeStat, awayStat, homeStatText, awayStatText,
}) => (
  // TODO: exists a possibility to highlight the best value, same as in the NumberStatSlide, modification is needed
  <div styleName='lframe-stats__bar' className='flex items-center justify-center'>
    {homeStat}
    <div className='flex align-middle items-center'>
      <span className='fs-18 font-main--bold uppercase'>{homeStatText}</span>
      <span className='fs-16 font-main--medium uppercase'>{statText}</span>
      <span className='fs-18 font-main--bold uppercase'>{awayStatText}</span>
    </div>
    {awayStat}
  </div>
);

NumberBarStatSlide.propTypes = {
  statText: PropTypes.string,
  homeStat: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string],
  ),
  awayStat: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string],
  ),
  homeStatText: PropTypes.string,
  awayStatText: PropTypes.string,
};

NumberBarStatSlide.defaultProps = {
  statText: '',
  homeStat: null,
  awayStat: null,
  homeStatText: '',
  awayStatText: '',
};

const NumberBarStatSlideStyled = cssModule(NumberBarStatSlide, style);
export default NumberBarStatSlideStyled;
