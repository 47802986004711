import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import HandballClock from 'newDesign/modules/common/components/clock/handball/HandballClock';
import SoccerClock from 'newDesign/modules/common/components/clock/soccer/SoccerClock';
import FutsalClock from 'newDesign/modules/common/components/clock/futsal/FutsalClock';
import MatchStatus from 'newDesign/modules/common/components/oddsPanel/scoreBoard/scoreBasedBoard/matchStatus/MatchStatus';
import { sport, matchStatusIds } from 'constants/enum';
import { isEnded, isInterrupted, isNotStarted } from 'utils/matchstatus';

import {
  getMatchStatusName,
  getMatchPTime,
  getMatchStatus,
  getMatchPeriodLength,
  getMatchSportId,
  getMatchTimeInfo,
  getMatchSuspended,
  getMatchOvertimeLength,
  getClockBreak, getReadyMatchId,
} from 'reducers';
import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
  pTime: getMatchPTime(state, getReadyMatchId(state)),
  status: getMatchStatusName(state, getReadyMatchId(state)),
  statusId: getMatchStatus(state, getReadyMatchId(state)),
  periodLength: getMatchPeriodLength(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  suspended: getMatchSuspended(state, getReadyMatchId(state)),
  timeInfo: getMatchTimeInfo(state, getReadyMatchId(state)),
  overtimeLength: getMatchOvertimeLength(state, getReadyMatchId(state)),
  clockBreak: getClockBreak(state),
});

const StatusTimeInfo = ({
  matchId,
  status,
  statusId,
  pTime,
  sportId,
  periodLength,
  suspended,
  timeInfo,
  overtimeLength,
  clockBreak,
}) => {
  let clock = null;

  const isSoccerMatch = sportId === sport.soccer;
  const isMatchInProgress = ![matchStatusIds.ended, matchStatusIds.not_started].includes(statusId);

  if (isSoccerMatch && isMatchInProgress) {
    clock = <SoccerClock
      matchId={matchId}
      statusId={statusId}
      pTime={pTime}
      periodLength={periodLength}
      sportId={sportId}
      suspended={suspended}
    />;
  } else if (sportId === sport.handball) {
    clock = <HandballClock
      matchId={matchId}
      statusId={statusId}
      pTime={pTime}
      periodLength={periodLength}
      sportId={sportId}
      suspended={suspended}
      timeInfo={timeInfo}
      overtimeLength={overtimeLength}
    />;
  } else if (sportId === sport.futsal) {
    clock = <FutsalClock
      statusId={statusId}
      timeInfo={timeInfo}
    />;
  }

  return (
    <Fragment>
      {!clockBreak && <MatchStatus status={status} statusId={statusId} />}
      {(!clockBreak
        && ![matchStatusIds.halftime, matchStatusIds.penalties].includes(statusId))
        && !isEnded(statusId)
        && !isNotStarted(statusId)
        && !isInterrupted(statusId)
        && (
          <div className='scoreboard-time flex'>
            <span className='fs-24 font-second--bold'>{clock}</span>
          </div>
        )}
    </Fragment>
  );
};

StatusTimeInfo.propTypes = {
  matchId: PropTypes.number.isRequired,
  status: PropTypes.string,
  statusId: PropTypes.number,
  pTime: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sportId: PropTypes.number.isRequired,
  periodLength: PropTypes.number,
  suspended: PropTypes.bool,
  timeInfo: PropTypes.object,
  overtimeLength: PropTypes.number,
  clockBreak: PropTypes.bool,
};

StatusTimeInfo.defaultProps = {
  status: '',
  statusId: null,
  pTime: null,
  periodLength: null,
  suspended: false,
  timeInfo: undefined,
  overtimeLength: 5,
  clockBreak: false,
};
const StatusTimeInfoStyled = cssModule(StatusTimeInfo, style);
export default connect(mapStateToProps)(StatusTimeInfoStyled);
