import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import WinLooseBox from './winLooseBox/WinLooseBox';

const WinsLossesBoxes = ({ homeStats, awayStats }) => {
  const intl = useIntl();

  return (
    <div className={'z-10 flex w-full gap-x-20'}>
      <div className={'flex flex-1 flex-col justify-between'}>
        <WinLooseBox statText={intl.formatMessage({ id: 't_statistics_wins' })} stat={homeStats.matchesWon} winBox />
        <WinLooseBox statText={intl.formatMessage({ id: 't_statistics_losses' })} stat={homeStats.matchesLost} />
      </div>
      <div className={'flex flex-1 flex-col justify-between'}>
        <WinLooseBox statText={intl.formatMessage({ id: 't_statistics_wins' })} stat={awayStats.matchesWon} reverse winBox />
        <WinLooseBox statText={intl.formatMessage({ id: 't_statistics_losses' })} stat={awayStats.matchesLost} reverse />
      </div>
    </div>
  );
};

WinsLossesBoxes.propTypes = {
  homeStats: PropTypes.object,
  awayStats: PropTypes.object,
};

WinsLossesBoxes.defaultProps = {
  homeStats: {
    matchesLost: 0,
    matchesWon: 0,
  },
  awayStats: {
    matchesLost: 0,
    matchesWon: 0,
  },
};

export default WinsLossesBoxes;
