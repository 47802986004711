/**
 * For tennis sport, we have different bg images depending on the ground type
 * @param sportId
 * @param ground
 * @param target 'statSlides' or 'opener' depends on the target screen
 * @return {string}
 */

// eslint-disable-next-line import/prefer-default-export
export const getBackgroundImageUrl = (sportId, ground, target = 'opener') => `general/${target}/${sportId + (ground ? '-' + ground : '')}.jpg`;
