import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getReadyMatchId,
  getMatchSportId,
} from 'reducers';
import { sport } from 'constants/enum';
import PeriodBasedCompetitors from 'newDesign/modules/live/components/footer/footerTop/periodBasedCompetitors/PeriodBasedCompetitors';
import ScoreBasedCompetitors from 'newDesign/modules/live/components/footer/footerTop/scoreBasedCompetitors/ScoreBasedCompetitors';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const FooterTop = ({
  sportId,
}) => {
  let top = null;
  switch (sportId) {
    case sport.basketball:
    case sport.ebasketball:
    case sport.table_tennis:
    case sport.tennis:
    case sport.ice_hockey:
    case sport.beach_volley:
    case sport.volleyball:
    case sport.basketball3x3:
      top = <PeriodBasedCompetitors />;
      break;
    case sport.soccer:
    case sport.esoccer:
    case sport.handball:
    default:
      top = <ScoreBasedCompetitors />;
      break;
  }

  return (top);
};

FooterTop.propTypes = {
  sportId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(FooterTop);
