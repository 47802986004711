import {
  call,
  put,
  select,
  fork,
} from 'redux-saga/effects';
import {
  stopScoreboard,
  startScoreboard,
} from 'actions/components';
import {
  getMatchSportId,
} from 'reducers';
import { sport } from '../../constants/enum';
import { waitForMatchData } from '../utilLCR';
import soccerTop from './scoreboard/soccer';
import { tennisTop, tennisBottom } from './scoreboard/tennis';
import { bbBottom, bbTop } from './scoreboard/basketball';
import { generalBottomRow, generalTopRow } from './scoreboard/general';
import { ttBottom, ttTop } from './scoreboard/tabletennis';
import icehockeyTop from './scoreboard/icehockey';
import handballTop from './scoreboard/handball';
import { volleyballTop, volleyballBottom } from './scoreboard/volleyball';
import futsalTop from './scoreboard/futsal';

export default function* controlScoreboard(matchId) {
  try {
    yield put(startScoreboard());

    yield call(waitForMatchData, matchId);
    const sportId = yield select(getMatchSportId, matchId);

    let topSagas;
    let bottomSagas;

    if (sportId === sport.tennis) {
      topSagas = tennisTop;
      bottomSagas = tennisBottom;
    } else if (sportId === sport.soccer || sportId === sport.esoccer) {
      topSagas = soccerTop;
      bottomSagas = null;
    } else if (sportId === sport.basketball || sportId === sport.ebasketball) {
      topSagas = bbTop;
      bottomSagas = bbBottom;
    } else if (sportId === sport.table_tennis) {
      topSagas = ttTop;
      bottomSagas = ttBottom;
    } else if (sportId === sport.ice_hockey) {
      topSagas = icehockeyTop;
    } else if (sportId === sport.handball) {
      topSagas = handballTop;
    } else if (sportId === sport.volleyball || sportId === sport.beach_volley) {
      topSagas = volleyballTop;
      bottomSagas = volleyballBottom;
    } else if (sportId === sport.futsal) {
      topSagas = futsalTop;
      bottomSagas = null;
    }

    yield fork(generalTopRow, matchId, topSagas);
    yield call(generalBottomRow, bottomSagas);
  } catch (error) {
    //
  } finally {
    yield put(stopScoreboard());
  }
}
