// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PreMatchOdds__prematch__odds___3yXwQ{font-size:1.4vw}.PreMatchOdds__prematch__odds___3yXwQ .PreMatchOdds__prematch__odds__blocks___2TDos{max-width:10vw;min-width:7vw}.PreMatchOdds__prematch__odds___3yXwQ .PreMatchOdds__prematch__odds__blocks___2TDos:first-child{-ms-flex:1;flex:1}.PreMatchOdds__prematch__odds___3yXwQ .PreMatchOdds__prematch__odds__blocks___2TDos:last-child{-ms-flex:1;flex:1}.PreMatchOdds__prematch__odds___3yXwQ .PreMatchOdds__prematch__odds__blocks___2TDos:nth-child(2){min-width:initial}.PreMatchOdds__prematch__odds___3yXwQ .PreMatchOdds__prematch__odds__blocks___2TDos span{font-size:1.6vw}.PreMatchOdds__prematch__odds___3yXwQ .PreMatchOdds__prematch__odds__blocks___2TDos>span{font-size:1vw}.PreMatchOdds__decoration-underline___1Kczp{position:relative}.PreMatchOdds__decoration-underline___1Kczp::after{position:absolute;width:100%;content:\"\";height:2px;background:#585a60;left:0;right:0;bottom:0}.PreMatchOdds__oddsGridRemove___gTWEY{animation-duration:1s;animation-name:PreMatchOdds__remove___GlrAy;height:0px}", ""]);
// Exports
exports.locals = {
	"prematch__odds": "PreMatchOdds__prematch__odds___3yXwQ",
	"prematch__odds__blocks": "PreMatchOdds__prematch__odds__blocks___2TDos",
	"decoration-underline": "PreMatchOdds__decoration-underline___1Kczp",
	"oddsGridRemove": "PreMatchOdds__oddsGridRemove___gTWEY",
	"remove": "PreMatchOdds__remove___GlrAy"
};
module.exports = exports;
