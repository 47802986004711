import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';

import { useImportImageNewDesign } from 'hooks';
import { getMatchSportId, getMatchSportName } from 'reducers';

import style from 'newDesign/modules/common/components/sportIcon/SportIcon.scss';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
  sportName: getMatchSportName(state, ownProps.matchId),
});

const SportIcon = ({ sportId, sportName }) => {
  const sportIcon = useImportImageNewDesign(`sport-icons-common/sport-icon-${sportId}.svg`);

  return (
    <Fragment>
      <div styleName='icon-circle' className='mr-2 my-1'>
        <div className='h-full' styleName='icon-container' style={{ backgroundImage: `url(${sportIcon})` }} />
      </div>
      <span className='whitespace-nowrap'>{sportName}</span>
    </Fragment>
  );
};

SportIcon.propTypes = {
  sportId: PropTypes.number,
  sportName: PropTypes.string,
};

SportIcon.defaultProps = {
  sportId: null,
  sportName: '',
};

const SportIconStyled = cssModule(SportIcon, style);
export default connect(mapStateToProps)(SportIconStyled);
