// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PreviousMeetingsHeader__competitors-form___2FOps span{color:#fff;line-height:1.6vw}.PreviousMeetingsHeader__competitors-form___2FOps:not(.PreviousMeetingsHeader__competitors-form--arrow___3uScu) span{max-width:45%}.PreviousMeetingsHeader__competitors-form___2FOps:not(.PreviousMeetingsHeader__competitors-form--arrow___3uScu) span:last-child{text-align:right}", ""]);
// Exports
exports.locals = {
	"competitors-form": "PreviousMeetingsHeader__competitors-form___2FOps",
	"competitors-form--arrow": "PreviousMeetingsHeader__competitors-form--arrow___3uScu"
};
module.exports = exports;
