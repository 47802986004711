import React from 'react';
import { getClientMatchId, getReadyMatchId, showMatchIdComp } from 'reducers/index';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import styles from './ClientOrMatchIdSeparator.scss';

const mapStateToProps = (state, ownProps) => ({
  clientMatchId: getClientMatchId(state, ownProps.matchId),
  showMatchIdCmp: showMatchIdComp(state, getClientMatchId(state, getReadyMatchId(state))),
});

const ClientOrMatchIdSeparator = ({ matchId, clientMatchId, showMatchIdCmp }) => {
  const intl = useIntl();

  let matchIdInformation;

  if (showMatchIdCmp) {
    matchIdInformation = (
      <span styleName='match_id' className={'uppercase font-second'}>
        {`${intl.formatMessage({ id: 't_general_badge_match_id' })} ${clientMatchId || matchId}`}
      </span>
    );
  }

  return (
    <div styleName='match_id_wrapper' className={'z-10'}>
      {matchIdInformation}
    </div>
  );
};


ClientOrMatchIdSeparator.propTypes = {
  matchId: PropTypes.number.isRequired,
  clientMatchId: PropTypes.string,
  showMatchIdCmp: PropTypes.bool,
};

ClientOrMatchIdSeparator.defaultProps = {
  clientMatchId: '',
  showMatchIdCmp: true,
};

const MatchIdStyled = cssModule(ClientOrMatchIdSeparator, styles);
export default connect(mapStateToProps)(MatchIdStyled);
