import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import PreMatchHeaderMatchInformation
from 'newDesign/modules/preMatch/common/components/preMatchHeaderMatchInformation/PreMatchHeaderMatchInformation';
import useImportImageFacelift from 'newDesign/modules/common/hooks/ImportImage';
import { getBackgroundImageUrl } from 'newDesign/modules/common/utils/sportBackground';
import ClientOrMatchIdSeparator from 'newDesign/modules/common/components/clientOrMatchIdSeparator/ClientOrMatchIdSeparator';
import PreMatchOdds from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchOdds/PreMatchOdds';
import PreMatchCompetitors from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/PreMatchCompetitors';
import { twoOrThreeWay } from 'constants/enum';
import {
  getMatchGround,
  getMatchSportId,
  getReadyMatchId,
} from 'reducers/index';

import style from 'newDesign/modules/preMatch/components/preMatchOpener/PreMatchOpener.scss';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
  ground: getMatchGround(state, getReadyMatchId(state)),
});

const PreMatchOpener = ({ matchId, sportId, ground }) => {
  const backgroundImage = useImportImageFacelift(getBackgroundImageUrl(sportId, ground, 'opener'));

  if (!sportId) {
    return null;
  }

  return (
    <div styleName='prematch' className='flex min-h-screen'>
      <div className='flex relative w-full justify-start items-center flex-col'>
        <div styleName='prematch__background'>
          <img
            alt=''
            className='h-full w-full object-cover'
            src={backgroundImage}
          />
        </div>
        <div styleName='bg-container' className='flex h-full flex-col px-28 box-border'>
          <PreMatchHeaderMatchInformation matchId={matchId} displayStadium />
          <div className='mb-16' />
          <ClientOrMatchIdSeparator matchId={matchId} />
          <PreMatchCompetitors matchId={matchId} />
          <PreMatchOdds
            matchId={matchId}
            marketIds={twoOrThreeWay}
            maxMarkets={1}
          />
        </div>
      </div>
    </div>
  );
};

PreMatchOpener.propTypes = {
  matchId: PropTypes.number.isRequired,
  sportId: PropTypes.number.isRequired,
  ground: PropTypes.string,
};

PreMatchOpener.defaultProps = {
  ground: '',
};

const PreMatchOpenerStyled = cssModule(PreMatchOpener, style);
export default connect(mapStateToProps)(PreMatchOpenerStyled);
