import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  Cell, Label, Pie, PieChart,
} from 'recharts';
import { getSeparatorColor } from 'reducers/index';

const emptyBar = [
  { name: 'won', matchOutcome: 0 },
  { name: 'loss', matchOutcome: 100 },
];

const mapStateToProps = state => ({
  color: getSeparatorColor(state),
});

const Diagram = ({ percent, matchesData, color }) => (
  <PieChart chartArea width={250} height={175}>
    <Pie
      startAngle={-270}
      endAngle={90}
      isAnimationActive={false}
      data={emptyBar}
      dataKey='matchOutcome'
      outerRadius={70}
      innerRadius={60}
      fillOpacity={0.3}
      fill={color}
      stroke='none'
    >
      <Label className={'fs-16 font-bold'} position='center'>{percent}</Label>
    </Pie>
    <Pie
      startAngle={-270}
      endAngle={90}
      isAnimationActive={false}
      data={matchesData}
      dataKey='matchOutcome'
      outerRadius={70}
      innerRadius={60}
      fill={color}
      stroke='none'
      cornerRadius={30}
    >
      {matchesData.map((entry, i) => (
        <Cell key={i} fill={i === 0 ? 'transparent' : color} />
      ))}
    </Pie>
  </PieChart>
);

Diagram.propTypes = {
  percent: PropTypes.string,
  matchesData: PropTypes.array,
  color: PropTypes.string.isRequired,
};

Diagram.defaultProps = {
  percent: '0%',
  matchesData: null,
};

export default connect(mapStateToProps)(Diagram);
