import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import Match from 'newDesign/modules/preMatch/components/statSlides/lastMatches/match/Match';
import { AMOUNT_OF_MATCHES_RESULT_BOX } from 'constants/components';
import {
  getMatchHomeLastM,
  getMatchAwayLastM,
  getMatchHomeUid,
  getMatchAwayUid,
} from 'reducers/index';

import style from 'newDesign/modules/preMatch/components/statSlides/lastMatches/matches/Matches.scss';

const mapStateToProps = (state, ownProps) => ({
  homeMatches: getMatchHomeLastM(state, ownProps.matchId, AMOUNT_OF_MATCHES_RESULT_BOX),
  awayMatches: getMatchAwayLastM(state, ownProps.matchId, AMOUNT_OF_MATCHES_RESULT_BOX),
  homeUid: getMatchHomeUid(state, ownProps.matchId),
  awayUid: getMatchAwayUid(state, ownProps.matchId),
});

const Matches = ({
  homeMatches, awayMatches, homeUid, awayUid,
}) => {
  const maxLength = Math.max(homeMatches.length, awayMatches.length);
  const rowStructuredHomeAwayMatches = Array.from({ length: maxLength }, (_, i) => ({
    home: i < homeMatches.length ? homeMatches[i] : {},
    away: i < awayMatches.length ? awayMatches[i] : {},
  }));

  // logic for data in boxes - in the center is the last game that happened for both teams (highlighted by bigger size)
  // logic for table data - the first element has to be the last game that happened
  const homeAwayRow = rowStructuredHomeAwayMatches.map((row, i) => (
    <div key={i} className='prematch__comp-row flex gap-14'>
      <div className='flex flex-1'>
        <Match teamId={homeUid} result={row.home.result} teams={row.home.teams} />
      </div>
      <div className='flex flex-1'>
        <Match teamId={awayUid} result={row.away.result} teams={row.away.teams} />
      </div>
    </div>
  ));

  return (
    <div className='flex flex-col h-full pb-8 box-border justify-between'>
      <div className='z-10 flex font-main fs-17 flex-col flex-1 justify-between'>
        {homeAwayRow}
      </div>
    </div>
  );
};

Matches.propTypes = {
  homeMatches: PropTypes.array,
  awayMatches: PropTypes.array,
  homeUid: PropTypes.number,
  awayUid: PropTypes.number,
};
Matches.defaultProps = {
  homeMatches: [],
  awayMatches: [],
  homeUid: null,
  awayUid: null,
};

const MatchesStyled = cssModule(Matches, style);
export default connect(mapStateToProps)(MatchesStyled);
