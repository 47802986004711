import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import useImportImageFacelift from 'newDesign/modules/common/hooks/ImportImage';
import { getBackgroundImageUrl } from 'newDesign/modules/common/utils/sportBackground';
import PreMatchHeaderMatchInformation
from 'newDesign/modules/preMatch/common/components/preMatchHeaderMatchInformation/PreMatchHeaderMatchInformation';
import PreMatchDecoratedTitle from 'newDesign/modules/preMatch/common/components/preMatchDecoratedTitle/PreMatchDecoratedTitle';
import PreMatchCompetitors from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/PreMatchCompetitors';
import CountdownSeparator from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/countdownSeparator/CountdownSeparator';
import FunFact from 'newDesign/modules/preMatch/components/highlightOfTheDay/funFact/FunFact';
import ClientOrMatchIdSeparator from 'newDesign/modules/common/components/clientOrMatchIdSeparator/ClientOrMatchIdSeparator';
import ErrorBoundary from 'utils/ErrorBoundary';
import {
  getMatchGround,
  getMatchSportId,
  getReadyMatchId,
} from 'reducers/index';

import style from 'newDesign/modules/preMatch/components/highlightOfTheDay/HOTD.scss';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
  ground: getMatchGround(state, getReadyMatchId(state)),
});

const HOTD = ({ matchId, sportId, ground }) => {
  const intl = useIntl();
  const backgroundImage = useImportImageFacelift(getBackgroundImageUrl(sportId, ground, 'opener'));

  return (
    <div styleName='prematch' className='highlightOfTheDayWrapper flex min-h-screen'>
      <div className='flex relative w-full justify-start items-center flex-col'>
        <div styleName='prematch__background'>
          <img alt='' className='h-full w-full object-cover' src={backgroundImage} />
        </div>
        <div styleName='bg-container' className='flex h-full flex-col px-28 box-border'>
          <PreMatchHeaderMatchInformation matchId={matchId} />
          <div className='mb-16' />
          <PreMatchDecoratedTitle titleTranslation={intl.formatMessage({ id: 't_highlights_match_of_the_day' })} />
          <ClientOrMatchIdSeparator matchId={matchId} displayMatchId />
          <PreMatchCompetitors matchId={matchId} separator={<CountdownSeparator matchId={matchId} />} />
          <ErrorBoundary fallback={<div />}>
            <FunFact matchId={matchId} />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

HOTD.propTypes = {
  matchId: PropTypes.number.isRequired,
  sportId: PropTypes.number.isRequired,
  ground: PropTypes.string,
};

HOTD.defaultProps = {
  ground: '',
};

const HOTDStyled = cssModule(HOTD, style);
export default connect(mapStateToProps)(HOTDStyled);
