import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SidebarBottomLayout from 'newDesign/modules/common/components/layout/sidebarBottomLayout/SidebarBottomLayout';
import TopBottomLayout from 'newDesign/modules/common/components/layout/topBottomLayout/TopBottomLayout';
import MainSidebarLayout from 'newDesign/modules/common/components/layout/mainSidebarLayout/MainSidebarLayout';
import FullscreenLayout from 'newDesign/modules/common/components/layout/fullscreenLayout/FullscreenLayout';
import {
  LAYOUT_FULLSCREEN,
  LAYOUT_FRAME,
  LAYOUT_TOPBOTTOM,
  LAYOUT_MAINSIDEBAR,
} from 'constants/bcms';
import {
  getLayout,
  getLogoUrl,
} from 'reducers';

const mapStateToProps = state => ({
  logoUrl: getLogoUrl(state),
  layout: getLayout(state),
});

export const LayoutWrapper = ({
  main,
  bottom,
  sidebar,
  logoUrl,
  layout,
}) => {
  switch (layout) {
    case LAYOUT_FULLSCREEN:
      return <FullscreenLayout main={main} />;
    case LAYOUT_FRAME:
      return (
        <SidebarBottomLayout
          main={main}
          bottom={bottom}
          sidebar={sidebar}
        />
      );
    case LAYOUT_TOPBOTTOM:
      return (
        <TopBottomLayout
          main={main}
          bottom={bottom}
          sidebar={sidebar}
          logoUrl={logoUrl}
        />
      );
    case LAYOUT_MAINSIDEBAR:
      return (
        <MainSidebarLayout
          main={main}
          sidebar={sidebar}
          logoUrl={logoUrl}
        />
      );
    default:
      return (
        <SidebarBottomLayout
          main={main}
          bottom={bottom}
          sidebar={sidebar}
        />
      );
  }
};

LayoutWrapper.propTypes = {
  main: PropTypes.element,
  bottom: PropTypes.element,
  sidebar: PropTypes.element,
  logoUrl: PropTypes.string,
  layout: PropTypes.number,
};
LayoutWrapper.defaultProps = {
  main: null,
  bottom: null,
  sidebar: null,
  logoUrl: '',
  layout: LAYOUT_FRAME,
};

export default connect(mapStateToProps)(LayoutWrapper);
