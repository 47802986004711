import {
  put,
  select,
  apply,
  fork,
  call,
  delay,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getMatchStatus,
  getMatchStartTime,
} from 'reducers';
import { StatsMatchSituationRequest } from 'fishnet-api';
import * as config from 'Config/fishnetUrls';
import { MATCH_SITUATION_POLL_INTERVAL } from 'constants/fishnet';
import { updateStatsMatchSituation, updateStatsMatchSituationFailed } from 'actions/fishnet/index';
import {
  sagaCancel, sagaRunning,
} from '../utilSagas';
import { isEnded } from '../../utils/matchstatus';
import { MIN, SEC } from '../../constants/app';
import { pollFishnet, pollTillResponse, takeMatchUpdate } from '../utilLCR';


function* loadStatsMatchSituation(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : (yield select(getQueryFeed));

  const request = new StatsMatchSituationRequest(matchId, clientAlias, language, qFeed);
  const response = yield apply(request, request.get);

  if (response && !response.error) {
    yield put(updateStatsMatchSituation(response, matchId));
  } else if (response && response.error) {
    if (response.message === 'No situations for the specified matchid.') {
      return;
    }

    yield put(updateStatsMatchSituationFailed(response, matchId));
    throw response;
  } else {
    throw new Error('Update stats_match_situation failed');
  }
}

// atleast once, poll from 5min before start to match end
function* pollStatsMatch(matchId, offset, pollInterval = MATCH_SITUATION_POLL_INTERVAL) {
  let status;
  let task;
  yield call(pollTillResponse, { logLevel: 0 }, loadStatsMatchSituation, matchId, offset); // atleast poll once


  for (;;) {
    status = yield select(getMatchStatus, matchId);
    const startTime = yield select(getMatchStartTime, matchId);

    if (
      !sagaRunning(task)
      && (startTime && (startTime * SEC) + 5 * MIN < Date.now())
      && (!isEnded(status))
    ) {
      // run if less than 5 min to start or live
      task = yield fork(
        pollFishnet,
        { pollInterval },
        loadStatsMatchSituation,
        matchId,
        offset,
      );
    } else if (sagaRunning(task) && isEnded(status)) {
      // stop polling if match ended
      yield delay(pollInterval);
      sagaCancel(task);
    }

    yield call(takeMatchUpdate, matchId);
  }
}

export default pollStatsMatch;
