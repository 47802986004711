import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import style from 'newDesign/modules/announcement/components/oddsMarketOutcome/OddsMarketOutcome.scss';

const OddsMarketOutcome = ({ outcome }) => {
  const { decOdds } = outcome;
  const [prevOdds, setPrevOdds] = useState(decOdds);
  const [newOdds, setNewOdds] = useState(decOdds);

  useEffect(() => {
    let timer;
    setNewOdds(decOdds);
    timer = setTimeout(() => {
      setPrevOdds(decOdds);
      timer = null;
    }, 3200);

    return () => {
      if (timer) {
        clearTimeout(timer);
        // Required when it is quickly swapped back to prev value
        setPrevOdds(decOdds);
      }
    };
  }, [decOdds]);

  // TODO: post MVP functionality of displaying changes in odds
  // let changeIndicator = null;
  //
  // if (newOdds !== decOdds) {
  //   // do nothing, so the indicators reset
  //   // this happens when useEffect has not run yet
  // } else if (prevOdds > newOdds) {
  //   changeIndicator = <span styleName='triangle_down' className='ml-2' />;
  // } else if (prevOdds < newOdds) {
  //   changeIndicator = <span styleName='triangle_up' className='ml-2' />;
  // }

  return (
    <div>
      <div className='flex justify-center items-center pr-5 font-bold uppercase'>
        <span>{outcome.name.slice(0, 4)} - {outcome.odds}</span>
        {/* TODO: post MVP functionality of displaying changes in odds */}
        {/* {changeIndicator} */}
      </div>
    </div>
  );
};

OddsMarketOutcome.defaultProps = {
  outcome: null,
};

OddsMarketOutcome.propTypes = {
  outcome: PropTypes.shape(),
};

const OddsMarketOutcomeStyled = cssModule(OddsMarketOutcome, style);
export default OddsMarketOutcomeStyled;
