import { sport, oddsT } from 'constants/enum';

const clientMarketRankings = {
  whitelabel1: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.nextgoal,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.total_current_round,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_first_half,
      oddsT.live.two_way_fist_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.basketball3x3]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.win_current_period,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.handicap,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.set_handicap,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.to_score_goal_x,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.asian_handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.draw_no_bet,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.badminton]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
  },
  megabetpluscy: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.restofthematch,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.to_score_goal_x_first_half,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.handicap,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_fist_half,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap_ot,
      oddsT.live.handicap_current_period,
      oddsT.live.asian_handicap_first_half,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.handicap,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.total_incl_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.set_handicap,
      oddsT.live.tiebreak_in_set,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.to_score_goal_x,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.handicap,
      oddsT.live.handicap_first_half,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
      oddsT.live.handicap,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.first_team_to_score,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap,
      oddsT.live.handicap_current_period,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.restofthematch,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.asian_handicap,
      oddsT.live.first_team_to_score,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.handicap_current_period,
    ],
  },
  supabets: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.nextgoal,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.total_current_round,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_first_half,
      oddsT.live.two_way_fist_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.handicap,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.set_handicap,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.to_score_goal_x,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.asian_handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.draw_no_bet,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.badminton]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
  },
  coderespain: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.nextgoal,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.total_current_round,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_first_half,
      oddsT.live.two_way_fist_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.handicap,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.set_handicap,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.to_score_goal_x,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.asian_handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.draw_no_bet,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.badminton]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
  },
  totolotekcashpoint: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.restofthematch,
      oddsT.live.handicap,
      oddsT.live.total_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.next_goal_first_half,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.who_scores_point,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.restofthematch,
      oddsT.live.handicap,
      oddsT.live.two_way_live_ot,
      oddsT.live.total_current_round,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.rest_of_period,
      oddsT.live.total_incl_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.win_games_of_set,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.to_score_goal_x,
      oddsT.live.total_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.handicap_first_half,
      oddsT.live.race_to_points,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.race_to_points,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.restofthematch,
      oddsT.live.asian_handicap,
      oddsT.live.total_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.next_goal_first_half,
      oddsT.live.ht_who_wins_the_rest,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.who_scores_point,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.asian_handicap,
      oddsT.live.race_to_points,
    ],
  },
  betonalfa: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.next_goal_first_half,
      oddsT.live.restofthematch,
      oddsT.live.handicap,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.win_current_period,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.rest_of_period,
      oddsT.live.restofthematch,
      oddsT.live.two_way_live_ot,
      oddsT.live.total_incl_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.handicap_first_half,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.to_score_goal_x,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.asian_handicap,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.total_first_half,
      oddsT.live.total,
      oddsT.live.asian_handicap,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.asian_handicap,
    ],
  },
  cybet: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.double_chance,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.handicap,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.who_scores_point,
      oddsT.live.race_to_points,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.double_chance,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.restofthematch,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.two_way_live_ot,
      oddsT.live.total_incl_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.to_score_goal_x,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      null,
      oddsT.live.asian_handicap,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.nextgoal,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
    ],
  },
  opap: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.nextgoal,
      oddsT.live.first_team_to_score,
      oddsT.live.restofthematch,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.total_first_half,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.double_chance,
      oddsT.live.to_score_goal_x,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.draw_no_bet,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_current_round,
      oddsT.live.total_incl_ot,
      oddsT.live.race_to_points,
      oddsT.live.handicap_current_period,
      oddsT.live.win_current_period,
      oddsT.live.who_scores_point,
      oddsT.live.total_first_half,
      oddsT.live.two_way_fist_half,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.handicap,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.total_incl_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.set_handicap,
      oddsT.live.tiebreak_in_set,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.to_score_goal_x,
      oddsT.live.handicap,
      oddsT.live.total_first_half,
      oddsT.live.first_team_to_score,
      oddsT.live.total,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.handicap_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.first_team_to_score,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.total,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap,
      oddsT.live.race_to_points,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.total,
      oddsT.live.restofthematch,
      oddsT.live.total_first_half,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.first_team_to_score,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.first_team_to_score,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.total,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap,
      oddsT.live.race_to_points,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
    ],
    [sport.table_tennis]: [
      oddsT.pre.two_way,
      oddsT.live.two_way_live,
      oddsT.live.total,
      oddsT.live.handicap_current_period,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.asian_handicap,
      oddsT.live.win_tennis_set,
      oddsT.live.win_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.total_current_round,

      // not sure if used
      oddsT.live.win_tennis_game,
      oddsT.live.total_games_in_match,
      oddsT.live.win_games_of_set,
    ],
  },
  opapsports: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.restofthematch,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.to_score_goal_x,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.handicap,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.two_way_live_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_fist_half,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap_ot,
      oddsT.live.handicap_current_period,
      oddsT.live.asian_handicap_first_half,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.handicap,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.total_incl_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.set_handicap,
      oddsT.live.tiebreak_in_set,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.to_score_goal_x,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.handicap,
      oddsT.live.handicap_first_half,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.first_team_to_score,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap,
      oddsT.live.handicap_current_period,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.restofthematch,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.first_team_to_score,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap,
      oddsT.live.handicap_current_period,
    ],
    [sport.table_tennis]: [
      oddsT.pre.two_way,
      oddsT.live.two_way_live,
      oddsT.live.total,
      oddsT.live.handicap_current_period,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.asian_handicap,
      oddsT.live.win_tennis_set,
      oddsT.live.win_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.total_current_round,

      // not sure if used
      oddsT.live.win_tennis_game,
      oddsT.live.total_games_in_match,
      oddsT.live.win_games_of_set,
    ],
  },
  luckiaes: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.nextgoal,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.handicap,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.next_goal_only_ot,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.total_incl_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.who_scores_point,
      oddsT.live.two_way_fist_half,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.nextgoal,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.restofthematch,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.set_handicap,
      oddsT.live.win_games_of_set,
      oddsT.live.total_games_in_match,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.handicap,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.handicap_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.nextgoal,
      oddsT.live.halftime_1x2,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.total_first_half,
      oddsT.live.next_goal_first_half,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
  },
  grupoacrismatic: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.nextgoal,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.total_current_round,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_first_half,
      oddsT.live.two_way_fist_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.handicap,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.set_handicap,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.to_score_goal_x,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.asian_handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.draw_no_bet,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.badminton]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
  },
  orenes: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.double_chance,
      oddsT.live.nextgoal,
      oddsT.live.restofthematch,
      oddsT.live.handicap,
      oddsT.live.draw_no_bet,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.to_score_goal_x,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.two_way_fist_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.total_first_half,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.two_way_live_ot,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_incl_ot,
      oddsT.live.nextgoal,
      oddsT.live.total_current_round,
      oddsT.live.handicap,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_games_in_match,
      oddsT.live.win_games_of_set,
      oddsT.live.total_current_round,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.set_handicap,
      oddsT.live.tiebreak_in_set,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.handicap_first_half,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.to_score_goal_x,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.handicap_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.first_team_to_score,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.asian_handicap,
      oddsT.live.nextgoal,
      oddsT.live.restofthematch,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.asian_handicap,
      oddsT.live.handicap_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.first_team_to_score,
    ],
  },
  kiroljokoa: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.nextgoal,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.total_current_round,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_first_half,
      oddsT.live.two_way_fist_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
      oddsT.live.handicap,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.set_handicap,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.to_score_goal_x,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.asian_handicap,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.draw_no_bet,
      oddsT.live.three_way_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.badminton]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
  },
  goldenpalace: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.to_score_goal_x,
      oddsT.live.double_chance,
      oddsT.live.draw_no_bet,
      oddsT.live.handicap,
      oddsT.live.asian_handicap,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.restofthematch,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
      oddsT.live.three_way_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.two_way_fist_half,
      oddsT.live.total_first_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.who_scores_point,
      oddsT.live.race_to_points,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.double_chance,
      oddsT.live.draw_no_bet,
      oddsT.live.handicap,
      oddsT.live.nextgoal,
      oddsT.live.total_current_round,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.restofthematch,
      oddsT.live.total_incl_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.total_current_round,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.set_handicap,
      oddsT.live.tiebreak_in_set,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.handicap,
      oddsT.live.asian_handicap,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.handicap_first_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
    ],
    [sport.darts]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.total,
      oddsT.live.handicap,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.asian_handicap,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.total,
      oddsT.live.race_to_points,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.asian_handicap,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.asian_handicap,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.total,
    ],
    [sport.table_tennis]: [
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.live.two_way_live,
      oddsT.live.total,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.win_tennis_set,
      oddsT.live.race_to_points,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.who_scores_point,
    ],
  },
  bbetpoint: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.nextgoal,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.handicap,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.total_first_half,
      oddsT.live.restofthematch,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.next_goal_only_ot,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.total_incl_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.who_scores_point,
      oddsT.live.two_way_fist_half,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
    ],
    [sport.ice_hockey]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.nextgoal,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.restofthematch,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.two_way_live_ot,
    ],
    [sport.tennis]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.set_handicap,
      oddsT.live.win_games_of_set,
      oddsT.live.total_games_in_match,
      oddsT.live.win_tennis_game,
      oddsT.live.total_current_round,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
    ],
    [sport.handball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.handicap,
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.handicap_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
      oddsT.live.asian_handicap,
    ],
    [sport.volleyball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.futsal]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.nextgoal,
      oddsT.live.halftime_1x2,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.total_first_half,
      oddsT.live.next_goal_first_half,
    ],
    [sport.beach_volley]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live,
      oddsT.live.win_current_period,
      oddsT.live.total,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
  },
  caspiantechuof: {
    [sport.soccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.nextgoal,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.basketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.total_current_round,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_first_half,
      oddsT.live.two_way_fist_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
    [sport.esoccer]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.three_way_live,
      oddsT.live.restofthematch,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.handicap,
      oddsT.live.nextgoal,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.halftime_1x2,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.three_way_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.ebasketball]: [
      oddsT.pre.three_way,
      oddsT.pre.two_way,
      oddsT.pre.total,
      oddsT.pre.spread,
      oddsT.pre.handicap,
      oddsT.pre.outright,
      oddsT.pre.correct_score,
      oddsT.live.two_way_live_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.total_current_round,
      oddsT.live.win_current_period,
      oddsT.live.handicap_current_period,
      oddsT.live.total_first_half,
      oddsT.live.two_way_fist_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.race_to_points,
      oddsT.live.who_scores_point,
    ],
  },
  boviktest: {
    [sport.soccer]: [
      oddsT.live.total,
      oddsT.live.to_score_goal_x_first_half,
      oddsT.live.three_way_live,
    ],
    [sport.tennis]: [
      oddsT.live.three_way_live,
    ],
  },
  olybet: {
    [sport.soccer]: [
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.nextgoal,
      oddsT.live.handicap,
      oddsT.live.total_first_half,
      oddsT.live.halftime_1x2,
      oddsT.live.to_score_goal_x_first_half,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
      oddsT.live.restofthematch,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.three_way_only_ot,
      oddsT.live.total_live_only_ot,
      oddsT.live.next_goal_only_ot,
      oddsT.live.rest_of_match_only_ot,
      oddsT.live.win_penalties,
    ],
    [sport.tennis]: [
      oddsT.live.win_tennis_match,
      oddsT.live.win_tennis_set,
      oddsT.live.win_tennis_game,
      oddsT.live.total_games_in_match,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.total_current_round,
      oddsT.live.set_handicap,
      oddsT.live.tennis_number_of_sets,
      oddsT.live.win_games_of_set,
      oddsT.live.tiebreak_in_set,
    ],
    [sport.basketball]: [
      oddsT.live.two_way_live_ot,
      oddsT.live.total_incl_ot,
      oddsT.live.asian_handicap_ot,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.race_to_points,
      oddsT.live.two_way_fist_half,
      oddsT.live.total_first_half,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.who_scores_point,
      oddsT.live.first_team_to_score,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
    ],
    [sport.handball]: [
      oddsT.live.three_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.handicap_first_half,
      oddsT.live.total_first_half,
      oddsT.live.who_scores_point,
      oddsT.live.race_to_points,
      oddsT.live.first_team_to_score,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
    ],
    [sport.volleyball]: [
      oddsT.live.two_way_live,
      oddsT.live.asian_handicap,
      oddsT.live.win_current_period,
      oddsT.live.who_scores_point,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.race_to_points,
      oddsT.live.first_team_to_score,
    ],
    [sport.beach_volley]: [
      oddsT.live.two_way_live,
      oddsT.live.asian_handicap,
      oddsT.live.win_current_period,
      oddsT.live.who_scores_point,
      oddsT.live.tennis_results_3_sets,
      oddsT.live.handicap_current_period,
      oddsT.live.total_current_round,
      oddsT.live.total,
      oddsT.live.race_to_points,
      oddsT.live.first_team_to_score,
    ],
    [sport.ice_hockey]: [
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.total,
      oddsT.live.handicap,
      oddsT.live.win_current_period,
      oddsT.live.total_current_round,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.first_team_to_score,
      oddsT.live.restofthematch,
      oddsT.live.rest_of_period,
      oddsT.live.draw_no_bet,
      oddsT.live.double_chance,
    ],
    [sport.futsal]: [
      oddsT.live.three_way_live,
      oddsT.live.nextgoal,
      oddsT.live.asian_handicap,
      oddsT.live.total,
      oddsT.live.halftime_1x2,
      oddsT.live.total_first_half,
      oddsT.live.goals_awayteam,
      oddsT.live.goals_hometeam,
      oddsT.live.first_team_to_score,
      oddsT.live.restofthematch,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.ht_who_wins_the_rest,
    ],
    [sport.darts]: [
      oddsT.live.two_way_live,
      oddsT.live.handicap,
      oddsT.live.total,
    ],
    [sport.table_tennis]: [
      oddsT.live.two_way_live,
      oddsT.live.total,
      oddsT.live.win_current_period,
      oddsT.live.who_scores_point,
      oddsT.live.race_to_points,
      oddsT.live.asian_handicap,
      oddsT.live.tennis_results_5_sets,
      oddsT.live.total_current_round,
      oddsT.live.handicap_current_period,
      oddsT.live.tennis_number_of_sets,
    ],
  },
  meridian: {
    [sport.esoccer]: [
      oddsT.live.three_way_live,
      oddsT.live.total,
      oddsT.live.total_first_half,
      oddsT.live.nextgoal,
      oddsT.live.halftime_1x2,
      oddsT.live.goals_hometeam,
      oddsT.live.goals_awayteam,
      oddsT.live.restofthematch,
      oddsT.live.draw_no_bet,
      oddsT.live.ht_who_wins_the_rest,
      oddsT.live.double_chance,
      oddsT.live.to_score_goal_x_first_half,
      oddsT.live.handicap,
      oddsT.live.asian_handicap,
      oddsT.live.asian_handicap_first_half,
      oddsT.live.first_team_to_score,
    ],
  },
};

export default clientMarketRankings;
