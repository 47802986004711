// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClientOrMatchId__match-id___1elbo{background:#e9e9ea}.ClientOrMatchId__match-id___1elbo h2{color:#3e3e3e;line-height:normal}", ""]);
// Exports
exports.locals = {
	"match-id": "ClientOrMatchId__match-id___1elbo"
};
module.exports = exports;
