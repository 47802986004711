import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getMatchWinPctGround, getReadyMatchId,
} from 'reducers/index';
import WinRateDiagrams from 'newDesign/modules/preMatch/components/statSlides/performanceSlides/winRateDiagrams/WinRateDiagrams';
import WinsLossesBoxes from 'newDesign/modules/preMatch/components/statSlides/performanceSlides/winsLossesBoxes/WinsLossesBoxes.js';
import Tennis from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/sports/tennis/Tennis';

const mapStateToProps = (state, ownProps) => ({
  winPercentage: getMatchWinPctGround(
    state,
    getReadyMatchId(state),
    ownProps.groundId,
  ),
});

const PerformanceWrapper = ({ winPercentage }) => {
  if (!winPercentage) {
    return null;
  }

  const date = new Date(Date.now());
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });

  return (
    <Fragment>
      <Tennis />
      <div className={'z-10 flex'}>
        <WinRateDiagrams stats={winPercentage} />
      </div>
      <WinsLossesBoxes homeStats={winPercentage.home} awayStats={winPercentage.away} />
      <div className={'z-10 flex w-full justify-center fs-18 mt-4'}>
        <span className={'font-light'}>{month} {year - 1} - {month} {year}</span>
      </div>
    </Fragment>
  );
};

PerformanceWrapper.propTypes = {
  winPercentage: PropTypes.object,
};

PerformanceWrapper.defaultProps = {
  winPercentage: null,
};

export default connect(mapStateToProps)(PerformanceWrapper);
