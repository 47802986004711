import React, { useState, useEffect } from 'react';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import { SEC } from 'constants/app';
import delay from 'newDesign/modules/common/utils/delay';
import styles from './LiveBanner.scss';

const LiveBanner = () => {
  const [displayLive, setDisplayLive] = useState(false);

  useEffect(() => {
    async function addDelay() {
      await delay(3 * SEC);
      setDisplayLive(true);
    }

    addDelay();

    return () => {};
  }, []);


  if (!displayLive) {
    return null;
  }

  return (
    <div className={`${styles.lframe_live} flex w-full absolute inset-0 overflow-hidden justify-center items-center`}>
      <div className={`${styles.lframe_live__container} absolute`}>
        <div className={`${styles.lframe_live__container_text} flex items-center w-full justify-center z-02`}>
          <div className={`${styles.live_dot}`} />
          <h2 className={'flex font-main uppercase whitespace-nowrap pl-4'}>
            <TranslationFormattedMessage
              id='t_general_live'
            />
          </h2>
          <div className={`${styles.live_shadow_2} flex absolute justify-center items-center`} />
        </div>
      </div>
      <div className={`${styles.live_shadow_1} absolute overflow-hidden z-01`} />
    </div>
  );
};

export default LiveBanner;
