import PropTypes from 'prop-types';

import useFutsalClock from 'newDesign/modules/common/hooks/clock/FutsalClockHook';

const FutsalClock = ({
  statusId,
  timeInfo,
}) => {
  const time = useFutsalClock(
    statusId,
    timeInfo.remaining,
    timeInfo.running,
  );

  return time;
};

FutsalClock.propTypes = {
  statusId: PropTypes.number,
  timeInfo: PropTypes.object,

};

FutsalClock.defaultProps = {
  statusId: null,
  timeInfo: {
    played: '0',
    remaining: '0',
    running: false,
  },
};

export default FutsalClock;
