import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import DisplaySolutionIcon from 'newDesign/modules/announcement/components/displaySolutionIcon/DisplaySolutionIcon';
import {
  showNextMatchRealCategory,
  getRealCategoryName,
  getTournamentName,
} from 'reducers';

import style from 'newDesign/modules/announcement/components/tournamentInfo/TournamentInfo.scss';


const mapStateToProps = (state, ownProps) => ({
  showRealCategory: showNextMatchRealCategory(state, ownProps.matchId),
  realCategoryName: getRealCategoryName(state, ownProps.matchId),
  tournamentName: getTournamentName(state, ownProps.matchId),
});

const TournamentInfo = ({
  showRealCategory,
  matchId,
  realCategoryName,
  tournamentName,
}) => (
  <div
    styleName='conditionalAlignment'
    className={'flex flex-1 justify-end'}
  >
    <DisplaySolutionIcon matchId={matchId} />
    <div className='font-second ml-4 py-2 fs-17'>
      <span>
        {showRealCategory && realCategoryName !== tournamentName ? `${realCategoryName}, ` : ''}
        {tournamentName}
      </span>
    </div>
  </div>
);

TournamentInfo.propTypes = {
  showRealCategory: PropTypes.bool,
  matchId: PropTypes.number.isRequired,
  realCategoryName: PropTypes.string,
  tournamentName: PropTypes.string,
};

TournamentInfo.defaultProps = {
  showRealCategory: false,
  realCategoryName: null,
  tournamentName: null,
};

const TournamentInfoStyled = cssModule(TournamentInfo, style);
export default connect(mapStateToProps)(TournamentInfoStyled);
