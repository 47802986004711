import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import { sport } from 'constants/enum';
import {
  getMatchSportId,
  getReadyMatchId,
  getTeamReds,
  getTeamSubs,
  getTeamYellows,
} from 'reducers';
import SoccerEvents from 'newDesign/modules/common/components/events/SoccerEvents';
import TournamentInfo from 'newDesign/modules/live/components/footer/footerBody/tournamentInfo/TournamentInfo';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  yellowCardsHome: getTeamYellows(state, getReadyMatchId(state), 'home'),
  redCardsHome: getTeamReds(state, getReadyMatchId(state), 'home'),
  substitutionsHome: getTeamSubs(state, getReadyMatchId(state), 'home'),
  yellowCardsAway: getTeamYellows(state, getReadyMatchId(state), 'away'),
  redCardsAway: getTeamReds(state, getReadyMatchId(state), 'away'),
  substitutionsAway: getTeamSubs(state, getReadyMatchId(state), 'away'),
});

const FooterBody = ({
  sportId,
  yellowCardsHome,
  redCardsHome,
  substitutionsHome,
  yellowCardsAway,
  redCardsAway,
  substitutionsAway,
}) => {
  let homeEvents = null;
  let awayEvents = null;
  let additionalEventsClass = '';

  switch (sportId) {
    case sport.basketball:
    case sport.basketball3x3:
    case sport.ebasketball:
    case sport.futsal:
      break;
    case sport.ice_hockey: // ice hockey share clock with basket
      additionalEventsClass = 'additional-events';
      break;
    case sport.tennis:
    case sport.table_tennis:
      break;
    case sport.soccer:
    case sport.esoccer:
    default:
      homeEvents = (
        <SoccerEvents
          yellowCards={yellowCardsHome}
          redCards={redCardsHome}
          substitutions={substitutionsHome}
        />
      );
      awayEvents = (
        <SoccerEvents
          yellowCards={yellowCardsAway}
          redCards={redCardsAway}
          substitutions={substitutionsAway}
        />
      );
      break;
  }

  return (
    <div styleName={`lframe-meta ${additionalEventsClass}`} className='flex box-border justify-between'>
      {homeEvents}
      <TournamentInfo />
      {awayEvents}
    </div>
  );
};

FooterBody.propTypes = {
  sportId: PropTypes.number.isRequired,
  yellowCardsHome: PropTypes.number,
  redCardsHome: PropTypes.number,
  substitutionsHome: PropTypes.number,
  yellowCardsAway: PropTypes.number,
  redCardsAway: PropTypes.number,
  substitutionsAway: PropTypes.number,
};

FooterBody.defaultProps = {
  yellowCardsHome: null,
  redCardsHome: null,
  substitutionsHome: null,
  yellowCardsAway: null,
  redCardsAway: null,
  substitutionsAway: null,
};

const FooterBodyStyled = cssModule(FooterBody, style, { allowMultiple: true });
export default connect(mapStateToProps)(FooterBodyStyled);
