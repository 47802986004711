import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';

import { getMatchSportId, getMatchTeamsNames, getReadyMatchId } from 'reducers';
import {
  eventStatus, eventTypeId, matchStatusIds,
} from 'constants/enum';
import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import styles from '../timelineContent/TimelineContent.scss';
import TimelineItem from '../timelineItem/TimelineItem';
import TimelineText from '../timelineText/TimelineText';
import Goal from '../events/goal/Goal';
import Card from '../events/card/Card';
import Substitution from '../events/substitution/Substitution';
import getTimelineEventProps from '../utils/getTimelineEventProps';
import Suspension from '../events/suspension/Suspension';

const mapStateToProps = state => ({
  teamNames: getMatchTeamsNames(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const TimelineEvent = ({ event, teamNames, sportId }) => {
  // block type event ie. Match start, halftime
  if (
    event._typeid === eventTypeId.match_ended
    && event.status
    && [matchStatusIds.AET, matchStatusIds.AP].includes(event.status._id)
  ) {
    // show AET/AP instead of regular "Match End"
    return (
      <div styleName='timeline_game_status' className={'flex justify-center'}>
        <span className={'font-second fs-18 uppercase'}>{event.status.name}</span>
      </div>
    );
  } else if (
    [eventTypeId.match_started, eventTypeId.period_start, eventTypeId.match_ended].includes(event._typeid)
  ) {
    return (
      <div styleName='timeline_game_status' className={'flex justify-center'}>
        <span className={'font-second fs-18 uppercase'}>
          {event.name === 'Started' ? <TranslationFormattedMessage
            id='t_statistics_match_start'
          /> : event.name}
        </span>
      </div>
    );
  }

  let timelineEvent;
  let timelineEventProps;

  if ([eventTypeId.goal, eventTypeId.penalty_shootout_event].includes(event._typeid)) {
    timelineEventProps = getTimelineEventProps(event, 'scorer', teamNames);
    timelineEvent = (
      <TimelineItem event={event}>
        <TimelineText
          text={timelineEventProps.text}
          secondaryText={timelineEventProps.secondaryText}
          team={timelineEventProps.team}
        />
        <Goal sportId={sportId} status={event.status} team={event.team} ownGoal={event.owngoal} />
      </TimelineItem>
    );
  } else if ([eventTypeId.yellow_card, eventTypeId.yellow_red_card, eventTypeId.red_card].includes(event._typeid)) {
    timelineEventProps = getTimelineEventProps(event, 'player', teamNames);
    timelineEvent = (
      <TimelineItem event={event}>
        <TimelineText
          text={timelineEventProps.text}
          secondaryText={timelineEventProps.secondaryText}
          team={timelineEventProps.team}
        />
        <Card eventType={event._typeid} team={event.team} />
      </TimelineItem>
    );
  } else if (event._typeid === eventTypeId.player_substitution) {
    timelineEvent = (
      <TimelineItem event={event}>
        <Substitution
          playerIn={getTimelineEventProps(event, 'playerin', teamNames)}
          playerOut={getTimelineEventProps(event, 'playerout', teamNames)}
        />
      </TimelineItem>
    );
  } else if (event._typeid === eventTypeId.penalty_missed) {
    timelineEvent = (
      <TimelineItem event={event}>
        <TimelineText
          team={event.team}
          text={event.name}
          secondaryText={event.player && event.player.name}
        />
        <Goal sportId={sportId} status={eventStatus.penalty.miss} team={event.team} />
      </TimelineItem>
    );
  } else if (event._typeid === eventTypeId.suspension) {
    timelineEventProps = getTimelineEventProps(event, 'player', teamNames);
    timelineEvent = (
      <TimelineItem event={event}>
        <TimelineText
          text={timelineEventProps.text}
          team={timelineEventProps.team}
        />
        <Suspension team={event.team} />
      </TimelineItem>
    );
  }

  return (
    <div className={'flex flex-col w-full items-center'}>
      <div className={'flex flex-col w-full items-center'}>
        {timelineEvent}
      </div>
    </div>
  );
};

TimelineEvent.propTypes = {
  event: PropTypes.object,
  teamNames: PropTypes.object,
  sportId: PropTypes.number.isRequired,
};

TimelineEvent.defaultProps = {
  event: null,
  teamNames: {
    home: '',
    away: '',
  },
};

const TimelineEventStyled = cssModule(TimelineEvent, styles);
export default connect(mapStateToProps)(TimelineEventStyled);
