// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BreakStageNewDesign__mainContentWrapper___2Iv-j{padding:0 0 .8vw 0;box-sizing:border-box}.BreakStageNewDesign__mainContentWrapper___2Iv-j .BreakStageNewDesign__containerHeightAdjuster___3zzpy{width:0;background:#212121;padding-top:56.25%;box-sizing:border-box;height:0}", ""]);
// Exports
exports.locals = {
	"mainContentWrapper": "BreakStageNewDesign__mainContentWrapper___2Iv-j",
	"containerHeightAdjuster": "BreakStageNewDesign__containerHeightAdjuster___3zzpy"
};
module.exports = exports;
