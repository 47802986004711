import React from 'react';
import style from 'styles/widgets/DebugPlayer.scss';
import cssModule from 'react-css-modules';
import PropTypes from 'prop-types';
import {
  getDebugPlayerUrl,
} from 'reducers';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  debugPlayerUrl: getDebugPlayerUrl(state),
});

const DebugPlayer = ({ debugPlayerUrl }) => (
  <div styleName='debugPlayerContainer'>
    <a
      styleName='debugPlayerLabel'
      href={debugPlayerUrl}
      target='_blank'
      rel='noopener noreferrer'
    >
      {debugPlayerUrl ? 'Launch debug player' : 'Debug stream not avalable.'}.
    </a>
  </div>
);

DebugPlayer.propTypes = {
  debugPlayerUrl: PropTypes.string,
};

DebugPlayer.defaultProps = {
  debugPlayerUrl: '',
};

export default connect(
  mapStateToProps,
)(cssModule(DebugPlayer, style));
