import React from 'react';
import cssModule from 'react-css-modules';
import styles from 'styles/widgets/LoggerMessage.scss';
import PropTypes from 'prop-types';
import { DISPLAY_ERROR, DISPLAY_INFO } from 'constants/bcms';

export const LoggerMessage = ({ message, title, type }) => {
  let msgIcon = null;
  let styling = '';
  if (type === DISPLAY_ERROR) {
    msgIcon = '[ERROR]';
    styling = styling.concat('errorMessage');
  } else if (type === DISPLAY_INFO) {
    msgIcon = '[INFO]';
    styling = styling.concat('infoMessage');
  } else {
    return null;
  }

  return (
    <div styleName={styling} style={{ overflow: 'hidden' }}>
      {msgIcon} {title}: {message}
    </div>
  );
};

LoggerMessage.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.number,
};

LoggerMessage.defaultProps = {
  message: 'Missing message',
  title: 'Missing title',
  type: null,
};
export default cssModule(LoggerMessage, styles);
