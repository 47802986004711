import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getNextMatchStatus,
  getNextMatchStatusMessage,
} from 'reducers';

import style from 'newDesign/modules/announcement/components/matchStatus/MatchStatus.scss';


const mapStateToProps = (state, ownProps) => ({
  matchBCMSStatus: getNextMatchStatus(state, ownProps.matchId),
  statusMsg: getNextMatchStatusMessage(state, ownProps.matchId),
});

const MatchStatus = ({ matchBCMSStatus, statusMsg }) => {
  const intl = useIntl();

  if (!matchBCMSStatus) {
    return null;
  }

  return (
    <div styleName='match-status-msg' className='match-status-msg items-center my-2 pr-4 mr-4'>
      <h3 className='mr-4 uppercase'>{matchBCMSStatus}</h3>
      <span>{statusMsg ? intl.formatMessage({ id: statusMsg }) : ''}</span>
    </div>
  );
};

MatchStatus.propTypes = {
  matchBCMSStatus: PropTypes.string,
  statusMsg: PropTypes.string,
};

MatchStatus.defaultProps = {
  matchBCMSStatus: null,
  statusMsg: null,
};

const MatchStatusStyled = cssModule(MatchStatus, style);
export default connect(mapStateToProps)(MatchStatusStyled);
