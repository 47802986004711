import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import { getThreeDigitsResultClassName } from 'newDesign/modules/common/utils/isThreeDigitsResultAvailable';
import { isEnded } from 'utils/matchstatus';
import { matchStatusIds } from 'constants/enum';
import {
  getMatchSportId,
  getMatchStatus,
  getReadyMatchId,
} from 'reducers';

import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
});

const TotalScore = ({
  result,
  side,
  sportId,
  matchStatusId,
}) => {
  const threeDigitsClassName = getThreeDigitsResultClassName(sportId);
  const isHighlighted = !isEnded(matchStatusId);

  return (isHighlighted ? (
    <div styleName={`table__row__score--highlighted ${threeDigitsClassName}`} className='flex h-full items-center justify-center'>
      <span className='font-main--medium fs-16'>{result[side]}</span>
    </div>
  ) : (
    <span styleName='table__row__score-total' className='fs-22 font-main--black flex h-full items-center'>
      {result[side]}
    </span>
  ));
};

TotalScore.propTypes = {
  sportId: PropTypes.number.isRequired,
  result: PropTypes.object,
  side: PropTypes.string.isRequired,
  matchStatusId: PropTypes.number,
};

TotalScore.defaultProps = {
  result: {
    home: null,
    away: null,
  },
  matchStatusId: matchStatusIds.not_started,
};

const TotalScoreStyled = cssModule(TotalScore, style, { allowMultiple: true });
export default connect(mapStateToProps)(TotalScoreStyled);
