import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import OddsPanel from 'newDesign/modules/common/components/oddsPanel/OddsPanel';
import ScoreBoardWrapper from 'newDesign/modules/common/components/oddsPanel/scoreBoard/scoreBoardManager/ScoreBoardManagerWrapper';
import ClientOrMatchId from 'newDesign/modules/common/components/oddsPanel/clientOrMatchId/ClientOrMatchId';
import {
  getOddsPanelIframeURL,
  getOddsPanelType,
  getOddsBreak,
  showMatchIdComp,
  getSwapOverUnder,
  getReadyMatchId,
  getClientMatchId,
  getRotateOddspanel,
} from 'reducers';
import {
  ODDSPANEL_WIDGET,
  ODDSPANEL_IFRAME,
} from 'constants/bcms';

import style from 'newDesign/modules/common/components/oddsManager/OddsManager.scss';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
  oddsPanelType: getOddsPanelType(state),
  oddsPanelIframeURL: getOddsPanelIframeURL(state),
  oddsBreak: getOddsBreak(state),
  swapOverUnder: getSwapOverUnder(state),
  showMatchIdCmp: showMatchIdComp(state, getClientMatchId(state, getReadyMatchId(state))),
  rotateMarkets: getRotateOddspanel(state),
});

/*
  Checks in this order:
  1. oddsbreak
  2. oddspanel iframe
  3. new react oddspanel
*/
export const OddsManager = ({
  matchId,
  oddsPanelType,
  oddsPanelIframeURL,
  oddsBreak,
  showMatchIdCmp,
  swapOverUnder,
  rotateMarkets,
}) => {
  const matchIdCmp = (
    <Fragment>
      {showMatchIdCmp ? <ClientOrMatchId matchId={matchId} /> : null}
    </Fragment>
  );

  if (oddsBreak) {
    return (
      <Fragment>
        {matchIdCmp}
        <ScoreBoardWrapper />
      </Fragment>
    );
  }

  if (oddsPanelType === ODDSPANEL_IFRAME) {
    return (
      <Fragment>
        <ScoreBoardWrapper />
        <div styleName={'iframeContainer'}>
          <iframe
            scrolling='no'
            styleName='iframeBox'
            title='LCR odds panel'
            src={oddsPanelIframeURL}
          />
        </div>
      </Fragment>
    );
  }

  return <OddsPanel
    matchId={matchId}
    maxMarkets={rotateMarkets ? 24 : 4}
    showMatchIdCmp={showMatchIdCmp}
    swapOverUnder={swapOverUnder}
    carousel={rotateMarkets}
  />;
};

OddsManager.propTypes = {
  matchId: PropTypes.number.isRequired,
  oddsPanelType: PropTypes.number,
  oddsPanelIframeURL: PropTypes.string,
  oddsBreak: PropTypes.bool,
  showMatchIdCmp: PropTypes.bool,
  swapOverUnder: PropTypes.bool,
  rotateMarkets: PropTypes.number,
};

OddsManager.defaultProps = {
  oddsPanelType: ODDSPANEL_WIDGET,
  oddsPanelIframeURL: '',
  oddsBreak: false,
  showMatchIdCmp: true,
  swapOverUnder: false,
  rotateMarkets: 0,
};

export default connect(
  mapStateToProps,
)(cssModule(OddsManager, style));
