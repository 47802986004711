import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getMatchSportId, getReadyMatchId } from 'reducers/index';
import TennisManager from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/sports/tennisManager/TennisManager';
import OtherSports from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/sports/otherSports/OtherSports';
import { sport } from 'constants/enum';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  matchId: getReadyMatchId(state),
});

const LiveStatsHeader = ({ matchId, sportId }) => {
  if ([sport.tennis, sport.table_tennis].includes(sportId)) {
    return <TennisManager matchId={matchId} />;
  }

  return <OtherSports matchId={matchId} />;
};

LiveStatsHeader.propTypes = {
  matchId: PropTypes.number.isRequired,
  sportId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(LiveStatsHeader);
