import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { isEnded } from 'utils/matchstatus';
import { matchStatusIds } from 'constants/enum';
import {
  getMatchResult, getMatchStatus,
  getReadyMatchId,
  getScoreBreak,
} from 'reducers/index';

import styles from './TimelineScore.scss';

const mapStateToProps = state => ({
  result: getMatchResult(state, getReadyMatchId(state)),
  scoreBreak: getScoreBreak(state),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
});

const TimelineScore = ({ result, scoreBreak, matchStatusId }) => {
  const intl = useIntl();

  let matchStatus;

  if (matchStatusId === matchStatusIds.halftime) {
    matchStatus = intl.formatMessage({ id: 't_statistics_halftime' });
  } else if (isEnded(matchStatusId)) {
    matchStatus = intl.formatMessage({ id: 't_general_ended' });
  }

  return (
    <div className={'flex flex-col justify-center items-center'}>
      <div className={`${styles.timeline_score} flex justify-center items-center`}>
        <span className={'font-main--black fs-30'}>{(result.home == null || scoreBreak) ? '-' : result.home}</span>
        <span className={'font-main--black fs-30'}>-</span>
        <span className={'font-main--black fs-30'}>{(result.away == null || scoreBreak) ? '-' : result.away}</span>
      </div>
      <span className={'font-second--medium fs-16 uppercase'}>{matchStatus}</span>
    </div>
  );
};

TimelineScore.propTypes = {
  result: PropTypes.object,
  scoreBreak: PropTypes.bool,
  matchStatusId: PropTypes.number,
};

TimelineScore.defaultProps = {
  result: {
    home: '-',
    away: '-',
  },
  scoreBreak: false,
  matchStatusId: null,
};

export default connect(mapStateToProps)(TimelineScore);
