// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TimelineScore__timeline_score___3YC4Q{gap:1vw}.TimelineScore__timeline_score___3YC4Q span{line-height:initial}", ""]);
// Exports
exports.locals = {
	"timeline_score": "TimelineScore__timeline_score___3YC4Q"
};
module.exports = exports;
