import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import {
  getMatchHomeUid,
  getMatchSportId,
  getMatchTeams,
  getReadyMatchId,
} from 'reducers/index';
import { getTeamNameProperty } from 'newDesign/modules/common/utils/teamName';
import { sport } from 'constants/enum';
import { splitTableTennisTeamNames } from 'utils/tableTennisHelpers';
import ResultBox from '../../../common/components/resultBox/ResultBox';

import styles from './PreviousMeetingsHeader.scss';

const mapStateToProps = (state, ownProps) => ({
  homeUid: getMatchHomeUid(state, ownProps.matchId),
  teams: getMatchTeams(state, ownProps.matchId),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const PreviousMeetingsHeader = ({
  previousMatches, homeUid, teams, sportId,
}) => {
  if (!previousMatches) {
    return null;
  }

  let homeTeam = teams.home;
  let awayTeam = teams.away;
  const teamNameProperty = getTeamNameProperty(sportId);

  if (sportId === sport.table_tennis
    && teams.tennisDouble
    && (!teams.home[1] || !teams.away[1])) {
    const splitTeams = splitTableTennisTeamNames(teams);
    homeTeam = splitTeams.homeTeam;
    awayTeam = splitTeams.awayTeam;
  }

  const whoWon = previousMatches.slice(0, 5).reduce((acc, match) => {
    const homeTeamIsHome = match.teams.home.uid === homeUid;

    if (match.result.home > match.result.away) {
      if (homeTeamIsHome) {
        acc.home = [...acc.home, 1];
        return acc;
      } else if (!homeTeamIsHome) {
        acc.away = [...acc.away, 1];
        return acc;
      }
    } else if (match.result.home < match.result.away) {
      if (homeTeamIsHome) {
        acc.away = [...acc.away, 1];
        return acc;
      } else if (!homeTeamIsHome) {
        acc.home = [...acc.home, 1];
        return acc;
      }
    }

    return acc;
  }, {
    home: [],
    away: [],
  });

  return (
    <div className={'z-10 flex flex-col'}>
      <div styleName='competitors-form' className={'z-10 flex mb-4 justify-center'}>
        <div className={'flex flex-col items-start justify-between min-w-[15vw]'}>
          <span className={'uppercase fs-18 text-left min-w-[20vw]'}>
            {homeTeam[0][teamNameProperty]}<br />
            {(1 in homeTeam) && homeTeam[1][teamNameProperty]}
          </span>
          <div className={'flex gap-x-3 min-w-[15vw] mt-4'}>
            {whoWon.home.map((result, i) => (
              <ResultBox key={i} result={result} />
            ))}
          </div>
        </div>
        <div className={'flex flex-col items-end justify-between min-w-[15vw]'}>
          <span className={'uppercase fs-18 text-right min-w-[20vw]'}>
            {awayTeam[0][teamNameProperty]}<br />
            {(1 in awayTeam) && awayTeam[1][teamNameProperty]}
          </span>
          <div className={'flex gap-x-3 justify-end min-w-[15vw] mt-4'}>
            {whoWon.away.map((result, i) => (
              <ResultBox key={i} result={result} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PreviousMeetingsHeader.propTypes = {
  previousMatches: PropTypes.array,
  homeUid: PropTypes.number,
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
    tennisDouble: PropTypes.array,
  }),
  sportId: PropTypes.number.isRequired,
};

PreviousMeetingsHeader.defaultProps = {
  previousMatches: null,
  homeUid: 0,
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
    tennisDouble: [
      { home: [{ name: '', abbr: '' }] },
      { away: [{ name: '', abbr: '' }] },
    ],
  },
};

const PreviousMeetingsResultBoxStyled = cssModule(PreviousMeetingsHeader, styles);
export default connect(mapStateToProps)(PreviousMeetingsResultBoxStyled);
