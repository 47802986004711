// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Logger__logger___30gTC{position:absolute;bottom:0;color:#fff;width:100%;z-index:1}", ""]);
// Exports
exports.locals = {
	"logger": "Logger__logger___30gTC"
};
module.exports = exports;
