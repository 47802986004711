import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty as _isEmpty } from 'lodash';

import TeamCrest from 'newDesign/modules/announcement/components/teamCrest/TeamCrest';
import { sport } from 'constants/enum';

const Competitor = ({
  flag, matchId, tennisDouble, teamName, sportId,
}) => {
  if (!teamName) {
    return null;
  }

  /**
   * Additional class to display text in the center of top footer
   * because 2nd row for 2nd competitor is removed (it is only applied for SINGLE)
   */
  let heightClass = '';

  if (_isEmpty(tennisDouble)) {
    heightClass = 'h-full';
  }

  return (
    <div className={`flex items-center max-w-full ${heightClass}`}>
      {[sport.tennis, sport.table_tennis].includes(sportId) && (
        <TeamCrest
          key={flag}
          uid={flag}
          typeClassName='flag-crest'
          matchId={matchId}
        />
      )}
      <span className={'fs-18 font-main--bold uppercase'}>{teamName}</span>
    </div>
  );
};

Competitor.propTypes = {
  flag: PropTypes.string,
  matchId: PropTypes.number.isRequired,
  tennisDouble: PropTypes.object,
  teamName: PropTypes.string,
  sportId: PropTypes.number.isRequired,
};

Competitor.defaultProps = {
  flag: '',
  tennisDouble: {},
  teamName: '',
};

export default Competitor;
