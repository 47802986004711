import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { useIntl } from 'react-intl';

import { matchStatusIds, sport } from 'constants/enum';
import { connect } from 'react-redux';
import { getLastMatch, getMatchStatus } from 'reducers/index';
import styles from '../styles/MatchTickerStyles.scss';

const mapStateToProps = state => ({
  matchStatusId: getMatchStatus(state, getLastMatch(state)),
});

const PeriodInfo = ({
  sportId,
  period,
  matchStatusId,
}) => {
  const intl = useIntl();

  if (
    period && period !== 0
    && !(matchStatusId === matchStatusIds.halftime || matchStatusId === matchStatusIds.pause)
  ) {
    let periodInfo = null;

    if ([sport.soccer, sport.esoccer, sport.handball, sport.futsal].includes(sportId)) {
      periodInfo = period;
    } else if ([sport.ice_hockey, sport.basketball].includes(sportId)) {
      periodInfo = `${intl.formatMessage({ id: 't_general_p' }) + period}`;
    }

    if (periodInfo) {
      return (
        <Fragment>
          <div styleName='matchticker__separator' />
          <h3 className={'fs-20 font-second--bold uppercase'}>{periodInfo}</h3>
        </Fragment>
      );
    }
  }

  return null;
};

PeriodInfo.propTypes = {
  sportId: PropTypes.number,
  period: PropTypes.number,
  matchStatusId: PropTypes.number,
};

PeriodInfo.defaultProps = {
  period: null,
  sportId: 0,
  matchStatusId: 0,
};

const PeriodInfoStyled = cssModule(PeriodInfo, styles);
export default connect(mapStateToProps)(PeriodInfoStyled);
