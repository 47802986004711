import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import ResultBox from 'newDesign/modules/preMatch/components/statSlides/common/components/resultBox/ResultBox';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const LastMatchesTeam = ({ results }) => {
  const boxes = results.map((result, i) => (
    <ResultBox result={result} key={`${result}${i}`} />
  ));

  return (
    <div styleName='form__block' className='flex gap-x-3 items-center'>
      {boxes}
      <svg className='z-10 h-[37%]' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' style={{ display: 'block' }}>
        <path
          d='M13.6,58.7c-6.4-4-6.4-13.3,0-17.4L76.2,2.2c6.8-4.3,15.7,0.6,15.7,8.7v78.3c0,8-8.8,12.9-15.7,8.7L13.6,58.7z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
};

LastMatchesTeam.propTypes = {
  results: PropTypes.array,
};

LastMatchesTeam.defaultProps = {
  results: [],
};
const LastMatchesTeamStyled = cssModule(LastMatchesTeam, style);

export default LastMatchesTeamStyled;
