import { createIntl, createIntlCache } from 'react-intl';
import intlOnError from './intlOnError';

const cache = createIntlCache();
const createIntlHelper = (intlConfigLanguage, intlConfigTranslations, isTranslationsAvailable) => createIntl({
  locale: intlConfigLanguage,
  messages: intlConfigTranslations,
  onError: error => {
    intlOnError(error, isTranslationsAvailable);
  },
}, cache);

export default createIntlHelper;
