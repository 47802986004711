import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getMatchId,
  getMatchDetailsStat,
  getSBCompConfig,
  getSBCompKey,
  getSBCompSlideIdx,
} from 'reducers';
import { SCOREBOARD_TOP } from 'constants/components';
import NumberStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberStatSlide';

const percentageValueFromString = stringValue => {
  if (!stringValue) {
    return null;
  }

  const valuesArray = stringValue.split('/');
  let valuePercentage = Math.round(((valuesArray[0] / valuesArray[2]) * 100) * 10) / 10;

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(valuePercentage)) {
    valuePercentage = 0;
  }
  return valuePercentage + '%';
};

const mapStateToProps = state => ({
  footerCurrentStatSlideIndex: getSBCompSlideIdx(state, SCOREBOARD_TOP),
  stat: getMatchDetailsStat(
    state, getMatchId(state), getSBCompKey(state, SCOREBOARD_TOP, getSBCompSlideIdx(state, SCOREBOARD_TOP)),
  ),
  config: getSBCompConfig(state, SCOREBOARD_TOP),
});

const MatchStatistics = ({
  footerCurrentStatSlideIndex, config, stat,
}) => {
  const usedConfig = config[footerCurrentStatSlideIndex];

  if (!usedConfig || !stat) {
    return null;
  }

  let arr = [];


  let hStat = stat.home;
  let aStat = stat.away;

  if (hStat != null && (typeof hStat !== 'number')) {
    arr = hStat.split('/');
  }

  // format "4/6/10" => 4/10 = 40%
  if (arr.length === 3) {
    hStat = percentageValueFromString(hStat);
    aStat = percentageValueFromString(aStat);
  } else if (usedConfig.percentage) {
    hStat += '%';
    aStat += '%';
  }

  return (
    <NumberStatSlide
      statText={stat.name}
      homeStat={hStat}
      awayStat={aStat}
      higherIsBetter={usedConfig.higherIsBetter}
    />
  );
};

MatchStatistics.propTypes = {
  stat: PropTypes.object,
  footerCurrentStatSlideIndex: PropTypes.number,
  config: PropTypes.object,
};

MatchStatistics.defaultProps = {
  stat: null,
  footerCurrentStatSlideIndex: null,
  config: {
    higherIsBetter: true,
    text: '',
  },
};

export default connect(mapStateToProps)(MatchStatistics);
