import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { useImportImageNewDesign } from 'hooks';
import { eventTypeId } from 'constants/enum';

import styles from '../../Timeline.scss';

const Card = ({ eventType, team }) => {
  const yellowCard = useImportImageNewDesign('general/timeline/timeline_yellow.png');
  const redCard = useImportImageNewDesign('general/timeline/timeline_red.png');
  const secondYellow = useImportImageNewDesign('general/timeline/timeline_yellow-red.png');

  let cardImgSrc = null;

  switch (eventType) {
    case eventTypeId.yellow_card:
      cardImgSrc = yellowCard;
      break;
    case eventTypeId.red_card:
      cardImgSrc = redCard;
      break;
    case eventTypeId.yellow_red_card:
      cardImgSrc = secondYellow;
      break;
    default:
      break;
  }

  return (
    <div styleName={team === 'away' ? 'flex-row-reverse' : ''} className={`${styles.timeline_icon_block} flex`}>
      <img src={cardImgSrc} alt={'card'} />
    </div>
  );
};

Card.propTypes = {
  eventType: PropTypes.number.isRequired,
  team: PropTypes.string,
};
Card.defaultProps = {
  team: '',
};

const CardStyled = cssModule(Card, styles);
export default CardStyled;
