import React from 'react';
import PropTypes from 'prop-types';

const Period = ({ period }) => (
  <div
    className='prematch__add-info flex flex-col font-second fs-17 justify-center min-w-[4.5vw] text-right px-[0.7vw] box-border'
  >
    <span className={'uppercase'}>{period}</span>
  </div>
);

Period.propTypes = {
  period: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

export default Period;
