import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LiveStats from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats';
import Timeline from 'newDesign/modules/break/components/statSlides/timeline/Timeline';
import {
  getQueryStatSlide,
  getSSCompSlideIdx,
} from 'reducers';
import {
  SS_LIVESTATS,
  SS_TIMELINE,
} from 'constants/components';

const mapStateToProps = state => ({
  curIdx: getSSCompSlideIdx(state),
  qStatSlide: getQueryStatSlide(state),
});

const StatSlidesNewDesign = ({
  curIdx, qStatSlide,
}) => {
  let slide;

  const breakStageSlideId = qStatSlide != null ? qStatSlide : curIdx; // override idx from query param

  switch (breakStageSlideId) {
    case SS_LIVESTATS:
      slide = <LiveStats />;
      break;
    case SS_TIMELINE:
      slide = <Timeline />;
      break;
    default:
      // TODO: Empty slide will be displayed
      slide = <div> </div>;
      break;
  }

  return (
    <React.Fragment>
      {slide}
    </React.Fragment>
  );
};

StatSlidesNewDesign.propTypes = {
  curIdx: PropTypes.number,
  qStatSlide: PropTypes.number,
};

StatSlidesNewDesign.defaultProps = {
  curIdx: null,
  qStatSlide: null,
};

export default connect(mapStateToProps)(StatSlidesNewDesign);
