import { useEffect } from 'react';
import { convertToCountdownString } from '../utils/index';
import { useCountdown } from './Timers';

const useDateCountdown = startTime => {
  const {
    elapsedTimeSecs,
    startTimer,
  } = useCountdown(10); // update every 10 sec

  useEffect(() => {
    if (startTime) {
      const now = Date.now() / 1000;
      const remainingSecs = parseInt(startTime - now, 10);

      startTimer(remainingSecs);
    }
  }, [startTime, startTimer]);

  return convertToCountdownString(elapsedTimeSecs);
};

export default useDateCountdown;
