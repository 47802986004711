import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PeriodBased from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/PeriodBasedBoard';
import ScoreBased from 'newDesign/modules/common/components/oddsPanel/scoreBoard/scoreBasedBoard/ScoreBasedBoard';
import { sport } from 'constants/enum';
import {
  getMatchSportId, getReadyMatchId,
} from 'reducers/index';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const ScoreBoard = ({
  sportId,
}) => {
  let scoreBoard = null;

  switch (sportId) {
    case sport.basketball:
    case sport.ebasketball:
    case sport.table_tennis:
    case sport.tennis:
    case sport.beach_volley:
    case sport.volleyball:
    case sport.ice_hockey:
      scoreBoard = <PeriodBased />;
      break;
    case sport.esoccer:
    case sport.soccer:
    case sport.handball:
    case sport.futsal:
    default:
      scoreBoard = <ScoreBased />;
      break;
  }

  return (
    <Fragment>
      {scoreBoard}
    </Fragment>
  );
};

ScoreBoard.propTypes = {
  sportId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ScoreBoard);
