import React, {
  useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';

const ImageFallback = ({
  src, fallback, alt, styles, typeClassName,
}) => {
  const [error, setError] = useState(false);
  const [imgSrc, setImgSrc] = useState(src);

  const onError = useCallback(() => {
    if (!error) {
      setImgSrc(fallback);
      setError(true);
    }
  }, [error, fallback]);

  useEffect(() => {
    if (imgSrc) {
      const img = new Image();
      img.src = imgSrc;
      img.onload = () => {
        // HACK: bad crest images returns a 1x1 image
        if (img.height < 2) {
          onError();
        }
      };
    }
  }, [imgSrc, onError]);


  return (
    <img
      alt={alt}
      src={imgSrc}
      onError={onError}
      style={{ ...styles }}
      className={`w-full ${typeClassName}`}
    />
  );
};

ImageFallback.propTypes = {
  src: PropTypes.string,
  fallback: PropTypes.string,
  styles: PropTypes.object,
  typeClassName: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

ImageFallback.defaultProps = {
  src: '',
  fallback: '',
  styles: {},
  alt: '',
};

export default ImageFallback;
