import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { connect } from 'react-redux';
import {
  getMatchResult,
  getReadyMatchId,
  getScoreBreak,
} from 'reducers/index';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';

const mapStateToProps = state => ({
  result: getMatchResult(state, getReadyMatchId(state)),
  scoreBreak: getScoreBreak(state),
});

const Score = ({
  result, scoreBreak,
}) => (
  <div styleName='halftimestats-blur' className='flex flex-col  items-center flex-1 h-full'>
    {/* TODO: add new translation after TAPI epic */}
    {/* <h3 className='font-main--medium fs-25 uppercase text-center'>Halftime Statistics</h3> */}
    <div styleName='halftimestats-score' className='fs-50 font-main--black uppercase flex items-center'>
      <h2>{(result.home == null || scoreBreak) ? '-' : result.home}</h2>
      <h2 className='fs-40'>-</h2>
      <h2>{(result.away == null || scoreBreak) ? '-' : result.away}</h2>
    </div>
  </div>
);

Score.propTypes = {
  result: PropTypes.object,
  scoreBreak: PropTypes.bool,
};

Score.defaultProps = {
  result: {
    home: '-',
    away: '-',
  },
  scoreBreak: false,
};

const ScoreStyled = cssModule(Score, style);
export default connect(mapStateToProps)(ScoreStyled);
