import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import TeamCrest from 'newDesign/modules/announcement/components/teamCrest/TeamCrest';

import { connect } from 'react-redux';
import { sport } from 'constants/enum';
import { getMatchSportId } from 'reducers/index';
import style from './MatchTeamCrest.scss';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
});

const MatchTeamCrest = ({ teams, matchId, sportId }) => {
  const sportsWithoutCrest = [sport.esoccer];

  if (sportsWithoutCrest.includes(sportId)) {
    return null;
  }

  return (
    <div
      styleName='competitor-logo'
      className={`competitor-logo ${teams.length > 1 ? 'double-logo' : 'single-logo'} flex relative justify-center flex-col`}
    >
      {teams.map(t => {
        let uid = t.countryCodeA2 ? t.countryCodeA2 : t.uniqueId;
        if (sportId === sport.soccer && t.iscountry === false) {
          uid = t.uniqueId;
        }
        const crestType = t.countryCodeA2 ? 'absolute flag-crest' : 'absolute';

        return (
          <Fragment key={t.uniqueId}>
            {uid && (
              // TODO: Tennis doubles forced demo
              // <Fragment>
              <TeamCrest uid={uid} double={teams.length > 1} typeClassName={crestType} matchId={matchId} />
              // </Fragment>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

MatchTeamCrest.propTypes = {
  teams: PropTypes.array.isRequired,
  matchId: PropTypes.number.isRequired,
  sportId: PropTypes.number.isRequired,
};

const MatchTeamCrestStyled = cssModule(MatchTeamCrest, style);
export default connect(mapStateToProps)(MatchTeamCrestStyled);
