import React from 'react';

import { eventTypeId } from 'constants/enum';
import StatWrapper from 'newDesign/modules/break/components/statSlides/liveStats/statWrapper/StatWrapper';

const Tennis = () => [
  <StatWrapper
    key={1}
    statKey={eventTypeId.tennis_aces}
    relative
  />,
  <StatWrapper
    key={2}
    statKey={eventTypeId.tennis_double_faults}
    relative
  />,
  <StatWrapper
    key={3}
    statKey={eventTypeId.tennis_breaks}
  />,
  <StatWrapper
    key={4}
    statKey={eventTypeId.tennis_service_points}
  />,
  <StatWrapper
    key={5}
    statKey={eventTypeId.tennis_total_points_count}
    relative
  />,
  <StatWrapper
    key={6}
    statKey={eventTypeId.tennis_max_points_in_a_row}
    relative
  />,
];

export default Tennis;
