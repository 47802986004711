import React from 'react';

import { eventTypeId } from 'constants/enum';
import StatWrapper from 'newDesign/modules/break/components/statSlides/liveStats/statWrapper/StatWrapper';

const Handball = () => [
  <StatWrapper
    key={1}
    statKey={eventTypeId.yellow_card_count}
    relative
    higherIsBetter={false}
  />,
  <StatWrapper
    key={2}
    statKey={eventTypeId.red_card_count}
    relative
    higherIsBetter={false}
  />,
  <StatWrapper
    key={3}
    statKey={eventTypeId.ball_possession}
    percentage
  />,
  <StatWrapper
    key={4}
    statKey={eventTypeId.suspensions}
    relative
    higherIsBetter={false}
  />,
  <StatWrapper
    key={5}
    statKey={eventTypeId.goals_powerplay}
    relative
  />,
  <StatWrapper
    key={6}
    statKey={eventTypeId.goals_shorthanded}
    relative
  />,
  <StatWrapper
    key={7}
    statKey={eventTypeId.seven_metres}
    percentage
  />,
  <StatWrapper
    key={8}
    statKey={eventTypeId.tennis_max_points_in_a_row}
    relative
  />,
];

export default Handball;
