// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WinLooseBox__stat_number___vxdDM{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;width:45px;height:45px;padding:26px;border-radius:4px;box-shadow:0px 4px 4px 0px rgba(0,0,0,.25);-webkit-backdrop-filter:blur(2px);backdrop-filter:blur(2px);background:rgba(108,112,115,.7)}.WinLooseBox__win_box___2-3Uq{background:#fff;color:#000}", ""]);
// Exports
exports.locals = {
	"stat_number": "WinLooseBox__stat_number___vxdDM",
	"win_box": "WinLooseBox__win_box___2-3Uq"
};
module.exports = exports;
