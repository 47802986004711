
import {
  put,
  select,
  apply,
  all,
  call,
} from 'redux-saga/effects';
import {
  getClientAlias,
  getLanguage,
  getQueryFeed,
  getMatchHomeUid,
  getMatchAwayUid,
} from 'reducers';
import { StatsTeamLastXRequest } from 'fishnet-api';
import * as config from 'Config/fishnetUrls';
import { updateStatsTeamLastXFailed, updateStatsTeamLastX } from 'actions/fishnet/index';
import { pollTillResponse, takeMatchUpdate } from '../utilLCR';

function* loadStatsTeamLastX(matchId, offset) {
  const clientAlias = yield select(getClientAlias);
  const language = yield select(getLanguage);
  const qFeed = offset > 0 ? config.fnReplayUrl : yield select(getQueryFeed);
  const tId1 = yield select(getMatchHomeUid, matchId);
  const tId2 = yield select(getMatchAwayUid, matchId);

  const request1 = new StatsTeamLastXRequest(
    tId1,
    clientAlias,
    language,
    qFeed,
  );

  const request2 = new StatsTeamLastXRequest(
    tId2,
    clientAlias,
    language,
    qFeed,
  );

  const [team1, team2] = yield all([
    apply(request1, request1.get),
    apply(request2, request2.get),
  ]);

  if ((team1 && !team1.error) && (team2 && !team2.error)) {
    const lastX = {
      [matchId]: {
        home: team1,
        away: team2,
      },
    };

    yield put(updateStatsTeamLastX(lastX, matchId));
  } else {
    yield put(updateStatsTeamLastXFailed(null, matchId));
    throw new Error('Update stats_team_lastx failed');
  }
}

// request once
function* pollStatsTeamLastX(matchId, offset) {
  yield call(takeMatchUpdate, matchId); // wait on one succesful match_info / match_timelinedelta
  yield call(pollTillResponse, {}, loadStatsTeamLastX, matchId, offset); // poll this endpoint once
}

export default pollStatsTeamLastX;
