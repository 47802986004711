import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import Odds from 'newDesign/modules/preMatch/components/highlightOfTheDay/odds/Odds';
import {
  getLanguage,
  getHOTDPhraseIdx,
  getHOTDPhrases,
} from 'reducers/index';
import { LANG_EN } from 'constants/app';

import style from 'newDesign/modules/preMatch/components/highlightOfTheDay/funFact/FunFact.scss';

const mapStateToProps = (state, ownProps) => ({
  lang: getLanguage(state),
  funFacts: getHOTDPhrases(state, ownProps.matchId),
  funFactsIdx: getHOTDPhraseIdx(state),
});

const FunFact = ({
  funFactsIdx, funFacts, matchId, lang,
}) => {
  const [sentenceParts, setSentenceParts] = useState([]);

  useEffect(() => {
    if (funFacts && funFacts[funFactsIdx]) {
      const {
        sentence, question, P1, P2, P3, P4, P5, P6, T1, T2, T3, LEAGUE,
      } = funFacts[funFactsIdx];

      const alteredSentence = sentence.replace('$LEAGUE', '$LE');
      const strArr = alteredSentence.split('');

      let idx = 0;
      let i = 0;
      const arr = [];

      while (i < strArr.length) {
        if (strArr[i] === '$') {
          const normal = {
            replaced: false,
            text: alteredSentence.slice(idx, i),
          };

          arr.push(normal); // add first text upto $ as normal text

          let replacementText = alteredSentence.slice(i, i + 3); // slice of $XX
          const secondChar = replacementText[1];
          // try replace vs all
          replacementText = replacementText.replace('$P1', P1);
          replacementText = replacementText.replace('$P2', P2);
          replacementText = replacementText.replace('$P3', P3);
          replacementText = replacementText.replace('$P4', P4);
          replacementText = replacementText.replace('$P5', P5);
          replacementText = replacementText.replace('$P6', P6);

          replacementText = replacementText.replace('$T1', T1);
          replacementText = replacementText.replace('$T2', T2);
          replacementText = replacementText.replace('$T3', T3);

          replacementText = replacementText.replace('$LE', LEAGUE);

          const teamNameInQuestion = question.split(' ').some(word => word === replacementText);
          const custom = {
            replaced: true,
            // highlight if "P" type replacement or replaced team name is in the question
            highlight: (secondChar === 'P' || teamNameInQuestion),
            text: replacementText,
          };
          arr.push(custom);

          // skip next 3 chars
          idx = i + 3;
          i += 3;
          // eslint-disable-next-line no-continue
          continue;
        } else if (i === strArr.length - 1) {
          const normal = {
            replaced: false,
            text: alteredSentence.slice(idx, i),
          };

          // add end of sentence after last replacement
          arr.push(normal);
        }

        i++;
      }

      setSentenceParts(arr);
    }
  }, [funFacts, funFactsIdx]);


  if (funFactsIdx == null || !funFacts) {
    return null;
  }

  const questionComps = sentenceParts.map((t, i) => (
    <Fragment key={i}>{t.text}</Fragment>
  ));

  // array of all the unique market ids used
  const marketIds = [...new Set(funFacts.map(f => f.market_id))];

  const funFact = (funFacts[funFactsIdx]) || {};

  return (
    <div className='flex justify-center'>
      <div styleName='prematch__add-box' className=' flex-1 z-10 items-center flex flex-col'>
        <h3 styleName='decoration-underline' className='uppercase font-second pb-4'>
          {funFact.summary}
        </h3>
        <div className='flex gap-x-8 w-full justify-center text-center pt-4 max-w-[80%]'>
          <div className='prematch__add-box__blocks flex flex-col items-center'>
            <span className='font-second'>
              {questionComps}
            </span>
          </div>
        </div>
      </div>
      <Odds
        matchId={matchId}
        marketIds={marketIds}
        outcomeName={funFact.outcome_name}
        marketId={funFact.market_id}
        maxMarkets={marketIds.length}
        keepAllOutcomes
        lang={lang}
        funFactQuestion={funFact.question}
      />
    </div>
  );
};

FunFact.propTypes = {
  funFactsIdx: PropTypes.number,
  funFacts: PropTypes.array,
  matchId: PropTypes.number.isRequired,
  lang: PropTypes.string,
};

FunFact.defaultProps = {
  funFactsIdx: null,
  funFacts: null,
  lang: LANG_EN,
};

const FunFactStyled = cssModule(FunFact, style);
export default connect(mapStateToProps)(FunFactStyled);
