import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash';

import {
  getMatchSportId,
  getMatchTennisDouble,
  getReadyMatchId,
} from 'reducers';
import Competitor from 'newDesign/modules/live/components/footer/footerTop/periodBasedCompetitors/Competitor';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
  tennisDouble: getMatchTennisDouble(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const TeamNames = ({
  team, flags, matchId, tennisDouble, sportId,
}) => {
  if (!team) {
    return null;
  }

  let firstTeamMember;
  let secondTeamMember;

  if (_isEmpty(tennisDouble)) {
    firstTeamMember = `${team[0].name} ${team[0].abbr && `(${team[0].abbr})`}`;
  } else {
    [firstTeamMember, secondTeamMember] = team[0].name.split(' / ');
  }

  return (
    <div styleName='lframe-competitor' className='flex items-center box-border'>
      <div className={'flex flex-col max-w-full overflow-hidden text-ellipsis'}>
        <Competitor
          key={flags[0]}
          flag={flags[0]}
          matchId={matchId}
          tennisDouble={tennisDouble}
          teamName={firstTeamMember}
          sportId={sportId}
        />
        <Competitor
          key={flags[1]}
          flag={flags[1]}
          matchId={matchId}
          tennisDouble={tennisDouble}
          teamName={secondTeamMember}
          sportId={sportId}
        />
      </div>
    </div>
  );
};

TeamNames.propTypes = {
  team: PropTypes.array.isRequired,
  flags: PropTypes.array,
  matchId: PropTypes.number.isRequired,
  tennisDouble: PropTypes.object,
  sportId: PropTypes.number.isRequired,
};

TeamNames.defaultProps = {
  flags: [],
  tennisDouble: {},
};

const TeamNamesStyled = cssModule(TeamNames, style);

export default connect(mapStateToProps)(TeamNamesStyled);
