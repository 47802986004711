// TODO: clean after the live stage, and all layout variants will be defined (props drilling, refactoring).
import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import LayoutWrapperCmp from 'newDesign/modules/common/components/layout/LayoutWrapper';
import LMT from 'newDesign/modules/live/components/LMT/LMT';
import OddsManagerCmp from 'newDesign/modules/common/components/oddsManager/OddsManager';
import DebugPlayer from 'views/widgets/DebugPlayer';
import {
  LAYOUT_FRAME,
  LAYOUT_FULLSCREEN,
} from 'constants/bcms';
import BettingSuggestions from 'components/BettingSuggestions/BettingSuggestions';
import TickerContainer from 'newDesign/modules/common/components/ticker/tickerContainer/TickerContainer';
import PlayerContainer from 'newDesign/modules/live/components/livePlayer/livePlayerContainer/LivePlayerContainer';
import FooterManager from 'newDesign/modules/live/components/footer/footerManager/FooterManager';
import FooterBasketballThreeOnThree from 'newDesign/modules/live/components/footerBasketballThreeOnThree/FooterBasketballThreeOnThree';
import {
  getShowLmt,
  getLogoUrl,
  getStatsBreak,
  getScoreboardRefresh,
  getLayout,
  getReadyMatchId,
  getMatchSportId,
} from 'reducers';

import styles from 'newDesign/modules/live/stage/LiveStage.scss';
import { sport } from 'constants/enum';
import Crawler from '../crawler/Crawler';

const mapStateToProps = state => ({
  showLmt: getShowLmt(state),
  logoUrl: getLogoUrl(state),
  statsBreak: getStatsBreak(state),
  footerRefresh: getScoreboardRefresh(state),
  layout: getLayout(state),
  matchId: getReadyMatchId(state),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const Live = ({
  showLmt,
  logoUrl,
  statsBreak,
  footerRefresh,
  layout,
  matchId,
  sportId,
}) => {
  const side = (
    <div className='flex-1 h-full'>
      <DebugPlayer />
      <OddsManagerCmp />
      <TickerContainer />
    </div>
  );

  let matchCmp = null;
  if (showLmt) {
    matchCmp = <LMT id={matchId} layoutProp={layout} logoUrl={logoUrl} />;
  } else {
    matchCmp = <PlayerContainer />;
  }

  const main = (
    <div styleName='mainContentWrapper' className='flex flex-1 relative'>
      <Crawler />
      {matchCmp}
      {layout !== LAYOUT_FULLSCREEN
        ? (
          <BettingSuggestions matchId={matchId} />
        )
        : null}
    </div>
  );

  const footer = statsBreak && sportId === sport.basketball3x3 ? (
    <FooterManager key={footerRefresh} />
  ) : sportId === sport.basketball3x3 ? (
    <FooterBasketballThreeOnThree />
  ) : statsBreak ? null : (
    <FooterManager key={footerRefresh} />
  );

  return (
    <LayoutWrapperCmp
      main={main}
      bottom={footer}
      sidebar={side}
    />
  );
};

Live.propTypes = {
  showLmt: PropTypes.bool,
  logoUrl: PropTypes.string,
  statsBreak: PropTypes.bool,
  footerRefresh: PropTypes.number,
  layout: PropTypes.number,
  matchId: PropTypes.number,
  sportId: PropTypes.number.isRequired,
};

Live.defaultProps = {
  showLmt: false,
  logoUrl: '',
  statsBreak: false,
  footerRefresh: 0,
  layout: LAYOUT_FRAME,
  matchId: null,
};

const LiveStyled = cssModule(Live, styles);
export default connect(mapStateToProps)(LiveStyled);
