// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClientMatchId__match-status-id___A3pJ-{border-left:2px solid #fff}", ""]);
// Exports
exports.locals = {
	"match-status-id": "ClientMatchId__match-status-id___A3pJ-"
};
module.exports = exports;
