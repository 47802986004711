import PropTypes from 'prop-types';

import useHandballClock from 'newDesign/modules/common/hooks/clock/HandballClockHook';

const HandballClock = ({
  statusId,
  periodLength,
  timeInfo,
  overtimeLength,
}) => {
  const time = useHandballClock(
    statusId,
    periodLength,
    overtimeLength,
    timeInfo.played,
    timeInfo.remaining,
    timeInfo.running,
  );

  return time;
};

HandballClock.propTypes = {
  statusId: PropTypes.number,
  periodLength: PropTypes.number,
  timeInfo: PropTypes.object,
  overtimeLength: PropTypes.number,
};

HandballClock.defaultProps = {
  statusId: null,
  periodLength: 30,
  overtimeLength: 5,
  timeInfo: {
    played: '0',
    remaining: '0',
    running: false,
  },
};

export default HandballClock;
