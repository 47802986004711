import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import { getSeparatorColor } from 'reducers';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const mapStateToProps = state => ({
  separatorColor: getSeparatorColor(state),
});

const StrengthBar = ({
  percentage, separatorColor,
}) => {
  const pct = Math.min(percentage, 100); // cap if it overflows

  return (
    <div styleName='bar-container' className='flex'>
      <div style={{ width: `${pct}%`, background: `${separatorColor}` }} />
    </div>
  );
};

StrengthBar.propTypes = {
  percentage: PropTypes.number,
  separatorColor: PropTypes.string.isRequired,
};

StrengthBar.defaultProps = {
  percentage: 0,
};

const StrengthBarStyled = cssModule(StrengthBar, style);
export default connect(mapStateToProps)(StrengthBarStyled);
