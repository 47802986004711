import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import styles from './WinLooseBox.scss';

const WinLooseBox = ({
  statText, stat, reverse, winBox,
}) => (
  <div className={'flex w-full justify-center my-4 font-medium'}>
    <div className={'flex w-full gap-x-8 '} style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
      <div className={'flex flex-1 items-center'} style={{ justifyContent: reverse ? 'flex-start' : 'flex-end' }}>
        <span className={'uppercase fs-16'}>
          {statText}
        </span>
      </div>
      <div className={'flex font-bold'}>
        <span className={`fs-16 ${styles.stat_number} ${winBox && styles.win_box}`}>{stat}</span>
      </div>
    </div>
  </div>
);

WinLooseBox.propTypes = {
  winBox: PropTypes.bool,
  statText: PropTypes.string,
  stat: PropTypes.number,
  reverse: PropTypes.bool,
};

WinLooseBox.defaultProps = {
  winBox: false,
  statText: '',
  stat: 0,
  reverse: false,
};

const WinLooseBoxStyled = cssModule(WinLooseBox, styles);
export default WinLooseBoxStyled;
