// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr .MainSidebarLayout__MainSidebarLayoutGrid___3Paz_{display:-ms-flexbox;display:flex;-ms-flex:1;flex:1}#lcr .MainSidebarLayout__MSLMainCol___3kNGi{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex:1;flex:1;max-height:100vh;position:relative;overflow:hidden}#lcr .MainSidebarLayout__MSLSidebarCol___2htdZ{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;position:relative;height:100%;width:420px;margin-left:20px}#lcr .MainSidebarLayout__MSLMainTop___3TVvM{position:relative;display:-ms-flexbox;display:flex;-ms-flex:1;flex:1;width:100%;overflow:hidden}", ""]);
// Exports
exports.locals = {
	"MainSidebarLayoutGrid": "MainSidebarLayout__MainSidebarLayoutGrid___3Paz_",
	"MSLMainCol": "MainSidebarLayout__MSLMainCol___3kNGi",
	"MSLSidebarCol": "MainSidebarLayout__MSLSidebarCol___2htdZ",
	"MSLMainTop": "MainSidebarLayout__MSLMainTop___3TVvM"
};
module.exports = exports;
