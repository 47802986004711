import { createConstants } from 'utils';

export default createConstants(
  /*
   * action types
   */
  'START_LCR',
  'ENQUEUE_MESSAGE',
  'DEQUEUE_MESSAGE',
  'CANCEL_MESSAGE',
  'SET_MESSAGE',
  'TOGGLE_VISIBILITY',
  'VISIBILITY_ON',
  'VISIBILITY_OFF',
  'STAGE_READY',
  'TECHNICAL_ISSUES',

  // BCMS
  'BCMS_BETSHOP_CONFIG_LOAD_SUCCESS',
  'SET_TRANSLATIONS',
  'HIGHLIGHTS_LOAD_SUCCESS',
  'INITALSTAGE_ENDED',
  'RESET_APP',
  'REACT_ERROR',
  'SET_VIDEO_BREAK',
  'REMOVE_VIDEO_BREAK',
  'CHANGE_MAIN_MATCH',
  'SET_AS_MATCHID', // AUTOSPORT
  'SET_AS_MATCHES',
  'SET_LMT_MATCH',
  'SET_AS_STAGEID',
  'SET_READY_MATCHID',
  'CHANGE_LAST_MATCH',
  'CHANGE_HOTD_MATCH',

  // Local actions / stages
  'PRE_MATCH_HIDE_OPENER',
  'MATCH_BREAK_STAGE_SWITCH_SLIDE',
  'MATCH_BREAK_UPDATE_SLIDES',
  'ANNOUNCEMENTLOOP_STAGE_SWITCH_SLIDE',
  'SET_SUBSTAGES',
  'CACHE_STATIC_VIDEOS',
  'SET_ACTIVE_STAGE',
  'SET_SHOWN_STAGE',
  'SET_ACTIVE_MATCH',
  'SET_STATIC_VIDEO_PLAYING',
  'SET_STATIC_VIDEO_ENDED',
  'SET_ACTIVE_TEMP_STAGE',
  'UNINTENDED_STAGE_CANCEL',

  // Liveplayer
  'STREAM_STOPPED_PLAYING',
  'STREAM_IS_PLAYING',
  'SET_LIVEPLAYER_INSTANCE',
  'PLAYER_LOADING',
  'PLAYER_LOAD_ERROR',
  'PLAYER_LOAD_SUCCESS',
  'PLAYER_AUTOPLAY_BLOCKED',

  // Next matches
  'NEXT_MATCHES_LOAD_SUCCESS',

  // Fishnet feeds
  'LIVE_ODDS_LOADING',
  'UPDATE_PREMATCH_ODDS',
  'UPDATE_LIVE_ODDS',
  'UPDATE_MATCH_INFO',
  'UPDATE_JERSEYS_INFO',
  'UPDATE_MATCH_DELTA',
  'UPDATE_MATCH_DETAILS',
  'UPDATE_TOURNAMENT_LIVETABLE',
  'UPDATE_STATS_SEASON_OVERUNDER',
  'UPDATE_STATS_SEASON_GOALS',
  'UPDATE_STATS_MATCH_SITUATION',
  'UPDATE_STATS_TEAM_LASTX',
  'SET_MATCHCLOCK',
  'REMOVE_MATCH',


  // Streams
  'UPDATE_STREAM_URLS',
  'STREAM_URLS_REQUEST_ERROR',
  'UPDATE_ACTIVE_VIDEO_HANDLER',
  'POLL_STREAM_URLS',
  'CANCEL_POLLING_STREAM_URLS',

  // Market packages
  'MARKET_PACKAGES_LOAD_SUCCESS',
  'MARKET_PACKAGES_LOADING',

  // Authorization
  'LCR_AUTH_CHECK',
  'LOGGED_IN_SUCCESS',
  'LOGGED_IN_FAILURE',
  'PAYMENT_CHECK_SUCCESS',
  'PAYMENT_CHECK_FAILED',
  'ACCOUNT_DISABLED',
  'ACCOUNT_ENABLED',
  'SET_BID',

  // Error logger
  'LOG_MESSAGE',
  'HIDE_MESSAGE',

  // SIR
  'SIR_LOADING',
  'SIR_LOAD_ERROR',
  'SIR_LOAD_SUCCESS',

  // Components
  'SCOREBOARD_START',
  'SCOREBOARD_STOP',
  'SCOREBOARD_TOP_SWITCH_SLIDE',
  'SCOREBOARD_TOP_UPDATE_SLIDES',

  'SCOREBOARD_BOTTOM_SWITCH_SLIDE',
  'SCOREBOARD_BOTTOM_UPDATE_SLIDES',

  'STATSLIDES_SWITCH_SLIDE',
  'STATSLIDES_UPDATE_SLIDES',

  'BETTING_SUGGESTION_EVENT',
  'CLEAR_BETTING_SUGGESTIONS',
  'HIDE_BETTING_SUGGESTION_EVENT',
  'SHOW_BETTING_SUGGESTION_EVENT',
  'INITIAL_WAIT_OVER',
);
