// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr__wrapper .odd-number > div{gap:0 .5vw}#lcr__wrapper .odd-number > div .odd-change-arrow{right:-1.2vw;margin-top:.2vw;padding:0}#lcr__wrapper .odd-number > div:nth-child(even){-ms-flex-direction:row-reverse;flex-direction:row-reverse}#lcr__wrapper .odd-number > div:nth-child(even) .odd-change-arrow{left:-1.2vw;right:initial}#lcr__wrapper .odd-number > div:last-child{-ms-flex-direction:row-reverse;flex-direction:row-reverse}#lcr__wrapper .odd-number > div:last-child .odd-change-arrow{left:-1.2vw;right:initial}#lcr__wrapper .odd-number span{line-height:1.5vw}.OddsPanelMarket__odd-title___3fJEm{padding:.4vw .8vw;color:#fff;border-radius:3px 3px 0px 0px;background:linear-gradient(90deg, #50616B 0%, #50616B 14.29%, #62737C 29.32%, #50616B 40.6%, #50616B 100%)}.OddsPanelMarket__odd-number___1yadN{padding:.4vw .8vw;color:#3e3e3e;border-radius:0px 0px 3px 3px;background:#fff;box-shadow:0px 4px 4px 0px rgba(0,0,0,.05)}", ""]);
// Exports
exports.locals = {
	"odd-title": "OddsPanelMarket__odd-title___3fJEm",
	"odd-number": "OddsPanelMarket__odd-number___1yadN"
};
module.exports = exports;
