import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import style from 'styles/components/BettingSuggestions/BettingSuggestions.scss';
import { makeMapStateToProps } from 'reducers/oddsProjection';
import { connect } from 'react-redux';
import useBSOdds from 'hooks/BSOddsHook';
import Market from './Market';


const BSOdds = ({
  marketsOutcomesArr, marketsNameShortArr, secondaryColor, setHasOdds,
}) => {
  const {
    usedMarket,
    usedMarket2,
  } = useBSOdds(marketsOutcomesArr, marketsNameShortArr, setHasOdds);

  return (
    <div styleName='oddsGrid'>
      <Market {...usedMarket} secondaryColor={secondaryColor} />
      <Market {...usedMarket2} secondaryColor={secondaryColor} />
    </div>
  );
};

BSOdds.propTypes = {
  marketsOutcomesArr: PropTypes.array,
  marketsNameShortArr: PropTypes.array,
  setHasOdds: PropTypes.func,
  secondaryColor: PropTypes.string,
};

BSOdds.defaultProps = {
  marketsOutcomesArr: [],
  marketsNameShortArr: [],
  setHasOdds: () => null,
  secondaryColor: '#FFFFFF',
};

const BSOddsStyled = cssModule(BSOdds, style);
export default connect(makeMapStateToProps)(BSOddsStyled);
