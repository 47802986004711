import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getScoreBreak,
  getClockBreak,
  getMatchSportId,
  getReadyMatchId,
} from 'reducers';
import { sport } from 'constants/enum';
import Footer from 'newDesign/modules/live/components/footer/Footer';

const mapStateToProps = state => ({
  scoreBreak: getScoreBreak(state),
  clockBreak: getClockBreak(state),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

class FooterManager extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.scoreBreak !== nextProps.scoreBreak) {
      return false;
    }

    if (this.props.clockBreak !== nextProps.clockBreak) {
      return false;
    }

    return true;
  }

  render() {
    switch (this.props.sportId) {
      case undefined:
      case null:
      case 0:
        return <div />;
      case sport.basketball:
      case sport.ebasketball:
      case sport.basketball3x3:
      case sport.esoccer:
      case sport.soccer:
      case sport.tennis:
      case sport.table_tennis:
      case sport.ice_hockey:
      case sport.handball:
      case sport.volleyball:
      case sport.beach_volley:
      case sport.futsal:
        return <Footer />;
      default:
        break;
    }

    return (<div />);
  }
}

FooterManager.propTypes = {
  scoreBreak: PropTypes.bool,
  clockBreak: PropTypes.bool,
  sportId: PropTypes.number.isRequired,
};

FooterManager.defaultProps = {
  scoreBreak: 0,
  clockBreak: 0,
};

export default connect(
  mapStateToProps,
)(FooterManager);
