import { useState, useEffect } from 'react';
import { ODDSPANEL_N_MARKETS } from '../constants/app';

// create array of indices to use in original array
// range(4, 8) => [4, 5, 6, 7, 8]
function range(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx);
}

// prefer to select markets not shown in oddspanel
const useBSOdds = (marketsOutcomesArr, marketsNameShortArr, setHasOdds) => {
  const [usedMarket, setUsedMarket] = useState(null);
  const [usedMarket2, setUsedMarket2] = useState(null);
  useEffect(() => {
    const createMarket = idx => ({
      outcomes: marketsOutcomesArr[idx],
      marketName: marketsNameShortArr[idx],
      betstop: marketsOutcomesArr[idx].some(outcome => outcome.betstop),
    });

    if (marketsOutcomesArr && marketsOutcomesArr.length > 2) {
      let used;
      const n = marketsOutcomesArr.length - ODDSPANEL_N_MARKETS;

      if (n > 1) {
        used = range(ODDSPANEL_N_MARKETS, marketsOutcomesArr.length - 1);
      } else if (n === 1) {
        used = range(ODDSPANEL_N_MARKETS - 1, marketsOutcomesArr.length - 1); // 4th and 5th elem
      } else {
        used = range(0, marketsOutcomesArr.length - 1);
      }

      const market1 = used[Math.floor(Math.random() * used.length)];
      let market2 = used[Math.floor(Math.random() * used.length)];

      while (market1 === market2) { // more than 1 element, so will be unique eventually
        market2 = used[Math.floor(Math.random() * used.length)];
      }

      setUsedMarket(createMarket(market1));
      setUsedMarket2(createMarket(market2));
    } else if (marketsOutcomesArr && marketsOutcomesArr.length === 2) {
      setUsedMarket(createMarket(0));
      setUsedMarket2(createMarket(1));
    } else if (marketsOutcomesArr && marketsOutcomesArr.length === 1) {
      setUsedMarket(createMarket(0));
      setUsedMarket2(null);
    } else {
      setUsedMarket(null);
      setUsedMarket2(null);
    }
  }, [marketsNameShortArr, marketsOutcomesArr]);

  useEffect(() => {
    const isNotBetstop = (
      usedMarket && usedMarket.betstop === false) || (usedMarket2 && usedMarket2.betstop === false
    );

    setHasOdds(isNotBetstop);

    return () => setHasOdds(false);
  }, [usedMarket, usedMarket2, setHasOdds]);

  return {
    usedMarket,
    usedMarket2,
  };
};

export default useBSOdds;
