// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr .PlayerContainer__liveplayerContainer___QD5m3{width:100%;height:100%}#lcr .PlayerContainer__playerWrapper___2iglW{width:calc((100vh - 236px)*1.7777777778);height:100%;margin:auto;position:absolute;top:0;bottom:0;left:0;right:0;max-height:100%;max-width:100%}#lcr .PlayerContainer__playerWrapperFullscreen___28bHp{width:100%;height:100%}#lcr .PlayerContainer__loadingOverlay___2j5eQ{height:100%;position:absolute;z-index:100;width:100%;visibility:visible;opacity:1;transition:visibility 2s,opacity 1s linear}#lcr .PlayerContainer__loadingOverlayHidden___3s7hb{height:100%;width:100%;visibility:hidden;opacity:0;position:absolute;transition:visibility 2s linear,opacity 2s linear;z-index:100}", ""]);
// Exports
exports.locals = {
	"liveplayerContainer": "PlayerContainer__liveplayerContainer___QD5m3",
	"playerWrapper": "PlayerContainer__playerWrapper___2iglW",
	"playerWrapperFullscreen": "PlayerContainer__playerWrapperFullscreen___28bHp",
	"loadingOverlay": "PlayerContainer__loadingOverlay___2j5eQ",
	"loadingOverlayHidden": "PlayerContainer__loadingOverlayHidden___3s7hb"
};
module.exports = exports;
