import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import darken from 'newDesign/modules/common/utils/darken';
import PageHeader from 'newDesign/modules/announcement/components/pageHeader/PageHeader';
import MatchList from 'newDesign/modules/announcement/components/matchList/MatchList';
import { getBgColor, getLogoUrl } from 'reducers';

import style from 'newDesign/modules/announcement/stage/AnnouncementStage.scss';

const mapStateToProps = state => ({
  logoUrl: getLogoUrl(state),
  bgColor: getBgColor(state),
});

export const Announcement = ({
  logoUrl,
  bgColor,
  noLogo,
}) => (
  <div
    className={`${style.announcement} announcement flex justify-center items-stretch`}
    style={{
      background: `linear-gradient(0deg, ${darken(bgColor, 0, 'rgba', 0.39)}, #000000 100%)`,
    }}
  >
    <div className='container flex flex-col max-w-[90%] m-auto py-11 animate-fadeIn-1.25s'>
      <PageHeader logoUrl={noLogo ? null : logoUrl} />
      <div className='eventList flex flex-col justify-between flex-1'>
        <MatchList />
      </div>
    </div>
  </div>
);

Announcement.propTypes = {
  logoUrl: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  noLogo: PropTypes.bool,
};

Announcement.defaultProps = {
  logoUrl: '',
  noLogo: false,
};

export default connect(mapStateToProps)(Announcement);
