import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { connect } from 'react-redux';
import {
  SBT_LAST_MATCHES,
  SBT_GOALS_SCORED,
  SBT_GOALS_CONCEDED,
  SBT_LEAGUE_RANK,
  SBT_CORNER_KICKS,
  SBT_ATT_STRENGTH,
  SBT_1ST_HALF_SCORE,
  SBT_NATIONALITY,
  SBT_ENDED,
  SBT_FORM_PERC,
  SBT_SETS_PER_MATCH,
  SBT_POINTS_PER_MATCH,
  SCOREBOARD_TOP,
} from 'constants/components';
import { getSBCompSlideIdx } from 'reducers';
import LastMatches from 'newDesign/modules/live/components/footer/footerBottom/slides/lastMatches/LastMatches';
import Scored from 'newDesign/modules/live/components/footer/footerBottom/slides/Scored';
import Conceded from 'newDesign/modules/live/components/footer/footerBottom/slides/Conceded';
import LeagueRank from 'newDesign/modules/live/components/footer/footerBottom/slides/LeagueRank';
import AttStrength from 'newDesign/modules/live/components/footer/footerBottom/slides/AttStrength';
import FirstHalfScore from 'newDesign/modules/live/components/footer/footerBottom/slides/FirstHalfScore';
import CornerKicks from 'newDesign/modules/live/components/footer/footerBottom/slides/CornerKicks';
import Nationality from 'newDesign/modules/live/components/footer/footerBottom/slides/Nationality';
import Ended from 'newDesign/modules/live/components/footer/footerBottom/slides/Ended';
import MatchStatistics from 'newDesign/modules/live/components/footer/footerBottom/slides/MatchStatistics';
import Form from 'newDesign/modules/live/components/footer/footerBottom/slides/Form';
import PointsPerMatch from 'newDesign/modules/live/components/footer/footerBottom/slides/PointsPerMatch';
import SetsPerMatch from 'newDesign/modules/live/components/footer/footerBottom/slides/SetsPerMatch';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const mapStateToProps = state => ({
  footerCurrentStatSlideIndex: getSBCompSlideIdx(state, SCOREBOARD_TOP),
});

const FooterBottom = ({ footerCurrentStatSlideIndex }) => {
  let slide = null;

  switch (footerCurrentStatSlideIndex) {
    case SBT_LAST_MATCHES:
      slide = <LastMatches />;
      break;
    case SBT_GOALS_SCORED:
      slide = <Scored />;
      break;
    case SBT_GOALS_CONCEDED:
      slide = <Conceded />;
      break;
    case SBT_LEAGUE_RANK:
      slide = <LeagueRank />;
      break;
    case SBT_NATIONALITY:
      slide = <Nationality />;
      break;
    case SBT_ATT_STRENGTH:
      slide = <AttStrength />;
      break;
    case SBT_CORNER_KICKS:
      slide = <CornerKicks />;
      break;
    case SBT_1ST_HALF_SCORE:
      slide = <FirstHalfScore />;
      break;
    case SBT_FORM_PERC:
      slide = <Form />;
      break;
    case SBT_POINTS_PER_MATCH:
      slide = <PointsPerMatch />;
      break;
    case SBT_SETS_PER_MATCH:
      slide = <SetsPerMatch />;
      break;
    case SBT_ENDED:
      slide = <Ended />;
      break;
    case undefined:
    case null:
      slide = null;
      break;
    default:
      slide = <MatchStatistics />;
      break;
  }

  return (
    <div styleName='lframe-stats' className='flex flex-1 justify-center items-center'>
      {slide}
    </div>
  );
};

FooterBottom.propTypes = {
  footerCurrentStatSlideIndex: PropTypes.number,
};

FooterBottom.defaultProps = {
  footerCurrentStatSlideIndex: null,
};

const FooterBottomStyled = cssModule(FooterBottom, style);
export default connect(mapStateToProps)(FooterBottomStyled);
