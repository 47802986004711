import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getQueryStatSlide,
  getReadyMatchId,
  getSSCompSlideIdx,
  getTournamentName,
} from 'reducers/index';
import {
  SS_FORM_CLAY,
  SS_FORM_GRASS,
  SS_FORM_HARDCOURT,
  SS_FORM_INDOOR,
  SS_FORM_OVERALL,
  SS_LAST_MATCHES,
  SS_LEAGUE_TABLE,
  SS_LIVESTATS,
  SS_PREMATCH_SUBSTAGE_PERFORMANCE,
  SS_PREVIOUS_MEETINGS,
  SS_TIMELINE,
} from 'constants/components';
import LiveStats from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats';
import Timeline from 'newDesign/modules/break/components/statSlides/timeline/Timeline';
import {
  CLAY,
  GRASS,
  HARDCOURT,
  INDOOR,
  OVERALL,
} from 'constants/fishnet';
import PreMatchDecoratedTitle from 'newDesign/modules/preMatch/common/components/preMatchDecoratedTitle/PreMatchDecoratedTitle';
import LeagueTable from 'newDesign/modules/preMatch/components/statSlides/leagueTable/components/leagueTable/LeagueTable';
import LastMatches from 'newDesign/modules/preMatch/components/statSlides/lastMatches/LastMatches';
import PreviousMeetingsList from 'newDesign/modules/preMatch/components/statSlides/previousMeetingsNewDesign/previousMeetingsList/PreviousMeetingsList';
import PerformanceWrapper from 'newDesign/modules/preMatch/components/statSlides/performanceSlides/performanceWrapper/PerformanceWrapper';

const mapStateToProps = state => ({
  curIdx: getSSCompSlideIdx(state),
  qStatSlide: getQueryStatSlide(state),
  tournamentName: getTournamentName(state, getReadyMatchId(state)),
});

const StatSlidesNewDesign = ({
  curIdx, qStatSlide, tournamentName,
}) => {
  const intl = useIntl();

  let slide;
  let text;
  let groundId;

  const preMatchSlideId = qStatSlide != null ? qStatSlide : curIdx; // override idx from query param

  switch (preMatchSlideId) {
    case SS_LAST_MATCHES:
      slide = <LastMatches />;
      text = intl.formatMessage({ id: 't_statistics_last_matches' });
      break;
    case SS_PREVIOUS_MEETINGS:
      slide = <PreviousMeetingsList />;
      text = intl.formatMessage({ id: 't_statistics_previous_meetings' });
      break;
    case SS_LIVESTATS:
      slide = <LiveStats />;
      text = null;
      break;
    case SS_TIMELINE:
      slide = <Timeline />;
      text = null;
      break;
    case SS_LEAGUE_TABLE:
      slide = <LeagueTable />;
      text = tournamentName;
      break;
    case SS_FORM_OVERALL:
      text = `${intl.formatMessage({ id: 't_statistics_performance' })} - ${intl.formatMessage({ id: 't_statistics_overall' })}`;
      groundId = OVERALL;
      break;
    case SS_FORM_CLAY:
      text = `${intl.formatMessage({ id: 't_statistics_performance' })} - ${intl.formatMessage({ id: 't_statistics_clay' })}`;
      groundId = CLAY;
      break;
    case SS_FORM_HARDCOURT:
      text = `${intl.formatMessage({ id: 't_statistics_performance' })} - ${intl.formatMessage({ id: 't_statistics_hardcourt' })}`;
      groundId = HARDCOURT;
      break;
    case SS_FORM_INDOOR:
      text = `${intl.formatMessage({ id: 't_statistics_performance' })} - ${intl.formatMessage({ id: 't_statistics_indoor' })}`;
      groundId = INDOOR;
      break;
    case SS_FORM_GRASS:
      text = `${intl.formatMessage({ id: 't_statistics_performance' })} - ${intl.formatMessage({ id: 't_statistics_grass' })}`;
      groundId = GRASS;
      break;
    default:
      // TODO: Empty slide will be displayed
      slide = <div> </div>;
      text = null;
      groundId = OVERALL;
      break;
  }

  // Display only for Pre-match stage, Performance substage
  if (SS_PREMATCH_SUBSTAGE_PERFORMANCE.includes(preMatchSlideId)) {
    slide = <PerformanceWrapper groundId={groundId} />;
  }

  return (
    <React.Fragment>
      <PreMatchDecoratedTitle titleTranslation={text} />
      {slide}
    </React.Fragment>
  );
};

StatSlidesNewDesign.propTypes = {
  curIdx: PropTypes.number,
  qStatSlide: PropTypes.number,
  tournamentName: PropTypes.string,
};

StatSlidesNewDesign.defaultProps = {
  curIdx: null,
  qStatSlide: null,
  tournamentName: '',
};

export default connect(mapStateToProps)(StatSlidesNewDesign);
