// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Odds__prematch__odds___16rV6{font-size:1.4vw}.Odds__prematch__odds___16rV6 .Odds__prematch__odds__blocks___2sedX{max-width:7vw;min-width:7vw}.Odds__prematch__odds___16rV6 .Odds__prematch__odds__blocks___2sedX:nth-child(2){min-width:initial}.Odds__prematch__odds___16rV6 .Odds__prematch__odds__blocks___2sedX span{font-size:1.6vw}.Odds__prematch__odds___16rV6 .Odds__prematch__odds__blocks___2sedX>span{font-size:1vw}.Odds__decoration-underline___2vdCW{position:relative}.Odds__decoration-underline___2vdCW::after{position:absolute;width:100%;content:\"\";height:2px;background:#585a60;left:0;right:0;bottom:0}@media only screen and (min-width: 1536px){.Odds__prematch__odds___16rV6{font-size:1.4vw}.Odds__prematch__odds___16rV6 .Odds__prematch__odds__blocks___2sedX{max-width:7vw;min-width:7vw}.Odds__prematch__odds___16rV6 .Odds__prematch__odds__blocks___2sedX:nth-child(2){min-width:initial}.Odds__prematch__odds___16rV6 .Odds__prematch__odds__blocks___2sedX span{font-size:1.6vw}.Odds__prematch__odds___16rV6 .Odds__prematch__odds__blocks___2sedX>span{font-size:1vw}}", ""]);
// Exports
exports.locals = {
	"prematch__odds": "Odds__prematch__odds___16rV6",
	"prematch__odds__blocks": "Odds__prematch__odds__blocks___2sedX",
	"decoration-underline": "Odds__decoration-underline___2vdCW"
};
module.exports = exports;
