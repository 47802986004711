import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';
import PropTypes from 'prop-types';

import { getMatchTeams, getReadyMatchId } from 'reducers/index';
import DefaultSeparator from '../../../defaultSeparator/DefaultSeparator';
import styles from './TennisDouble.scss';
import Ranking from '../components/ranking/Ranking';
import TennisFlags from '../components/tennisFlags/TennisFlags';

const mapStateToProps = state => ({
  teams: getMatchTeams(state, getReadyMatchId(state)),
});

const TennisDouble = ({
  competitors, teams,
}) => {
  const homeTeamArray = competitors.home[0].name.split('/');
  const awayTeamArray = competitors.away[0].name.split('/');

  /*
   Logic to handle the case, when second competitor is not defined even though it is a double match.
   Create a copy of the teams to avoid mutating the original object.
  */
  const homeTeam = [...teams.home];
  const awayTeam = [...teams.away];

  if (!homeTeam[1]) {
    homeTeam.push({
      countryCodeA2: '', name: '', mediumname: '', uniqueId: '',
    });
  }

  if (!awayTeam[1]) {
    awayTeam.push({
      countryCodeA2: '', name: '', mediumname: '', uniqueId: '',
    });
  }

  return (
    <Fragment>
      <div className={'z-10 mb-8 fs-16 flex justify-center items-start w-full'}>
        <div styleName='double_flags' className={'flex justify-center items-center flex-col w-2/5'}>
          <TennisFlags teams={homeTeam} />
          <h2 className={'font-main--bold uppercase items-center text-center flex'}>{homeTeamArray[0]}</h2>
          <h2 className={'font-main--bold uppercase items-center text-center flex'}>{homeTeamArray[1]}</h2>
        </div>
        <div className={'flex flex-col justify-center items-center w-1/5 h-full'}>
          <DefaultSeparator />
        </div>
        <div styleName='double_flags' className={'flex justify-center items-center flex-col w-2/5'}>
          <TennisFlags teams={awayTeam} />
          <h2 className={'font-main--bold uppercase items-center text-center flex'}>{awayTeamArray[0]}</h2>
          <h2 className={'font-main--bold uppercase items-center text-center flex'}>{awayTeamArray[1]}</h2>
        </div>
      </div>
      <Ranking />
    </Fragment>
  );
};

TennisDouble.propTypes = {
  competitors: PropTypes.object.isRequired,
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
};

TennisDouble.defaultProps = {
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};

const TennisDoubleStyled = cssModule(TennisDouble, styles);
export default connect(mapStateToProps)(TennisDoubleStyled);
