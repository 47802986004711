import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sport } from 'constants/enum';

import { getMatchSportId, getMatchStadium } from 'reducers/index';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
  stadium: getMatchStadium(state, ownProps.matchId),
});

const Stadium = ({ displayStadium, sportId, stadium }) => {
  if (!displayStadium) {
    return null;
  }

  let stadiumName = '';

  if (stadium && sportId === sport.tennis) {
    stadiumName = stadium.city + ', ' + stadium.country;
  } else {
    stadiumName = stadium ? stadium.name + ', ' + stadium.country : '';
  }

  return (
    <span className={'font-main--thin uppercase'}>{stadiumName}</span>
  );
};

Stadium.propTypes = {
  displayStadium: PropTypes.bool,
  sportId: PropTypes.number.isRequired,
  stadium: PropTypes.object,
};

Stadium.defaultProps = {
  displayStadium: false,
  stadium: {},
};

export default connect(mapStateToProps)(Stadium);
