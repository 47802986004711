import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import ChannelTrailerCmp from 'views/widgets/ChannelTrailer';
import LayoutWrapperCmp from 'newDesign/modules/common/components/layout/LayoutWrapper';
import {
  getStageConfig,
  getLogoUrl,
  getLayout,
} from 'reducers';
import FooterManager from 'newDesign/modules/live/components/footer/footerManager/FooterManager';
import BreakStatSlides from 'newDesign/modules/preMatch/components/statSlides/BreakStatSlides';
import TickerContainer from 'newDesign/modules/common/components/ticker/tickerContainer/TickerContainer';
import OddsManagerCmp from 'newDesign/modules/common/components/oddsManager/OddsManager';
import {
  STAGE_BREAK,
  CHANNEL_TRAILER,
  MATCH_BREAK_STATS, LAYOUT_FRAME,
} from 'constants/bcms';

import style from 'newDesign/modules/break/stage/BreakStageNewDesign.scss';

const mapStateToProps = state => ({
  stageConfig: getStageConfig(state, STAGE_BREAK),
  logoUrlPath: getLogoUrl(state),
  layout: getLayout(state),
});

export class Break extends React.Component {
  render() {
    const {
      stageConfig,
      logoUrlPath,
      layout,
    } = this.props;

    const bottom = <FooterManager />;

    const sidebar = (
      <div className='flex-1 h-full'>
        <OddsManagerCmp />
        <TickerContainer />
      </div>
    );

    const main = (
      <div styleName='mainContentWrapper' className='componentWrapper'>
        <div styleName='containerHeightAdjuster' />
        <BreakStatSlides />
      </div>
    );

    this.slides = {
      [CHANNEL_TRAILER]: <ChannelTrailerCmp />,
      [MATCH_BREAK_STATS]: (
        <LayoutWrapperCmp
          main={main}
          bottom={bottom}
          sidebar={sidebar}
          logoUrl={logoUrlPath}
          layout={layout}
        />
      ),
    };

    const curSlideIdx = stageConfig.currentSlideIdx || 0;
    const currentSlideKey = stageConfig.dynamicSlides[curSlideIdx];
    const currentSlide = currentSlideKey
      ? this.slides[currentSlideKey]
      : this.slides[CHANNEL_TRAILER];

    return (
      <Fragment>
        {currentSlide}
      </Fragment>
    );
  }
}

Break.propTypes = {
  stageConfig: PropTypes.object,
  logoUrlPath: PropTypes.string,
  layout: PropTypes.number,
};

Break.defaultProps = {
  stageConfig: null,
  logoUrlPath: '',
  layout: LAYOUT_FRAME,
};

const BreakStyled = cssModule(Break, style);
export default connect(mapStateToProps)(BreakStyled);
