import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import StatusTimeInfo from 'newDesign/modules/common/components/oddsPanel/scoreBoard/scoreBasedBoard/statusTimeInfo/StatusTimeInfo';
import { isEnded } from 'utils/matchstatus';
import { matchStatusIds } from 'constants/enum';
import {
  getMatchResult,
  getMatchStatus,
  getReadyMatchId,
  getScoreBreak,
} from 'reducers';

import style from '../../ScoreBoard.scss';

const mapStateToProps = state => ({
  statusId: getMatchStatus(state, getReadyMatchId(state)),
  result: getMatchResult(state, getReadyMatchId(state)),
  scoreBreak: getScoreBreak(state),
});

const Board = ({
  result,
  scoreBreak,
  statusId,
}) => {
  const getInactiveScoreClass = team => {
    if (isEnded(statusId)) {
      return result.winner !== team ? 'scoreboard-score--inactive' : '';
    }
    return '';
  };

  const homeInactiveScoreClass = getInactiveScoreClass('home');
  const awayInactiveScoreClass = getInactiveScoreClass('away');
  const separatorInactiveScoreClass = isEnded(statusId) ? 'scoreboard-score--inactive' : '';

  const showScore = statusId !== matchStatusIds.not_started;

  return (
    <div styleName='scoreboard__text' className='flex-col flex-2 flex justify-center items-center'>
      {showScore && (
        <div styleName='scoreboard-score' className='flex'>
          <span styleName={homeInactiveScoreClass} className='fs-40 font-second--bold'>
            {(result.home == null || scoreBreak) ? '-' : result.home}
          </span>
          <span styleName={separatorInactiveScoreClass} className='fs-40 font-second--bold'>-</span>
          <span styleName={awayInactiveScoreClass} className='fs-40 font-second--bold'>
            {(result.away == null || scoreBreak) ? '-' : result.away}
          </span>
        </div>
      )}
      <StatusTimeInfo />
    </div>
  );
};

Board.propTypes = {
  result: PropTypes.object,
  scoreBreak: PropTypes.bool,
  statusId: PropTypes.number,
};

Board.defaultProps = {
  result: {
    home: '-',
    away: '-',
  },
  scoreBreak: false,
  statusId: null,
};
const BoardStyled = cssModule(Board, style);
export default connect(mapStateToProps)(BoardStyled);
