import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getClientMatchId,
} from 'reducers/index';

import style from 'newDesign/modules/common/components/oddsPanel/clientOrMatchId/ClientOrMatchId.scss';

const mapStateToProps = (state, ownProps) => ({
  clientMatchId: getClientMatchId(state, ownProps.matchId),

});
const ClientOrMatchId = ({
  matchId,
  clientMatchId,
}) => {
  const intl = useIntl();

  return (
    <div styleName='match-id' className='flex justify-center items-center'>
      <h2 className='fs-18 font-main--bold py-1 box-border'>
        {`${intl.formatMessage({ id: 't_general_badge_match_id' })} ${clientMatchId || matchId}`}
      </h2>
    </div>
  );
};

ClientOrMatchId.propTypes = {
  matchId: PropTypes.number.isRequired,
  clientMatchId: PropTypes.string,
};
ClientOrMatchId.defaultProps = {
  clientMatchId: '',
};

const ClientOrMatchIdStyled = cssModule(ClientOrMatchId, style);
export default connect(mapStateToProps)(ClientOrMatchIdStyled);
