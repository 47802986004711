import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import darken from 'newDesign/modules/common/utils/darken';
import {
  getBgColor,
  getMatchSportId, getMatchTeams,
  getMatchTennisDouble,
  getReadyMatchId,
} from 'reducers';


import style from 'newDesign/modules/live/components/footer/Footer.scss';
import { PERIOD_BASE_SPORTS } from 'constants/components';
import TeamNames from './TeamNames';

const mapStateToProps = state => ({
  bgColor: getBgColor(state),
  tennisDouble: getMatchTennisDouble(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  teams: getMatchTeams(state, getReadyMatchId(state)),
  matchId: getReadyMatchId(state),
});

const PeriodBasedCompetitors = ({
  teams,
  tennisDouble,
  bgColor,
  sportId,
  matchId,
}) => {
  let homeTeam;
  let awayTeam;
  let flagsHome;
  let flagsAway;

  const isPeriodBasedSportsWithFlags = PERIOD_BASE_SPORTS.includes(sportId);

  if (tennisDouble) {
    homeTeam = tennisDouble.home;
    awayTeam = tennisDouble.away;
    const homeTeamFirstMemberFlag = teams.home[0] ? (teams.home[0].countryCodeA2 ? teams.home[0].countryCodeA2 : '') : '';
    const homeTeamSecondMemberFlag = teams.home[1] ? (teams.home[1].countryCodeA2 ? teams.home[1].countryCodeA2 : '') : '';
    const awayTeamFirstMemberFlag = teams.away[0] ? (teams.away[0].countryCodeA2 ? teams.away[0].countryCodeA2 : '') : '';
    const awayTeamSecondMemberFlag = teams.away[1] ? (teams.away[1].countryCodeA2 ? teams.away[1].countryCodeA2 : '') : '';
    flagsHome = [homeTeamFirstMemberFlag, homeTeamSecondMemberFlag];
    flagsAway = [awayTeamFirstMemberFlag, awayTeamSecondMemberFlag];
  } else if (teams) {
    homeTeam = teams.home;
    awayTeam = teams.away;
    flagsHome = [teams.home[0].countryCodeA2];
    flagsAway = [teams.away[0].countryCodeA2];
  }

  return (
    <div styleName={'lframe-header lframe-header--teams'} className='flex justify-center items-strech'>
      <TeamNames key={matchId + '_home'} team={homeTeam} flags={isPeriodBasedSportsWithFlags ? flagsHome : []} />
      <div
        className='flex items-center justify-center'
        style={{
          background: `linear-gradient(90deg,
            ${darken(bgColor, 6)} 0%,
            ${bgColor} 0.50%,
            ${darken(bgColor, 6)} 100%)`,
        }}
      >
        <span className='fs-20 font-main--bold uppercase'><TranslationFormattedMessage id='t_general_vs' /></span>
      </div>
      <TeamNames key={matchId + '_away'} team={awayTeam} flags={isPeriodBasedSportsWithFlags ? flagsAway : []} />
    </div>
  );
};

PeriodBasedCompetitors.propTypes = {
  teams: PropTypes.object,
  tennisDouble: PropTypes.object,
  bgColor: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
  matchId: PropTypes.number.isRequired,
};

PeriodBasedCompetitors.defaultProps = {
  teams: {
    home: null,
    away: null,
  },
  tennisDouble: null,
};
const PeriodBasedCompetitorsStyled = cssModule(PeriodBasedCompetitors, style, { allowMultiple: true });
export default connect(mapStateToProps)(PeriodBasedCompetitorsStyled);
