import { matchStatusIds, sport } from './enum';

export const eSoccerMinuteConversions = {
  // These are the time factors from LD: https://gitlab.sportradar.ag/ld/livedata-core/-/blob/master/livedata-core/src/main/java/com/oddsarbitrage/scout/sports/soccer/ScoutMatchDataSoccer.java#L65
  4: {
    [matchStatusIds.first_half]: 2700 / 275,
    [matchStatusIds.second_half]: 2700 / 275,
    [matchStatusIds.first_extra]: 900 / 115,
    [matchStatusIds.second_extra]: 900 / 115,
  },
  5: {
    [matchStatusIds.first_half]: 2700 / 345,
    [matchStatusIds.second_half]: 2700 / 345,
    [matchStatusIds.first_extra]: 90 / 15,
    [matchStatusIds.second_extra]: 90 / 15,
  },
  6: {
    [matchStatusIds.first_half]: 27 / 4,
    [matchStatusIds.second_half]: 270 / 41,
    [matchStatusIds.first_extra]: 5,
    [matchStatusIds.second_extra]: 5,
  },
  9: {
    [matchStatusIds.first_half]: 27 / 6,
    [matchStatusIds.second_half]: 2700 / 615,
    [matchStatusIds.first_extra]: 90 / 27,
    [matchStatusIds.second_extra]: 90 / 27,
  },
};

export const clockStatusConfigs = {
  [matchStatusIds.not_started]: {
    previousPeriods: 0,
    countdownToMatch: true, // countdown to start of game
    countdownDuration: 30, // 30 mins prior to the game the countdown will start
    startTime: 0,
    timerunning: false,
  },
  [matchStatusIds.match_about_to_start]: {
    previousPeriods: 0,
    countdownToMatch: true, // countdown to start of game
    countdownDuration: 30, // 30 mins prior to the game the countdown will start
    startTime: 0,
    timerunning: true,
  },
  [matchStatusIds.first_half]: { // 1st half
    previousPeriods: 0,
    timerunning: true,
    startTime: 0,

  },
  [matchStatusIds.halftime]: { // Halftime
    previousPeriods: 1,
    timerunning: false,
    startTime: 45,

  },
  [matchStatusIds.second_half]: { // 2nd half
    previousPeriods: 1,
    timerunning: true,
    startTime: 45,

  },
  [matchStatusIds.awaiting_extra_time]: { // Awaiting extra time
    previousPeriods: 2,
    timerunning: false,
    startTime: 90,

  },
  [matchStatusIds.first_extra]: { // 1st extra half
    previousPeriods: 2,
    playingExtra: true,
    timerunning: true,
    startTime: 90,

  },
  [matchStatusIds.extra_time_halftime]: { // Extra time halftime
    previousPeriods: 2,
    previousExtra: 1,
    timerunning: false,
    startTime: 105,

  },
  [matchStatusIds.second_extra]: { // 2nd extra half
    previousPeriods: 2,
    previousExtra: 1,
    playingExtra: true,
    timerunning: true,
    startTime: 105,

  },
  [matchStatusIds.awaiting_penalties]: { // Awaiting penalties
    previousPeriods: 2,
    previousExtra: 2,
    timerunning: false,
    startTime: 120,

  },
  [matchStatusIds.penalties]: { // Awaiting penalties
    previousPeriods: 2,
    previousExtra: 2,
    timerunning: false,
    startTime: 120,

  },
  [matchStatusIds.ended]: { // regular end
    previousPeriods: 2,
    timerunning: false,
    startTime: 90,

  },
  [matchStatusIds.AET]: { // end after extensions ???
    previousPeriods: 2,
    previousExtra: 2,
    timerunning: false,
    startTime: 120,
  },
  [matchStatusIds.AP]: { // ended penalities?
    previousPeriods: 2,
    previousExtra: 2,
    timerunning: false,
    startTime: 120,
  },
};

export const defaultClockConfigs = {
  [sport.soccer]: {
    format: 'mm:ss{I} +ii:ll{/I}',
    type: 'soccer',
    defaultPeriodLength: 45,
    defaultCountdownDuration: 30,
    extraLength: 15,
    defaultPostmatchStatus: [matchStatusIds.ended],
    clockStatusConfigs,
  },

  [sport.basketball]: {
    format: 'mm:ss',
    type: 'stoppable_countdown',
  },

  [sport.ice_hockey]: {
    format: 'mm:ss',
    type: 'stoppable_countdown',
    suspensions: true,
  },

  [sport.tennis]: {
    format: 'mm:ss',
    type: 'played_time',
  },

  [sport.football]: {
    format: 'mm:ss',
    type: 'stoppable_countdown',
  },

  [sport.volleyball]: {
    format: 'mm:ss',
    type: 'played_time',
  },

  [sport.beach_volley]: {
    format: 'mm:ss',
    type: 'played_time',
  },

  [sport.handball]: {
    format: 'mm:ss',
    suspensionFormat: 'm:ss',
    type: 'stoppable_countup',
    suspensions: true,
    clockStatusConfigs,
    defaultPeriodLength: 30,
    extraLength: 5,
  },

  [sport.table_tennis]: {
    format: 'mm:ss',
    type: 'played_time',
  },

  [sport.badminton]: {
    format: 'mm:ss',
    type: 'played_time',
  },

  [sport.rugby]: {
    format: 'mm:ss',
    type: 'stoppable_countup',
    clockStatusConfigs,
    defaultPeriodLength: 40,
    extraLength: 5,
  },

  [sport.futsal]: {
    format: 'mm:ss',
    type: 'stoppable_countdown',
    clockStatusConfigs,
    suspensions: true,
    defaultPeriodLength: 20,
    extraLength: 5,
  },

  [sport.esoccer]: {
    format: 'mm:ss{I} +ii:ll{/I}',
    type: 'esoccer',
    defaultPeriodLength: 45,
    defaultCountdownDuration: 30,
    extraLength: 15,
    defaultPostmatchStatus: [matchStatusIds.ended],
    clockStatusConfigs,
  },

  [sport.ebasketball]: {
    format: 'mm:ss',
    type: 'stoppable_countdown',
  },

  [sport.esport_ice_hockey]: {
    format: 'mm:ss',
    type: 'stoppable_countdown',
    suspensions: true,
  },
};

export default defaultClockConfigs;
