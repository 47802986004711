import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { eventTypeId } from 'constants/enum';
import TimelineTime from '../timelineTime/TimelineTime';

import styles from '../Timeline.scss';

const TimelineItem = ({ children, event }) => {
  if (!event) {
    return null;
  }

  const hideCondition = (
    (event && event._typeid === eventTypeId.penalty_shootout_event)
  );

  return (
    <div styleName='timeline_game_item' className={`w-full flex items-center ${event.team === 'away' ? 'justify-end' : ''}`}>
      <div className={`flex items-center justify-end ${event.team === 'away' && 'flex-row-reverse'}`}>
        {children}
        <TimelineTime
          injuryTime={event.injurytime}
          time={event.time}
          hide={hideCondition}
        />
      </div>
    </div>
  );
};

TimelineItem.propTypes = {
  event: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TimelineItem.defaultProps = {
  event: null,
  children: null,
};

const TimelineItemStyled = cssModule(TimelineItem, styles, { allowMultiple: true });
export default TimelineItemStyled;
