// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr .LivePlayerContainer__liveplayerContainer___1J5ep{width:100%;height:100%}#lcr .LivePlayerContainer__containerHeightAdjuster___2y2-E{width:0;background:#212121;padding-top:56.25%;box-sizing:border-box;height:0}#lcr .LivePlayerContainer__playerWrapper___2fHE-{height:100%;margin:auto;position:relative;top:0;bottom:0;left:0;right:0;max-height:100%;max-width:100%}#lcr .LivePlayerContainer__playerWrapperFullscreen___1ZDxA{width:100%;height:100%}#lcr .LivePlayerContainer__loadingOverlay___10uBO{height:100%;position:absolute;z-index:100;width:100%;visibility:visible;opacity:1;transition:visibility 2s,opacity 1s linear;background-color:#212121 !important}#lcr .LivePlayerContainer__loadingOverlayHidden___3IiQX{height:100%;width:100%;visibility:hidden;opacity:0;position:absolute;transition:visibility 2s linear,opacity 2s linear;z-index:100;background-color:#212121 !important}", ""]);
// Exports
exports.locals = {
	"liveplayerContainer": "LivePlayerContainer__liveplayerContainer___1J5ep",
	"containerHeightAdjuster": "LivePlayerContainer__containerHeightAdjuster___2y2-E",
	"playerWrapper": "LivePlayerContainer__playerWrapper___2fHE-",
	"playerWrapperFullscreen": "LivePlayerContainer__playerWrapperFullscreen___1ZDxA",
	"loadingOverlay": "LivePlayerContainer__loadingOverlay___10uBO",
	"loadingOverlayHidden": "LivePlayerContainer__loadingOverlayHidden___3IiQX"
};
module.exports = exports;
