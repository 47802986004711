import { useRef, useState, useEffect } from 'react';
import delay from 'newDesign/modules/common/utils/delay';
import useOnScreen from './OnScreen';

/**
 * Vertical scroll
 * @param elementRef Element that needs to be visible to reset scroll
 * @param stepPixels Number of pixels for each step
 * @param delayTime Number of delay before scrolling in ms (1000 is 1 sec)
 * @return {number}
 */
export default function useOnScrollVertical(elementRef, stepPixels, delayTime) {
  const [top, setTop] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const scrollDirection = useRef('down');
  const currentTopOffset = useRef(top);

  const isVisible = useOnScreen(elementRef);
  const isVisibleRef = useRef(false);
  isVisibleRef.current = isVisible;

  useEffect(() => {
    async function changeScrollDirection() {
      scrollDirection.current = scrollDirection.current === 'down' ? 'top' : 'down';
      setScrolling(false);
    }
    async function scrollTable() {
      // to avoid that setInterval is executed multiple times
      setScrolling(true);
      await delay(delayTime);

      // If element is invisible, scroll down until element become visible
      // Afterward, wait DELAY time, reset position to 0, wait again and scroll again
      const intervalPosition = setInterval(() => {
        if (!isVisibleRef.current && scrollDirection.current === 'down') {
          return setTop(currentTop => currentTop + stepPixels);
        } else if (scrollDirection.current === 'top' && currentTopOffset.current > 0) {
          return setTop(currentTop => currentTop - stepPixels);
        }

        if (isVisibleRef.current || currentTopOffset.current <= 0) {
          clearInterval(intervalPosition);
          changeScrollDirection();
        }

        return () => {};
      }, 20);
    }

    if (!scrolling) {
      scrollTable();
    }

    return () => {};
  }, [top, setTop, scrolling, delayTime, stepPixels]);

  currentTopOffset.current = top;
  return top;
}
