import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { sport } from 'constants/enum';
import {
  getMatchId,
  getMatchHomeScored,
  getMatchAwayScored,
  getMatchSportId,
} from 'reducers';
import NumberStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberStatSlide';

const mapStateToProps = state => ({
  homeStat: getMatchHomeScored(state, getMatchId(state)),
  awayStat: getMatchAwayScored(state, getMatchId(state)),
  sportId: getMatchSportId(state, getMatchId(state)),
});

const Scored = ({
  homeStat, awayStat, sportId,
}) => {
  const intl = useIntl();

  const homeRounded = Math.round(homeStat * 10) / 10;
  const awayRounded = Math.round(awayStat * 10) / 10;

  const translationKey = [sport.basketball, sport.basketball3x3].includes(sportId) ? 't_footer_points_scored' : 't_footer_goals_scored';
  const translatedPhrase = intl.formatMessage({ id: translationKey });

  return (
    <NumberStatSlide
      statText={intl.formatMessage({ id: 't_footer_score_action_prefix' }, { action: translatedPhrase })}
      homeStat={homeRounded}
      awayStat={awayRounded}
      higherIsBetter
    />
  );
};

Scored.propTypes = {
  homeStat: PropTypes.number,
  awayStat: PropTypes.number,
  sportId: PropTypes.number,
};

Scored.defaultProps = {
  homeStat: null,
  awayStat: null,
  sportId: 1,
};

export default connect(mapStateToProps)(Scored);
