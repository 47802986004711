import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const SoccerEvents = ({
  yellowCards, redCards, substitutions,
}) => (
  <div styleName='lframe-meta__cards' className=' flex'>
    {substitutions !== null && (
      <div className='flex'>
        <svg
          styleName='substitution-icon'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          style={{ display: 'block' }}
        >
          <path
            strokeWidth='1'
            d='  M51.2,74.4c-2.7,0-5.2-0.4-7.6-1.3c-2.4-0.9-4.6-2.2-6.5-3.8c-1.9-1.6-3.5-3.5-4.8-5.7c-1.3-2.2-2.1-4.6-2.6-7.1v-0.1l7.2,5.3  l2.5-3.5l-12.5-9l-9.1,12.5l3.5,2.6l4.2-5.9c0.7,3,1.8,5.7,3.4,8.2c1.6,2.5,3.5,4.7,5.7,6.5c2.3,1.8,4.8,3.2,7.6,4.2  c2.8,1,5.8,1.6,8.9,1.6c2.2,0,4.4-0.3,6.5-0.8c2.1-0.5,4-1.3,5.9-2.3c1.8-1,3.5-2.2,5.1-3.6c1.6-1.4,2.9-3,4.1-4.7l0.1-0.1l-3.6-2.4  c-1,1.5-2.1,2.8-3.4,4c-1.3,1.2-2.7,2.2-4.3,3c-1.5,0.9-3.2,1.5-4.9,2C54.9,74.2,53.1,74.4,51.2,74.4z'
            fill='currentColor'
          />
          <path
            strokeWidth='1'
            d='M77.3,54.2l10.5-14.5l-4.1-2.9l-5.1,7.1l-0.1-0.2c-0.7-3.4-2-6.6-3.8-9.5c-1.8-2.9-4-5.4-6.6-7.6  c-2.6-2.1-5.5-3.8-8.8-5c-3.3-1.2-6.7-1.9-10.4-1.9c-2.9,0-5.7,0.4-8.3,1.2c-2.7,0.8-5.2,1.9-7.6,3.4c-2.3,1.4-4.4,3.1-6.2,5.1  c-1.9,2-3.5,4.3-4.8,6.7l4.4,2.3l0.1-0.1c1.1-2,2.4-3.9,3.9-5.5c1.6-1.7,3.3-3.1,5.2-4.3c2-1.2,4.1-2.1,6.3-2.8c2.2-0.7,4.6-1,7-1  c3.1,0,6,0.5,8.7,1.6c2.8,1.1,5.3,2.6,7.5,4.4c2.2,1.8,4,4,5.5,6.5c1.5,2.5,2.5,5.3,3.1,8.3l-8-5.8l-2.9,4L77.3,54.2z'
            fill='currentColor'
          />
        </svg>
        <span className='font-second fs-18'>{substitutions}</span>
      </div>
    )}

    {yellowCards !== null && (
      <div className='flex'>
        <svg
          styleName='card card--yellow'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          style={{ display: 'block' }}
        >
          <path
            d='M23.2,0.3h54c5,0,9,4,9,9v81c0,5-4,9-9,9h-54c-5,0-9-4-9-9v-81C14.2,4.4,18.2,0.3,23.2,0.3z'
            fill='currentColor'
          />
        </svg>
        <span className='font-second fs-18'>{yellowCards}</span>
      </div>
    )}

    {redCards !== null && (
      <div className='flex'>
        <svg styleName='card card--red' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' style={{ display: 'block' }}>
          <path
            d='M23.2,0.3h54c5,0,9,4,9,9v81c0,5-4,9-9,9h-54c-5,0-9-4-9-9v-81C14.2,4.4,18.2,0.3,23.2,0.3z'
            fill='currentColor'
          />
        </svg>
        <span className='font-second fs-18'>{redCards}</span>
      </div>
    )}
  </div>
);

SoccerEvents.propTypes = {
  yellowCards: PropTypes.number,
  redCards: PropTypes.number,
  substitutions: PropTypes.number,
};

SoccerEvents.defaultProps = {
  yellowCards: null,
  redCards: null,
  substitutions: null,
};

const SoccerEventsStyled = cssModule(SoccerEvents, style, { allowMultiple: true });
export default SoccerEventsStyled;
