import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { useImportImageNewDesign } from 'hooks/index';
import { getMatchAwayLastMResult, getMatchHomeLastMResult } from 'reducers/index';
import { AMOUNT_OF_MATCHES_RESULT_BOX } from 'constants/components';
import styles from './WDLResultBox.scss';
import ResultBox from '../resultBox/ResultBox';

const mapStateToProps = (state, ownProps) => ({
  homeStatResults: getMatchHomeLastMResult(state, ownProps.matchId, AMOUNT_OF_MATCHES_RESULT_BOX),
  awayStatResults: getMatchAwayLastMResult(state, ownProps.matchId, AMOUNT_OF_MATCHES_RESULT_BOX),
});

const WDLResultBox = ({
  homeStatResults, awayStatResults,
}) => {
  const arrow = useImportImageNewDesign('arrow-left.svg');

  return (
    <div className={`z-10 ${styles.container_form} ${styles.container_form__arrow} flex justify-center mb-10 gap-16`}>
      <div className={`flex gap-x-3 items-center bigger_first_box ${styles.form__block}`}>
        {homeStatResults.map((result, i) => (
          <ResultBox key={i} result={result} />
        ))}
        {homeStatResults.length > 0 && <img className={'z-10 h-[37%]'} src={arrow} alt={''} />}
      </div>
      <div className={`flex gap-x-3 items-center bigger_first_box ${styles.form__block}`}>
        {awayStatResults.map((result, i) => (
          <ResultBox key={i} result={result} />
        ))}
        {awayStatResults.length > 0 && <img className={'z-10 h-[37%]'} src={arrow} alt={''} />}
      </div>
    </div>
  );
};

WDLResultBox.propTypes = {
  homeStatResults: PropTypes.array,
  awayStatResults: PropTypes.array,
};

WDLResultBox.defaultProps = {
  homeStatResults: [],
  awayStatResults: [],
};

const WDLStyled = cssModule(WDLResultBox, styles);
export default connect(mapStateToProps)(WDLStyled);
