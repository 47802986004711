import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import ErrorBoundary from 'utils/ErrorBoundary';
import LoadingSpinner from 'newDesign/modules/common/components/loadingSpinner/LoadingSpinner';
import PreMatchHeaderMatchInformation
from 'newDesign/modules/preMatch/common/components/preMatchHeaderMatchInformation/PreMatchHeaderMatchInformation';
import useImportImageFacelift from 'newDesign/modules/common/hooks/ImportImage';
import { getBackgroundImageUrl } from 'newDesign/modules/common/utils/sportBackground';
import {
  getMatchGround,
  getMatchSportId, getReadyMatchId,
} from 'reducers';

import style from 'newDesign/modules/common/components/layout/fullscreenLayout/FullscreenLayout.scss';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  ground: getMatchGround(state, getReadyMatchId(state)),
});

const FullscreenLayout = ({
  main,
  matchId,
  sportId,
  ground,
}) => {
  const backgroundImage = useImportImageFacelift(getBackgroundImageUrl(sportId, ground, 'statSlides'));

  return (
    <div styleName='prematch' className={'min-h-screen flex'}>
      <ErrorBoundary fallback={<LoadingSpinner />}>
        <div className={'flex-1 relative justify-start items-center flex-col flex'}>
          <div styleName='prematch__background'>
            <img alt={''} className={'h-full w-full object-cover'} src={backgroundImage} />
          </div>
          <div styleName='bg-container' className={'z-10 h-full flex flex-col px-28 box-border'}>
            <PreMatchHeaderMatchInformation matchId={matchId} displayStadium={false} />
            <div className={'mb-8'} />
            {main}
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

FullscreenLayout.propTypes = {
  main: PropTypes.node.isRequired,
  sportId: PropTypes.number.isRequired,
  matchId: PropTypes.number.isRequired,
  ground: PropTypes.string,
};

FullscreenLayout.defaultProps = {
  ground: '',
};

const FullscreenLayoutStyled = cssModule(FullscreenLayout, style);
export default connect(mapStateToProps)(FullscreenLayoutStyled);
