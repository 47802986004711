import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import { getClientMatchId, getMatchIdVisibility } from 'reducers';

import style from 'newDesign/modules/announcement/components/clientMatchId/ClientMatchId.scss';


const mapStateToProps = (state, ownProps) => ({
  clientMatchId: getClientMatchId(state, ownProps.matchId),
  showMatchId: getMatchIdVisibility(state),
});

const ClientMatchId = ({
  clientMatchId,
  showMatchId,
}) => {
  if (showMatchId && clientMatchId) {
    return (
      <div styleName='match-status-id' className='flex items-center box-border my-2 ml-4 pl-4 uppercase'>
        {/* TODO: not sure what approach of rendering is right, TFM or intl.formatMessage */}
        {/* <span>{TranslatorCmp.t('Match')}</span> */}
        <span><TranslationFormattedMessage id='t_general_badge_match_id' /></span>
        <span className='ml-1.5'>{clientMatchId}</span>
      </div>
    );
  }
  return null;
};

ClientMatchId.propTypes = {
  clientMatchId: PropTypes.string,
  showMatchId: PropTypes.bool,
};

ClientMatchId.defaultProps = {
  clientMatchId: '',
  showMatchId: false,
};

const ClientMatchIdStyled = cssModule(ClientMatchId, style);
export default connect(mapStateToProps)(ClientMatchIdStyled);
