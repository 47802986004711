// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr__wrapper .event__meta--delay .match-status-msg{display:-ms-flexbox;display:flex}.MatchStatus__match-status-msg___1qSgp{display:none;border-right:2px solid #fff}.MatchStatus__match-status-msg___1qSgp h3{font-weight:900;font-style:italic}", ""]);
// Exports
exports.locals = {
	"match-status-msg": "MatchStatus__match-status-msg___1qSgp"
};
module.exports = exports;
