import PropTypes from 'prop-types';

import useBasketClock from 'newDesign/modules/common/hooks/clock/BasketClockHook';

const BasketClock = ({
  statusId,
  timeInfo,
}) => {
  const time = useBasketClock(
    statusId,
    timeInfo.remaining,
    timeInfo.running,
  );

  return time;
};

BasketClock.propTypes = {
  statusId: PropTypes.number,
  timeInfo: PropTypes.object,

};

BasketClock.defaultProps = {
  statusId: null,
  timeInfo: {
    played: '0',
    remaining: '0',
    running: false,
  },
};

export default BasketClock;
