/**
 * Darkens a color by a specified percentage.
 *
 * @param {string} color - The color to be darkened in hexadecimal format (e.g., '#RRGGBB').
 * @param {number} amount - The percentage by which to darken the color (0-100).
 * @param {string} [outputFormat] - The format of the darkened color ('hex' or 'rgba').
 * @param {number} [alphaChannel=1] - The alpha channel value for 'rgba' format (0-1).
 * @returns {string} The darkened color in hexadecimal format.
 */
function darkenColor(color, amount, outputFormat, alphaChannel = 1) {
  /**
   * Ensure that 'amount' is within the valid range (0-100).
   *
   * @type {number}
   */
  const amountNumber = Math.min(100, Math.max(0, amount));

  /**
   * Remove the '#' character if it's included in the color.
   *
   * @type {string}
   */
  const colorCode = color.replace(/#/g, '');

  /**
   * Parse the color components (red, green, blue).
   *
   * @type {number}
   */
  let r = parseInt(colorCode.slice(0, 2), 16);
  let g = parseInt(colorCode.slice(2, 4), 16);
  let b = parseInt(colorCode.slice(4, 6), 16);

  /**
   * Calculate the new color components after darkening.
   *
   * @type {number}
   */
  r = Math.round(r * (1 - amountNumber / 100));
  g = Math.round(g * (1 - amountNumber / 100));
  b = Math.round(b * (1 - amountNumber / 100));

  /**
   * Ensure that the color components is within the valid range (0-255).
   *
   * @type {number}
   */
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  if (outputFormat === 'rgba') {
    /**
     * Convert the color components to 'rgba' format and return the darkened color.
     *
     * @type {string}
     */
    return `rgba(${r},${g},${b},${alphaChannel})`;
  }

  /**
   * Convert the color components back to hexadecimal format.
   *
   * @type {string}
   */
  r = r.toString(16).padStart(2, '0');
  g = g.toString(16).padStart(2, '0');
  b = b.toString(16).padStart(2, '0');

  /**
   * Combine the components and return the darkened color in hexadecimal format.
   *
   * @type {string}
   */
  return `#${r}${g}${b}`;
}

export default darkenColor;
