import Root from 'containers/Root';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';


const countOccurences = (str, searchString) => {
  const regex = new RegExp(searchString, 'g');
  return (str.match(regex) || []).length;
};


const Client = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const location = useLocation();

  let qUrl = null;
  let queryStr = location.search;

  const cond1 = countOccurences(queryStr, 'streamUrl=');
  const cond2 = countOccurences(queryStr, '\\[');
  const cond3 = countOccurences(queryStr, '\\]');

  // get url inside []
  if (cond1 === 1 && cond2 === 1 && cond3 === 1) {
    const startIdx = queryStr.indexOf('streamUrl=');
    const firstBracket = queryStr.indexOf('[');
    const endIdx = queryStr.indexOf(']');
    qUrl = queryStr.slice(firstBracket + 1, endIdx);

    queryStr = queryStr.slice(0, startIdx) + queryStr.slice(endIdx + 2);
  }

  const searchParams = new URLSearchParams(queryStr);

  const parseNumber = (str, defaultVal = null) => {
    const num = parseInt(searchParams.get(str), 10);
    return (typeof num === 'number' && !Number.isNaN(num)) ? num : defaultVal;
  };

  const parseBool = (str, defaultVal = undefined) => (
    parseInt(searchParams.get(str), 10) >= 1
      ? true
      : parseInt(searchParams.get(str), 10) === 0
        ? false
        : defaultVal
  );

  const qMMStatus = parseNumber('mmStatus');
  const qAutoStage = parseNumber('aStage', 0);
  const qOddsRot = parseNumber('oddsRot', 0);
  const qAutoSport = parseNumber('as');
  const qStatSlide = parseNumber('ss');
  const qSRDev = parseNumber('srDev', 0);
  const newFE = parseNumber('new', 1);
  /*
    newFE = 1 = force new react comps for all
    newFE = 2 = prematch new, oddspanel old
  */

  const qLastMatchId = parseNumber('lm');
  const qHOTD = parseNumber('hotd');
  let qMatchId = parseNumber('matchId');
  let qStage = parseNumber('stage');
  let qLayout = parseNumber('layout');
  let qTmpStage = parseNumber('tStage');

  // short versions
  qMatchId = parseNumber('m', qMatchId);
  qStage = parseNumber('s', qStage);
  qLayout = parseNumber('l', qLayout);
  if (parseNumber('x')) {
    qTmpStage = -1;
  }

  const qMirrorProd = parseBool('useProd');
  const qOldFeeds = parseBool('oldFeeds');
  const qPlayerUI = parseBool('ui');
  const qRefresh = parseBool('refresh', true);
  const qLMT = parseBool('lmt', undefined);
  const qLightMode = parseBool('lightMode', undefined);
  const qRelease = parseBool('lsrelease', false);
  const qTT = parseBool('tt', false);
  const qStopTime = parseBool('st', false);

  const qLang = searchParams.get('lang');
  const qFeed = searchParams.get('fnFeed');
  const qReplay = searchParams.get('replay');

  useEffect(() => {
    dispatch({
      type: 'START_LCR',
      payload: {
        userId: parseInt(userId, 10),
        newFE,
        qMatchId,
        qStage,
        qTmpStage,
        qUrl,
        qLang,
        qFeed,
        qMirrorProd,
        qAutoStage,
        qLayout,
        qMMStatus,
        qReplay,
        qDateNow: parseInt(Date.now() / 1000, 10),
        qLMT,
        qLightMode,
        qRelease,
        qRefresh,
        qOddsRot,
        qSRDev,
        qAutoSport: qTT === true ? 20 : qAutoSport,
        qStopTime,
        qLastMatchId,
        qPlayerUI,
        qOldFeeds,
        qStatSlide,
        qHOTD,
      },
    });

    return () => {};
  }, [
    dispatch,
    newFE,
    qAutoSport,
    qAutoStage,
    qFeed,
    qHOTD,
    qLMT,
    qLang,
    qLastMatchId,
    qLayout,
    qLightMode,
    qMMStatus,
    qMatchId,
    qMirrorProd,
    qOddsRot,
    qOldFeeds,
    qPlayerUI,
    qRefresh,
    qRelease,
    qReplay,
    qSRDev,
    qStage,
    qStatSlide,
    qStopTime,
    qTT,
    qTmpStage,
    qUrl,
    userId,
  ]);

  return <Root />;
};

export default Client;
