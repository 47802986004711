import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/PreMatchCompetitors.scss';
import DefaultSeparator from '../../../defaultSeparator/DefaultSeparator';
import Ranking from '../components/ranking/Ranking';
import TennisFlags from '../components/tennisFlags/TennisFlags';

const TennisSingle = ({ competitors }) => {
  const homeCompetitorName = competitors.home[0].name;
  const awayCompetitorName = competitors.away[0].name;

  return (
    <Fragment>
      <div className={`${styles.prematch__competitors} z-10 mb-8 fs-18 flex justify-center items-start w-full`}>
        <div className={'flex justify-center items-center flex-col w-2/5'}>
          <TennisFlags teams={competitors.home} />
          <h2 className={'font-main--bold uppercase items-center text-center flex'}>{homeCompetitorName}</h2>
        </div>
        <div className={'flex flex-col w-1/5 justify-center items-center h-full'}>
          <DefaultSeparator />
        </div>
        <div className={'flex justify-center items-center flex-col w-2/5'}>
          <TennisFlags teams={competitors.away} />
          <h2 className={'font-main--bold uppercase items-center text-center flex'}>{awayCompetitorName}</h2>
        </div>
      </div>
      <Ranking />
    </Fragment>
  );
};

TennisSingle.propTypes = {
  competitors: PropTypes.object,
};

TennisSingle.defaultProps = {
  competitors: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};

export default TennisSingle;
