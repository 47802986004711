import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TickerClockContainer from 'newDesign/modules/common/components/ticker/tickerClockContainer/TickerClockContainer';
import PeriodInfo from 'newDesign/modules/common/components/ticker/periodInfo/PeriodInfo';
import darken from 'newDesign/modules/common/utils/darken';
import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import { SEC } from 'constants/app';
import {
  useImportImageNewDesign,
  useInterval,
} from 'hooks/index';
import {
  getClientMatchId,
  showMatchIdComp,
  getLastMatch,
  getMatchCurPeriod,
  getMatchSportId,
  getSeparatorColor,
} from 'reducers/index';

import styles from 'newDesign/modules/common/components/ticker/styles/MatchTickerStyles.scss';


const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getLastMatch(state)),
  lastMatch: getLastMatch(state),
  clientMatchId: getClientMatchId(state, getLastMatch(state)),
  showMatchIdCmp: showMatchIdComp(state, getClientMatchId(state, getLastMatch(state))),
  bgColor: getSeparatorColor(state),
  period: getMatchCurPeriod(state, getLastMatch(state)),
});
const AnimatedMatchTickerHeader = ({
  sportId, lastMatch, clientMatchId, showMatchIdCmp, bgColor, period,
}) => {
  const [animation, setAnimation] = useState(false);
  const sportIcon = useImportImageNewDesign(`sport-icons-common/sport-icon-${sportId}.svg`);

  // Change between match ID / match status every 30 seconds
  useInterval(() => {
    setAnimation(!animation);
  }, 30 * SEC);

  let animatedStatus;
  let animatedMatchId;
  let animatedMatchIdBackground;
  if (animation) {
    animatedStatus = styles.animated_match_status_fadeIn;
    animatedMatchId = styles.animated_match_id_fadeOut;
    animatedMatchIdBackground = styles.animated_match_bg_fadeOut;
  } else {
    animatedStatus = styles.animated_match_status_fadeOut;
    animatedMatchId = styles.animated_match_id_fadeIn;
    animatedMatchIdBackground = styles.animated_match_bg_fadeIn;
  }

  let matchIdInformation;
  if (showMatchIdCmp) {
    matchIdInformation = (
      <Fragment>
        <span className={'fs-20 font-second'}><TranslationFormattedMessage id='t_general_badge_match_id' /></span>
        <h3 className={'fs-20 font-second--bold ml-1'}>{clientMatchId || lastMatch}</h3>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={`${styles.matchticker__game_info} flex items-center`}>
        {sportId && sportIcon && (
          <div
            className={`${styles.box_shadow} icon-circle my-1 z-0 z-10`}
            style={{
              background: `linear-gradient(90deg, ${bgColor} 0%, ${darken(bgColor, 10)} 100%)`,
              height: '2.5vw',
            }}
          >
            <img className={'h-full'} alt={'sportIcon'} src={sportIcon} />
          </div>
        )}
        <div className={`overflow-hidden ${animatedMatchIdBackground}`}>
          <div className={`flex items-center ${animatedMatchId}`}>
            {matchIdInformation}
          </div>
        </div>
      </div>
      <div className={`${styles.matchticker__period_info} flex items-center`}>
        <div
          className={`${styles.matchticker__game_status} ${animatedStatus} flex py-2 items-center justify-end whitespace-nowrap fs-20`}
        >
          <TickerClockContainer lastMatchId={lastMatch} />
          <PeriodInfo sportId={sportId} period={period} />
        </div>
      </div>
    </Fragment>
  );
};

AnimatedMatchTickerHeader.propTypes = {
  sportId: PropTypes.number.isRequired,
  lastMatch: PropTypes.number.isRequired,
  clientMatchId: PropTypes.number,
  showMatchIdCmp: PropTypes.bool,
  bgColor: PropTypes.string.isRequired,
  period: PropTypes.number,
};

AnimatedMatchTickerHeader.defaultProps = {
  period: null,
  clientMatchId: null,
  showMatchIdCmp: true,
};
export default connect(mapStateToProps)(AnimatedMatchTickerHeader);
