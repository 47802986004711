// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OddsPanel__odds-block___2Vmrq{padding:.8vw;box-sizing:border-box;background:#e1e3e4;gap:.8vw}#lcr__wrapper .OddsPanel__odds-panel___UJ2s5{width:22.5%;background:#000}", ""]);
// Exports
exports.locals = {
	"odds-block": "OddsPanel__odds-block___2Vmrq",
	"odds-panel": "OddsPanel__odds-panel___UJ2s5"
};
module.exports = exports;
