import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash';

import Match from 'newDesign/modules/announcement/components/match/Match';
import NoScheduledMatches from 'newDesign/modules/announcement/components/noScheduledMatches/NoScheduledMatches';
import TomorrowMatchLabel from 'newDesign/modules/announcement/components/tomorrowMatchLabel/TomorrowMatchLabel';
import { getIsNextMatchesLoaded, getNextMatches } from 'reducers';

const checkIsTomorrowMatchDate = ts => {
  const msPerDay = 8.64e7;
  // Copy dates so don't mess them up
  const x0 = new Date();
  const x1 = new Date(parseInt(ts, 10) * 1000);

  // Set to noon - avoid DST errors
  x0.setHours(12, 0, 0);
  x1.setHours(12, 0, 0);

  return Math.round((x1 - x0) / msPerDay) === 1;
};

const mapStateToProps = state => ({
  nextMatches: getNextMatches(state),
  isNextMatchesLoaded: getIsNextMatchesLoaded(state),
});

const MatchList = ({ nextMatches, isNextMatchesLoaded }) => {
  if (!isNextMatchesLoaded) {
    return (<React.Fragment />);
  }

  if (_isEmpty(nextMatches)) {
    return <NoScheduledMatches />;
  }

  let tomorrowLabelRendered = false;

  return (
    <Fragment>
      {
        Object.values(nextMatches).map(match => {
          if (checkIsTomorrowMatchDate(match.timestamp) && !tomorrowLabelRendered) {
            tomorrowLabelRendered = true;

            return (
              <Fragment key={match.matchId}>
                <TomorrowMatchLabel />
                <Match matchId={match.matchId} />
              </Fragment>
            );
          }

          return (
            <Match key={match.matchId} matchId={match.matchId} />
          );
        })
      }
    </Fragment>
  );
};

MatchList.propTypes = {
  nextMatches: PropTypes.arrayOf(PropTypes.object),
  isNextMatchesLoaded: PropTypes.bool.isRequired,
};

MatchList.defaultProps = {
  nextMatches: [],
};

export default connect(mapStateToProps)(MatchList);
