import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import {
  getLanguage,
  getTranslations,
  getIsTranslationsAvailable,
} from '../reducers';
import { LANG_EN } from '../constants/app';
import intlOnError from './intlOnError';

const mapStateToProps = state => ({
  lang: getLanguage(state),
  messages: getTranslations(state),
  isTranslationsAvailable: getIsTranslationsAvailable(state),
});

const AsyncIntlProvider = ({
  children, lang, messages, isTranslationsAvailable,
}) => (
  <IntlProvider
    messages={messages}
    locale={lang}
    defaultLocale={LANG_EN}
    onError={error => {
      intlOnError(error, isTranslationsAvailable);
    }}
  >
    {children}
  </IntlProvider>
);

AsyncIntlProvider.propTypes = {
  children: PropTypes.element,
  lang: PropTypes.string,
  messages: PropTypes.object,
  isTranslationsAvailable: PropTypes.bool,
};

AsyncIntlProvider.defaultProps = {
  children: null,
  lang: LANG_EN,
  messages: {},
  isTranslationsAvailable: false,
};

export default connect(
  mapStateToProps,
)(AsyncIntlProvider);
