// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Match__prematch__teams___7zY-p .Match__active___fdoBz{font-family:\"Roboto\",sans-serif;font-weight:500}.Match__prematch__teams___7zY-p span:not(.Match__active___fdoBz){color:#a9aaaa}.Match__prematch-separator___63iZr{background:#fff;opacity:.1;height:2px;width:100%}.Match__prematch__scores___11bg6>div{border:1px solid #585a60;text-align:center}.Match__prematch__scores___11bg6>div>div{width:2.3vw;height:2.3vw;box-sizing:border-box}.Match__prematch__scores___11bg6>div>div:first-child{border-bottom:1px solid #585a60}.Match__prematch__scores___11bg6 .Match__active___fdoBz{background:#3c3f45;box-shadow:4px 4px 4px 0px rgba(0,0,0,.1) inset,0px 4px 4px 0px rgba(0,0,0,.25)}.Match__prematch__scores___11bg6 div:not(.Match__active___fdoBz){background:rgba(0,0,0,.1);-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px)}", ""]);
// Exports
exports.locals = {
	"prematch__teams": "Match__prematch__teams___7zY-p",
	"active": "Match__active___fdoBz",
	"prematch-separator": "Match__prematch-separator___63iZr",
	"prematch__scores": "Match__prematch__scores___11bg6"
};
module.exports = exports;
