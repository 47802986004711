import 'semantic-ui-css/components/grid.min.css';
import 'styles/tailwind.css';
import 'styles/GridOverride.css';
import 'babel-polyfill'; // NOTE: 'babel-polyfill' needed for redux-saga generator function
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import styles from 'styles/app.scss';
import 'newDesign/styles/style.scss';
import configureStore from './stores';
import Routes from './Routes';
import AsyncIntlProvider from './localization/AsyncIntlProvider';

const app = document.createElement('div');
app.className = styles.app;
document.body.appendChild(app);
const store = configureStore(window.__INITIAL_STATE__);
const root = ReactDOM.createRoot(document.querySelector('div'));

const App = () => (
  <HashRouter>
    <Provider store={store}>
      <AsyncIntlProvider>
        <Routes />
      </AsyncIntlProvider>
    </Provider>
  </HashRouter>
);

root.render(<App />);
