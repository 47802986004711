import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useFilterEvents from 'hooks/FilterEvents';
import useOnScrollVertical from 'newDesign/modules/common/hooks/OnScrollVertical';
import TimelineEvent from 'newDesign/modules/break/components/statSlides/timeline/timelineEvent/TimelineEvent';
import { SEC } from 'constants/app';
import {
  getMatchEvents,
  getMatchPeriodLength,
  getReadyMatchId,
} from 'reducers';

import styles from 'newDesign/modules/break/components/statSlides/timeline/timelineContent/TimelineContent.scss';

const mapStateToProps = state => ({
  events: getMatchEvents(state, getReadyMatchId(state)),
  periodLength: getMatchPeriodLength(state, getReadyMatchId(state)),
});

const TimelineContent = ({ events, periodLength }) => {
  const scrollEndRef = useRef(null);
  const scrollPosition = useOnScrollVertical(scrollEndRef, 1, 4 * SEC);
  const filterEvents = useFilterEvents(events, periodLength);

  return (
    <div className={'overflow-hidden'}>
      <div className={'z-10 relative'} style={{ 'top': -scrollPosition + 'px' }}>
        <div className={`${styles.timeline_body} flex flex-col justify-center items-center`}>
          {filterEvents.map(event => (
            <TimelineEvent key={event.id} event={event} />
          ))}
        </div>
        <div className={'mb-2'} />
        <div ref={scrollEndRef} />
      </div>
    </div>
  );
};

TimelineContent.propTypes = {
  events: PropTypes.object,
  periodLength: PropTypes.number,
};

TimelineContent.defaultProps = {
  events: null,
  periodLength: 45,
};
export default connect(mapStateToProps)(TimelineContent);
