import React from 'react';
import { getLastMatch, getMatchSportId } from 'reducers/index';
import { sport } from 'constants/enum';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DefaultTickerTable from '../tickerTables/defaultTickerTable/DefaultTickerTable';
import SportsWithServiceTickerTable from '../tickerTables/sportsWithServiceTickerTable/SportsWithServiceTickerTable';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getLastMatch(state)),
});

/** Match results are displayed in different ways - it is sport-related logic.
 * DefaultTickerTable is responsible for displaying results on the left side of the table.
 * Example sports related to this logic: soccer, esoccer, basketball, ice hockey etc */

const TickerTableContainer = ({ sportId }) => {
  if ([sport.volleyball, sport.beach_volley, sport.table_tennis, sport.tennis].includes(sportId)) {
    return <SportsWithServiceTickerTable />;
  } else {
    return <DefaultTickerTable />;
  }
};

TickerTableContainer.propTypes = {
  sportId: PropTypes.number,
};

TickerTableContainer.defaultProps = {
  sportId: 0,
};

export default connect(mapStateToProps)(TickerTableContainer);
