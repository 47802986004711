import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isNil as _isNil } from 'lodash';

import Flags from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/sports/tennisManager/components/flags/Flags';

const TennisFlags = ({ teams }) => (
  <Fragment>
    {teams.map(team => {
      const uid = team.countryCodeA2 ? team.countryCodeA2 : team.uniqueId;
      const crestType = team.countryCodeA2 ? 'flag-crest' : '';

      if (_isNil(uid)) {
        return null;
      }

      return (
        <Fragment key={team.uniqueId}>
          <Flags uid={uid} typeClassName={crestType} />
        </Fragment>
      );
    })}
  </Fragment>
);

TennisFlags.propTypes = {
  teams: PropTypes.array.isRequired,
};

export default TennisFlags;
