import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import style from 'styles/components/BettingSuggestions/BettingSuggestions.scss';

const Market = cssModule(({ marketName, outcomes, secondaryColor }) => {
  if (!marketName || !(outcomes && outcomes.length)) {
    return null;
  }

  return (
    <div styleName='marketContainer'>
      <div styleName='market'>
        <div styleName='oddsMarket' style={{ color: secondaryColor, whiteSpace: 'nowrap' }}>
          {marketName}
        </div>
        <div styleName='outcomesRow'>
          {outcomes.map((outcome, i) => {
            let oddsStyle = {};

            if (outcomes.length > 2) {
              oddsStyle = {
                marginLeft: i ? 20 : 0,
                flex: i === 1 ? 0 : 1,
                overflow: 'hidden',
              };
            } else {
              oddsStyle = {
                marginLeft: i ? 60 : 0,
                flex: 1,
              };
            }

            return (
              <div
                styleName='oddsOutcome'
                key={outcome.name}
                style={oddsStyle}
              >
                <div styleName='oddsRow'>
                  {outcome.odds}
                </div>

                <div styleName='outcomeRow'>
                  {outcome.abbr || outcome.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}, style);

Market.propTypes = {
  outcomes: PropTypes.array,
  marketsName: PropTypes.string,
};

Market.defaultProps = {
  outcomes: null,
  marketName: '',
  secondaryColor: '#3F3f3f',
};

export default Market;
