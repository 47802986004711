// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Logo__logo___2-vkT{padding-top:20px;padding-bottom:20px}.Logo__logo___2-vkT img{width:100%;max-height:100%;max-width:380px}.Logo__logoAnnouncement___2MowZ img{width:100%;max-height:100%;max-width:380px}.Logo__logoScoreboard___KTM8P{padding-top:20px;height:100%;padding-bottom:20px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.Logo__logoScoreboard___KTM8P img{width:100%;max-height:100%;max-width:380px}", ""]);
// Exports
exports.locals = {
	"logo": "Logo__logo___2-vkT",
	"logoAnnouncement": "Logo__logoAnnouncement___2MowZ",
	"logoScoreboard": "Logo__logoScoreboard___KTM8P"
};
module.exports = exports;
