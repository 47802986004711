// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Root__root___8l0e9{display:-ms-flexbox;display:flex;-ms-flex:1;flex:1;-ms-flex-direction:column;flex-direction:column;position:relative;overflow:hidden;color:#fff;background:#000002}", ""]);
// Exports
exports.locals = {
	"root": "Root__root___8l0e9"
};
module.exports = exports;
