import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import Logo from 'newDesign/modules/common/components/logo/Logo';
import ErrorBoundary from 'utils/ErrorBoundary';
import { getLogoBgColor, getLogoUrl } from 'reducers/index';

import styles from 'newDesign/modules/live/components/footer/Footer.scss';

const mapStateToProps = state => ({
  logoBgColor: getLogoBgColor(state),
  logoUrl: getLogoUrl(state),
});

const SidebarBottomLayout = ({
  main, bottom, sidebar, logoUrl, logoBgColor,
}) => (
  <div className={'min-h-screen flex'}>
    <div className='flex-1 relative justify-start flex max-w-full overflow-hidden'>
      <div styleName='lframe__container' className='flex flex-col box-border'>
        <ErrorBoundary fallback={<div />}>
          {main}
        </ErrorBoundary>
        <div className='flex max-w-full flex-1 flex-col'>
          <ErrorBoundary fallback={<div />}>
            {bottom}
          </ErrorBoundary>
        </div>
      </div>

      <div className='odds-panel'>
        <ErrorBoundary fallback={<div />}>
          <div
            className='flex justify-center items-center p-[0.8vw]'
            style={{
              background: `${logoBgColor}`,
            }}
          >
            <Logo logoClassName='odds-panel-logo' logoUrl={logoUrl} />
          </div>
          {sidebar}
        </ErrorBoundary>
      </div>
    </div>
  </div>
);

SidebarBottomLayout.propTypes = {
  main: PropTypes.element.isRequired,
  bottom: PropTypes.element,
  sidebar: PropTypes.element,
  logoUrl: PropTypes.string.isRequired,
  logoBgColor: PropTypes.string.isRequired,
};

SidebarBottomLayout.defaultProps = {
  bottom: null,
  sidebar: null,
};

const SidebarBottomLayoutStyled = cssModule(SidebarBottomLayout, styles);
export default connect(mapStateToProps)(SidebarBottomLayoutStyled);
