import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import { isEnded, isLive } from 'utils/matchstatus';
import Fouls from 'newDesign/modules/live/components/footer/footerBody/tournamentInfo/basketballFutsal/Fouls';
import Suspensions from 'newDesign/modules/live/components/footer/footerBody/tournamentInfo/iceHockey/Suspensions';
import fallbackDefaultFlag from 'newDesign/styles/images/default-flag.png';
import { matchStatusIds, seasonType, sport } from 'constants/enum';
import {
  getRealCategoryName,
  getTournamentName,
  getTournamentFlag,
  getMatchStadium,
  getMatchTournament,
  getMatchRound,
  getMatchRoundName,
  getReadyMatchId,
  getMatchSportId,
  getMatchFoulsTotal,
  getMatchDirectFoulsCurPer,
  getMatchStatus,
  getMatchTimeInfo,
} from 'reducers';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const mapStateToProps = state => ({
  realCategoryName: getRealCategoryName(state, getReadyMatchId(state)),
  tournamentName: getTournamentName(state, getReadyMatchId(state)),
  tournamentFlag: getTournamentFlag(state, getReadyMatchId(state)),
  stadium: getMatchStadium(state, getReadyMatchId(state)),
  round: getMatchRound(state, getReadyMatchId(state)),
  roundName: getMatchRoundName(state, getReadyMatchId(state)),
  tournament: getMatchTournament(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  foulsTotal: getMatchFoulsTotal(state, getReadyMatchId(state)),
  directFouls: getMatchDirectFoulsCurPer(state, getReadyMatchId(state)),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
  timeInfo: getMatchTimeInfo(state, getReadyMatchId(state)),
});

const TournamentInfo = ({
  tournamentName,
  tournamentFlag,
  tournament,
  sportId,
  stadium,
  realCategoryName,
  round,
  roundName,
  foulsTotal,
  directFouls,
  matchStatusId,
  timeInfo,
}) => {
  const intl = useIntl();

  const tournamentFlagSource = tournamentFlag === 'international'
    ? fallbackDefaultFlag
    : `https://img.sportradar.com/ls/crest/medium/${tournamentFlag}.png`;

  let tourName = tournamentName;

  try {
    if (!tournament) {
      //
    } else if (
      tournament.seasontype
      && tournament.seasontype === seasonType.group_stage.toString()
    ) {
      // TODO:substring search seasontypename?
      const matchDayTranslated = intl.formatMessage({ id: 't_general_match_day' });
      let mDay = (round ? (` | ${matchDayTranslated} ${round}`) : '');

      if (roundName !== round) {
        mDay = roundName;
      }

      const seasonTypeName = tournamentName.includes(tournament.seasontypename) ? '' : `, ${tournament.seasontypename}`;
      tourName = tournamentName + seasonTypeName + mDay;
    } else if (
      tournament.seasontype
      && (tournament.seasontype === seasonType.cup.toString()
      || tournament.seasontype === seasonType.qualification.toString()
      )
    ) {
      const loc = ([sport.table_tennis, sport.tennis].includes(sportId) && stadium && stadium.city && stadium.country)
        ? ' | ' + stadium.city + ', ' + stadium.country
        : '';

      if (sportId === sport.basketball || sportId === sport.ice_hockey) {
        tourName = tournamentName;
        if (roundName) {
          tourName += ' | ' + roundName;
        }
      } else {
        tourName = tournamentName + loc;
        if (roundName) {
          tourName += ' | ' + roundName;
        }
      }
    }

    if (sportId === sport.esoccer && realCategoryName !== tournamentName) {
      tourName = realCategoryName + ', ' + tourName;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  let basketFoulsHome = null;
  let basketFoulsAway = null;
  let futsalFoulsHome = null;
  let futsalFoulsAway = null;
  let suspensionsHome = null;
  let suspensionsAway = null;

  const isBasketballMatch = [sport.basketball, sport.ebasketball].includes(sportId);
  const isIceHockeyMatch = sport.ice_hockey === sportId;
  const isFutsalMatch = sport.futsal === sportId;
  const isLiveMatch = isLive(matchStatusId) && matchStatusId !== matchStatusIds.pause;

  if (isBasketballMatch && isLiveMatch) {
    basketFoulsHome = <Fouls fouls={foulsTotal} side='home' />;
    basketFoulsAway = <Fouls fouls={foulsTotal} side='away' />;
  } else if (isIceHockeyMatch && !isEnded(matchStatusId)) {
    suspensionsHome = <Suspensions timeInfo={timeInfo} matchStatusId={matchStatusId} side='home' />;
    suspensionsAway = <Suspensions timeInfo={timeInfo} matchStatusId={matchStatusId} side='away' />;
  } else if (isFutsalMatch && !isEnded(matchStatusId)) {
    futsalFoulsHome = <Fouls fouls={directFouls} side='home' />;
    futsalFoulsAway = <Fouls fouls={directFouls} side='away' />;
  }

  return (
    <Fragment>
      {basketFoulsHome}
      {futsalFoulsHome}
      {suspensionsHome}
      <div className='flex justify-center flex-1'>
        <div className='flex justify-center items-center text-center'>
          <img src={tournamentFlagSource} alt={'flag'} />
          <span className='fs-18 font-second'>{tourName}</span>
        </div>
      </div>
      {basketFoulsAway}
      {futsalFoulsAway}
      {suspensionsAway}
    </Fragment>

  );
};

TournamentInfo.propTypes = {
  tournament: PropTypes.object,
  round: PropTypes.number,
  roundName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stadium: PropTypes.object,
  tournamentName: PropTypes.string,
  tournamentFlag: PropTypes.string,
  sportId: PropTypes.number.isRequired,
  realCategoryName: PropTypes.string,
  foulsTotal: PropTypes.object,
  directFouls: PropTypes.object,
  matchStatusId: PropTypes.number,
  timeInfo: PropTypes.object,
};

TournamentInfo.defaultProps = {
  tournament: {},
  tournamentName: '',
  tournamentFlag: '',
  stadium: {},
  realCategoryName: '',
  round: null,
  roundName: null,
  foulsTotal: null,
  directFouls: null,
  matchStatusId: matchStatusIds.not_started,
  timeInfo: {},
};

const TournamentInfoStyled = cssModule(TournamentInfo, style);
export default connect(mapStateToProps)(TournamentInfoStyled);
