import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import { getLastMatch, getMatchSportId, getSeparatorColor } from 'reducers/index';
import { sport } from 'constants/enum';
import styles from '../styles/MatchTickerStyles.scss';
import TickerTableContainer from '../tickerTableContainer/TickerTableContainer';
import AnimatedMatchTickerHeader from '../animatedMatchTickerHeader/AnimatedMatchTickerHeader';

const mapStateToProps = state => ({
  bgColor: getSeparatorColor(state),
  sportId: getMatchSportId(state, getLastMatch(state)),
});

// Additional class needs to be applied in case of default ticker - with results on the left side
const isTickerDefault = sportId => {
  if (![sport.tennis, sport.beach_volley, sport.table_tennis, sport.tennis].includes(sportId)) {
    return styles.matchticker__default;
  }
  return '';
};

const MatchTicker = ({ bgColor, sportId }) => (
  <div
    className={`${styles.matchticker} ${isTickerDefault(sportId)} z-10 flex flex-1 flex-col relative box-border overflow-hidden`}
    style={{
      background: `linear-gradient(90deg, ${bgColor} 0%, #0B111F 60%, #0B111E 700%, #000000 100%)`,
    }}
  >
    <div className={`${styles.matchticker__arrow_bg} flex justify-center`}>
      <svg className='h-full' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' style={{ display: 'block' }}>
        <path d='M23.7,0.5H5l34.4,49.9L5,100.9h18.8l35.8-50.5L23.7,0.5z' fill='currentColor' />
        <path d='M60.9,0.2H42.1l34.4,49.9l-34.4,50.5h18.8l35.8-50.5L60.9,0.2z' fill='currentColor' />
      </svg>
    </div>
    <div className={'flex justify-between items-center'}>
      <AnimatedMatchTickerHeader />
    </div>
    <div className={`${styles.matchticker__tbody} flex flex-col flex-1 justify-end`}>
      <TickerTableContainer />
    </div>
  </div>
);

MatchTicker.propTypes = {
  bgColor: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
};

const MatchTickerStyled = cssModule(MatchTicker, styles);
export default connect(mapStateToProps)(MatchTickerStyled);
