export default {
  adsFrequency: false,
  layout: 'single',
  scoreboard: 'disable',
  detailedScoreboard: 'disable',
  momentum: 'disable',
  tabsPosition: 'disable',
  dbgData: null,
  sirsolution: 'lmtdemo',
  // disablePitchClock: null,
  collapseTo: 'disable',
  showOdds: false,
  disablePitchStripes: false,
  disablePitchNoise: true,
  disablePitchSpotlights: true,
  disableFooterStats: true,
  disableWidgetHeader: true,
  forceTeamInvert: false,
  silent: true,
  brandingVariant: '3',
  disableSpottingLine: false,
  dbg: false,
  dataByLogoColor: null,
  flashLogoOnSlide: true,
};
