import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import style from 'newDesign/modules/common/components/oddsChangeIndicator/OddsChangeIndicator.scss';

const OddsChangeIndicator = ({ changeIndicatorDirection, showIndicator }) => {
  const [showArrows, setShowArrows] = useState([false, false, false]);
  const [indicatorStyle, setIndicatorStyle] = useState('');
  const timeoutRef = useRef([]);

  useEffect(() => {
    const loopAnimation = () => {
      // Initial displaying state of each of three animation arrow segments
      // Animation consists of three vertically aligned arrows
      setShowArrows([false, false, false]);

      // Timeout order between animation frames can be reversed by changeIndicatorDirection property
      const timeoutOrder = [0, 200, 400, 600, 800, 1000];

      if (changeIndicatorDirection === 'up') {
        setIndicatorStyle('odd-change-arrow--up');
        timeoutOrder.reverse();
      } else if (changeIndicatorDirection === 'down') {
        setIndicatorStyle('odd-change-arrow--down');
      }

      const setShowArrowsTimeout = (index, values) => {
        timeoutRef.current[index] = setTimeout(() => setShowArrows(values), timeoutOrder[index]);
      };

      // This code controls one animation cycle consisting of six frames
      // Each bool value inside the array displays the state of one of three animation arrow segments
      setShowArrowsTimeout(0, [false, false, false]);
      setShowArrowsTimeout(1, [true, false, false]);
      setShowArrowsTimeout(2, [true, true, false]);
      setShowArrowsTimeout(3, [true, true, true]);
      setShowArrowsTimeout(4, [false, true, true]);
      setShowArrowsTimeout(5, [false, false, true]);

      // Repeat the animation after a delay
      timeoutRef.current[6] = setTimeout(loopAnimation, 1200);
    };

    const timeoutReference = timeoutRef.current;

    loopAnimation();

    return () => {
      timeoutReference.forEach(timeoutId => clearTimeout(timeoutId));
    };
  }, [changeIndicatorDirection]);

  return (
    <div styleName={`odd-change-arrow ${indicatorStyle} ${showIndicator ? 'show-indicator' : 'hide-indicator'}`} className='odd-change-arrow flex flex-col justify-center box-border absolute'>
      {showArrows.map((show, index) => (
        <svg
          key={index}
          className={`transition-opacity duration-200 ease-in ${show ? 'opacity-100' : 'opacity-0'}`}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          style={{ display: 'block' }}
        >
          <path d='M97.7,73.7l1.4-1.6l-1.4-1.6l-46-49.7L50,19l-1.7,1.8l-46,49.7l-1.4,1.6l1.4,1.6l5.1,5.5L9.1,81l1.7-1.8  L50,36.8l39.2,42.3l1.7,1.8l1.7-1.8L97.7,73.7z' fill='currentColor' />
        </svg>
      ))}
    </div>
  );
};

OddsChangeIndicator.propTypes = {
  changeIndicatorDirection: PropTypes.string,
  showIndicator: PropTypes.bool,
};

OddsChangeIndicator.defaultProps = {
  changeIndicatorDirection: '',
  showIndicator: false,
};

const OddsChangeIndicatorStyled = cssModule(OddsChangeIndicator, style, { allowMultiple: true });
export default OddsChangeIndicatorStyled;
