import React from 'react';
import LivePlayer from 'components/LivePlayer/LivePlayer';
import {
  getMarketingVideoCachedUrl,
} from 'reducers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setStaticVideoEnded, setStaticVideoPlaying } from 'actions/stages';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  marketingVideoUrl: getMarketingVideoCachedUrl(state),
});

const mapDispatchToProps = dispatch => ({
  videoEnded: bindActionCreators(setStaticVideoEnded, dispatch),
  videoPlaying: bindActionCreators(setStaticVideoPlaying, dispatch),
});

export const MarketingVideo = ({ marketingVideoUrl, videoEnded, videoPlaying }) => (
  <LivePlayer
    streams={[
      {
        url: marketingVideoUrl,
        type: 'video/mp4',
      },
    ]}
    playing={videoPlaying}
    ended={videoEnded}
    errorCallback={videoEnded}
  />
);

MarketingVideo.propTypes = {
  marketingVideoUrl: PropTypes.string,
  videoEnded: PropTypes.func,
  videoPlaying: PropTypes.func,
};

MarketingVideo.defaultProps = {
  marketingVideoUrl: false,
  videoEnded: () => null,
  videoPlaying: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketingVideo);
