import bsTranslations from 'ClientsBranding/configs/bettingsuggestions';
import { matchStatusIds as mS } from '../constants/enum';
import { CALL_TO_ACTION } from '../constants/app';

// TODO: remove after testing before merge to develop
/*
const defaultTable = {
  [oddsT.pre.three_way]: {
    [ALL_SPORT]: {
    },
    [sportIds.ice_hockey]: {
    },
  },
  [oddsT.pre.two_way]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.pre.handicap]: {
    [ALL_SPORT]: {
      en: 'Handicap ({hcp})',
    },
  },
  [oddsT.pre.total]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.pre.spread]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.pre.correct_score]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.three_way_live]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.two_way_live]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.handicap]: {
    [ALL_SPORT]: {
      en: 'Handicap ({hcp})',
    },
  },
  [oddsT.live.handicap_first_half]: {
    [ALL_SPORT]: {
      en: 'Handicap 1st Half ({hcp})',
    },
  },
  [oddsT.live.total]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.restofthematch]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.nextgoal]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.total_first_half]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.total_current_round]: {
    [ALL_SPORT]: {
    },
    [sportIds.basketball]: {
      en: 'Total Q$x',
    },
    [sportIds.basketball3x3]: {
      en: 'Total Q$x',
    },
    [sportIds.tennis]: {
      en: 'Total Games In Set $x.',
    },
    [sportIds.volleyball]: {
      en: 'Total Set $x',
    },
    [sportIds.ice_hockey]: {
      en: 'TOTAL {pSuffix} PERIOD',
    },
    [sportIds.table_tennis]: {
      en: 'Total Games In Set $x.',
    },
  },
  [oddsT.live.total_incl_ot]: {
    [sportIds.basketball]: {
      en: 'Total Incl. OT',
    },
    [sportIds.basketball3x3]: {
      en: 'Total Incl. OT',
    },
    [sportIds.handball]: {
      en: 'Total Incl. OT',
    },
  },
  [oddsT.live.asian_handicap]: {
    [ALL_SPORT]: {

    },
  },
  [oddsT.live.asian_handicap_ot]: {
    [sportIds.basketball]: {
      en: 'Asian Handicap Incl. OT',
    },
    [sportIds.basketball3x3]: {
      en: 'Asian Handicap Incl. OT',
    },
    [sportIds.handball]: {
      en: 'Asian Handicap Incl. OT',
    },
  },
  [oddsT.live.handicap_current_period]: {
    [sportIds.volleyball]: {
      en: 'Asian Handicap Set $x',
    },
    [sportIds.basketball]: {
      en: 'Asian Handicap Q$x',
    },
    [sportIds.basketball3x3]: {
      en: 'Asian Handicap Q$x',
    },
    [sportIds.volleyball]: {
      en: 'POINTS HANDICAP FOR SET $x',
    },
    [sportIds.table_tennis]: {
      en: 'Asian HC Set {period}.',
    },
  },
  [oddsT.live.win_current_period]: {
    [sportIds.volleyball]: {
      en: 'Winner Set $x',
    },
    [sportIds.basketball]: {
      en: 'Win Q$x',
    },
    [sportIds.basketball3x3]: {
      en: 'Win Q$x',
    },
    [sportIds.table_tennis]: {
      en: 'Win {pSuffix} Set',
    },
    [sportIds.ice_hockey]: {
      en: 'Win {pSuffix} Period',
    },
  },
  [oddsT.live.win_tennis_match]: {
    [sportIds.tennis]: {
    },
  },
  [oddsT.live.win_tennis_set]: {
    [sportIds.tennis]: {
    },
  },
  [oddsT.live.win_tennis_game]: {
    [sportIds.tennis]: {
      en: 'WINNER OF GAME $x',
    },
  },
  [oddsT.live.tennis_number_of_sets]: {
    [sportIds.tennis]: {
    },
  },
  [oddsT.live.win_games_of_set]: {
    [sportIds.tennis]: {
    },
  },
  [oddsT.live.total_games_in_match]: {
    [sportIds.tennis]: {
    },
  },
  [oddsT.live.correct_score_live]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.halftime_1x2]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.next_goal_first_half]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.three_way_only_ot]: {
    [ALL_SPORT]: {
    },
  },
  [oddsT.live.ht_who_wins_the_rest]: {
    [sportIds.ice_hockey]: {
    },
  },
  [oddsT.live.draw_no_bet]: {
    [sportIds.ice_hockey]: {
    },
  },
};
*/

export function ordinalSuffixOf(input) {
  let i = input;
  if (typeof i === 'string') {
    switch (input.toLowerCase()) {
      case 'first':
        i = 1;
        break;
      case 'second':
        i = 2;
        break;
      case 'third':
        i = 3;
        break;
      case 'fourth':
        i = 4;
        break;
      case 'fifth':
        i = 5;
        break;
      default:
        i = 0;
        break;
    }
  }

  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

const statuses = {
  [mS.not_started]: {
    en: 'not started',
  },
  [mS.halftime]: {
    en: 'HT',
  },
  [mS.pause]: {
    en: 'pause',
  },
  [mS.overtime]: {
    en: 'OT',
  },
  [mS.first_half]: {
    en: '1st half',
  },
  [mS.second_half]: {
    en: '2nd half',
  },
  [mS.first_period]: {
    en: '1st period',
  },
  [mS.second_period]: {
    en: '2nd period',
  },
  [mS.third_period]: {
    en: '3rd period',
  },
  [mS.fourth_period]: {
    en: '4th period',
  },
  [mS.first_set]: {
    en: '1st set',
  },
  [mS.second_set]: {
    en: '2nd set',
  },
  [mS.third_set]: {
    en: '3rd set',
  },
  [mS.fourth_set]: {
    en: '4th set',
  },
  [mS.fifth_set]: {
    en: '5th set',
  },
  [mS.first_quarter]: {
    en: '1st quarter',
  },
  [mS.second_quarter]: {
    en: '2nd quarter',
  },
  [mS.third_quarter]: {
    en: '3rd quarter',
  },
  [mS.fourth_quarter]: {
    en: '4th quarter',
  },
  [mS.first_extra]: {
    en: '1st extra',
  },
  [mS.second_extra]: {
    en: '2nd extra',
  },
  [mS.penalties]: {
    en: 'penalties',
  },
};

export const statusesLong = {
  [mS.not_started]: {
    en: 'not started',
  },
  [mS.halftime]: {
    en: 'HT',
  },
  [mS.first_half]: {
    en: '1st half',
  },
  [mS.second_half]: {
    en: '2nd half',
  },
  [mS.first_period]: {
    en: 'first period',
  },
  [mS.second_period]: {
    en: 'second period',
  },
  [mS.third_period]: {
    en: 'third period',
  },
  [mS.fourth_period]: {
    en: 'fourth period',
  },
  [mS.first_set]: {
    en: 'first set',
  },
  [mS.second_set]: {
    en: 'second set',
  },
  [mS.third_set]: {
    en: 'third set',
  },
  [mS.fourth_set]: {
    en: 'fourth set',
  },
  [mS.fifth_set]: {
    en: 'fifth set',
  },
  [mS.first_quarter]: {
    en: 'first quarter',
  },
  [mS.second_quarter]: {
    en: 'second quarter',
  },
  [mS.third_quarter]: {
    en: 'third quarter',
  },
  [mS.fourth_quarter]: {
    en: 'fourth quarter',
  },
  [mS.overtime]: {
    en: 'OT',
  },
  [mS.first_extra]: {
    en: 'first extra',
  },
  [mS.second_extra]: {
    en: 'second extra',
  },
  [mS.penalties]: {
    en: 'penalties',
  },
};

export const getMatchStatusStr = (matchStatusId, lang = 'en') => {
  if (statuses[matchStatusId] && statuses[matchStatusId][lang]) {
    return statuses[matchStatusId][lang];
  }

  return '';
};


export const getLocalMarketName = (marketId, sportId, lang, srAlias, translations, intl) => {
  const sportOddsMarketNameKey = `t_odds_market_name_${sportId}_${marketId}`;

  if (translations && translations[sportOddsMarketNameKey]) {
    return intl.formatMessage({ id: sportOddsMarketNameKey });
  }

  return null;
};

export const getCallToAction = (srAlias, lang) => {
  if (bsTranslations[srAlias] && bsTranslations[srAlias][lang]) {
    return bsTranslations[srAlias][lang];
  }

  return CALL_TO_ACTION;
};
