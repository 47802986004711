import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ScoreboardManager from 'newDesign/modules/common/components/oddsPanel/scoreBoard/scoreBoardManager/ScoreBoardManager';
import {
  getScoreboardRefresh,
  getReadyMatchId,
} from 'reducers';

const mapStateToProps = state => ({
  scoreboardRefresh: getScoreboardRefresh(state),
  matchId: getReadyMatchId(state),
});

const ScoreBoardWrapper = ({
  scoreboardRefresh,
  matchId,
}) => (
  <ScoreboardManager
    key={scoreboardRefresh}
    matchId={matchId}
  />
);

ScoreBoardWrapper.propTypes = {
  scoreboardRefresh: PropTypes.number,
  matchId: PropTypes.number.isRequired,
};

ScoreBoardWrapper.defaultProps = {
  scoreboardRefresh: 0,
};

export default connect(
  mapStateToProps,
)(ScoreBoardWrapper);
