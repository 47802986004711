import React from 'react';
import PropTypes from 'prop-types';

const StreamsContainer = ({ streams }) => (
  <video>
    <track kind='captions' key={1} />
    {streams.map((stream, i) => (
      <source
        key={stream.url ? stream.url : i}
        src={stream.url ? stream.url : ''}
        type={stream.type}
      />
    ))}
    {streams.length === 0 ? (
      <source
        key={-1}
        src={''}
        type={'video/mp4'}
      />
    ) : null}
  </video>
);

StreamsContainer.propTypes = {
  streams: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

StreamsContainer.defaultProps = {
  streams: [],
};
export default StreamsContainer;
