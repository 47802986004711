import React from 'react';
import cssModule from 'react-css-modules';

import LeagueStandingsWrapper from './leagueStandings/LeagueStandingsWrapper';

import style from './FooterCrawler.scss';

const FooterCrawler = () => (
  <div styleName='lframe-crawler' className='flex flex-1 relative overflow-hidden'>
    <LeagueStandingsWrapper />
  </div>
);

const FooterCrawlerStyled = cssModule(FooterCrawler, style);
export default FooterCrawlerStyled;
