// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PreviousMeeting__prematch__scores___1Zdj5{border:1px solid #585a60;text-align:center;-ms-flex-pack:center;justify-content:center;width:55px;height:55px;box-sizing:border-box;color:#fff}.PreviousMeeting__prematch__scores___1Zdj5.PreviousMeeting__three-digits-result-available___3MASM{width:2.5vw;height:2.5vw}.PreviousMeeting__prematch__scores___1Zdj5.PreviousMeeting__active___2zWTE{background:#3c3f45;box-shadow:4px 4px 4px 0px rgba(0,0,0,.1) inset,0px 4px 4px 0px rgba(0,0,0,.25)}.PreviousMeeting__prematch__scores___1Zdj5.PreviousMeeting__active___2zWTE span{color:#fff}.PreviousMeeting__prematch__scores___1Zdj5:not(.PreviousMeeting__active___2zWTE){background:rgba(0,0,0,.2);-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px)}.PreviousMeeting__prematch__separator___1HtJC{background:#3c3d3e;height:2px;width:1rem}.PreviousMeeting__prematch__teams___3TbBa.PreviousMeeting__active___2zWTE{color:#fff}.PreviousMeeting__prematch__period___1oz9q{text-align:center;-ms-flex-pack:center;justify-content:center;min-width:26px;padding:0 10px;height:35px;box-sizing:border-box;background:linear-gradient(180deg, #F1F1F1 0%, #C1BFBF 100%);box-shadow:3px 2px 4px 0px rgba(0,0,0,.15);color:#0c0d0e;font-weight:bold;border-radius:3px;font-style:normal}.PreviousMeeting__prematch__period_separator___g8xyo{background:#fff;height:2px;width:.4rem}.PreviousMeeting__active___2zWTE{color:#fff}", ""]);
// Exports
exports.locals = {
	"prematch__scores": "PreviousMeeting__prematch__scores___1Zdj5",
	"three-digits-result-available": "PreviousMeeting__three-digits-result-available___3MASM",
	"active": "PreviousMeeting__active___2zWTE",
	"prematch__separator": "PreviousMeeting__prematch__separator___1HtJC",
	"prematch__teams": "PreviousMeeting__prematch__teams___3TbBa",
	"prematch__period": "PreviousMeeting__prematch__period___1oz9q",
	"prematch__period_separator": "PreviousMeeting__prematch__period_separator___g8xyo"
};
module.exports = exports;
