import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchId,
  getMatchHomeForm,
  getMatchAwayForm,
} from 'reducers';
import StrengthBar from 'newDesign/modules/live/components/footer/footerBottom/strengthBar/StrengthBar';
import NumberBarStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberBarStatSlide';

const mapStateToProps = state => ({
  homeStat: getMatchHomeForm(state, getMatchId(state), 5),
  awayStat: getMatchAwayForm(state, getMatchId(state), 5),
});

const Form = ({ homeStat, awayStat }) => {
  const intl = useIntl();

  const home = (
    <StrengthBar percentage={homeStat} />
  );

  const away = (
    <StrengthBar percentage={awayStat} />
  );

  return (
    <NumberBarStatSlide
      statText={intl.formatMessage({ id: 't_footer_ground_form' })}
      homeStat={home}
      awayStat={away}
      homeStatText={homeStat + '%'}
      awayStatText={awayStat + '%'}
      higherIsBetter
    />
  );
};

Form.propTypes = {
  homeStat: PropTypes.number,
  awayStat: PropTypes.number,

};

Form.defaultProps = {
  homeStat: null,
  awayStat: null,
};

export default connect(mapStateToProps)(Form);
