// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PreMatchCompetitors__prematch__competitors___2JydT span{font-size:2vw}.PreMatchCompetitors__prematch__competitors___2JydT h2{font-size:1.5vw;line-height:1.7vw}.PreMatchCompetitors__prematch__competitors___2JydT .PreMatchCompetitors__icon-controller___2qPDN{width:5vw}.PreMatchCompetitors__prematch__vs___pUeyG span{margin-top:-2rem}@media only screen and (min-width: 1536px){.PreMatchCompetitors__prematch__competitors___2JydT span{font-size:2vw}.PreMatchCompetitors__prematch__competitors___2JydT h2{font-size:1.5vw;line-height:1.7vw}}", ""]);
// Exports
exports.locals = {
	"prematch__competitors": "PreMatchCompetitors__prematch__competitors___2JydT",
	"icon-controller": "PreMatchCompetitors__icon-controller___2qPDN",
	"prematch__vs": "PreMatchCompetitors__prematch__vs___pUeyG"
};
module.exports = exports;
