import React from 'react';
import PropTypes from 'prop-types';
import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import { resultStatus } from 'constants/enum';

const ResultBox = ({ result }) => {
  let className;
  let resultText;

  // @TODO Please confirm styles for OTL, OTW and default
  switch (result) {
    case resultStatus.win:
      resultText = 't_general_w';
      className = 'win--form-item';
      break;
    case resultStatus.draw:
      resultText = 't_general_d';
      className = 'draw--form-item';
      break;
    case resultStatus.lose:
      resultText = 't_general_l';
      className = 'lose--form-item';
      break;
    case resultStatus.overtimeWin:
      resultText = 't_general_otw';
      className = 'win--form-item';
      break;
    case resultStatus.overtimeLose:
      resultText = 't_general_otl';
      className = 'lose--form-item';
      break;
    default:
      resultText = 't_general_result_minus';
      className = 'draw--form-item';
  }

  return (
    <div className={`z-10 form__item ${className} flex fs-16 justify-center items-center`}>
      <span className={'font-second'}><TranslationFormattedMessage id={resultText} /></span>
    </div>
  );
};

ResultBox.propTypes = {
  result: PropTypes.number,
};

ResultBox.defaultProps = {
  result: null,
};

export default ResultBox;
