import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';


const RankingTitle = ({
  displayAtpOrWta, ranking,
}) => {
  const intl = useIntl();

  if (displayAtpOrWta === false || !ranking) {
    return null;
  }

  return (
    <span className={'uppercase fs-17 font-light'}>
      {intl.formatMessage({ id: ranking && ranking.sex === 'f' ? 't_statistics_wta_ranking' : 't_statistics_atp_ranking' })}
      {' - ' + intl.formatMessage({ id: 't_statistics_overall' })}
    </span>
  );
};

RankingTitle.propTypes = {
  displayAtpOrWta: PropTypes.bool.isRequired,
  ranking: PropTypes.object,
};

RankingTitle.defaultProps = {
  ranking: null,
};

export default RankingTitle;
