// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PreMatchDecoratedTitle__prematch__title___3aMeY{position:relative;line-height:normal}.PreMatchDecoratedTitle__prematch__title___3aMeY .PreMatchDecoratedTitle__branded-color-underline___2YoEN{content:\"\";width:25%;height:3px;position:absolute;bottom:0;left:37%}@media only screen and (min-width: 1536px){.PreMatchDecoratedTitle__prematch__title___3aMeY h1{font-size:2vw !important}}", ""]);
// Exports
exports.locals = {
	"prematch__title": "PreMatchDecoratedTitle__prematch__title___3aMeY",
	"branded-color-underline": "PreMatchDecoratedTitle__branded-color-underline___2YoEN"
};
module.exports = exports;
