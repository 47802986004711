// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TournamentInfo__conditionalAlignment___2r_ne:only-child{-ms-flex-pack:center;justify-content:center}", ""]);
// Exports
exports.locals = {
	"conditionalAlignment": "TournamentInfo__conditionalAlignment___2r_ne"
};
module.exports = exports;
