// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TomorrowMatchLabel__tomorrow-block___1a2hw{color:#fff;font-style:italic}.TomorrowMatchLabel__tomorrow-block___1a2hw .TomorrowMatchLabel__line___85J80{background:#444e60;height:1px}@media only screen and (min-width: 1536px){.TomorrowMatchLabel__tomorrow-block___1a2hw{font-size:1.25vw}}", ""]);
// Exports
exports.locals = {
	"tomorrow-block": "TomorrowMatchLabel__tomorrow-block___1a2hw",
	"line": "TomorrowMatchLabel__line___85J80"
};
module.exports = exports;
