import style from 'styles/widgets/Logo.scss';
import React from 'react';
import cssModule from 'react-css-modules';
import PropTypes from 'prop-types';

const Logo = ({ logoUrl, logoStyle }) => (
  <div styleName={logoStyle}>
    <img
      src={logoUrl}
      alt='Logo'
    />
  </div>
);

Logo.propTypes = {
  logoUrl: PropTypes.string,
  logoStyle: PropTypes.string,
};

Logo.defaultProps = {
  logoUrl: '',
  logoStyle: 'logo',
};

export default cssModule(Logo, style);
