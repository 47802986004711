import React from 'react';
import PropTypes from 'prop-types';

import Logo from 'newDesign/modules/common/components/logo/Logo';

const PageHeader = ({ logoUrl }) => (
  logoUrl
    ? <Logo logoUrl={logoUrl} logoClassName={'max-h-[80px] self-center mb-8'} />
    : null
);

PageHeader.propTypes = {
  logoUrl: PropTypes.string,
};

PageHeader.defaultProps = {
  logoUrl: '',
};

export default PageHeader;
