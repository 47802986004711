import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FullscreenLayoutPreMatch from 'newDesign/modules/common/components/layout/fullscreenLayout/FullscreenLayoutPreMatch';
import OddsManagerCmp from 'newDesign/modules/common/components/oddsManager/OddsManager';
import TwoColSidebarRightNewDesign
from 'newDesign/modules/common/components/layout/twoColSidebarRightNewDesign/TwoColSidebarRightNewDesign';
import PreMatchOpener from 'newDesign/modules/preMatch/components/preMatchOpener/PreMatchOpener';
import PreMatchStatSlides from 'newDesign/modules/preMatch/components/statSlides/PreMatchStatSlides';
import TickerContainer from 'newDesign/modules/common/components/ticker/tickerContainer/TickerContainer';
import { LAYOUT_FRAME, LAYOUT_FULLSCREEN } from 'constants/bcms';
import { sport } from 'constants/enum';
import {
  getPreMatchHideOpener,
  getReadyMatchId,
  getOddsBreak,
  getLayout,
  getMatchSportId,
  getSSCompSlideIdx,
  getQueryStatSlide,
  getSSCompActivePreMatchSlideIds,
} from 'reducers';

const mapStateToProps = state => ({
  activePreMatchSlideIds: getSSCompActivePreMatchSlideIds(state),
  curIdx: getSSCompSlideIdx(state),
  qStatSlide: getQueryStatSlide(state),
  hideOpener: getPreMatchHideOpener(state),
  matchId: getReadyMatchId(state),
  layout: getLayout(state),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  oddsBreak: getOddsBreak(state),
});

const PreMatchStage = ({
  activePreMatchSlideIds,
  curIdx,
  qStatSlide,
  hideOpener,
  matchId,
  layout,
  sportId,
}) => {
  const preMatchSlideId = qStatSlide != null ? qStatSlide : curIdx; // override idx from query param

  if (!hideOpener || !activePreMatchSlideIds.includes(preMatchSlideId)) {
    // @TODO update list of sports and remove old component
    if ([
      sport.tennis,
      sport.soccer,
      sport.esoccer,
      sport.basketball,
      sport.ice_hockey,
      sport.handball,
      sport.volleyball,
      sport.beach_volley,
      sport.futsal,
      sport.table_tennis,
      sport.basketball3x3,
    ].includes(sportId)) {
      return <PreMatchOpener matchId={matchId} />;
    }
  }

  const prematch = <PreMatchStatSlides />;

  if (layout === LAYOUT_FULLSCREEN) {
    return <FullscreenLayoutPreMatch main={prematch} />;
  }

  const sidebar = (
    <div className='flex-1 h-full'>
      <OddsManagerCmp />
      <TickerContainer />
    </div>
  );

  return (
    <TwoColSidebarRightNewDesign
      main={prematch}
      sidebar={sidebar}
      matchId={matchId}
    />
  );
};

PreMatchStage.propTypes = {
  activePreMatchSlideIds: PropTypes.array,
  curIdx: PropTypes.number,
  qStatSlide: PropTypes.number,
  hideOpener: PropTypes.bool,
  matchId: PropTypes.number,
  layout: PropTypes.number,
  sportId: PropTypes.number,
};

PreMatchStage.defaultProps = {
  activePreMatchSlideIds: [],
  curIdx: null,
  qStatSlide: null,
  hideOpener: false,
  matchId: null,
  layout: LAYOUT_FRAME,
  sportId: null,
};

export default connect(mapStateToProps)(PreMatchStage);
