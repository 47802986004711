import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeMapStateToProps } from 'reducers/oddsProjection';
import OddsMarketOutcome from 'newDesign/modules/announcement/components/oddsMarketOutcome/OddsMarketOutcome';
import { isNotStarted } from 'utils/matchstatus';

const MatchOdds = ({
  matchStatus,
  marketsOutcomesArr,
  marketsNameArr,
}) => {
  // Only show markets for prematch and if markets are available
  if (!isNotStarted(matchStatus) || !marketsNameArr || !marketsNameArr.length) {
    return null;
  }

  const outcomes = marketsOutcomesArr[0];

  return (
    <div className='font-second flex flex-1 justify-start items-center'>
      {outcomes.map(outcome => (
        <OddsMarketOutcome
          key={outcome.name}
          outcome={outcome}
        />
      ))}
    </div>
  );
};

MatchOdds.propTypes = {
  matchStatus: PropTypes.number,
  marketsNameArr: PropTypes.array,
  marketsOutcomesArr: PropTypes.array,
};

MatchOdds.defaultProps = {
  matchStatus: null,
  marketsNameArr: [],
  marketsOutcomesArr: [],
};

export default connect(makeMapStateToProps)(MatchOdds);
