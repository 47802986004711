import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getHOTDMatch,
} from 'reducers';
import HOTD from 'newDesign/modules/preMatch/components/highlightOfTheDay/HOTD';

const mapStateToProps = state => ({
  matchId: getHOTDMatch(state),
});

const HighlightOfTheDayStage = ({ matchId }) => <HOTD matchId={matchId} />;

HighlightOfTheDayStage.propTypes = {
  matchId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(HighlightOfTheDayStage);
