import React from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';

const DesignWrapper = ({ newDesignComponent }) => {
  /**
   * In a new design only,
   * prevent to zoom it and zoom out - display message to the end user that the level needs to set as 100%
   */
  React.useEffect(() => {
    const resizeAlert = () => {
      const devicePixelRatio = _get(window, 'devicePixelRatio', null);

      /**
       * Allowed - do not display alert:
       * - 1 for 100% (included in the range)
       * - 2 for 200% - because in some resolution we need 2 pixels to render 1 pixel
       * - range between 0.7 and 1.3 (in case if someone increase zoom level in Windows)
       */
      if (devicePixelRatio === 2 || (devicePixelRatio > 0.7 && devicePixelRatio < 1.3)) {
        return;
      }

      // eslint-disable-next-line no-alert
      alert(
        'The application works with a zoom level set to 100%. \nYour configuration is different. \n\n Please ensure that the zoom level in your browser is set to 100%.',
      );
    };

    // To check zoom level for initial render and once stage is changed
    resizeAlert();

    window.addEventListener('resize', resizeAlert);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', resizeAlert);
  }, []);

  // TODO: exists idea to include sortId check inside of if statement (will not for for the announcement screen)

  return (
    <div id='lcr__wrapper'>
      {newDesignComponent}
    </div>
  );
};

DesignWrapper.propTypes = {
  newDesignComponent: PropTypes.element.isRequired,
};

DesignWrapper.defaultProps = {
  newDesignComponent: null,
};

export default DesignWrapper;
