// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LastMatches__competitors_form___5Iyu- span{color:#fff;line-height:1.6vw}.LastMatches__competitors_form___5Iyu-:not(.LastMatches__competitors_form__arrow___303VF) span{max-width:34%}.LastMatches__competitors_form___5Iyu-:not(.LastMatches__competitors_form__arrow___303VF) span:last-child{text-align:right}.LastMatches__competitors_form__arrow___303VF span{margin:0 2.125rem 0 0;-ms-flex:1;flex:1;text-align:right}.LastMatches__competitors_form__arrow___303VF span:last-child{margin:0 0 0 2.125rem;text-align:left}", ""]);
// Exports
exports.locals = {
	"competitors_form": "LastMatches__competitors_form___5Iyu-",
	"competitors_form__arrow": "LastMatches__competitors_form__arrow___303VF"
};
module.exports = exports;
