import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import LiveStatsHeader from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/LiveStatsHeader';
import { SEC } from 'constants/app';
import { sport } from 'constants/enum';
import useImportImageFacelift from 'newDesign/modules/common/hooks/ImportImage';
import { getBackgroundImageUrl } from 'newDesign/modules/common/utils/sportBackground';
import useOnScrollVertical from 'newDesign/modules/common/hooks/OnScrollVertical';
import Basketball from 'newDesign/modules/break/components/statSlides/liveStats/sports/basketball/Basketball';
import Soccer from 'newDesign/modules/break/components/statSlides/liveStats/sports/soccer/Soccer';
import Tennis from 'newDesign/modules/break/components/statSlides/liveStats/sports/tennis/Tennis';
import IceHockey from 'newDesign/modules/break/components/statSlides/liveStats/sports/iceHockey/IceHockey';
import Handball from 'newDesign/modules/break/components/statSlides/liveStats/sports/handball/Handball';
import Volleyball from 'newDesign/modules/break/components/statSlides/liveStats/sports/volleyball/Volleyball';
import TableTennis from 'newDesign/modules/break/components/statSlides/liveStats/sports/tableTennis/TableTennis';
import Futsal from 'newDesign/modules/break/components/statSlides/liveStats/sports/futsal/Futsal';
import {
  getMatchGround,
  getMatchSportId,
  getReadyMatchId,
} from 'reducers';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  ground: getMatchGround(state, getReadyMatchId(state)),
});

const LiveStats = ({
  sportId,
  ground,
}) => {
  const scrollEndRef = useRef(null);
  const scrollPosition = useOnScrollVertical(scrollEndRef, 1, 4 * SEC);

  const backgroundImage = useImportImageFacelift(getBackgroundImageUrl(sportId, ground, 'statSlides'));

  let stats = null;

  switch (sportId) {
    case sport.basketball:
    case sport.ebasketball:
    case sport.basketball3x3:
      stats = <Basketball />;
      break;
    case sport.table_tennis:
      stats = <TableTennis />;
      break;
    case sport.tennis:
      stats = <Tennis />;
      break;
    case sport.ice_hockey:
      stats = <IceHockey />;
      break;
    case sport.beach_volley:
    case sport.volleyball:
      stats = <Volleyball />;
      break;
    case sport.soccer:
      stats = <Soccer />;
      break;
    case sport.esoccer:
      stats = <Soccer />;
      break;
    case sport.handball:
      stats = <Handball />;
      break;
    case sport.futsal:
      stats = <Futsal />;
      break;
    default:
      break;
  }

  return (
    <div className={'w-full flex-col relative'}>
      <div className='absolute inset-0 flex justify-center animate-fadeIn-1.25s'>
        <div styleName='halftimestats__content' className='flex absolute h-full w-10/12 flex-col'>
          <LiveStatsHeader />
          <div className={'overflow-hidden'}>
            <div className={'z-10 relative'} style={{ 'top': -scrollPosition + 'px' }}>
              {stats}
              <div className={'mb-10'} />
              <div ref={scrollEndRef} />
            </div>
          </div>
        </div>
        <img className='w-full h-full object-cover' src={backgroundImage} alt='' />
      </div>
    </div>
  );
};

LiveStats.propTypes = {
  sportId: PropTypes.number.isRequired,
  ground: PropTypes.string,
};

LiveStats.defaultProps = {
  ground: '',
};

const LiveStatsStyled = cssModule(LiveStats, style, { allowMultiple: true });
export default connect(mapStateToProps)(LiveStatsStyled);
