import 'styles/components/common.scss';
import React from 'react';
import cssModule from 'react-css-modules';
import style from 'styles/widgets/Root.scss';
import { connect } from 'react-redux';
import {
  STAGE_PRE_MATCH,
  STAGE_ANNOUNCEMENT,
  STAGE_HIGHLIGHT,
  STAGE_ANNOUNCEMENT_LOOP,
  STAGE_LIVE,
  STAGE_BREAK,
  STAGE_WORLD_LOTTERY,
  STAGE_LOADING,
  CHANNEL_TRAILER,
  MARKETING_VIDEO,
  STAGE_INITIAL,
} from 'constants/bcms';
import PreMatchStage from 'newDesign/modules/preMatch/stage/PreMatchStage';

import AnnouncementStageNewDesign from 'newDesign/modules/announcement/stage/AnnouncementStage';
import HighlightOfTheDayStageNewDesign from 'newDesign/modules/preMatch/stage/HighlightOfTheDayStage';
import LiveStage from 'newDesign/modules/live/stage/LiveStage';
import WorldLotteryCmp from 'views/widgets/WorldLottery';
import BreakStageNewDesign from 'newDesign/modules/break/stage/BreakStageNewDesign.js';
import AnnouncementLoopStageNewDesign from 'newDesign/modules/common/stage/AnnouncementLoopStage';
import LoadingSpinnerCmp from 'views/widgets/LoadingSpinner';
import ExpirationStage from 'views/widgets/ExpirationStage';
import PropTypes from 'prop-types';
import ChannelTrailerCmp from 'views/widgets/ChannelTrailer';
import MarketingVideoCmp from 'views/widgets/MarketingVideo';
import LoggerCmp from 'containers/Logger';
import {
  getPaymentCheckSuccess,
  getLoggedInStatus,
  getShownStage,
  getStageRefresh,
  getAccountActiveStatus,
  getTechnicalIssuesStatus,
} from 'reducers';
import ErrorBoundary from 'utils/ErrorBoundary';
import DesignWrapper from 'newDesign/modules/common/utils/DesignWrapper';
import { useKeyCount } from 'hooks';
import DisabledAccount from '../views/widgets/DisabledAccount';
import TechnicalIssuesStage from '../views/widgets/TechnicalIssues';
import { redirectToLogin } from '../utils/Url';


const mapStateToProps = state => ({
  stageId: getShownStage(state),
  hasPayed: getPaymentCheckSuccess(state),
  loggedIn: getLoggedInStatus(state),
  accountActive: getAccountActiveStatus(state),
  technicalIssuesStatus: getTechnicalIssuesStatus(state),
  refresh: getStageRefresh(state),
});

export const StageCmp = ({ stageId, refresh }) => {
  switch (stageId) {
    case STAGE_LOADING:
      return <LoadingSpinnerCmp />;
    case CHANNEL_TRAILER:
      // use key prop to force video to run again
      // to avoid it getting stuck by the video ended action not being caught
      return <ChannelTrailerCmp key={refresh} />;
    case MARKETING_VIDEO:
      return <MarketingVideoCmp key={refresh} />;
    case STAGE_PRE_MATCH:
      return <DesignWrapper
        newDesignComponent={<PreMatchStage />}
      />;
    case STAGE_LIVE:
      return <DesignWrapper
        newDesignComponent={<LiveStage />}
      />;
    case STAGE_BREAK:
      return <DesignWrapper
        newDesignComponent={<BreakStageNewDesign />}
      />;
    case STAGE_ANNOUNCEMENT:
      return <DesignWrapper
        newDesignComponent={<AnnouncementStageNewDesign />}
      />;
    case STAGE_HIGHLIGHT:
      return <DesignWrapper
        newDesignComponent={<HighlightOfTheDayStageNewDesign />}
      />;
    case STAGE_ANNOUNCEMENT_LOOP:
      return <DesignWrapper
        newDesignComponent={<AnnouncementLoopStageNewDesign />}
      />;
    case STAGE_WORLD_LOTTERY:
      return <WorldLotteryCmp />;
    default:
      return <DesignWrapper
        newDesignComponent={<AnnouncementStageNewDesign />}
      />;
  }
};

StageCmp.propTypes = {
  stageId: PropTypes.number,
  refresh: PropTypes.number,
};

StageCmp.defaultProps = {
  stageId: STAGE_ANNOUNCEMENT,
  refresh: 0,
};

const initialToAnnHack = stageId => (stageId === STAGE_INITIAL ? STAGE_ANNOUNCEMENT : stageId);

const Root = ({
  stageId, refresh, loggedIn, accountActive, technicalIssuesStatus, hasPayed,
}) => {
  const count = useKeyCount('r'); // soft refresh

  let stage = null;
  // to avoid flashing stage when only one is checked
  if (technicalIssuesStatus) {
    stage = <TechnicalIssuesStage />;
  } else if (accountActive === false) {
    stage = <DisabledAccount />;
  } else if (
    (loggedIn === null && hasPayed === null)
    || (loggedIn === null && hasPayed === false)
    || (hasPayed === null && loggedIn === false)
  ) {
    stage = <LoadingSpinnerCmp />;
  } else if (loggedIn === false) {
    redirectToLogin();

    return null;
  } else if (hasPayed === false) {
    stage = <ExpirationStage />;
  } else {
    stage = <StageCmp stageId={stageId} refresh={refresh} />;
  }

  return (
    // key change retries errored component
    <ErrorBoundary
      fallback={<LoadingSpinnerCmp />}
      key={initialToAnnHack(stageId) + (count * 100)}
    >
      <div
        id='lcr'
        styleName='root'
      >
        {stage}
        {<LoggerCmp />}
      </div>
    </ErrorBoundary>
  );
};


Root.propTypes = {
  stageId: PropTypes.number,
  refresh: PropTypes.number,
  hasPayed: PropTypes.bool,
  loggedIn: PropTypes.bool,
  accountActive: PropTypes.bool,
  technicalIssuesStatus: PropTypes.bool,
};

Root.defaultProps = {
  stageId: STAGE_ANNOUNCEMENT,
  refresh: 0,
  hasPayed: false,
  loggedIn: false,
  accountActive: true,
  technicalIssuesStatus: false,
};

export default connect(mapStateToProps)(cssModule(Root, style));
