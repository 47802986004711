import React, { useState } from 'react';
import { getJerseyAsBase64 } from '@streaming-embet/embet-jersey';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import {
  jerseyStripesType, jerseyType, jerseyTypeShortSleeves, jerseyTypeLongSleeves, jerseyTypeNoSleeves, sport,
} from 'constants/enum';
import cssModule from 'react-css-modules';
import styles from './Jersey.scss';

const getDefaultSleevesConfiguration = (configuration, sportId) => {
  // default sleeves length based on sports
  const defaultConfiguration = configuration;
  if ([sport.basketball, sport.basketball3x3].includes(sportId)) {
    defaultConfiguration.type = jerseyTypeNoSleeves;
  } else if (sportId === sport.ice_hockey) {
    defaultConfiguration.type = jerseyTypeLongSleeves;
  }

  return defaultConfiguration;
};

/**
 * Parse data from Fishnet to embet-jersey structure
 *
 * @example
 * Usage with <Jersey /> component
 * <Jersey jersey={jersey}/>
 *
 * Example structure of the respnse from Fishnet:
 * {
 *     "base": "D8D8D8",
 *     "sleeve": "D8D8D8",
 *     "number": "000000",
 *     "real": false,
 *     "type": "short_sleeves"
 * }
 */
export const jerseyParser = (configuration, sportId) => {
  const jersey = {
    color: _get(configuration, 'base', null),
    sleeveColor: _get(configuration, 'sleeve', null),
  };

  const jerseyConfiguration = getDefaultSleevesConfiguration(configuration, sportId);

  if (jerseyConfiguration.type === jerseyTypeLongSleeves) {
    jersey.type = jerseyType.long_sleeve;
    jersey.sleeveColor = _get(jerseyConfiguration, 'sleevelong', null);
  } else if (jerseyConfiguration.type === jerseyTypeShortSleeves) {
    jersey.type = jerseyType.short_sleeve;
    jersey.sleeveColor = _get(jerseyConfiguration, 'sleevelong', null);
  } else if (jerseyConfiguration.type === jerseyTypeNoSleeves) {
    jersey.type = jerseyType.no_sleeve;
  } else {
    jersey.type = jerseyType.short_sleeve;
  }

  if (jerseyConfiguration.stripes) {
    jersey.variantColor = jerseyConfiguration.stripes;
    jersey.variant = jerseyStripesType.vertical_stripes;
  } else if (jerseyConfiguration.horizontalstripes) {
    jersey.variantColor = jerseyConfiguration.horizontalstripes;
    jersey.variant = jerseyStripesType.horizontal_stripes;
  } else if (jerseyConfiguration.split) {
    jersey.variantColor = jerseyConfiguration.split;
    jersey.variant = jerseyStripesType.split;
  } else if (jerseyConfiguration.squares) {
    jersey.variantColor = jerseyConfiguration.squares;
    jersey.variant = jerseyStripesType.squares;
  }

  return jersey;
};

/**
 * @example
 * <Jersey jersey={{color: 'ffffff'}}/>
 * <Jersey jersey={{color: 'ffffff', outlineColor: 'ababab'}} />
 * <Jersey
 *    jersey={{color:'ffffff', type: 2, outlineColor: 'ababab', variant: 1, variantColor: 'ffffff'}}
 *    className={'own_class'}
 * />
 * <Jersey jersey={jersey} className={'own_class'} />
 * <Jersey jersey={jersey} />
 * @constructor
 */
const Jersey = ({
  jersey, className, jerseySize, sportId,
}) => {
  let classNameStyles = '';
  const [jerseyBase64, setJerseyBase64] = useState(null);

  const {
    color, outlineColor, sleeveColor, type, variant, variantColor,
  } = jerseyParser(jersey, sportId);

  getJerseyAsBase64({
    color, outlineColor, sleeveColor, type, variant, variantColor,
  }).then(jerseyResult => {
    setJerseyBase64(jerseyResult);
  });

  if (className) {
    classNameStyles = {
      'className': className,
    };
  }

  if (jersey) {
    return <img styleName={jerseySize} {...classNameStyles} src={jerseyBase64} alt={'Jersey'} />;
  }

  return null;
};

Jersey.propTypes = {
  jersey: PropTypes.shape({
    base: PropTypes.string,
    sleeve: PropTypes.string,
    sleevelong: PropTypes.string,
    number: PropTypes.string,
    real: PropTypes.bool,
    type: PropTypes.string,
    horizontalstripes: PropTypes.string,
    split: PropTypes.string,
    squares: PropTypes.string,
  }),
  className: PropTypes.string,
  jerseySize: PropTypes.string,
  sportId: PropTypes.number,
};

Jersey.defaultProps = {
  jersey: {
    base: null,
    sleeve: null,
    sleevelong: null,
    number: null,
    real: false,
    type: null,
    horizontalstripes: null,
    split: null,
    squares: null,
  },
  className: null,
  jerseySize: 'jersey_big',
  sportId: null,
};

const JerseyStyled = cssModule(Jersey, styles);
export default JerseyStyled;
