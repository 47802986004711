import React from 'react';
import { useTable } from 'react-table';
import { getMatchTable, getMatchTeamsNames } from 'reducers/index';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';
import Cell from './cell/Cell';
import styles from '../leagueTable/LeagueTable.scss';

const mapStateToProps = (state, ownProps) => ({
  matchTableData: getMatchTable(state, ownProps.matchId),
  teamNames: getMatchTeamsNames(state, ownProps.matchId),
});

const isActualTeam = (cell, teamNames) => (
  cell.row.original.name === teamNames.home || cell.row.original.name === teamNames.away
);

const getActiveTeamRowStyle = (cell, teamNames) => {
  if (isActualTeam(cell, teamNames)) {
    return 'active';
  }
  return '';
};

const getActivePositionCellStyle = (cell, teamNames) => {
  if (cell.column.id === 'pos' && isActualTeam(cell, teamNames)) {
    return 't-number';
  }
  return '';
};

const Table = ({
  columns, matchTableData, teamNames,
}) => {
  const {
    rows,
    prepareRow,
  } = useTable({ columns, data: matchTableData });

  if (!matchTableData) {
    return null;
  }

  return (
    <React.Fragment>
      {columns.map((column, i) => {
        const justifyClass = column.accessor === 'name' ? 'justify-start' : 'justify-center';
        return <span key={i} className={`flex font-second--medium ${justifyClass}`}>{column.Header}</span>;
      })}
      {rows.map((row, rowId) => {
        prepareRow(row);
        return (
          <React.Fragment key={row.id}>
            {row.cells.map((cell, cellPosition) => {
              const activeTeamRowStyle = getActiveTeamRowStyle(cell, teamNames);
              const activePositionCellStyle = getActivePositionCellStyle(cell, teamNames);
              const justifyClass = cell.column.id === 'name' ? 'justify-start' : 'justify-center';

              return (
                <span
                  key={rowId + '_' + cellPosition}
                  styleName={`${activeTeamRowStyle} ${activePositionCellStyle}`}
                  className={`flex ${justifyClass}`}
                ><Cell cell={cell} />
                </span>
              );
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  matchTableData: PropTypes.array,
  teamNames: PropTypes.object,
};

Table.defaultProps = {
  columns: [],
  matchTableData: [],
  teamNames: null,
};

const TableStyled = cssModule(Table, styles, { allowMultiple: true });
export default connect(mapStateToProps)(TableStyled);
