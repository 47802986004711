import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getMatchTennisDouble, getReadyMatchId } from 'reducers/index';
import styles from './TeamNames.scss';

const mapStateToProps = state => ({
  tennisDouble: getMatchTennisDouble(state, getReadyMatchId(state)),
});
const TeamNames = ({
  competitorName, activeClass, tennisDouble, justifyClass,
}) => {
  let competitors;

  if (!tennisDouble) {
    competitors = <span className={`${activeClass} ${styles.competitor} py-2 uppercase`}>{competitorName}</span>;
  } else {
    const [firstTeamMember, secondTeamMember] = competitorName.split(' / ');
    competitors = (
      <Fragment>
        <span className={`${activeClass} ${styles.competitor} py-2 uppercase`}>{firstTeamMember}</span>
        <span className={`${activeClass} mx-2`}>/</span>
        <span className={`${activeClass} ${styles.competitor} py-2 uppercase`}>{secondTeamMember}</span>
      </Fragment>
    );
  }

  return (
    <div className={`${styles.teamNamesWrapper}`}>
      <div className={`${styles.teamNames} flex ${justifyClass && justifyClass} items-center`}>
        {competitors}
      </div>
    </div>
  );
};

TeamNames.propTypes = {
  competitorName: PropTypes.string,
  activeClass: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tennisDouble: PropTypes.object,
  justifyClass: PropTypes.string,
};

TeamNames.defaultProps = {
  competitorName: null,
  activeClass: '',
  tennisDouble: null,
  justifyClass: '',
};

export default connect(mapStateToProps)(TeamNames);
