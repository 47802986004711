import React from 'react';
import { getLastMatch, getMatchStatus, getTicker } from 'reducers/index';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { matchStatusIds } from 'constants/enum';
import MatchTicker from '../matchTicker/MatchTicker';

const mapSateToProps = state => ({
  hasTicker: getTicker(state),
  matchStatus: getMatchStatus(state, getLastMatch(state)),
});

const TickerContainer = ({ hasTicker, matchStatus }) => (
  (hasTicker && matchStatus !== matchStatusIds.ended) && <MatchTicker />
);

TickerContainer.propTypes = {
  hasTicker: PropTypes.bool,
  matchStatus: PropTypes.string.isRequired,
};

TickerContainer.defaultProps = {
  hasTicker: true,
};

export default connect(mapSateToProps)(TickerContainer);
