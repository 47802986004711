import {
  createSelector,
  createSelectorCreator,
  lruMemoize,
} from 'reselect';
import isEqual from 'lodash.isequal';
import {
  AMERICAN_ODDS,
  DECIMAL_ODDS,
} from 'constants/bcms';
import {
  getOddsFormat,
  getMatchCurPeriod,
  getMatchSportId,
  getBId,
  getLanguage,
  getClientAlias,
  getMarketPackages,
  getTranslations,
  getIsTranslationsAvailable,
} from 'reducers/index';
import { formatOdds } from 'odds-conversion/index';
import clientRanking from 'ClientsBranding/configs/marketRanking';
import { getLocalMarketName, ordinalSuffixOf } from 'localization';
import { isEmpty } from 'lodash';
import {
  allOddsTypes,
  oddsT,
  oddsTypeRanking,
  sport,
} from '../constants/enum';
import createIntlHelper from '../localization/intlInitHelper';

const equalEnough = (arr, arrTmp) => (
  arr.length === arrTmp.length
  && arrTmp.every((mN, i) => mN === arr[i])
    ? arr
    : arrTmp
);

// JANG, SU JEONG => JANG S J
const doubleShortening = name => {
  try {
    if (name.includes(',')) {
      const idx = name.indexOf(',');
      let afterComma = name.split(', ')[1];
      afterComma = afterComma.split(' ').map(i => i.charAt(0)).join(' ');
      return name.slice(0, idx) + ' ' + afterComma;
    }
    return name;
  } catch (error) {
    return name;
  }
};

const createDeepEqualSelector = createSelectorCreator(lruMemoize, isEqual);

// merge team names to odds and select rigth oddsformat for client
const finalOutcomesOutput = (odds, outcomeNames, oddsFormat, betstop, keepAllOutcomes, sportId) => {
  if (!odds || !odds.length) {
    return [];
  }

  const oddsArr = Object.values(odds);
  // will count as active/shown
  if (oddsArr.every(outcome => outcome.odds === '-' || outcome.odds <= 0)) {
    return [];
  }

  let arr = [];
  for (let i = 0; i < oddsArr.length; i++) {
    const outcome = { ...oddsArr[i] };
    outcome.feedOdds = outcome.odds;
    outcome.feedName = outcome.name;
    outcome.decOdds = parseFloat(outcome.odds);

    if (betstop) {
      outcome.odds = '-';
    } else if (oddsFormat === AMERICAN_ODDS) {
      outcome.odds = formatOdds(outcome.odds, AMERICAN_ODDS);
    } else {
      outcome.odds = formatOdds(outcome.odds, DECIMAL_ODDS);
    }

    if (
      !outcomeNames
      || outcome.marketId === oddsT.live.goals_hometeam // use feed names
      || outcome.marketId === oddsT.live.goals_awayteam
    ) {
      // do nothing
    } else if (typeof outcome.totalHcp === 'number') {
      outcome.abbr = outcome.name + ' ' + outcome.totalHcp;
      outcome.name = outcome.name + ' ' + outcome.totalHcp;
    } else if (typeof outcome.spreadHcp === 'number') {
      const hcp = ' (' + (outcome.spreadHcp > 0 ? '+' + outcome.spreadHcp : outcome.spreadHcp) + ')';
      outcome.abbr = outcomeNames.abbr[i] + hcp;
      outcome.name = outcomeNames.name[i] + hcp;
    } else if (outcome.name === '1' && isEmpty(outcomeNames) && sportId === sport.table_tennis) {
      // @TODO: TAPI
      outcome.name = 'Home';
      outcome.abbr = 'Home';
    } else if (outcome.name === '2' && isEmpty(outcomeNames) && sportId === sport.table_tennis) {
      // @TODO: TAPI
      outcome.name = 'Away';
      outcome.abbr = 'Away';
    } else if (outcome.name === '1') {
      /* eslint-disable prefer-destructuring */
      outcome.name = outcomeNames.name[0];
      outcome.abbr = outcomeNames.abbr[0];
    } else if (outcome.name === '2') {
      outcome.name = outcomeNames.name[1];
      outcome.abbr = outcomeNames.abbr[1];
      /* eslint-enable  prefer-destructuring */
    }

    arr.push(outcome);
  }

  // dont sort odds for team goals market
  if (
    arr.length > 3
    && !arr.some(
      o => o.marketId === oddsT.live.goals_hometeam
        || o.marketId === oddsT.live.goals_awayteam,
    )
  ) {
    arr = arr.filter(outcome => outcome.odds !== '-');
    arr.sort((a, b) => ((a.decOdds > b.decOdds) ? 1 : -1));
  }
  if (!keepAllOutcomes) {
    arr = arr.slice(0, 3);
  }

  return arr;
};

// insert dynamic values into market name
const shortMarketNameOutput = (
  marketName, localMarketName, extra, curPeriod, hcp, ahcp, betstop,
) => {
  let oTypeShort = localMarketName || marketName;
  const localExtra = extra || ''; // avoid using str func on non string

  if (
    (oTypeShort.includes('$x') && oTypeShort.includes('$y'))
    || (oTypeShort.includes('{extra88:gameX}') && oTypeShort.includes('{extra88:gameY}'))
  ) {
    // oddsid 88 "Win games $x and $y"
    let and = localExtra.toLowerCase().indexOf('and');
    if (and < 0) {
      and = '';
    }
    const game1 = localExtra.substring(2, and);
    const game2 = localExtra.substring(and + 3);

    oTypeShort = oTypeShort.replace('$x', game1);
    oTypeShort = oTypeShort.replace('$y', game2);
    oTypeShort = oTypeShort.replace('{extra88:gameX}', game1);
    oTypeShort = oTypeShort.replace('{extra88:gameY}', game2);
  }
  if (oTypeShort.includes('$x')) {
    // should only use curPeriod when localM is used
    oTypeShort = oTypeShort.replace('$x', betstop ? '-' : (extra || curPeriod));
  }
  if (oTypeShort.includes('{extra}')) {
    // from extra prop in fishnet feed
    oTypeShort = oTypeShort.replace('{extra}', extra || '-');
  }
  if (oTypeShort.includes('{period}')) {
    oTypeShort = oTypeShort.replace('{period}', betstop ? '-' : curPeriod);
  }
  if (oTypeShort.includes('{pSuffix}')) {
    oTypeShort = oTypeShort.replace('{pSuffix}', betstop ? '-' : ordinalSuffixOf(curPeriod));
  }
  if (oTypeShort.includes('{hcp}')) {
    oTypeShort = oTypeShort.replace('{hcp}', hcp);
  }
  if (oTypeShort.includes('{ahcp}')) {
    oTypeShort = oTypeShort.replace('{ahcp}', ahcp);
  }

  // remove unreplaced {var}
  oTypeShort = oTypeShort.replace(/{.*?}/, '');

  return oTypeShort;
};

// array of odds objects for one market
const oddsOutputSelector = (odds, oddsIds) => oddsIds.map(oddsId => odds[oddsId]);

export class MarketProjection {
  constructor(
    state,
    matchId,
    marketId,
    sportId,
    lang,
    bId,
    srAlias,
    keepAllOutcomes,
    translations,
    intl,
  ) {
    this.id = matchId;
    this.state = state;
    this.marketId = marketId;
    this.sportId = sportId;
    this.lang = lang;
    this.bId = bId;
    this.srAlias = srAlias;
    this.keepAllOutcomes = keepAllOutcomes;
    this.translations = translations;
    this.intl = intl;
  }
  // private instances of memoized selectors

  // array of oddsIds for one market
  oddsIdsByMarketSelector = createSelector(
    [
      state => state,
      (state, matchId) => matchId,
      (state, matchId, marketId) => marketId,
    ],
    (state, matchId, marketId) => {
      if (
        !state.markets
        || !state.markets.matches
        || !state.markets.matches[matchId]
        || !state.markets.matches[matchId].oddsIdsByMarket[marketId]
      ) {
        return [];
      }

      return state.markets.matches[matchId].oddsIdsByMarket[marketId]; // [0]
    },
  );


  // gets array of odds objects for one market
  oddsSelctor = createSelector(
    [
      state => state.markets.odds,
      this.oddsIdsByMarketSelector,
    ],
    oddsOutputSelector,
  );

  localMarketNameSelector = createSelector(
    [
      (state, matchId, marketId) => marketId,
      (state, matchId, marketId, curPeriod, sportId) => sportId,
      (state, matchId, marketId, curPeriod, sportId, lang) => lang,
      (state, matchId, marketId, curPeriod, sportId, lang, srAlias) => srAlias,
      (state, matchId, marketId, curPeriod, sportId, lang, srAlias, translations) => translations,
      (state, matchId, marketId, curPeriod, sportId, lang, srAlias, translations, intl) => intl,
    ],
    // should just be run once
    (
      marketId,
      sportId,
      lang,
      srAlias,
      translations,
      intl,
    ) => getLocalMarketName(marketId, sportId, lang, srAlias, translations, intl),
  );

  // gets extra property, only not null for dynamic marketnames
  extrasSelector = createSelector(
    [
      state => state.extras,
      (state, matchId) => matchId,
      (state, matchId, marketId) => marketId,
    ],
    (extras, matchId, marketId) => (extras ? extras[matchId + '_' + marketId] : null),
  );

    // get hcp, only cares about hcp in form (0:1) not -1.5
    hcpSelector = createSelector(
      [
        (state, matchId, marketId) => (
          state.bigMarket
            ? state.bigMarket[matchId + '_' + marketId]
            : null),
      ],
      market => (
        market
        && market.hcp
        && typeof market.hcp.value === 'string'
        && market.hcp.value.includes(':')
          ? market.hcp.value
          : null),
    );

    // get ahcp, only cares about hcp in form -1.5 or total 2.5
    ahcpSelector = createSelector(
      [
        (state, matchId, marketId) => (
          state.bigMarket
            ? state.bigMarket[matchId + '_' + marketId]
            : null),
      ],
      market => (
        market
        && market.hcp
        && typeof market.hcp.value !== 'string'
          ? market.hcp.value
          : null),
    );

    // get betstop
    betstopSelector = createSelector(
      (state, matchId, marketId) => {
        // given different levels of state
        if (state && state.bigMarket) {
          return state.bigMarket[matchId + '_' + marketId];
        } else if (state && state.markets.bigMarket) {
          return state.markets.bigMarket[matchId + '_' + marketId];
        }
        return null;
      },
      // state => state,
      market => (market ? market.betstop : null),
    );

  // gets oddsTypeShort prop
  marketNameShortSelectorPure = createSelector(
    [
      state => state.marketLookup,
      (state, matchId, marketId) => marketId,
    ],
    (marketLookup, marketId) => (marketLookup[marketId] ? marketLookup[marketId].oddsTypeShort : ''),
  );

  // add dynamic values to marketname
  marketNameShortSelector = createSelector(
    [
      this.marketNameShortSelectorPure,
      this.localMarketNameSelector,
      this.extrasSelector,
      (state, matchId, marketId, curPeriod) => curPeriod,
      this.hcpSelector,
      this.ahcpSelector,
      this.betstopSelector,
    ],
    shortMarketNameOutput,
  );

  // gets oddsType prop
  marketNameSelector = createSelector(
    [
      state => state.marketLookup,
      (state, matchId, marketId) => marketId,
    ],
    (marketLookup, marketId) => (marketLookup[marketId] ? marketLookup[marketId].oddsType : ''),
  );

  // check if market has odds
  outcomesLengthSelector = createSelector(
    this.oddsIdsByMarketSelector,
    ids => ids.length,
  );


  // get team names from matchinfo feed, move into fishnet reducerfile?
  // is run every matchinfo update (but not timelinedelta)
  // will be deep equal checked
  outcomeNamesSelector = createSelector(
    (state, matchId) => (state.matchInfo[matchId] ? state.matchInfo[matchId].teams : null),

    teams => {
      if (!teams) {
        return null;
      }
      const retObj = {};

      if (teams.home.length === 1 && teams.away.length === 1) {
        retObj.abbr = [teams.home[0].abbr, teams.away[0].abbr];
        retObj.name = [teams.home[0].name, teams.away[0].name];
      } else if (teams.home.length === 2 && teams.away.length === 2) {
        retObj.abbr = [
          teams.home[0].abbr + ' / ' + teams.home[1].abbr,
          teams.away[0].abbr + ' / ' + teams.away[1].abbr,
        ];
        retObj.name = [
          doubleShortening(teams.home[0].name) + ' / ' + doubleShortening(teams.home[1].name),
          doubleShortening(teams.away[0].name) + ' / ' + doubleShortening(teams.away[1].name),
        ];
      }

      return retObj;
    },
  );

  feedOutcomesSelector = createDeepEqualSelector(
    [
      this.oddsSelctor,
      () => null,
      (state, matchId, marketId, oddsFormat) => oddsFormat,
      this.betstopSelector,
      (state, matchId, marketId, oddsFormat, keepAllOutcomes) => keepAllOutcomes,
    ],
    // eslint-disable-next-line max-len
    (odds, _, oddsFormat, betstop, keepAllOutcomes) => finalOutcomesOutput(odds, null, oddsFormat, betstop, keepAllOutcomes, this.sportId),
  );

  // do deep equal check to not trigger rerender if e.g. odds didnt change
  outcomesSelector = createDeepEqualSelector(
    [
      this.oddsSelctor,
      this.outcomeNamesSelector,
      (state, matchId, marketId, oddsFormat) => oddsFormat,
      this.betstopSelector,
      (state, matchId, marketId, oddsFormat, keepAllOutcomes) => keepAllOutcomes,
    ],
    // eslint-disable-next-line max-len
    (odds, outcomeNames, oddsFormat, betstop, keepAllOutcomes) => finalOutcomesOutput(odds, outcomeNames, oddsFormat, betstop, keepAllOutcomes, this.sportId),
  );

  // update state
  update(state, matchId, marketId, sportId) {
    this.state = state;
    this.matchId = matchId;
    this.marketId = marketId;
    this.sportId = sportId;
  }

  get outcomes() {
    return this.outcomesSelector(
      this.state.fishnetFeeds,
      this.matchId,
      this.marketId,
      getOddsFormat(this.state),
      this.keepAllOutcomes,
    );
  }

  get feedOutcomes() {
    return this.feedOutcomesSelector(
      this.state.fishnetFeeds,
      this.matchId,
      this.marketId,
      getOddsFormat(this.state),
      this.keepAllOutcomes,
    );
  }

  get outcomeNames() {
    return this.outcomeNamesSelector(
      this.state.fishnetFeeds.matchInfo,
      this.matchId,
    );
  }

  get outcomesLength() {
    return this.outcomesLengthSelector(
      this.state.fishnetFeeds,
      this.matchId,
      this.marketId,
    );
  }

  get marketNameShort() {
    return this.marketNameShortSelector(
      this.state.fishnetFeeds.markets,
      this.matchId,
      this.marketId,
      getMatchCurPeriod(this.state, this.matchId),
      this.sportId,
      this.lang,
      this.srAlias,
      this.translations,
      this.intl,
    );
  }

  get marketName() {
    return this.marketNameSelector(
      this.state.fishnetFeeds.markets,
      this.matchId,
      this.marketId,
    );
  }

  get hcp() {
    return this.hcpSelector(
      this.state.fishnetFeeds.markets,
      this.matchId,
      this.marketId,
    );
  }

  get betstop() {
    return this.betstopSelector(
      this.state.fishnetFeeds,
      this.matchId,
      this.marketId,
    );
  }
}

export const makeMapStateToProps = (initState, initProps) => {
  // projections is created once when component is mounted
  const {
    useFeedOutcomes, keepAllOutcomes, marketIds, maxMarkets = 1,
  } = initProps;
  const lang = getLanguage(initState);
  const bId = getBId(initState);
  const srAlias = getClientAlias(initState);
  let sportId = getMatchSportId(initState, initProps.matchId);
  let marketPackages = getMarketPackages(initState);
  marketPackages = marketPackages.map(mp => mp + '');
  const translations = getTranslations(initState);
  const isTranslationsAvailable = getIsTranslationsAvailable(initState);


  let rank = [];
  let mIds = [];

  // if passed marketIds as prop dont use rank
  if (marketIds && marketIds.length) {
    mIds = [...marketIds];
  } else {
    if (clientRanking && clientRanking[srAlias] && clientRanking[srAlias][sportId]) { // check if custom client ranking for specific sport
      rank = clientRanking[srAlias][sportId];
    } else if (sportId === sport.table_tennis) { // no client is setup for TT
      rank = oddsTypeRanking[sportId] || allOddsTypes;
    } else if (clientRanking && clientRanking[srAlias] && clientRanking[srAlias][sport.all]) { // check if custom client ranking for all sports
      rank = clientRanking[srAlias][sport.all];
    } else {
      rank = oddsTypeRanking[sportId] || allOddsTypes; // use default 0 if no ranking for sport
    }

    // allow all active markets just sort based on rank
    // mIds = removeDuplicatesKeepFirst(rank.concat(allOddsTypes));

    // don't allow other than ranked markets
    mIds = rank;

    // only include markets from market package, keeps ranking order
    if (marketPackages && marketPackages.length) {
      mIds = mIds.filter(id => marketPackages.includes(id));
    }
  }


  const intl = createIntlHelper(lang, translations, isTranslationsAvailable);


  let mpArr = mIds.map(marketId => new MarketProjection(
    initState,
    initProps.matchId,
    marketId,
    sportId,
    lang,
    bId,
    srAlias,
    keepAllOutcomes,
    translations,
    intl,
  ));

  let marketsNameArr = [];
  let marketsNameShortArr = [];
  let marketsOutcomesArr = [];

  // run every state upt
  return (state, props) => {
    // here we update the projection with latest 'state' and 'props', props shouldnt really update
    if (sportId === -1 && !mpArr.length) {
      sportId = getMatchSportId(state, props.matchId);
      mIds = sportId > -1
        ? oddsTypeRanking[sportId] || oddsTypeRanking[0]
        : [];

      mpArr = mIds.map(marketId => new MarketProjection(
        state,
        props.matchId,
        marketId,
        sportId,
        lang,
        bId,
        srAlias,
        keepAllOutcomes,
        translations,
        intl,
      ));
    }

    // new arr created each update
    const usedMarketsIndices = [];
    const betstopIndices = [];
    let odds = 0;

    for (let i = 0; i < mpArr.length; i++) {
      mpArr[i].update(
        state,
        props.matchId,
        mIds[i],
        getMatchSportId(state, props.matchId),
      );

      if (mpArr[i].betstop && mpArr[i].outcomesLength) {
        betstopIndices.push(i);
      } else if (mpArr[i].outcomesLength) {
        usedMarketsIndices.push(i);
        odds++;
      }
      // early bailout
      if (odds === maxMarkets) {
        break;
      }
    }

    // fill remaining slots with betstopped markets
    for (let j = 0; j < betstopIndices.length && usedMarketsIndices.length < maxMarkets; j++) {
      usedMarketsIndices.push(betstopIndices[j]);
    }

    // .outcomes will trigger outcomes selector
    let marketsOutcomesArrTmp;
    if (useFeedOutcomes) {
      marketsOutcomesArrTmp = usedMarketsIndices.map(idx => mpArr[idx].feedOutcomes);
    } else {
      marketsOutcomesArrTmp = usedMarketsIndices.map(idx => mpArr[idx].outcomes);
    }
    const marketsNameArrTmp = usedMarketsIndices.map(idx => mpArr[idx].marketName);
    const marketsNameShortArrTmp = usedMarketsIndices.map(idx => mpArr[idx].marketNameShort);

    // check if elements in array have changed, if so replace the array to trigger props diff
    // unchanged markets will still have same ref
    marketsNameArr = equalEnough(marketsNameArr, marketsNameArrTmp);
    marketsNameShortArr = equalEnough(marketsNameShortArr, marketsNameShortArrTmp);
    marketsOutcomesArr = equalEnough(marketsOutcomesArr, marketsOutcomesArrTmp);

    return {
      // returns the same arrays if nothing changes
      marketsNameArr,
      marketsNameShortArr,
      marketsOutcomesArr,
    };
  };
};
