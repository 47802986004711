import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import Diagram from './diagram/Diagram';

const WinRateDiagrams = ({ stats }) => {
  const [dataHome, setDataHome] = useState(null);
  const [dataAway, setDataAway] = useState(null);

  useEffect(() => {
    if (stats) {
      setDataHome([
        { name: 'won', matchOutcome: stats.home.matchesLost },
        { name: 'won', matchOutcome: stats.home.matchesWon },
      ]);

      setDataAway([
        { name: 'won', matchOutcome: stats.away.matchesLost },
        { name: 'won', matchOutcome: stats.away.matchesWon },
      ]);
    }
  }, [stats]);

  if (!stats) {
    return null;
  }

  return (
    <div className={'flex mb-4 justify-center items-center flex-1'}>
      {dataHome && (
        <Diagram
          percent={Math.round(stats.home.winPct) + '%'}
          matchesData={dataHome}
        />
      )}
      <div>
        <span className={'fs-16 uppercase'}><TranslationFormattedMessage id='t_statistics_win_rate' /></span>
      </div>
      {dataAway && (
        <Diagram
          percent={Math.round(stats.away.winPct) + '%'}
          matchesData={dataAway}
        />
      )}
    </div>
  );
};

WinRateDiagrams.propTypes = {
  stats: PropTypes.object,
};

WinRateDiagrams.defaultProps = {
  stats: {
    home: { matchesWon: 0, matchesLost: 0, winPct: '0' },
    away: { matchesWon: 0, matchesLost: 0, winPct: '0' },
  },
};

export default WinRateDiagrams;
