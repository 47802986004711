import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import Board from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/board/Board';
import PeriodResultsTable from 'newDesign/modules/common/components/oddsPanel/scoreBoard/periodBasedBoard/resultTable/PeriodResultsTable';
import ErrorBoundary from 'utils/ErrorBoundary';
import { isNotStarted } from 'utils/matchstatus';
import {
  getMatchTeams,
  getMatchStatus,
  getReadyMatchId,
  getMatchSportId,
  getScoreBreak,
} from 'reducers';
import { matchStatusIds, sport } from 'constants/enum';

import style from '../ScoreBoard.scss';

const mapStateToProps = state => ({
  teams: getMatchTeams(state, getReadyMatchId(state)),
  matchStatusId: getMatchStatus(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  scoreBreak: getScoreBreak(state),
});

const PeriodBased = ({
  teams,
  matchStatusId,
  sportId,
  scoreBreak,
}) => {
  let noPeriodsScoreClass = '';
  let noPeriodsScoreNotStartedClass = '';

  if ([sport.basketball, sport.ebasketball].includes(sportId)) {
    noPeriodsScoreClass = 'no-periods-score';

    if (isNotStarted(matchStatusId)) {
      noPeriodsScoreNotStartedClass = 'no-periods-score-not-started';
    }
  }

  return (
    <ErrorBoundary fallback={<div className='periodScoreboardError' />}>
      {!teams ? (
        <div className='periodScoreboardError' />
      ) : (
        <div styleName={`scoreboard scoreboard-wrapper scoreboard-table ${noPeriodsScoreClass} ${noPeriodsScoreNotStartedClass}`}>
          <Board />
          {!isNotStarted(matchStatusId) && teams && !scoreBreak && (
            <PeriodResultsTable />
          )}
        </div>
      )}
    </ErrorBoundary>
  );
};

PeriodBased.propTypes = {
  matchStatusId: PropTypes.number,
  teams: PropTypes.object,
  sportId: PropTypes.number.isRequired,
  scoreBreak: PropTypes.bool,
};

PeriodBased.defaultProps = {
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
  matchStatusId: matchStatusIds.not_started,
  scoreBreak: false,
};

const PeriodBasedStyled = cssModule(PeriodBased, style, { allowMultiple: true });
export default connect(mapStateToProps)(PeriodBasedStyled);
