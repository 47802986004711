import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Stat from 'newDesign/modules/break/components/statSlides/liveStats/stat/Stat';
import {
  getMatchId,
  getMatchDetailsStat,
} from 'reducers';

const mapStateToProps = (state, ownProps) => ({
  stat: getMatchDetailsStat(
    state, getMatchId(state), ownProps.statKey,
  ),
});

const StatsWrapper = props => <Stat
  text={props.text}
  percentage={props.percentage}
  stat={props.stat}
  higherIsBetter={props.higherIsBetter}
  relative={props.relative}
  hideOnEmpty={props.hideOnEmpty}
  statKey={props.statKey}
/>;

StatsWrapper.propTypes = {
  higherIsBetter: PropTypes.bool,
  text: PropTypes.string,
  stat: PropTypes.object,
  relative: PropTypes.bool,
  percentage: PropTypes.bool,
  hideOnEmpty: PropTypes.bool,
  statKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

StatsWrapper.defaultProps = {
  stat: null,
  higherIsBetter: true,
  relative: false,
  percentage: false,
  text: '',
  hideOnEmpty: false,
  statKey: '',
};

export default connect(mapStateToProps)(StatsWrapper);
