import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import style from 'newDesign/modules/live/components/footer/Footer.scss';

const NumberBoxStatSlide = ({
  statText, homeStat, awayStat,
}) => (
  <div styleName='container-form container-form--arrow' className='flex my-2 justify-center items-center'>
    {homeStat}
    <span className='fs-16 uppercase'>{statText}</span>
    {awayStat}
  </div>
);

NumberBoxStatSlide.propTypes = {
  statText: PropTypes.string,
  homeStat: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string],
  ),
  awayStat: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string],
  ),
};

NumberBoxStatSlide.defaultProps = {
  statText: '',
  homeStat: null,
  awayStat: null,
};
const NumberBoxStatSlideStyled = cssModule(NumberBoxStatSlide, style, { allowMultiple: true });
export default NumberBoxStatSlideStyled;
