// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CountdownSeparator__prematch__time___EEbYr span:first-child{color:#a9aaaa;font-size:14px}.CountdownSeparator__prematch__time___EEbYr span:last-child{font-size:20px}@media only screen and (min-width: 1536px){.CountdownSeparator__prematch__time___EEbYr span:first-child{font-size:1.1vw}.CountdownSeparator__prematch__time___EEbYr span:last-child{font-size:1.6vw}}", ""]);
// Exports
exports.locals = {
	"prematch__time": "CountdownSeparator__prematch__time___EEbYr"
};
module.exports = exports;
