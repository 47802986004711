import { useState, useEffect } from 'react';

import { defaultClockConfigs, eSoccerMinuteConversions } from 'constants/defaultClockConfigs';
import { sport } from 'constants/enum';
import { MIN } from 'constants/app';
import { useStopwatch } from 'hooks/Timers';

const soccerConfig = defaultClockConfigs[sport.soccer];

const useSoccerClock = (
  statusId,
  pTime,
  sportId,
  periodLength,
  suspended,
) => {
  const [first, setFirst] = useState(true);

  const {
    elapsedTime,
    startTimer,
    stopTimer,
    setSpeedupFactor,
    pauseTimer,
    resumeTimer,
  } = useStopwatch();

  const setClockConfig = clockConfigCurStatus => {
    const timeRunning = clockConfigCurStatus.timerunning;
    const startTime = clockConfigCurStatus.startTime * 60;
    const speedup = (
      sportId === sport.soccer
        ? 1
        : eSoccerMinuteConversions[periodLength || 6][statusId] || 1 // esoccer speedup factor
    );

    const timePlayedSecs = (
      startTime
      + ((Date.now() / 1000) - pTime)
      * speedup
    );

    if (speedup) {
      setSpeedupFactor(speedup); // speedfactor changes from reg to OT in esoccer
    }

    if (pTime === false || timePlayedSecs < 0) {
      stopTimer(0); // not started
    } else if (!timeRunning) {
      stopTimer(startTime);
    } else if (suspended) {
      stopTimer(timePlayedSecs); // stop of clock
    } else if (timeRunning) {
      startTimer(timePlayedSecs);
    }
  };

  useEffect(() => {
    if (
      (statusId != null)
      && (pTime != null)
      && (periodLength != null)
      && (sportId === sport.soccer)
    ) {
      setFirst(false);
      const clockConfigCurStatus = soccerConfig.clockStatusConfigs[statusId];

      if (clockConfigCurStatus) {
        setClockConfig(clockConfigCurStatus);
      }
    }
  }, [
    pauseTimer,
    periodLength,
    setSpeedupFactor,
    sportId,
    startTimer,
    stopTimer,
    resumeTimer,
    suspended,
    statusId,
    pTime,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      const clockConfigCurStatus = soccerConfig.clockStatusConfigs[statusId];
      if (clockConfigCurStatus) {
        setClockConfig(clockConfigCurStatus);
      }
    }, 5 * MIN);

    return () => clearInterval(interval);
  }, []);

  if (first) {
    return '';
  } else if (elapsedTime === '00:00') {
    return '--:--';
  } else {
    return elapsedTime;
  }
};

export default useSoccerClock;
