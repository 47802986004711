import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as config from 'Config/fishnetUrls';
import ImageFallback from 'newDesign/modules/common/utils/ImageFallback';
import crestFallbackImage from 'newDesign/styles/images/icon-crest-default-x2.png';
import flagFallbackImage from 'newDesign/styles/images/default-flag.png';
import { getMatchSportId } from 'reducers/index';
import { PERIOD_BASE_SPORTS } from 'constants/components';

const styleDouble = {
  width: '100%',
};

const styleSingle = {
  width: '100%',
};

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
});


const TeamCrest = ({
  uid, size, double, customStyle, typeClassName, sportId,
}) => {
  const crestUrl = config.fishnetCrestUrl.replace('[size]', size).replace('[UID]', uid);
  // TODO: consider different size options
  // const fallback = config.fishnetCrestUrl.replace('[size]', size).replace('[UID]', 'int');
  let fallbackImage;

  if (PERIOD_BASE_SPORTS.includes(sportId)) {
    fallbackImage = flagFallbackImage;
  } else {
    fallbackImage = crestFallbackImage;
  }


  return <ImageFallback
    fallback={fallbackImage}
    typeClassName={typeClassName}
    src={crestUrl}
    styles={(customStyle) || (double ? styleDouble : styleSingle)}
    alt={'Team Crest'}
  />;
};

TeamCrest.propTypes = {
  uid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  size: PropTypes.string,
  double: PropTypes.bool,
  customStyle: PropTypes.object,
  typeClassName: PropTypes.string.isRequired,
  sportId: PropTypes.number.isRequired,
};

TeamCrest.defaultProps = {
  size: 'big',
  customStyle: null,
  double: false,
};

export default connect(mapStateToProps)(TeamCrest);
