// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FooterCrawler__lframe-crawler___IYqdT{max-height:4vw;color:#fff;gap:1.5vw}.FooterCrawler__lframe-crawler___IYqdT .FooterCrawler__textMarquee___33-cs{gap:1.5vw}.FooterCrawler__lframe-crawler___IYqdT .FooterCrawler__textMarquee___33-cs>div{gap:.4vw}.FooterCrawler__lframe-crawler___IYqdT svg{height:.7vw}.FooterCrawler__lframe-crawler___IYqdT .FooterCrawler__position___2Aco1{outline:1px solid;border-radius:3px;padding:0 .2vw}.FooterCrawler__lframe-crawler___IYqdT .FooterCrawler__active___372r2{color:#ddf51b}.FooterCrawler__lframe-crawler___IYqdT::after,.FooterCrawler__lframe-crawler___IYqdT::before{background:linear-gradient(270deg, #212121 0%, rgba(33, 33, 33, 0) 100%);height:100%;content:\"\";width:2vw;position:absolute;z-index:1}.FooterCrawler__lframe-crawler___IYqdT::after{right:0}.FooterCrawler__lframe-crawler___IYqdT::before{left:-1px;transform:rotate(180deg)}.FooterCrawler__textMarquee___33-cs{white-space:nowrap;position:absolute;width:100%;height:100%;margin:0;text-align:center}", ""]);
// Exports
exports.locals = {
	"lframe-crawler": "FooterCrawler__lframe-crawler___IYqdT",
	"textMarquee": "FooterCrawler__textMarquee___33-cs",
	"position": "FooterCrawler__position___2Aco1",
	"active": "FooterCrawler__active___372r2"
};
module.exports = exports;
