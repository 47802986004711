import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import { getMatchResult, getReadyMatchId } from 'reducers';
import TennisFlags from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/sports/tennisManager/components/tennisFlags/TennisFlags';
import Score from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/sports/tennisManager/components/score/Score';

import style from 'newDesign/modules/break/components/statSlides/liveStats/LiveStats.scss';

const mapStateToProps = state => ({
  result: getMatchResult(state, getReadyMatchId(state)),
});

const TennisSingle = ({ competitors }) => {
  const homeCompetitorName = competitors.home[0].name;
  const awayCompetitorName = competitors.away[0].name;

  return (
    <div styleName='halftimestats__content__header' className='flex w-full'>
      <div className='flex flex-col items-center h-full'>
        <div styleName='tennis_flags' className='flex justify-center items-center'>
          <TennisFlags teams={competitors.home} />
        </div>
        <span
          styleName='halftimestats-competitior'
          className='fs-20 font-main--bold uppercase text-center'
        >{homeCompetitorName}
        </span>
      </div>
      <Score />
      <div className='flex flex-col items-center h-full'>
        <div styleName='tennis_flags' className='flex justify-center items-center'>
          <TennisFlags teams={competitors.away} />
        </div>
        <span
          styleName='halftimestats-competitior'
          className='fs-20 font-main--bold uppercase text-center'
        >{awayCompetitorName}
        </span>
      </div>
    </div>
  );
};

TennisSingle.propTypes = {
  competitors: PropTypes.object,
};

TennisSingle.defaultProps = {
  competitors: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};

const TennisSingleStyled = cssModule(TennisSingle, style);
export default connect(mapStateToProps)(TennisSingleStyled);
