import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash';

import MatchTeam from 'newDesign/modules/announcement/components/matchTeam/MatchTeam';
import MatchTimeInfoCmp from 'newDesign/modules/announcement/components/matchTimeInfo/MatchTimeInfo';
import {
  getMatchSportId,
  getMatchTeams,
} from 'reducers';

import { secondTableTennisCompetitorCheck } from 'utils/tableTennisHelpers';
import MatchTeamCrest from '../matchTeamCrest/MatchTeamCrest';

const mapStateToProps = (state, ownProps) => ({
  sportId: getMatchSportId(state, ownProps.matchId),
  teams: getMatchTeams(state, ownProps.matchId),
});

const MatchTeams = ({ matchId, teams, sportId }) => {
  if (_isEmpty(teams)) {
    return null;
  }

  /*
     In case of doubles, we need to check if the second competitor is missing
     and add a dummy competitor with a dash as a name and 'xyz' as a country code.
     This is needed to keep the layout consistent.
  */
  const copiedTeams = secondTableTennisCompetitorCheck(teams, sportId);

  return (
    <div className='matchTeams flex justify-center items-center my-1.5'>
      <MatchTeamCrest teams={copiedTeams.home} matchId={matchId} />
      <div className='flex flex-1 items-stretch'>
        <MatchTeam matchId={matchId} teams={copiedTeams.home} />
        <MatchTimeInfoCmp matchId={matchId} />
        <MatchTeam matchId={matchId} teams={copiedTeams.away} />
      </div>
      <MatchTeamCrest teams={copiedTeams.away} matchId={matchId} />
    </div>
  );
};

MatchTeams.propTypes = {
  matchId: PropTypes.number.isRequired,
  teams: PropTypes.shape(),
  sportId: PropTypes.number.isRequired,
};

MatchTeams.defaultProps = {
  teams: {},
};

export default connect(mapStateToProps)(MatchTeams);
