import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import useDateCountdown from 'hooks/Countdown';
import { getMatchStartTime } from 'reducers';
import { SEC } from 'constants/app';

import style from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/countdownSeparator/CountdownSeparator.scss';

const mapStateToProps = (state, ownProps) => ({
  time: getMatchStartTime(state, ownProps.matchId),
});

const CountdownSeparator = ({ time }) => {
  const timeTo = useDateCountdown(time);

  return (
    <div styleName='prematch__time' className='flex gap-y-4 flex-col h-full font-main justify-center items-center'>
      <div className='flex flex-col items-center gap-y-1'>
        <span className='uppercase color-prematch-2'><TranslationFormattedMessage id='t_highlights_kickoff' /></span>
        <span className='lowercase'>
          {new Date(time * SEC)
            .toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
        </span>
      </div>
      <div className='flex flex-col items-center gap-y-1'>
        <span className='uppercase color-prematch-2'><TranslationFormattedMessage id='t_highlights_starting_in' /></span>
        <span className='lowercase'>{timeTo}</span>
      </div>
    </div>
  );
};

CountdownSeparator.propTypes = {
  time: PropTypes.number,
};

CountdownSeparator.defaultProps = {
  time: null,
};

const CountdownSeparatorStyled = cssModule(CountdownSeparator, style);
export default connect(mapStateToProps)(CountdownSeparatorStyled);
