import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import {
  getMatchSportId, getReadyMatchId,
} from 'reducers';
import ErrorBoundary from 'utils/ErrorBoundary';
import LoadingSpinner from 'newDesign/modules/common/components/loadingSpinner/LoadingSpinner';

import style from 'newDesign/modules/common/components/layout/fullscreenLayout/FullscreenLayout.scss';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});

const FullscreenLayout = ({
  main,
}) => (
  <ErrorBoundary fallback={<LoadingSpinner />}>
    {main}
  </ErrorBoundary>
);

FullscreenLayout.propTypes = {
  main: PropTypes.node.isRequired,
};

const FullscreenLayoutStyled = cssModule(FullscreenLayout, style);
export default connect(mapStateToProps)(FullscreenLayoutStyled);
