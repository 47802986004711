import { useEffect, useState } from 'react';
import { eventStatus, eventTypeId as eId } from 'constants/enum';

const timelineTypes = [
  eId.match_started,
  eId.period_start, // halftime and periods in hockey
  eId.yellow_card,
  eId.yellow_red_card,
  eId.red_card,
  eId.player_substitution,
  eId.match_ended,
  eId.goal,
  eId.penalty_shootout_event,
  eId.penalty_missed,
  eId.suspension,
];

function compare(a, b) {
  if (a.time < b.time) {
    return -1;
  }
  if (a.time > b.time) {
    return 1;
  }
  return 0;
}

const useFilterEvents = (events, periodLength) => {
  const [filterEvents, setFilterEvents] = useState([]);

  useEffect(() => {
    if (events) {
      const ev = Object.values(events)
        .reduce((acc, e) => {
          const typeId = parseInt(e._typeid, 10);

          // skip these
          if (
            !(
              timelineTypes.includes(typeId) // events in arr
              && !(typeId === eId.period_start && e.period <= 20) // 1 = start of 1st period, 2= start 2nd, 10 = overtime, 20=Pen
              && !(
                typeId === eId.goal
                && (
                  e.goaltypeid === eventStatus.goalTypeId.soccer_penalties
                  || e.goaltypeid === eventStatus.goalTypeId.hockey_shootout
                )
              ) // remove "pen type" goals
              && !(
                typeId === eId.penalty_shootout_event
                && !(e.status === eventStatus.penalty.goal || e.status === eventStatus.penalty.miss) // filter out status = "not taken yet"
              )
            )
          ) {
            return acc;
          }

          let { time } = e;

          if (typeId === eId.match_started) {
            time = 0;
          } else if (typeId === eId.period_start) {
            time = (e.period - 30) * periodLength; // 31 = 1st period, 32 2nd period
          } else if (typeId === eId.match_ended) {
            time = 999;
          } else if (typeId === eId.goal && e.time === -1 && acc[acc.length - 1]) {
            time = acc[acc.length - 1].time; // no time data, use previous events time
          } else if (typeId === eId.penalty_shootout_event && acc[acc.length - 1]) {
            time = acc[acc.length - 1].time + 1; // no time data, use previous events time
          }

          const eventObj = {
            ...e,
            time,
            _typeid: typeId,
          };

          return acc.concat([eventObj]);
        }, [])
        .sort(compare);

      for (let i = 0; i < ev.length; i++) {
        /*
          add line and spacing between two "block event" with no events between
          example: match start -> halftime
        */
        if (
          (
            ev[i]._typeid === eId.match_started && ev[i + 1]
            && ev[i + 1]._typeid === eId.period_start
          )
          || (
            ev[i]._typeid === eId.period_start && ev[i + 1]
            && ev[i + 1]._typeid === eId.match_ended
          )
          || (
            ev[i]._typeid === eId.period_start && ev[i + 1]
            && ev[i + 1]._typeid === eId.period_start
          )
        ) {
          ev.splice(i + 1, 0, { id: 'spacing' + i }); // add to arr
        }
      }

      setFilterEvents(ev);
    }
    return () => { };
  }, [events, periodLength]);

  return filterEvents;
};

export default useFilterEvents;
