import React from 'react';

import { eventTypeId } from 'constants/enum';
import StatWrapper from 'newDesign/modules/break/components/statSlides/liveStats/statWrapper/StatWrapper';

const Basketball = () => [
  <StatWrapper
    key={1}
    statKey={eventTypeId.free_throws}
    percentage
  />,
  <StatWrapper
    key={2}
    statKey={eventTypeId.three_pointers}
    relative
  />,
  <StatWrapper
    key={3}
    statKey={eventTypeId.two_pointers}
    relative
  />,
  <StatWrapper
    key={4}
    statKey={eventTypeId.rebounds}
    relative
  />,
  <StatWrapper
    key={5}
    statKey={eventTypeId.biggest_lead}
    relative
  />,
  <StatWrapper
    key={6}
    statKey={eventTypeId.tennis_max_points_in_a_row}
    relative
  />,
];

export default Basketball;
