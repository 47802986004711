import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { isEmpty as _isEmpty } from 'lodash';
import {
  getMatchJerseys,
  getMatchSportId,
  getMatchTeams,
  getReadyMatchId,
} from 'reducers';
import { sport } from 'constants/enum';
import Board from './board/Board';

import style from '../ScoreBoard.scss';
import CompetitorAvatar from './competitorAvatar/CompetitorAvatar';
import Basketball3x3Board from './board/Basketball3x3Board';

const mapStateToProps = state => ({
  jerseys: getMatchJerseys(state, getReadyMatchId(state)),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  teams: getMatchTeams(state, getReadyMatchId(state)),
});

const ScoreBased = ({
  jerseys,
  teams,
  sportId,
}) => {
  const intl = useIntl();

  let homeName = intl.formatMessage({ id: 't_scoreboard_home' });
  let awayName = intl.formatMessage({ id: 't_scoreboard_away' });

  if (!_isEmpty(teams)) {
    homeName = teams.home[0].abbr;
    awayName = teams.away[0].abbr;
  }

  return (
    <div styleName='scoreboard' className='flex box-border w-full'>
      <CompetitorAvatar sportId={sportId} jersey={jerseys.home.player} teamName={homeName} />
      {sportId === sport.basketball3x3 ? <Basketball3x3Board /> : <Board />}
      <CompetitorAvatar sportId={sportId} jersey={jerseys.away.player} teamName={awayName} />
    </div>
  );
};

ScoreBased.propTypes = {
  sportId: PropTypes.number.isRequired,
  jerseys: PropTypes.object,
  teams: PropTypes.shape({
    home: PropTypes.array,
    away: PropTypes.array,
  }),
};

ScoreBased.defaultProps = {
  jerseys: {
    away: {
      player: {},
    },
    home: {
      player: {},
    },
  },
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
};

const ScoreBasedStyled = cssModule(ScoreBased, style);
export default connect(mapStateToProps)(ScoreBasedStyled);
