import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import {
  getMatchGameScore,
  getMatchTeams,
  getMatchTennisDouble,
  getReadyMatchId,
} from 'reducers';

import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const mapStateToProps = state => ({
  teams: getMatchTeams(state, getReadyMatchId(state)),
  gameScore: getMatchGameScore(state, getReadyMatchId(state)),
  tennisDouble: getMatchTennisDouble(state, getReadyMatchId(state)),
});

const TableRowName = ({
  teams, tennisDouble, side, gameScore,
}) => {
  const { service } = gameScore;

  let homeTeam;
  let awayTeam;
  let serveIndicator = null;

  if (tennisDouble) {
    homeTeam = tennisDouble.home;
    awayTeam = tennisDouble.away;
  } else if (teams) {
    homeTeam = teams.home;
    awayTeam = teams.away;
  }

  if ((service === '1' && side === 'home') || (service === '2' && side === 'away')) {
    serveIndicator = (<div styleName='table__row__serve-circle' className=' flex' />);
  }

  const team = side === 'home' ? homeTeam : awayTeam;

  return (
    <div className='flex flex-1 items-center'>
      <h3 className='fs-18 font-second--bold whitespace-nowrap'>
        {team[0].abbr ? team[0].abbr : team[0].name}
      </h3>
      {serveIndicator}
    </div>
  );
};

TableRowName.propTypes = {
  teams: PropTypes.object,
  tennisDouble: PropTypes.object,
  side: PropTypes.string.isRequired,
  gameScore: PropTypes.object,
};

TableRowName.defaultProps = {
  teams: {
    home: [{ name: '', abbr: '' }],
    away: [{ name: '', abbr: '' }],
  },
  tennisDouble: null,
  gameScore: {
    home: '-',
    away: '-',
  },
};

const TableRowNameStyled = cssModule(TableRowName, style);
export default connect(mapStateToProps)(TableRowNameStyled);
