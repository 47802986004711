import React from 'react';

import cssModule from 'react-css-modules';
import style from '../../ScoreBoard.scss';


const Basketball3x3Board = () => (
  <div styleName='scoreboard__text' className='flex-col flex-2 flex justify-center items-center'>
    <div styleName='scoreboard-score' className='flex'>
      <span className='fs-40 font-second--bold'>-</span>
    </div>
    <div className='scoreboard-time flex'>
      <span className='fs-24 font-second--bold'>&nbsp;</span>
    </div>
  </div>
);

const Basketball3x3BoardStyled = cssModule(Basketball3x3Board, style);
export default Basketball3x3BoardStyled;
