import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty as _isEmpty } from 'lodash';

import {
  getMatchPrevMeetings,
  getMatchSportId,
  getReadyMatchId,
} from 'reducers/index';

import PreviousMeeting from '../previousMeeting/PreviousMeeting';
import PreviousMeetingsHeader from './previousMeetingsHeader/PreviousMeetingsHeader';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
  sportId: getMatchSportId(state, getReadyMatchId(state)),
  previousMeetings: getMatchPrevMeetings(state, getReadyMatchId(state)),
});

const PreviousMeetingsList = ({ matchId, previousMeetings, sportId }) => {
  if (_isEmpty(previousMeetings) || _isEmpty(previousMeetings.matches)) {
    return null;
  }

  const previousMatches = previousMeetings.matches;
  const { tournaments } = previousMeetings;

  const previousMeetingList = previousMatches.slice(0, 5).map(match => (
    <PreviousMeeting
      key={match._id}
      tournaments={tournaments[match._tid]}
      result={match.result}
      teams={match.teams}
      date={match.time.date}
      sportId={sportId}
    />
  ));

  return (
    <React.Fragment>
      <PreviousMeetingsHeader
        matchId={matchId}
        previousMatches={previousMatches}
      />
      {previousMeetingList}
    </React.Fragment>
  );
};

PreviousMeetingsList.propTypes = {
  matchId: PropTypes.number.isRequired,
  sportId: PropTypes.number.isRequired,
  previousMeetings: PropTypes.object,
};

PreviousMeetingsList.defaultProps = {
  previousMeetings: null,
};

export default connect(mapStateToProps)(PreviousMeetingsList);
