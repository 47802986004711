import style from 'styles/widgets/Logger.scss';
import React from 'react';
import { connect } from 'react-redux';
import cssModule from 'react-css-modules';
import PropTypes from 'prop-types';
import {
  getActiveMessages,
} from 'reducers';
import LoggerMessageCmp from 'views/widgets/LoggerMessage';
import { DISPLAY_ERROR, DISPLAY_INFO } from 'constants/bcms';

const mapStateToProps = state => ({
  messages: getActiveMessages(state),
});

class Logger extends React.PureComponent {
  render() {
    return (
      <div styleName='logger'>
        {this.props.messages.map(msg => (
          (msg.active && (msg.type === DISPLAY_ERROR || msg.type === DISPLAY_INFO)
          )
            ? (
              <LoggerMessageCmp
                key={msg.id}
                title={msg.title}
                message={msg.message}
                type={msg.type}
              />
            )
            : null))}
      </div>
    );
  }
}

Logger.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape()),
};

Logger.defaultProps = {
  messages: [],
};

export default connect(mapStateToProps)(cssModule(Logger, style));
