import React from 'react';
import PropTypes from 'prop-types';

const TimelineText = ({ text, secondaryText, team }) => (
  <div className={`flex flex-col ${team === 'home' ? 'items-end' : 'items-start'}`}>
    <h3 className={`font-main--medium fs-16 uppercase ${team === 'home' ? 'text-right' : ''}`}>{text}</h3>
    {secondaryText && <span className={'font-main--light fs-16 uppercase'}>{secondaryText}</span> }
  </div>
);

TimelineText.propTypes = {
  text: PropTypes.string,
  secondaryText: PropTypes.string,
  team: PropTypes.string,
};

TimelineText.defaultProps = {
  text: '',
  secondaryText: '',
  team: '',
};

export default TimelineText;
