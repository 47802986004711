// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TimelineHeader__timeline_header___2oCCg{margin-top:1.3vw;padding:.3vw 0}.TimelineHeader__timeline_header___2oCCg>div:first-child{-ms-flex-pack:end;justify-content:flex-end;padding:0 1.5vw;box-sizing:border-box}.TimelineHeader__timeline_header___2oCCg>div:first-child h3{text-align:right;padding-right:1vw}.TimelineHeader__timeline_header___2oCCg>div:first-child h3+div{-ms-flex-pack:end;justify-content:flex-end}.TimelineHeader__timeline_header___2oCCg>div:last-child{-ms-flex-direction:row-reverse;flex-direction:row-reverse;-ms-flex-pack:end;justify-content:flex-end;padding:0 1.5vw;box-sizing:border-box}.TimelineHeader__timeline_header___2oCCg>div:last-child h3{padding-left:1vw}.TimelineHeader__timeline_header___2oCCg>div:last-child h3+div{-ms-flex-pack:start;justify-content:flex-start}.TimelineHeader__timeline_header___2oCCg .TimelineHeader__halftimestats_jersey___1QV9L{height:4vw;filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))}.TimelineHeader__timeline_header___2oCCg .TimelineHeader__halftimestats_flag___38dgM{height:2vw;filter:drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))}.TimelineHeader__timeline_header___2oCCg h3+div{gap:1vw}.TimelineHeader__timeline_header_bg___3Pqzb{background:linear-gradient(178deg, rgba(185, 185, 185, 0.28) 3.14%, rgba(92, 92, 92, 0) 123.85%);box-shadow:0px 8px 30px 0px rgba(0,0,0,.5);-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}", ""]);
// Exports
exports.locals = {
	"timeline_header": "TimelineHeader__timeline_header___2oCCg",
	"halftimestats_jersey": "TimelineHeader__halftimestats_jersey___1QV9L",
	"halftimestats_flag": "TimelineHeader__halftimestats_flag___38dgM",
	"timeline_header_bg": "TimelineHeader__timeline_header_bg___3Pqzb"
};
module.exports = exports;
