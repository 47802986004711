import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AnnouncementStage from 'newDesign/modules/announcement/stage/AnnouncementStage';
import HighlightOfTheDayStage from 'newDesign/modules/preMatch/stage/HighlightOfTheDayStage';
import ChannelTrailerCmp from 'views/widgets/ChannelTrailer';
import { getStageConfig } from 'reducers';
import { STAGE_ANNOUNCEMENT_LOOP } from 'constants/bcms';

const mapStateToProps = state => ({
  stageConfig: getStageConfig(state, STAGE_ANNOUNCEMENT_LOOP),
});

const AnnouncementLoopStage = ({ stageConfig }) => {
  const slides = [
    <ChannelTrailerCmp key={1} />,
    <AnnouncementStage key={2} />,
    <HighlightOfTheDayStage key={3} />,
  ];
  const currentSlide = slides[stageConfig.currentSlideIdx];
  return (
    <Fragment>
      {currentSlide}
    </Fragment>
  );
};

AnnouncementLoopStage.propTypes = {
  stageConfig: PropTypes.shape(),
};

AnnouncementLoopStage.defaultProps = {
  stageConfig: null,
};

export default connect(mapStateToProps)(AnnouncementLoopStage);
