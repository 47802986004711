import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { matchStatusIds } from 'constants/enum';
import { useCountdown } from 'hooks/Timers';

const useFutsalClock = (
  statusId,
  remaining,
  running,
) => {
  const intl = useIntl();

  const [first, setFirst] = useState(true);

  const {
    elapsedTime,
    setTimer,
    pauseTimer,
    resumeTimer,
  } = useCountdown();


  useEffect(() => {
    if (remaining) {
      setFirst(false);
      const rem = parseInt(remaining, 10);
      setTimer(rem);
    }
  }, [remaining, setTimer]);

  useEffect(() => {
    if (!running) {
      pauseTimer();
    } else {
      resumeTimer();
    }
  }, [pauseTimer, resumeTimer, running]);


  if (first) {
    return '--:--';
  } else if (statusId === matchStatusIds.pause) {
    return intl.formatMessage({ id: 't_scoreboard_brk' });
  } else if (elapsedTime === '00:00') {
    return '--:--';
  } else {
    return elapsedTime;
  }
};

export default useFutsalClock;
