import React from 'react';
import cssModule from 'react-css-modules';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';

import style from 'newDesign/modules/preMatch/components/preMatchOpener/preMatchCompetitors/defaultSeparator/DefaultSeparator.scss';

const DefaultSeparator = () => (
  <span styleName='prematch__vs' className={'font-main--thin opacity-50 h-full flex items-center uppercase'}>
    <TranslationFormattedMessage id='t_general_vs' />
  </span>
);

const DefaultSeparatorStyled = cssModule(DefaultSeparator, style);
export default DefaultSeparatorStyled;
