import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isNull as _isNull } from 'lodash';

import { getLastMatch, getMatchSportId } from 'reducers/index';
import { sport } from 'constants/enum';

import styles from 'newDesign/modules/common/components/ticker/styles/MatchTickerStyles.scss';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getLastMatch(state)),
});

const TennisGameScore = ({ sportId, gamescore }) => {
  if (sportId === sport.tennis && !_isNull(gamescore)) {
    return (
      <div className={`${styles.table__row__score__highlighted} flex h-full items-center justify-center`}>
        <span className={'font-main--medium fs-16'}>{gamescore}</span>
      </div>
    );
  }
  return '';
};

TennisGameScore.propTypes = {
  sportId: PropTypes.number.isRequired,
  gamescore: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(TennisGameScore);
