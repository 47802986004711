import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { get as _get } from 'lodash';
import { useIntl } from 'react-intl';

import { seasonType } from 'constants/enum';
import { connect } from 'react-redux';
import { useImportImageNewDesign } from 'hooks/index';
import {
  getMatch,
  getMatchSportId,
  getMatchTournament,
  getRealCategoryName,
  getTournamentFlag,
  getTournamentName,
} from 'reducers/index';
import fallbackDefaultFlag from 'newDesign/styles/images/default-flag.png';
import style from './PreMachHeaderMatchInformation.scss';
import Stadium from './Stadium/Stadium';

const mapStateToProps = (state, ownProps) => ({
  tournamentName: getTournamentName(state, ownProps.matchId),
  realCategoryName: getRealCategoryName(state, ownProps.matchId),
  tournamentFlag: getTournamentFlag(state, ownProps.matchId),
  tournament: getMatchTournament(state, ownProps.matchId),
  match: getMatch(state, ownProps.matchId),
  sportId: getMatchSportId(state, ownProps.matchId),
});

const PreMatchHeaderMatchInformation = ({
  matchId,
  displayStadium,
  tournamentName,
  realCategoryName,
  tournamentFlag,
  tournament,
  match,
  sportId,
}) => {
  const intl = useIntl();
  const sportIcon = useImportImageNewDesign(`sport-icons-common/sport-icon-${sportId}.svg`);

  if (!tournament || !match) {
    return null;
  }

  let tournamentInformation = realCategoryName;

  if (
    tournament.seasontype
    && tournament.seasontype === seasonType.group_stage.toString()
  ) {
    // @TODO:substring search seasontypename?
    const matchDayTranslated = intl.formatMessage({ id: 't_general_match_day' });
    const matchDay = (match.round ? (` | ${matchDayTranslated} ${match.round}`) : '');
    tournamentInformation = tournament.seasontypename + matchDay;
  } else if (
    tournament.seasontype
    && (tournament.seasontype === seasonType.cup.toString()
      || tournament.seasontype === seasonType.qualification.toString())
  ) {
    const roundName = _get(match, 'roundname.name', '');
    tournamentInformation = realCategoryName;

    if (roundName) {
      tournamentInformation += ` | ${roundName}`;
    }
  }

  // announcement screen uses international, change it here for now
  const tournamentFlagSource = tournamentFlag === 'international'
    ? fallbackDefaultFlag
    : `https://img.sportradar.com/ls/crest/medium/${tournamentFlag}.png`;

  return (
    <div
      styleName='prematch__meta-header'
      className={'preMatchMetaHeader z-10 gap-y-2 flex justify-center items-center flex-col mt-12'}
    >
      <div className={'flex items-center w-full py-2 justify-center header-decoration'}>
        <div className={'mr-2 icon-circle'}>
          <img className={'h-full'} src={sportIcon} alt={''} />
        </div>
        <span className={'uppercase text-xl font-second'}>{tournamentName}</span>
      </div>
      <div className={'flex items-center gap-4'}>
        <img
          styleName='flag'
          src={tournamentFlagSource}
          alt={'flag'}
        />
        <span className={'uppercase font-second'}>{tournamentInformation}</span>
      </div>
      <Stadium matchId={matchId} displayStadium={displayStadium} />
    </div>
  );
};

PreMatchHeaderMatchInformation.propTypes = {
  matchId: PropTypes.number.isRequired,
  displayStadium: PropTypes.bool,
  tournamentName: PropTypes.string,
  realCategoryName: PropTypes.string,
  tournamentFlag: PropTypes.string,
  tournament: PropTypes.object,
  match: PropTypes.object,
  sportId: PropTypes.number,
};
PreMatchHeaderMatchInformation.defaultProps = {
  displayStadium: false,
  tournamentName: '',
  realCategoryName: '',
  tournamentFlag: '',
  tournament: {},
  match: {},
  sportId: 0,
};

const PreMatchHeaderMatchInformationStyled = cssModule(PreMatchHeaderMatchInformation, style);
export default connect(mapStateToProps)(PreMatchHeaderMatchInformationStyled);
