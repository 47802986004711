import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import TeamCrest from 'newDesign/modules/announcement/components/teamCrest/TeamCrest';
import { isNotStarted } from 'utils/matchstatus';

import { matchStatusIds } from 'constants/enum';
import { getReadyMatchId } from 'reducers/index';
import style from '../../../ScoreBoard.scss';

const mapStateToProps = state => ({
  matchId: getReadyMatchId(state),
});

const TeamWithFlagOrCrest = ({
  matchStatusId,
  team,
  flags,
  side,
  matchId,
}) => {
  const isDouble = flags.length > 1;
  const flagsStyle = {
    height: 'auto',
    padding: '4px',
    WebkitFilter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.15))',
    filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.15))',
    boxSizing: 'content-box',
  };

  let classNameFlagsPositioning = 'flex-col justify-start items-start';
  let styleNamePositioning = 'single-name-positioning';

  flagsStyle.width = '4vw';
  let notStartedMatchSecondFlagAlignment = '';

  if (isDouble) {
    flagsStyle.width = '2.3vw';

    if (isNotStarted(matchStatusId)) {
      classNameFlagsPositioning = 'justify-center items-center';
      styleNamePositioning = 'double-name-positioning';
      notStartedMatchSecondFlagAlignment = 'not-started-alignment';
    }
  }

  return (
    <div className={`flex flex-col flex-1 ${side === 'left' ? 'justify-start items-start' : 'justify-end items-end'} overflow-hidden whitespace-nowrap`}>
      <div className={`${classNameFlagsPositioning} ${isDouble ? 'double-flags-positioning' : ''} flex flex-1 overflow-hidden whitespace-nowrap gap-1 justify-center`}>
        {
            flags.map((countryCodeA2, i) => (
              <TeamCrest
                key={countryCodeA2 + i}
                uid={countryCodeA2}
                customStyle={flagsStyle}
                typeClassName={`flag-crest ${i > 0 ? `second-flag ${notStartedMatchSecondFlagAlignment}` : 'first-flag'}`}
                matchId={matchId}
              />
            ))
          }
      </div>

      {isNotStarted(matchStatusId) && (
        <div styleName={styleNamePositioning} className='flex flex-col max-w-full w-full text-center'>
          <h2 className='fs-18 font-second--bold max-w-full text-ellipsis overflow-hidden'>
            {team[0].abbr ? team[0].abbr : team[0].name}
          </h2>
        </div>
      )}
    </div>
  );
};

TeamWithFlagOrCrest.propTypes = {
  matchStatusId: PropTypes.number,
  team: PropTypes.array.isRequired,
  flags: PropTypes.array,
  side: PropTypes.string.isRequired,
  matchId: PropTypes.number.isRequired,
};

TeamWithFlagOrCrest.defaultProps = {
  matchStatusId: matchStatusIds.not_started,
  flags: [],
};

const TeamWithFlagOrCrestStyled = cssModule(TeamWithFlagOrCrest, style, { allowMultiple: true });
export default connect(mapStateToProps)(TeamWithFlagOrCrestStyled);
