import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import TranslationFormattedMessage from 'utils/TranslationFormattedMessage';
import style from 'newDesign/modules/preMatch/components/statSlides/lastMatches/match/Match.scss';
import { getMatchSportId, getReadyMatchId } from 'reducers/index';
import { sport, translationPeriodKey } from 'constants/enum';
import TeamNames from '../../common/components/teamNames/TeamNames';
import Period from '../../common/components/period/Period';

const mapStateToProps = state => ({
  sportId: getMatchSportId(state, getReadyMatchId(state)),
});


const Match = ({
  result, teams, teamId, sportId,
}) => {
  if (!result || !teams || !teamId) {
    return null;
  }

  const home = teams.home.uid === teamId;
  const homeActive = home ? 'active' : '';
  const awayActive = !home ? 'active' : '';
  const homeWin = result.home > result.away ? 'active' : '';
  const awayWin = result.home < result.away ? 'active' : '';

  const period = (['ot', 'ap', 'aet'].includes(result.period)) && (
    <TranslationFormattedMessage id={translationPeriodKey[result.period]} />
  );

  const displayPeriod = ![sport.tennis, sport.volleyball].includes(sportId);
  let margin;
  let periodComponent;
  if (displayPeriod) {
    margin = '';
    periodComponent = <Period period={period} />;
  } else {
    margin = 'mx-4';
    periodComponent = null;
  }

  return (
    <Fragment>
      <div className={`prematch__teams flex flex-1 flex-col gap-x-4 min-w-[18vw] ${margin}`}>
        <TeamNames competitorName={teams.home.name} activeClass={homeActive} />
        <div styleName='prematch-separator' />
        <TeamNames competitorName={teams.away.name} activeClass={awayActive} />
      </div>
      {periodComponent}
      <div styleName='prematch__scores' className='prematch__scores flex flex-col font-second fs-17 justify-center'>
        <div className='flex flex-col'>
          <div styleName={homeWin} className=' flex justify-center items-center'>
            <span>{result.home}</span>
          </div>
          <div styleName={awayWin} className='flex justify-center items-center'>
            <span>{result.away}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Match.propTypes = {
  result: PropTypes.object,
  teams: PropTypes.object,
  teamId: PropTypes.number,
  sportId: PropTypes.number.isRequired,
};

Match.defaultProps = {
  result: null,
  teams: null,
  teamId: null,
};

const MatchStyled = cssModule(Match, style, { allowMultiple: true });
export default connect(mapStateToProps)(MatchStyled);
