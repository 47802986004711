import { sport } from 'constants/enum';


/**
 * For normal sports we need to display name
 * For eSoccer we need to display mediumname
 * - eSoccer competitor looks like - TEAM NAME (PLAYER NAME)
 * - we need to display player name in brackets
 * @param sportId
 * @return {string}
 */
// eslint-disable-next-line import/prefer-default-export
export const getTeamNameProperty = sportId => {
  let teamNameProperty = 'name';

  if (sportId === sport.esoccer) {
    teamNameProperty = 'mediumname';
  }

  return teamNameProperty;
};
