import React from 'react';
import { useImportImageNewDesign } from 'hooks';

import cssModule from 'react-css-modules';
import style from './FooterBasketballThreeOnThree.scss';

const FooterBasketballThreeOnThree = () => {
  const footerImage = useImportImageNewDesign('ice-25-main-bg.png');
  const footerSlide1 = useImportImageNewDesign('ice-25-slide-1.png');
  const footerSlide2 = useImportImageNewDesign('ice-25-slide-2.png');
  const footerSlide3 = useImportImageNewDesign('ice-25-slide-3.png');
  const footerSlide4 = useImportImageNewDesign('ice-25-slide-4.png');

  return (
    <div styleName='slider-container' className='flex max-w-full flex-1 flex-col'>
      <img src={footerImage} alt={'ICE-2025 Product Demo'} />
      <div styleName='slider'>
        <div styleName='slide'>
          <img src={footerSlide1} alt='Slide 1' />
        </div>
        <div styleName='slide'>
          <img src={footerSlide2} alt='Slide 2' />
        </div>
        <div styleName='slide'>
          <img src={footerSlide3} alt='Slide 3' />
        </div>
        <div styleName='slide'>
          <img src={footerSlide4} alt='Slide 4' />
        </div>
      </div>
    </div>
  );
};

const FooterBasketballThreeOnThreeStyled = cssModule(FooterBasketballThreeOnThree, style);
export default FooterBasketballThreeOnThreeStyled;
