import React from 'react';
import PropTypes from 'prop-types';

import style from 'newDesign/modules/announcement/components/matchTeam/MatchTeam.scss';

const MatchTeamTennisDouble = ({ teams }) => (
  <div className={`${style.team_row} flex flex-1 items-stretch flex-col`}>
    <div className={`${style.team} ${style.team__doubles} flex-col flex flex-1`}>
      <h2 className={`${style.team_name} flex-nowrap font-bold fs-18 uppercase`}>
        <span>{teams[0].name}</span>
      </h2>
    </div>
    <div className={`${style.team} ${style.team__doubles} flex-col flex flex-1`}>
      <h2 className={`${style.team_name} flex-nowrap font-bold fs-18 uppercase`}>
        <span>{teams[1].name}</span>
      </h2>
    </div>
  </div>

);

MatchTeamTennisDouble.propTypes = {
  teams: PropTypes.object.isRequired,
};

export default MatchTeamTennisDouble;
