import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { useIntl } from 'react-intl';

import style from 'newDesign/modules/common/components/oddsPanel/scoreBoard/ScoreBoard.scss';

const GameScore = ({ score }) => {
  const intl = useIntl();

  let scoreText = '-';

  if (score == null) {
    // noop
  } else if (score === 50) {
    scoreText = intl.formatMessage({ id: 't_scoreboard_ad' });
  } else if (score >= 0) {
    scoreText = score;
  }

  return (
    <div styleName='table__row__score--highlighted' className='flex h-full items-center justify-center'>
      <span className='font-main--medium fs-16'>{scoreText}</span>
    </div>
  );
};

GameScore.propTypes = {
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

GameScore.defaultProps = {
  score: '-',
};

const GameScoreColStyled = cssModule(GameScore, style);
export default GameScoreColStyled;
