import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';

import OddsChangeIndicator from 'newDesign/modules/common/components/oddsChangeIndicator/OddsChangeIndicator';

import style from 'newDesign/modules/common/components/oddsPanel/oddsPanelOutcome/OddsPanelOutcome.scss';

const OddsPanelOutcome = ({ outcome }) => {
  const { decOdds } = outcome;
  const [prevOdds, setPrevOdds] = useState(decOdds);
  const [newOdds, setNewOdds] = useState(decOdds);

  useEffect(() => {
    let timer;
    setNewOdds(decOdds);
    timer = setTimeout(() => {
      setPrevOdds(decOdds);
      timer = null;
    }, 5000);

    return () => {
      if (timer) {
        clearTimeout(timer);
        // Required when it is quickly swapped back to prev value
        setPrevOdds(decOdds);
      }
    };
  }, [decOdds]);


  let changeIndicatorDirection = '';

  if (newOdds !== decOdds) {
    // do nothing, so the indicators reset
    // this happens when useEffect has not run yet
  } else if (prevOdds > newOdds) {
    changeIndicatorDirection = 'down';
  } else if (prevOdds < newOdds) {
    changeIndicatorDirection = 'up';
  }

  return (
    <div className='flex justify-center items-start'>
      <div className='flex flex-col justify-center items-center'>
        <div className='flex relative'>
          <OddsChangeIndicator
            showIndicator={!!(changeIndicatorDirection && !outcome.betstop)}
            changeIndicatorDirection={changeIndicatorDirection}
          />
          <span>{outcome.odds}</span>
        </div>

        <div styleName='odd-description' className='flex'>
          <span className='font-second fs-17 uppercase'>{outcome.abbr || outcome.name}</span>
        </div>
      </div>

    </div>
  );
};

OddsPanelOutcome.propTypes = {
  outcome: PropTypes.object,
};

OddsPanelOutcome.defaultProps = {
  outcome: null,
};
const OddsPanelOutcomeStyled = cssModule(OddsPanelOutcome, style, { allowMultiple: true });
export default OddsPanelOutcomeStyled;
