// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Crawler__lframe_topcrawler___2lqo8{color:#fff;z-index:200;height:3.8vh;transition:all .5s ease-in;width:100%;overflow:hidden}.Crawler__lframe_topcrawler___2lqo8>div{padding:.2vw 0;box-sizing:border-box}.Crawler__lframe_topcrawler___2lqo8::after,.Crawler__lframe_topcrawler___2lqo8::before{background:linear-gradient(270deg, #212121 0%, rgba(33, 33, 33, 0) 100%);height:100%;content:\"\";width:2vw;position:absolute;z-index:130;top:0}.Crawler__lframe_topcrawler___2lqo8::after{right:0}.Crawler__lframe_topcrawler___2lqo8::before{left:-1px;transform:rotate(180deg)}.Crawler__textMarquee___2L_jx{white-space:nowrap;position:absolute;overflow:visible;width:100%;height:100%;margin:0 2px;text-align:center;opacity:0;z-index:125;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;animation:Crawler__scrollText___3i_EQ 20s linear 20}@keyframes Crawler__scrollText___3i_EQ{0%{transform:translateX(100%)}5%{opacity:0}15%{opacity:1}80%{opacity:1}95%{opacity:0}100%{transform:translateX(-100%)}}", ""]);
// Exports
exports.locals = {
	"lframe_topcrawler": "Crawler__lframe_topcrawler___2lqo8",
	"textMarquee": "Crawler__textMarquee___2L_jx",
	"scrollText": "Crawler__scrollText___3i_EQ"
};
module.exports = exports;
