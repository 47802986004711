import { useState, useEffect } from 'react';
import { matchStatusIds } from 'constants/enum';
import { useStopwatch } from 'hooks/Timers';
import { convertToTimeString } from 'utils';

const useHandballClock = (
  statusId,
  periodLength,
  overtimeLength,
  played,
  remaining,
  running,

) => {
  const [first, setFirst] = useState(true);

  const {
    elapsedTime,
    resumeTimer,
    pauseTimer,
    setTimer,
  } = useStopwatch();

  useEffect(() => {
    if (played) {
      setFirst(false);
      const playedPar = parseInt(played, 10);
      setTimer(playedPar);
    }
  }, [played, setTimer]);

  useEffect(() => {
    if (!running) {
      pauseTimer();
    } else {
      resumeTimer();
    }
  }, [pauseTimer, resumeTimer, running]);


  if (first) {
    return '--:--';
  } else if (statusId === matchStatusIds.ended) {
    return '60:00';
  } else if (statusId === matchStatusIds.halftime) {
    return '30:00';
  } else if (statusId === matchStatusIds.AP_handball) {
    return convertToTimeString((periodLength * 2 + overtimeLength * 2) * 60);
  } else if (elapsedTime === '00:00') {
    return '--:--';
  } else {
    return elapsedTime;
  }
};

export default useHandballClock;
