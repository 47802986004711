// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OddsMarketOutcome__triangle_down___2kCot{width:0;height:0;border-left:8px solid rgba(0,0,0,0);border-right:8px solid rgba(0,0,0,0);border-top:10px solid #c8102e;font-size:0;line-height:0}.OddsMarketOutcome__triangle_up___9gffI{width:0;height:0;border-left:8px solid rgba(0,0,0,0);border-right:8px solid rgba(0,0,0,0);border-bottom:10px solid green;font-size:0;line-height:0}", ""]);
// Exports
exports.locals = {
	"triangle_down": "OddsMarketOutcome__triangle_down___2kCot",
	"triangle_up": "OddsMarketOutcome__triangle_up___9gffI"
};
module.exports = exports;
