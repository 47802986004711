// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DisplaySolutionIcon__display-solution-icon___1oTn0 svg{height:100%;margin-top:-1px}", ""]);
// Exports
exports.locals = {
	"display-solution-icon": "DisplaySolutionIcon__display-solution-icon___1oTn0"
};
module.exports = exports;
