import React from 'react';
import PropTypes from 'prop-types';

import Jersey from 'newDesign/modules/common/components/jersey/Jersey';
import PlayerIcon from 'newDesign/modules/break/components/statSlides/liveStats/liveStatsHeader/competitorAvatar/PlayerIcon';
import { sport } from 'constants/enum';

const CompetitorAvatar = ({ sportId, jersey }) => {
  if (sportId === sport.esoccer) {
    return <PlayerIcon />;
  }

  return <Jersey jersey={jersey} jerseySize='jersey_halftime_stats' sportId={sportId} />;
};

CompetitorAvatar.propTypes = {
  sportId: PropTypes.number.isRequired,
  jersey: PropTypes.object.isRequired,
};

export default CompetitorAvatar;
