import React from 'react';
import PropTypes from 'prop-types';

import { sport } from 'constants/enum';
import PlayerIcon from 'newDesign/modules/common/components/oddsPanel/scoreBoard/scoreBasedBoard/competitorAvatar/PlayerIcon';
import TeamJersey from 'newDesign/modules/common/components/oddsPanel/scoreBoard/scoreBasedBoard/competitorAvatar/TeamJersey';

const CompetitorAvatar = ({ sportId, jersey, teamName }) => {
  if (sportId === sport.esoccer) {
    return <PlayerIcon teamName={teamName} />;
  }

  return <TeamJersey jersey={jersey} teamName={teamName} sportId={sportId} />;
};

CompetitorAvatar.propTypes = {
  sportId: PropTypes.number.isRequired,
  jersey: PropTypes.object.isRequired,
  teamName: PropTypes.string.isRequired,
};

export default CompetitorAvatar;
