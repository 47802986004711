// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OddsManager__iframeBox___eqoi_{height:100%;width:420px;margin:0 auto;border:0;overflow:hidden}.OddsManager__iframeContainer___8QISt{height:100%;max-height:770px}", ""]);
// Exports
exports.locals = {
	"iframeBox": "OddsManager__iframeBox___eqoi_",
	"iframeContainer": "OddsManager__iframeContainer___8QISt"
};
module.exports = exports;
