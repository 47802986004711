import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getMatchId,
  getMatchHomeAttStrength,
  getMatchAwayAttStrength,
} from 'reducers';
import Stat from 'newDesign/modules/break/components/statSlides/liveStats/stat/Stat';

const mapStateToProps = state => ({
  homeStat: getMatchHomeAttStrength(state, getMatchId(state)),
  awayStat: getMatchAwayAttStrength(state, getMatchId(state)),
});

const AttStrength = ({ homeStat, awayStat }) => {
  let stat;
  if (!(homeStat == null && awayStat == null)) {
    stat = { home: homeStat, away: awayStat };
  }

  return (
    <Stat
      stat={stat}
      percentage
      text='t_statistics_attacking_strength'
    />
  );
};

AttStrength.propTypes = {
  homeStat: PropTypes.number,
  awayStat: PropTypes.number,

};

AttStrength.defaultProps = {
  homeStat: null,
  awayStat: null,
};

export default connect(mapStateToProps)(AttStrength);
