// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SportIcon__icon-container___3wflx{background-size:contain;background-repeat:no-repeat;background-position:center center;max-width:26px;min-width:26px}.SportIcon__icon-circle___AFC6C{background:hsla(0,0%,100%,.3);border-radius:50%;padding:4px;box-sizing:border-box;height:34px}", ""]);
// Exports
exports.locals = {
	"icon-container": "SportIcon__icon-container___3wflx",
	"icon-circle": "SportIcon__icon-circle___AFC6C"
};
module.exports = exports;
