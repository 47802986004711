import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMatchId,
  getTeamCorners,
} from 'reducers';
import NumberStatSlide from 'newDesign/modules/live/components/footer/footerBottom/numberSlideContainers/NumberStatSlide';

const mapStateToProps = state => ({
  homeStat: getTeamCorners(state, getMatchId(state), 'home'),
  awayStat: getTeamCorners(state, getMatchId(state), 'away'),
});

const CornerKicks = ({
  homeStat, awayStat,
}) => {
  const intl = useIntl();
  return (
    <NumberStatSlide
      statText={intl.formatMessage({ id: 't_footer_corner_kicks' })}
      homeStat={homeStat}
      awayStat={awayStat}
      higherIsBetter
    />
  );
};
CornerKicks.propTypes = {
  homeStat: PropTypes.number,
  awayStat: PropTypes.number,
};

CornerKicks.defaultProps = {
  homeStat: null,
  awayStat: null,
};

export default connect(mapStateToProps)(CornerKicks);
