import React from 'react';
import PropTypes from 'prop-types';
import cssModule from 'react-css-modules';
import { connect } from 'react-redux';

import {
  getNextMatchDisplaySolution,
} from 'reducers';
import { DISPLAY_SOLUTION_LMT } from 'constants/bcms';

import style from 'newDesign/modules/announcement/components/displaySolutionIcon/DisplaySolutionIcon.scss';

const mapStateToProps = (state, ownProps) => ({
  displaySolution: getNextMatchDisplaySolution(state, ownProps.matchId),
});

const DisplaySolutionIcon = ({ displaySolution }) => {
  let displayIconSvgPath;

  if (displaySolution === DISPLAY_SOLUTION_LMT) {
    displayIconSvgPath = <path d='M1.5,11.5c-.276,0-.5-.224-.5-.5V5.536c0-.827,.673-1.5,1.5-1.5h3.5c.652,.003,.655,.997,0,1,0,0-3.5,0-3.5,0-.275,0-.5,.225-.5,.5v5.464c0,.276-.224,.5-.5,.5ZM15,3.536V12.536c.012,.398-.487,.65-.8,.4,0,0-4-3-4-3-.126-.095-.2-.243-.2-.4v-3c0-.157,.074-.306,.2-.4l4-3c.313-.248,.812,0,.8,.4Zm-1,1l-3,2.25v2.5l3,2.25V4.536Zm-4.218-2.043c.228,.156,.286,.467,.131,.695l-.915,1.34c.007-.001-.002,6.006,.002,6.008,0,.827-.673,1.5-1.5,1.5H3.872l-1.558,2.282c-.36,.54-1.199-.033-.826-.564,0,0,7.6-11.13,7.6-11.13,.157-.229,.468-.285,.695-.131Zm-1.782,3.497l-3.445,5.046h2.945c.275,0,.5-.225,.5-.5V5.99Z' fill='currentColor' />;
  } else {
    displayIconSvgPath = <path d='M7.5,4.036H2.5c-.827,0-1.5,.673-1.5,1.5v5c0,.827,.673,1.5,1.5,1.5H7.5c.827,0,1.5-.673,1.5-1.5V5.536c0-.827-.673-1.5-1.5-1.5Zm.5,6.5c0,.275-.225,.5-.5,.5H2.5c-.275,0-.5-.225-.5-.5V5.536c0-.275,.225-.5,.5-.5H7.5c.275,0,.5,.225,.5,.5v5ZM3.998,6.036l3.002,2.034-3.002,1.966V6.036ZM14.724,3.089c-.169-.085-.373-.065-.523,.047l-4,3c-.126,.095-.2,.243-.2,.4v3c0,.157,.074,.306,.2,.4l4,3c.151,.113,.355,.131,.523,.047,.169-.085,.276-.258,.276-.447V3.536c0-.189-.107-.362-.276-.447Zm-.724,8.447l-3-2.25v-2.5l3-2.25v7Z' fill='currentColor' />;
  }

  return (
    <div styleName='display-solution-icon' className='flex py-0.5'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' style={{ display: 'block' }}>
        {displayIconSvgPath}
      </svg>
    </div>
  );
};

DisplaySolutionIcon.propTypes = {
  displaySolution: PropTypes.number.isRequired,
};

const DisplaySolutionIconStyled = cssModule(DisplaySolutionIcon, style);
export default connect(mapStateToProps)(DisplaySolutionIconStyled);
