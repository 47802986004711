// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#lcr .TopBottomLayout__TopBottomLayoutGrid___9IDX1{display:-ms-flexbox;display:flex;-ms-flex:1;flex:1}#lcr .TopBottomLayout__TBLMainCol___3pKkh{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex:1;flex:1;position:relative;max-height:100vh}#lcr .TopBottomLayout__TBLMainTop___28cIX{position:relative;display:-ms-flexbox;display:flex;-ms-flex:1;flex:1;width:100%;overflow:hidden}#lcr .TopBottomLayout__TBLMainBottom___2G-bo{width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-direction:column;flex-direction:column;-ms-flex-negative:0;flex-shrink:0;overflow:hidden;position:relative}", ""]);
// Exports
exports.locals = {
	"TopBottomLayoutGrid": "TopBottomLayout__TopBottomLayoutGrid___9IDX1",
	"TBLMainCol": "TopBottomLayout__TBLMainCol___3pKkh",
	"TBLMainTop": "TopBottomLayout__TBLMainTop___28cIX",
	"TBLMainBottom": "TopBottomLayout__TBLMainBottom___2G-bo"
};
module.exports = exports;
